import http from '../http-common';
import {
  IAttributsData,
  AttributStateType,
  AttributsStateType
} from '../types/attributs.type';

class AttributsDataService {
  create(data: IAttributsData) {
    return http.post<IAttributsData>('/attributs', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/attributs/${id}`, data);
  }

  findByIdentifier(params: any) {
    return http.get<AttributStateType>('/attributs/identifier', { params });
  }

  findAllByIdentifierName(params: any) {
    return http.get<AttributsStateType>('/attributs/id_or_name', { params });
  }

  findAllByUser(id_user: any) {
    return http.get<AttributsStateType>(`/attributs/user/${id_user}`);
  }

  getAll() {
    return http.get<AttributsStateType>('/attributs');
  }
}

export default new AttributsDataService();
