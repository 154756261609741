import * as React from 'react';
import { ButtonGroup, Button, Slider } from '@mui/material';
import { Modal, Button as ReactButton } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import FactionDataService from '../../services/faction.service';
import FactionsShopsDataService from '../../services/factionsshops.service';
import ItemsDataService from '../../services/items.service';
import { FactionStateType, factionEmpty } from '../../types/faction.type';
import { ItemsStateType, IItemData } from '../../types/items.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const FactionMarketplace = () => {
  const { userSession, userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [showPower, setShowPower] = React.useState(true);
  const [showBonus, setShowBonus] = React.useState(false);
  const handleShowPower = () => {
    setShowPower(true);
    setShowBonus(false);
  };
  const handleShowBonus = () => {
    setShowPower(false);
    setShowBonus(true);
  };

  const maxItems = 50;

  const [selectedItem, setSelectedItem] = React.useState({
    divStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties,
    rarity: '',
    attack: false,
    defense: false,
    id_item: 0,
    item_type: 0,
    item_identifier: '',
    item_img: '',
    item_name_1: '',
    item_name_2: '',
    item_label: '',
    item_value: '',
    item_qty: 0
  });

  const [transactionItem, setTransactionItem] =
    React.useState<IItemData | null>(null);

  const [flgItemCreation, setFlgItemCreation] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [message, setMessage] = React.useState<string>();
  const [messageSuccess, setMessageSuccess] = React.useState<string>();
  const [itemQty, setItemQty] = React.useState(0);
  const [itemCreate, setItemCreate] = React.useState(0);

  const [errorBalance, setErrorBalance] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = (item: IItemData) => {
    setShowConfirmModal(true);
    setTransactionItem(item);

    const divStyle = {
      '--rarityColor': item.item_rarityColor
    } as React.CSSProperties;
    const rarity = item.item_rarity;

    let attack = false;
    let defense = false;
    switch (item.flg_use) {
      case 1:
        attack = true;
        break;
      case 2:
        defense = true;
        break;
      default:
        attack = true;
        defense = true;
        break;
    }

    const id_item = item.id_item;
    const item_type = item.item_type;
    const item_identifier = item.item_identifier;
    const item_img = item.item_img;

    const nameT = item.item_name.split(' ');

    let item_name_1 = '';
    let item_name_2 = '';
    switch (nameT.length) {
      case 1:
        item_name_1 = nameT[0];
        break;
      case 2:
        item_name_1 = nameT[0];
        item_name_2 = nameT[1];
        break;
      default:
        item_name_1 = nameT[0] + ' ' + nameT[1];
        item_name_2 = nameT[2];
        break;
    }

    let item_label = '';
    let item_value = '';
    if (item.item_type == 2) {
      item_label = 'Power';
      item_value = item.item_power.toFixed(2);
    }
    if (item.item_type == 4) {
      if (item.bonus_value > 0) {
        item_label = 'Faction Power';
        item_value = '+' + item.bonus_value + '%';
      } else {
        item_label = 'Enemy Power';
        item_value = item.bonus_value + '%';
      }
    }

    const item_qty = item.item_quantity;

    setSelectedItem({
      divStyle: divStyle,
      rarity: rarity,
      attack: attack,
      defense: defense,
      id_item: id_item,
      item_type: item_type,
      item_identifier: item_identifier,
      item_img: item_img,
      item_name_1: item_name_1,
      item_name_2: item_name_2,
      item_label: item_label,
      item_value: item_value,
      item_qty: item_qty
    });
  };

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setFlgItemCreation(false);
    setItemQty(0);
    setItemCreate(0);
    fetchFactionsData();
  };

  const [factionImg, setFactionImg] = React.useState('');
  const [userLeader, setUserLeader] = React.useState(false);
  const [factionInfos, setFactionInfos] = React.useState<FactionStateType>({
    faction: factionEmpty,
    message: '',
    status: ''
  });
  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });

  const fetchUserInfos = () => {
    if (
      userInfos.user.id_user == userInfos.user.faction?.id_user ||
      userInfos.user.flg_leader
    ) {
      setUserLeader(true);
    } else {
      setUserLeader(false);
    }
  };

  const fetchFactionsData = () => {
    FactionDataService.findOneById(userInfos.user.id_faction).then(
      ({ data }) => {
        if (data.status == 'success') {
          setFactionInfos({
            faction: data.faction,
            status: data.status
          });
        }
      }
    );
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const handleChangeQuantity = (e: any, selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity = e.target.value;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  const handleIncrementQuantity = (selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity += 1;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  const handleDecrementQuantity = (selItem: IItemData) => {
    const itemsData = { ...itemsInfos };
    itemsData.items.map((item) => {
      if (item.id_item == selItem.id_item) {
        item.item_quantity -= 1;
      }
    });
    setItemsInfos({
      items: itemsData.items,
      status: itemsData.status
    });
  };

  const handleBuyItem = () => {
    if (transactionItem != null) {
      setShowConfirmModal(false);
      setShowModal(true);
      txCreation(transactionItem);
    }
  };

  const txCreation = (item: IItemData) => {
    setIsProcessing(true);

    const dataShop: any = {
      id_user: userSession.userID,
      id_item: item.id_item,
      quantity: item.item_quantity
    };

    FactionsShopsDataService.createEvent(dataShop).then(({ data }) => {
      if (data.status == 'success') {
        setMessageSuccess(data.message);
      } else {
        setMessage(data.message);
      }
      setIsProcessing(false);
    });
  };

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchUserInfos();
      fetchFactionsData();
      fetchFactionInfos();
      fetchItemsData();
    }
  }, []);

  const { faction } = factionInfos;
  const { items } = itemsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestBox'>
                <div className='pageTitle'>Faction Marketplace</div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightRank'>
                  <div className='stripInfos'>
                    <div className='stripInfosIn'>
                      <div className='stripInfosText'>{faction.name}</div>
                    </div>
                  </div>
                </div>
                <div className='stripBoxRightBtn'>
                  <div className='imgBoxFaction'>
                    <img src={`${factionImg}`} className='imgFactionSmall' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>{faction.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxRightBtn'>
                    <div className='imgBoxFaction'>
                      <img src={`${factionImg}`} className='imgFactionSmall' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Rise of Factions Balance</div>
                  <div className='infosBalance'>
                    {tokensListNames.map((token, i) => {
                      if (faction.token_identifier == token.token_identifier) {
                        const balanceValue = faction.amount;

                        const balance = balanceValue.toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        return (
                          <span key={i}>
                            {balance} {token.name}
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className='infosBoxSub'>
                  <div className='buttonsNFTContainer'>
                    <ReactButton
                      className='btnStandardReverse'
                      onClick={() => navigate(routeNames.marketplace)}
                    >
                      Marketplace
                    </ReactButton>
                    <ReactButton
                      className='btnStandard'
                      onClick={() => navigate(routeNames.factioninventory)}
                    >
                      Faction Inventory
                    </ReactButton>
                    <ReactButton
                      className='btnStandard'
                      onClick={() =>
                        navigate(routeNames.factionmarketplacehistory)
                      }
                    >
                      Faction Market History
                    </ReactButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body text-center p-2'>
              {showPower ? (
                <React.Fragment>
                  <ReactButton
                    className='navStandardSmallReverse'
                    onClick={handleShowPower}
                  >
                    Power
                  </ReactButton>
                  <ReactButton
                    className='navStandardSmall'
                    onClick={handleShowBonus}
                  >
                    Bonus
                  </ReactButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ReactButton
                    className='navStandardSmall'
                    onClick={handleShowPower}
                  >
                    Power
                  </ReactButton>
                  <ReactButton
                    className='navStandardSmallReverse'
                    onClick={handleShowBonus}
                  >
                    Bonus
                  </ReactButton>
                </React.Fragment>
              )}
            </div>
          </div>

          {!userLeader && (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusWarning'>
                  Only faction leaders can make faction purchases.
                </div>
              </div>
            </div>
          )}

          {showPower && (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='itemBlock'>
                  {items.map((item, i) => {
                    const divStyle = {
                      '--rarityColor': item.item_rarityColor
                    } as React.CSSProperties;

                    let token_name = '';
                    let balanceValue = 0;

                    tokensListNames.map((token) => {
                      if (item.token_identifier == token.token_identifier) {
                        token_name = token.name;
                      }

                      if (faction.token_identifier == token.token_identifier) {
                        balanceValue = faction.amount;
                      }
                    });

                    let attack = false;
                    let defense = false;
                    switch (item.flg_use) {
                      case 1:
                        attack = true;
                        break;
                      case 2:
                        defense = true;
                        break;
                      default:
                        attack = true;
                        defense = true;
                        break;
                    }

                    const NFTscoreRounded = item.item_power.toFixed(2);

                    const nameT = item.item_name.split(' ');

                    let item_name_1;
                    let item_name_2;
                    switch (nameT.length) {
                      case 1:
                        item_name_1 = nameT[0];
                        break;
                      case 2:
                        item_name_1 = nameT[0];
                        item_name_2 = nameT[1];
                        break;
                      default:
                        item_name_1 = nameT[0] + ' ' + nameT[1];
                        item_name_2 = nameT[2];
                        break;
                    }

                    const price = item.item_cost * item.item_quantity;
                    const itemCost = price.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    });

                    let buyDisabled = false;
                    if (price > balanceValue) {
                      buyDisabled = true;
                    }

                    let minusDisabled = true;
                    if (item.item_quantity > 1) minusDisabled = false;
                    let plusDisabled = false;
                    if (item.item_quantity >= maxItems) plusDisabled = true;

                    let available = false;
                    if (item.item_type == 2) {
                      available = true;
                    }

                    if (available) {
                      return (
                        <div
                          key={i}
                          style={divStyle}
                          className='cardItemBlockShop'
                        >
                          <div className='cardItemContainer'>
                            <div className='cardItemBox'>
                              <div className='cardItemBoxIn'>
                                <div className='cardItemInfos'>
                                  <div className='cardItemHierarchy'>
                                    {item.item_rarity}
                                  </div>
                                  <div className='cardItemPower'>
                                    Power : {NFTscoreRounded}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='cardItemPictureBox'>
                              <img
                                src={`../${item.item_img}`}
                                className='cardItemPicture'
                              />
                            </div>
                            <div className='cardItemInfosBis'>
                              {!item_name_2 ? (
                                <div className='cardItemTitle'>
                                  {item_name_1}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div className='cardItemNumber'>
                                    {item_name_1}
                                  </div>
                                  <div className='cardItemName'>
                                    {item_name_2}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                            <div className='cardItemUsage'>
                              {attack && (
                                <IconContext.Provider
                                  value={{ className: 'cardUsageAttack' }}
                                >
                                  <GiCrossedSwords />
                                </IconContext.Provider>
                              )}
                              {defense && (
                                <IconContext.Provider
                                  value={{ className: 'cardUsageDef' }}
                                >
                                  <GiBorderedShield />
                                </IconContext.Provider>
                              )}
                            </div>
                          </div>
                          <div className='contentShopActions'>
                            <div className='contentShopBox'>
                              <div className='textImportant'>Quantity</div>
                              <ButtonGroup
                                size='small'
                                className='contentShopBoxButtons'
                              >
                                {minusDisabled ? (
                                  <Button className='btnDisabled' disabled>
                                    -
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleDecrementQuantity(item)
                                    }
                                  >
                                    -
                                  </Button>
                                )}
                                <Button disabled>{item.item_quantity}</Button>
                                {plusDisabled ? (
                                  <Button className='btnDisabled' disabled>
                                    +
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleIncrementQuantity(item)
                                    }
                                  >
                                    +
                                  </Button>
                                )}
                              </ButtonGroup>
                            </div>
                            <div>
                              <Slider
                                size='small'
                                value={item.item_quantity}
                                aria-label='Small'
                                min={1}
                                max={maxItems}
                                onChange={(e) => handleChangeQuantity(e, item)}
                              />
                            </div>
                            <div className='contentShopBox'>
                              <div className='textImportant'>Price</div>
                              <div className='textImportant'>
                                {itemCost} {token_name}
                              </div>
                            </div>
                            {userLeader && (
                              <React.Fragment>
                                {!buyDisabled ? (
                                  <ReactButton
                                    className='actionStandardSmall'
                                    onClick={() => handleShowConfirmModal(item)}
                                  >
                                    Buy
                                  </ReactButton>
                                ) : (
                                  <ReactButton className='actionStandardSmallDisable'>
                                    Buy
                                  </ReactButton>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}

          {showBonus && (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='itemBlock'>
                  {items.map((item, i) => {
                    const divStyle = {
                      '--rarityColor': item.item_rarityColor
                    } as React.CSSProperties;

                    let token_name = '';
                    let balanceValue = 0;

                    tokensListNames.map((token) => {
                      if (item.token_identifier == token.token_identifier) {
                        token_name = token.name;
                      }

                      if (faction.token_identifier == token.token_identifier) {
                        balanceValue = faction.amount;
                      }
                    });

                    const price = item.item_cost * item.item_quantity;
                    const itemCost = price.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    });

                    let buyDisabled = false;
                    if (price > balanceValue) {
                      buyDisabled = true;
                    }

                    let minusDisabled = true;
                    if (item.item_quantity > 1) minusDisabled = false;
                    let plusDisabled = false;
                    if (item.item_quantity >= maxItems) plusDisabled = true;

                    let available = false;
                    if (item.item_type == 4) {
                      available = true;
                    }

                    let bonusLabel = '';
                    let bonusValue = '';
                    if (item.bonus_value > 0) {
                      bonusLabel = 'Faction Power';
                      bonusValue = '+' + item.bonus_value + '%';
                    } else {
                      bonusLabel = 'Enemy Power';
                      bonusValue = item.bonus_value + '%';
                    }

                    const nameT = item.item_name.split(' ');

                    let item_name_1;
                    let item_name_2;
                    switch (nameT.length) {
                      case 1:
                        item_name_1 = nameT[0];
                        break;
                      case 2:
                        item_name_1 = nameT[0];
                        item_name_2 = nameT[1];
                        break;
                      default:
                        item_name_1 = nameT[0] + ' ' + nameT[1];
                        item_name_2 = nameT[2];
                        break;
                    }

                    if (available) {
                      return (
                        <div
                          key={i}
                          style={divStyle}
                          className='cardItemBlockShop'
                        >
                          <div className='cardItemContainer'>
                            <div className='cardItemBox'>
                              <div className='cardItemBoxIn'>
                                <div className='cardItemInfos'>
                                  <div className='cardItemHierarchy'>
                                    {item.item_rarity}
                                  </div>
                                  <div className='cardItemPower'>
                                    {bonusLabel} : {bonusValue}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='cardItemPictureBox'>
                              <img
                                src={`../${item.item_img}`}
                                className='cardItemPicture'
                              />
                            </div>
                            <div className='cardItemInfosBis'>
                              {!item_name_2 ? (
                                <div className='cardItemTitle'>
                                  {item_name_1}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div className='cardItemNumber'>
                                    {item_name_1}
                                  </div>
                                  <div className='cardItemName'>
                                    {item_name_2}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          <div className='contentShopActions'>
                            <div className='contentShopBox'>
                              <div className='textImportant'>Quantity</div>
                              <ButtonGroup
                                size='small'
                                className='contentShopBoxButtons'
                              >
                                {minusDisabled ? (
                                  <Button className='btnDisabled' disabled>
                                    -
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleDecrementQuantity(item)
                                    }
                                  >
                                    -
                                  </Button>
                                )}
                                <Button disabled>{item.item_quantity}</Button>
                                {plusDisabled ? (
                                  <Button className='btnDisabled' disabled>
                                    +
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleIncrementQuantity(item)
                                    }
                                  >
                                    +
                                  </Button>
                                )}
                              </ButtonGroup>
                            </div>
                            <div>
                              <Slider
                                size='small'
                                value={item.item_quantity}
                                aria-label='Small'
                                min={1}
                                max={maxItems}
                                onChange={(e) => handleChangeQuantity(e, item)}
                              />
                            </div>
                            <div className='contentShopBox'>
                              <div className='textImportant'>Price</div>
                              <div className='textImportant'>
                                {itemCost} {token_name}
                              </div>
                            </div>
                            {userLeader && (
                              <React.Fragment>
                                {!buyDisabled ? (
                                  <ReactButton
                                    className='actionStandardSmall'
                                    onClick={() => handleShowConfirmModal(item)}
                                  >
                                    Buy
                                  </ReactButton>
                                ) : (
                                  <ReactButton className='actionStandardSmallDisable'>
                                    Buy
                                  </ReactButton>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showConfirmModal}
        onHide={handleCloseConfirmModal}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Purchase</div>
          </div>
          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='itemBlock'>
                <div style={selectedItem.divStyle} className='cardItemBlock'>
                  <div className='cardItemContainer'>
                    <div className='cardItemBox'>
                      <div className='cardItemBoxIn'>
                        <div className='cardItemInfos'>
                          <div className='cardItemHierarchy'>
                            {selectedItem.rarity}
                          </div>
                          <div className='cardItemPower'>
                            {selectedItem.item_label} :&nbsp;
                            {selectedItem.item_value}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='cardItemPictureBox'>
                      <div className='overlayNumber'>
                        <span className='overlayNumberText'>x</span>
                        <span className='overlayNumberTextNumber'>
                          {selectedItem.item_qty}
                        </span>
                      </div>
                      <img
                        src={`../${selectedItem.item_img}`}
                        className='cardItemPicture'
                      />
                    </div>
                    <div className='cardItemInfosBis'>
                      {selectedItem.item_name_2 == '' ? (
                        <div className='cardItemTitle'>
                          {selectedItem.item_name_1}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className='cardItemNumber'>
                            {selectedItem.item_name_1}
                          </div>
                          <div className='cardItemName'>
                            {selectedItem.item_name_2}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {selectedItem.item_type == 2 && (
                      <div className='cardItemUsage'>
                        {selectedItem.attack && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageAttack' }}
                          >
                            <GiCrossedSwords />
                          </IconContext.Provider>
                        )}
                        {selectedItem.defense && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageDef' }}
                          >
                            <GiBorderedShield />
                          </IconContext.Provider>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {errorBalance && (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusAlert'>
                  Not enough balance to make the purchase !
                </div>
              </div>
            </div>
          )}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <ReactButton
                  className='btnStandardSmallReverse'
                  onClick={handleBuyItem}
                >
                  Validate
                </ReactButton>
                <ReactButton
                  className='btnStandardSmall'
                  onClick={handleCloseConfirmModal}
                >
                  Close
                </ReactButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Purchase</div>
          </div>
          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='itemBlock'>
                <div style={selectedItem.divStyle} className='cardItemBlock'>
                  <div className='cardItemContainer'>
                    <div className='cardItemBox'>
                      <div className='cardItemBoxIn'>
                        <div className='cardItemInfos'>
                          <div className='cardItemHierarchy'>
                            {selectedItem.rarity}
                          </div>
                          <div className='cardItemPower'>
                            {selectedItem.item_label} :&nbsp;
                            {selectedItem.item_value}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='cardItemPictureBox'>
                      <div className='overlayNumber'>
                        <span className='overlayNumberText'>x</span>
                        <span className='overlayNumberTextNumber'>
                          {selectedItem.item_qty}
                        </span>
                      </div>
                      <img
                        src={`../${selectedItem.item_img}`}
                        className='cardItemPicture'
                      />
                    </div>
                    <div className='cardItemInfosBis'>
                      {selectedItem.item_name_2 == '' ? (
                        <div className='cardItemTitle'>
                          {selectedItem.item_name_1}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className='cardItemNumber'>
                            {selectedItem.item_name_1}
                          </div>
                          <div className='cardItemName'>
                            {selectedItem.item_name_2}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {selectedItem.item_type == 2 && (
                      <div className='cardItemUsage'>
                        {selectedItem.attack && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageAttack' }}
                          >
                            <GiCrossedSwords />
                          </IconContext.Provider>
                        )}
                        {selectedItem.defense && (
                          <IconContext.Provider
                            value={{ className: 'cardUsageDef' }}
                          >
                            <GiBorderedShield />
                          </IconContext.Provider>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isProcessing ? (
            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <div className='textImportant'>Creation in progress...</div>
              </div>
            </div>
          ) : null}

          {!isProcessing && message ? (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{message}</div>
              </div>
            </div>
          ) : null}

          {!isProcessing && messageSuccess ? (
            <div className='margin10'>
              <div className='card-body p-2'>
                <div className='statusSuccess'>{messageSuccess}</div>
              </div>
            </div>
          ) : null}

          {!isProcessing ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <ReactButton
                    className='btnStandardSmallReverse'
                    onClick={handleCloseModal}
                  >
                    Close
                  </ReactButton>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FactionMarketplace;
