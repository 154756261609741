import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getTokensList } from 'apiRequests';
import { network, jcorpWallet, jcorpTreasuryWallet, tokensList } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import MetaDataService from '../../../services/meta.service';
import RewardsLogsDataService from '../../../services/rewardslogs.service';
import ShopsDataService from '../../../services/shops.service';
import { MetaStateType, metaEmpty } from '../../../types/meta.type';
import { RewardsLogsStatsStateType } from '../../../types/rewardslogs.type';
import { ShopsStatsStateType } from '../../../types/shops.type';
import { TokensStateType } from '../../../types/token.type';

const General = () => {
  const navigate = useNavigate();

  const { userSession } = useAuthContext();

  const { explorerAddress } = network;
  const linkGame = explorerAddress + '/accounts/' + jcorpWallet;
  const linkTreasury = explorerAddress + '/accounts/' + jcorpTreasuryWallet;

  const [listTokens, setListTokens] = React.useState<TokensStateType>({
    tokens: [],
    tokensFetched: undefined
  });

  const [metaInfos, setMetaInfos] = React.useState<MetaStateType>({
    meta: metaEmpty,
    message: '',
    status: ''
  });

  const [shopsStatsInfos, setShopsStatsInfos] =
    React.useState<ShopsStatsStateType>({
      shops: [],
      message: '',
      status: ''
    });
  const [rewardsLogsStatsInfos, setRewardsLogsStatsInfos] =
    React.useState<RewardsLogsStatsStateType>({
      rewards_logs: [],
      message: '',
      status: ''
    });

  const fetchTokensInfosData = () => {
    getTokensList({
      tokensList
    }).then(({ data, success }) => {
      setListTokens({
        tokens: data,
        tokensFetched: success
      });
    });
  };

  const fetchShopsData = () => {
    ShopsDataService.findAllByToken().then(({ data }) => {
      if (data.status == 'success') {
        setShopsStatsInfos({
          shops: data.shops,
          status: data.status
        });
      }
    });
  };

  const fetchRewardsData = () => {
    RewardsLogsDataService.findAllByToken().then(({ data }) => {
      if (data.status == 'success') {
        setRewardsLogsStatsInfos({
          rewards_logs: data.rewards_logs,
          status: data.status
        });
      }
    });
  };

  const handleUpdateMeta = () => {
    const minValue = 1;
    const params: any = {};
    params['ticker'] = 'VILLAIN';
    params['nonce'] = '1';
    params['str'] = Math.floor(Math.random() * (20 - minValue)) + minValue;
    params['dex'] = Math.floor(Math.random() * (20 - minValue)) + minValue;
    params['int'] = Math.floor(Math.random() * (20 - minValue)) + minValue;

    console.log('params : ', params);

    MetaDataService.update(params).then(({ data }) => {
      if (data.status == 'success') {
        fetchReadMeta();
      }
    });
  };

  const fetchReadMeta = () => {
    const params: any = {};
    params['ticker'] = 'VILLAIN';
    params['nonce'] = '1';

    MetaDataService.read(params).then(({ data }) => {
      console.log('data : ', data);
      if (data.status == 'success') {
        setMetaInfos({
          meta: data.meta,
          status: data.status
        });
      }
    });
  };

  const metaLink = routeNames.home + 'metadata/villains/1.json';

  React.useEffect(() => {
    fetchTokensInfosData();
    fetchShopsData();
    fetchRewardsData();
    fetchReadMeta();
  }, []);

  const { tokens } = listTokens;
  const { meta } = metaInfos;

  return (
    <>
      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Game Wallets</div>
          </div>
          <div className='infosBoxSub'>
            <a href={linkGame} target='_blank' rel='noreferrer'>
              <Button className='btnStandardReverse'>Game Wallet</Button>
            </a>
            <a href={linkTreasury} target='_blank' rel='noreferrer'>
              <Button className='btnStandardReverse'>Treasury Wallet</Button>
            </a>
          </div>
        </div>
      </div>

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Game Management</div>
          </div>
          <div className='infosBoxSub'>
            <Button
              className='btnStandardReverse'
              onClick={() => navigate('/jcorpgest/tournament')}
            >
              Tournaments
            </Button>
          </div>
        </div>
      </div>

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Game Links</div>
          </div>
          <div className='infosBoxSub'>
            <a href={routeNames.arenatournament} rel='noreferrer'>
              <Button className='btnStandardReverse'>Tournament</Button>
            </a>
          </div>
        </div>
      </div>

      <div className='boxContent'>
        <div className='card-body p-2'>
          <div className='titleContent'>
            <h3 className='py-2'>
              <span className='mr-1 fantasy'>Metadata</span>
            </h3>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='buttonContainer'>
            <div
              className='homeLinkContent buttonGreen fantasy'
              onClick={handleUpdateMeta}
            >
              Update metadata
            </div>
            <a href={metaLink} rel='noreferrer' target='_blank'>
              <div className='homeLinkContent buttonBlue fantasy'>
                Metadata file
              </div>
            </a>
          </div>
        </div>
        <div className='card-body p-2'>
          <p className='profileLine'>
            <span className='fantasy'>Name : </span>
            {meta.name}
          </p>
          <p className='profileLine'>
            <span className='fantasy'>Str : </span>
            {meta.str}
          </p>
          <p className='profileLine'>
            <span className='fantasy'>Dex : </span>
            {meta.dex}
          </p>
          <p className='profileLine'>
            <span className='fantasy'>Int : </span>
            {meta.int}
          </p>
        </div>
      </div>

      <div className='boxContent'>
        <div className='card-body p-2'>
          <div className='titleContent'>
            <h3 className='py-2'>
              <span className='mr-1 fantasy'>Incomes</span>
            </h3>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='card-body text-center p-2'>
            <h5 className='py-2'>
              <span className='mr-1 fantasy'>Shop</span>
            </h5>
            {shopsStatsInfos.shops.map((shop, i) => {
              let token_name = '';

              if (shop.token_identifier == 'EGLD') {
                token_name = 'EGLD';
              } else {
                tokens.map((token) => {
                  if (shop.token_identifier == token.identifier) {
                    token_name = token.name;
                  }
                });
              }

              const totalAmount = shop.totalAmount.toLocaleString('en-GB', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 3
              });

              return (
                <div className='blockReward' key={i}>
                  <span className='fantasy'>{token_name}</span>
                  <br />
                  <div className='factionInfos'>{totalAmount}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='boxContent'>
        <div className='card-body p-2'>
          <div className='titleContent'>
            <h3 className='py-2'>
              <span className='mr-1 fantasy'>Expenses</span>
            </h3>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='card-body text-center p-2'>
            <h5 className='py-2'>
              <span className='mr-1 fantasy'>Conquests Rewards</span>
            </h5>
            {rewardsLogsStatsInfos.rewards_logs.map((log, i) => {
              let token_name = '';

              if (log.token_identifier == 'EGLD') {
                token_name = 'EGLD';
              } else {
                tokens.map((token) => {
                  if (log.token_identifier == token.identifier) {
                    token_name = token.name;
                  }
                });
              }

              const totalAmount = log.totalAmount.toLocaleString('en-GB', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 3
              });

              return (
                <div className='blockReward' key={i}>
                  <span className='fantasy'>{token_name}</span>
                  <br />
                  <div className='factionInfos'>{totalAmount}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='boxContent'>
        <div className='card-body p-2'>
          <div className='titleContent'>
            <h3 className='py-2'>
              <span className='mr-1 fantasy'>Ratio incomes / expenses</span>
            </h3>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='card-body text-center p-2'>
            {shopsStatsInfos.shops.map((shop, i) => {
              let flg_ratio = false;
              let ratio = 0;

              rewardsLogsStatsInfos.rewards_logs.map((log) => {
                if (shop.token_identifier == log.token_identifier) {
                  flg_ratio = true;
                  ratio = (shop.totalAmount / log.totalAmount) * 100;
                }
              });

              if (flg_ratio) {
                let token_name = '';

                if (shop.token_identifier == 'EGLD') {
                  token_name = 'EGLD';
                } else {
                  tokens.map((token) => {
                    if (shop.token_identifier == token.identifier) {
                      token_name = token.name;
                    }
                  });
                }

                const ratio_rounded = ratio.toFixed(2);

                return (
                  <div className='blockReward' key={i}>
                    <span className='fantasy'>{token_name}</span>
                    <br />
                    <div className='factionInfos'>{ratio_rounded} %</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default General;
