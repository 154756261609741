import * as React from 'react';
import { faFrown, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup
} from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { xoxnoLink, deadrareLink } from 'config';
import { idItemPowerPotion, idItemLifePotion, idItemSpeedPotion } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import ArenaItemsDataService from '../../services/arenaitems.service';
import ArenaLdbLogsDataService from '../../services/arenaldblogs.service';
import ArenaRegistrationsDataService from '../../services/arenaregistrations.service';
import ArenaResultsDataService from '../../services/arenaresults.service';
import ArenaSkillsDataService from '../../services/arenaskills.service';
import AttributsDataService from '../../services/attributs.service';
import InventoriesDataService from '../../services/inventories.service';
import ItemsDataService from '../../services/items.service';
import SettingDataService from '../../services/setting.service';
import {
  IArenaItemData,
  IArenaRegistrationItemsData
} from '../../types/arenaitems.type';
import { ArenaLdbLogsStateType } from '../../types/arenaldblogs.type';
import {
  ArenaRegistrationsStateType,
  ArenaRegistrationsDailyStateType,
  IArenaRegistrationData
} from '../../types/arenaregistrations.type';
import {
  ArenaResultStateType,
  ArenaResultsStateType
} from '../../types/arenaresults.type';
import { IArenaSkillData } from '../../types/arenaskills.type';
import {
  AttributsStateType,
  AttributsDisplayStateType
} from '../../types/attributs.type';
import {
  InventoryStateType,
  InventoryInfos,
  inventoryEmpty
} from '../../types/inventories.type';
import { ItemsStateType } from '../../types/items.type';
import { hierarchyColor } from '../../types/token.type';
import angelic from './../../assets/img/angelic.png';
import arenawarrior from './../../assets/img/arenawarrior.png';
import dagger from './../../assets/img/dagger.png';
import infoIcon from './../../assets/img/icons/info.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';
import staff from './../../assets/img/staff.png';
import sword from './../../assets/img/sword.png';
import { ReactComponent as VectorAdd } from './../../assets/img/vector_add.svg';

const ArenaRanked = () => {
  const { userSession, userInfos } = useAuthContext();

  const navigate = useNavigate();

  function MyTimerFights({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshArena()
    });

    return (
      <span>
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  function MyTimerRest({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp
    });

    return (
      <span>
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result != null) {
      const color =
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16);
      return color;
    }
  }

  const [showRules, setShowRules] = React.useState(false);
  const handleCloseRules = () => {
    setShowRules(false);
  };
  const handleShowRules = () => {
    setShowRules(true);
  };

  const maxRegistrationPerDay = 5;
  const resultsSize = 30;

  const [fightInfos, setFightInfos] = React.useState({
    nextFights: new Date()
  });
  const [restInfos, setRestInfos] = React.useState({
    nextRest: new Date()
  });
  const [currentLeaderboard, setCurrentLeaderboard] = React.useState(0);

  const [showLatestResults, setShowLatestResults] = React.useState(true);
  const [showMyResults, setShowMyResults] = React.useState(false);
  const handleShowLatestResults = () => {
    setShowLatestResults(true);
    setShowMyResults(false);
  };
  const handleShowMyResults = () => {
    setShowLatestResults(false);
    setShowMyResults(true);
  };

  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });

  const [listNFTsGlobal, setListNFTsGlobal] =
    React.useState<AttributsDisplayStateType>({
      nfts: [],
      status: ''
    });
  const [listNFTsGlobalRest, setListNFTsGlobalRest] =
    React.useState<AttributsDisplayStateType>({
      nfts: [],
      status: ''
    });
  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });
  const [listNFTsDisplayRest, setListNFTsDisplayRest] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageRest, setPageRest] = React.useState(0);
  const [totalPagesRest, setTotalPagesRest] = React.useState(0);

  const [sortType, setSortType] = React.useState('hierarchy');

  const handleChangeSort = (event: any, value: any) => {
    const sortNFTs: AttributsStateType = {
      attributs: listNFTs.attributs,
      status: listNFTs.status
    };

    switch (value) {
      case 'hierarchy':
        sortNFTs.attributs.sort(
          (a, b) =>
            b.nft_id_hierarchy - a.nft_id_hierarchy || b.nft_power - a.nft_power
        );
        break;
      case 'power':
        sortNFTs.attributs.sort((a, b) => b.nft_power - a.nft_power);
        break;
    }

    setListNFTs({
      attributs: sortNFTs.attributs,
      status: sortNFTs.status
    });

    setSortType(value);
    handleCloseSortModal();
  };

  const [nbUserClassifiedRegistration, setNbUserClassifiedRegistration] =
    React.useState(0);
  const [nbClassifiedRegistration, setNbClassifiedRegistration] =
    React.useState(0);
  const [flgRest, setFlgRest] = React.useState(false);
  const [countRest, setCountRest] = React.useState(0);

  const [leaderboardsInfos, setLeaderboardsInfos] =
    React.useState<ArenaLdbLogsStateType>({
      arena_ldb_logs: [],
      message: '',
      status: ''
    });
  const [ArenaInfos, setArenaInfos] =
    React.useState<ArenaRegistrationsStateType>({
      arena_registrations: [],
      message: '',
      status: ''
    });
  const [ArenaRankedRegistrationInfos, setArenaRankedRegistrationInfos] =
    React.useState<ArenaRegistrationsDailyStateType>({
      arena_registrations: [],
      message: '',
      status: ''
    });
  const [ArenaResult, setArenaResult] = React.useState<ArenaResultStateType>({
    arena_result: [],
    message: '',
    status: ''
  });
  const [ArenaResults, setArenaResults] = React.useState<ArenaResultsStateType>(
    {
      arena_results: [],
      message: '',
      status: ''
    }
  );

  const [userRank, setUserRank] = React.useState('');
  const [registrationTab, setRegistrationTab] = React.useState({
    occurs: new Array<string>()
  });

  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });
  const [inventoryInfos, setInventoryInfos] =
    React.useState<InventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [inventoryGrpInfos, setInventoryGrpInfos] =
    React.useState<InventoryInfos>({
      inventory: []
    });

  const [showSortModal, setShowSortModal] = React.useState(false);
  const handleCloseSortModal = () => {
    setShowSortModal(false);
  };
  const handleShowSortModal = () => {
    setShowSortModal(true);
  };

  const [showRegistration, setShowRegistration] = React.useState(false);
  const handleCloseRegistration = () => {
    setShowRegistration(false);
  };
  const handleShowRegistration = () => {
    setPage(1);
    setShowRegistration(true);
  };

  const [arenaResultId, setArenaResultId] = React.useState(0);

  const [showArenaResult, setShowArenaResult] = React.useState(false);
  const handleCloseArenaResult = () => {
    setShowArenaResult(false);
  };
  const handleShowArenaResult = (resultId: number) => {
    setArenaResultId(resultId);
    setShowArenaResult(true);
  };

  const [nftIdentifier, setNftIdentifier] = React.useState('');
  const [selectedSkill, setSelectedSkill] = React.useState(0);
  const [selectedItems, setSelectedItems] =
    React.useState<IArenaRegistrationItemsData>({
      items: []
    });

  const [showRegistrationInfos, setShowRegistrationInfos] =
    React.useState(false);
  const handleCloseRegistrationInfos = () => {
    setShowRegistrationInfos(false);
  };
  const handleShowRegistrationInfos = (nft_identifier: string) => {
    setShowRegistration(false);
    setNftIdentifier(nft_identifier);
    setSelectedSkill(0);
    setSelectedItems({
      items: []
    });
    setShowRegistrationInfos(true);
  };

  const handleRegistrationItems = (id_item: number, itemIdentifier: string) => {
    let isDeployed = false;
    const itemsSelection = { ...selectedItems };

    const itemData = {
      id_item: id_item,
      identifier: itemIdentifier
    };

    selectedItems.items.map((item) => {
      if (item.id_item == id_item) {
        isDeployed = true;
        const index = selectedItems.items.findIndex(
          (i) => i.id_item === id_item
        );
        itemsSelection.items.splice(index, 1);
      }
    });
    if (!isDeployed) itemsSelection.items.push(itemData);

    setSelectedItems({
      items: itemsSelection.items
    });
  };

  const fetchNFTsData = () => {
    AttributsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        data.attributs.sort(
          (a, b) =>
            b.nft_id_hierarchy - a.nft_id_hierarchy || b.nft_power - a.nft_power
        );

        setListNFTs({
          attributs: data.attributs,
          status: data.status
        });
      }
    });
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'fightRanked';

    // Fetch of the current fights infos in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setFightInfos({
          nextFights: data.setting.nextPhase
        });
      }
    });

    params['name'] = 'restRanked';

    // Fetch of the current fights infos in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setRestInfos({
          nextRest: data.setting.nextPhase
        });
      }
    });

    params['name'] = 'arena_leaderboard';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentLeaderboard(data.setting.value);
      }
    });

    // TODO - launch
    /*
    params['name'] = 'arenaLaunch';

    // Fetch of the current fights infos in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        const date = new Date();
        const launchDate = new Date(data.setting.nextPhase);
        if (launchDate > date) {
          navigate(routeNames.menu);
        }
      }
    });
    */
  };

  const fetchArenaResultData = () => {
    ArenaResultsDataService.findOneResultById(arenaResultId).then(
      ({ data }) => {
        if (data.status == 'success') {
          setArenaResult({
            arena_result: data.arena_result,
            status: data.status
          });
        }
      }
    );
  };

  const fetchArenaUserResultsData = () => {
    const params: any = {};
    params['registration_mode'] = 1;
    params['size'] = resultsSize;

    ArenaResultsDataService.findAllUserResults(userSession.userID, params).then(
      ({ data }) => {
        if (data.status == 'success') {
          setArenaResults({
            arena_results: data.arena_results,
            status: data.status
          });
        }
      }
    );
  };

  const fetchArenaAllResultsData = () => {
    const params: any = {};
    params['registration_mode'] = 1;
    params['size'] = resultsSize;

    ArenaResultsDataService.findLatestResults(params).then(({ data }) => {
      if (data.status == 'success') {
        setArenaResults({
          arena_results: data.arena_results,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsLogsData = () => {
    ArenaLdbLogsDataService.findAllByLeaderboard(currentLeaderboard).then(
      ({ data }) => {
        if (data.status == 'success') {
          setLeaderboardsInfos({
            arena_ldb_logs: data.arena_ldb_logs,
            status: data.status
          });
        }
      }
    );
  };

  const fetchArenaRegistrationData = () => {
    const params: any = {};
    params['registration_mode'] = 1;

    ArenaRegistrationsDataService.findAllDailyRegisteredByUser(
      userSession.userID,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setArenaRankedRegistrationInfos({
          arena_registrations: data.arena_registrations,
          status: data.status
        });
      }
    });

    ArenaRegistrationsDataService.findAllRegisteredByUser(
      userSession.userID,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setArenaInfos({
          arena_registrations: data.arena_registrations,
          status: data.status
        });

        let nbUserClassified = 0;

        data.arena_registrations.map((arena) => {
          if (arena.registration_mode == 1) nbUserClassified++;
        });

        setNbUserClassifiedRegistration(nbUserClassified);
      }
    });

    ArenaRegistrationsDataService.findAllRegistered(params).then(({ data }) => {
      if (data.status == 'success') {
        let nbClassified = 0;

        data.arena_registrations.map((arena) => {
          if (arena.registration_mode == 1) nbClassified++;
        });

        setNbClassifiedRegistration(nbClassified);
      }
    });
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const fetchInventoryData = () => {
    InventoriesDataService.findAllByUser(userSession.userID).then(
      ({ data }) => {
        if (data.status == 'success') {
          setInventoryInfos({
            inventory: data.inventory,
            status: data.status
          });
        }
      }
    );
  };

  const handleInventoryGrpInfos = () => {
    const inventory_item_type = 5;

    let prevIdItem = 0;
    let itemCount = 0;
    let dataInventory = inventoryEmpty;
    const inventory: InventoryInfos = {
      inventory: []
    };
    inventoryInfos.inventory.map((item) => {
      if (item.id_item != prevIdItem) {
        if (prevIdItem != 0 && itemCount > 0) {
          inventory.inventory.push(dataInventory);
        }

        prevIdItem = item.id_item;
        itemCount = 0;

        dataInventory = {
          id_item: item.id_item,
          img: item.item.item_img,
          itemInfos: item.item,
          itemsAvailableCount: 0,
          itemsAvailable: []
        };
      }
      if (!item.flg_used && item.item.item_type == inventory_item_type) {
        itemCount++;
        dataInventory.itemsAvailableCount++;
        dataInventory.itemsAvailable.push({
          identifier: item.identifier
        });
      }
    });
    if (prevIdItem != 0 && itemCount > 0) {
      inventory.inventory.push(dataInventory);
    }
    setInventoryGrpInfos(inventory);
  };

  const handleLeaderboardsInfos = () => {
    let rank = 'Unranked';

    leaderboardsInfos.arena_ldb_logs.map((arena_ldb_log) => {
      if (arena_ldb_log.id_user == userSession.userID) {
        rank = 'Rank ' + arena_ldb_log.rank;
      }
    });

    setUserRank(rank);
  };

  const registerRanked = (nft_identifier: string) => {
    listNFTs.attributs.map((token) => {
      if (token.nft_identifier == nft_identifier) {
        const dataArena: IArenaRegistrationData = {
          registration_mode: 1,
          id_user: userSession.userID,
          identifier: '',
          id_nft: token.id_nft,
          nft_identifier: token.nft_identifier,
          id_fight: 0,
          flg_winner: false
        };

        ArenaRegistrationsDataService.create(dataArena).then(async function ({
          data
        }) {
          if (data.status == 'success') {
            const dataArenaSkill: IArenaSkillData = {
              id_registration: data.id_registration,
              id_round: 0,
              id_skill: selectedSkill
            };

            await ArenaSkillsDataService.create(dataArenaSkill);

            for (let i = 0; i < selectedItems.items.length; i++) {
              const item = selectedItems.items[i];

              const dataArenaItem: IArenaItemData = {
                id_registration: data.id_registration,
                id_item: item.id_item,
                identifier: item.identifier
              };

              const dataItem: any = {
                flg_used: true
              };

              await ArenaItemsDataService.create(dataArenaItem);
              await InventoriesDataService.update(dataItem, item.identifier);
            }

            fetchArenaRegistrationData();
            fetchInventoryData();
          }
        });
      }
    });
  };

  const registerArena = () => {
    registerRanked(nftIdentifier);
    handleCloseRegistrationInfos();
  };

  /*
  const unregisterArena = (arena: IArenaRegistrationData) => {
    let fightRankedDate = new Date();

    const params: any = {};
    params['name'] = 'fightRanked';

    // Fetch of the current fights infos in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        fightRankedDate = new Date(data.setting.nextPhase);
      }

      const currentDate = new Date();
      const fightsDate = new Date(fightRankedDate);
      const secsUnregisterArena = 15;
      fightsDate.setTime(fightsDate.getTime() - secsUnregisterArena * 1000);

      if (currentDate < fightsDate) {
        const id_registration = arena.id_registration;
        const arena_items = arena.arena_items;

        ArenaRegistrationsDataService.delete(id_registration).then(
          async function () {
            await ArenaSkillsDataService.delete(id_registration);

            if (arena_items != null) {
              for (let i = 0; i < arena_items.length; i++) {
                const arena_item = arena_items[i];

                const dataItem: any = {
                  flg_used: false
                };

                await InventoriesDataService.update(
                  dataItem,
                  arena_item.identifier
                );
              }

              await ArenaItemsDataService.delete(id_registration);
            }

            fetchArenaRegistrationData();
            fetchInventoryData();
          }
        );
      }
    });
  };
  */

  const handleRegistration = () => {
    let nbMaxRegistration = 0;
    const nbUserNFTs = userInfos.user.nb_nfts;

    switch (true) {
      case nbUserNFTs < 10:
        nbMaxRegistration = 2;
        break;
      case nbUserNFTs >= 10 && nbUserNFTs < 20:
        nbMaxRegistration = 3;
        break;
      case nbUserNFTs >= 20 && nbUserNFTs < 50:
        nbMaxRegistration = 4;
        break;
      case nbUserNFTs >= 50:
        nbMaxRegistration = 5;
        break;
      default:
        break;
    }

    if (userInfos.user.nb_slots_arena_ranked > 0) {
      nbMaxRegistration = userInfos.user.nb_slots_arena_ranked;
    }

    const tab = {
      occurs: new Array<string>()
    };

    for (let i = nbUserClassifiedRegistration; i < nbMaxRegistration; i++) {
      const number = i + 1;
      let occur = number.toString();
      if (number < 10) {
        occur = '0' + number;
      }
      tab.occurs.push(occur);
    }

    setRegistrationTab(tab);
  };

  const handleCheckRegistration = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: [],
      status: 'success'
    };
    const nftsListRest: AttributsDisplayStateType = {
      nfts: [],
      status: 'success'
    };

    let available = true;
    let nbAtRest = 0;

    listNFTs.attributs.map((token) => {
      let isAtRest = false;
      ArenaRankedRegistrationInfos.arena_registrations.map((reg) => {
        if (
          token.nft_identifier == reg.nft_identifier &&
          reg.count >= maxRegistrationPerDay
        ) {
          available = false;
          isAtRest = true;
          nbAtRest++;
        }
      });

      if (isAtRest) {
        nftsListRest.nfts.push(token);
      } else {
        nftsList.nfts.push(token);
      }
    });

    // At least one warrior is at rest
    if (!available) {
      setFlgRest(true);
    } else {
      setFlgRest(false);
    }

    setCountRest(nbAtRest);

    const nbPages = Math.ceil(nftsList.nfts.length / pageSize);
    setPage(1);
    setTotalPages(nbPages);
    setListNFTsGlobal(nftsList);

    const nbPagesRest = Math.ceil(nftsListRest.nfts.length / pageSize);
    setPageRest(1);
    setTotalPagesRest(nbPagesRest);
    setListNFTsGlobalRest(nftsListRest);
  };

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageChangeRest = (event: any, value: any) => {
    setPageRest(value);
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTsGlobal.nfts.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTsGlobal.nfts[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };

  const handleRefreshNFTsListRest = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = pageRest - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTsGlobalRest.nfts.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTsGlobalRest.nfts[i]);
      }
    }

    setListNFTsDisplayRest(nftsList);
  };

  const handleRefreshArena = () => {
    fetchSettingData();
    fetchArenaRegistrationData();
    if (showMyResults) {
      fetchArenaUserResultsData();
    } else {
      fetchArenaAllResultsData();
    }
    fetchLeaderboardsLogsData();
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    fetchNFTsData();
    fetchArenaRegistrationData();
    fetchArenaAllResultsData();
    fetchItemsData();
    fetchInventoryData();
  }, []);

  React.useEffect(() => {
    if (currentLeaderboard != 0) {
      fetchLeaderboardsLogsData();
    }
  }, [currentLeaderboard]);

  React.useEffect(() => {
    handleRegistration();
  }, [nbUserClassifiedRegistration]);

  React.useEffect(() => {
    if (leaderboardsInfos.status == 'success') {
      handleLeaderboardsInfos();
    }
  }, [leaderboardsInfos]);

  React.useEffect(() => {
    if (arenaResultId != 0) {
      fetchArenaResultData();
    }
  }, [arenaResultId]);

  React.useEffect(() => {
    if (showMyResults) {
      fetchArenaUserResultsData();
    } else {
      fetchArenaAllResultsData();
    }
  }, [showMyResults, showLatestResults]);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0) {
      handleCheckRegistration();
    }
  }, [listNFTs, ArenaRankedRegistrationInfos]);

  React.useEffect(() => {
    if (listNFTsGlobal.status == 'success' && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTsGlobal, page]);

  React.useEffect(() => {
    if (listNFTsGlobalRest.status == 'success' && page != 0) {
      handleRefreshNFTsListRest();
    }
  }, [listNFTsGlobalRest, pageRest]);

  React.useEffect(() => {
    if (inventoryInfos.status == 'success') {
      handleInventoryGrpInfos();
    }
  }, [inventoryInfos]);

  const { items } = itemsInfos;
  const { inventory } = inventoryGrpInfos;
  const { attributs } = listNFTs;
  const { arena_registrations } = ArenaInfos;
  const { arena_results } = ArenaResults;
  const { arena_result } = ArenaResult;

  if (userInfos.status == 'success') {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosConquestContainer'>
                  <div className='infosConquestBox'>
                    <div className='conquestTitleBox'>
                      <div className='conquestTitle'>Arena</div>
                    </div>
                    <div className='conquestSub'>Mode</div>
                    <div className='phaseTitle'>Ranked</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='bigBox'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>{userRank}</div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxRightBtn'>
                    <div
                      className='stripBtn'
                      onClick={() => navigate(routeNames.arenaleaderboard)}
                    >
                      <div className='stripBtnIn'>
                        <div className='stripBtnText'>Leaderboard</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusWarning'>Feature in beta version</div>
              </div>
            </div>
            */}

            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerLeft'>
                <div className='stripBoxLeft'>
                  <div className='stripBoxLeftRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>
                          Season {currentLeaderboard}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxLeftTimerBox'>
                    <div className='stripBoxLeftTimer'>
                      <div className='infosTimerText'>Next Fights</div>
                      <div className='infosTimerTimer'>
                        <MyTimerFights
                          expiryTimestamp={new Date(fightInfos.nextFights)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topArenaContainer'>
              <div className='arenaRegistrationInfosContainer'>
                <div className='arenaRegistrationInfosPictureBox'>
                  <img
                    src={`${arenawarrior}`}
                    className='arenaRegistrationInfosPicture'
                  />
                </div>
                <div className='arenaRegistrationInfosBox'>
                  <div className='arenaRegistrationInfosBoxIn'>
                    <div className='arenaRegistrationTextBox'>
                      <div className='arenaRegistrationText'>
                        There&nbsp;
                        {nbClassifiedRegistration > 1 ? (
                          <span>are</span>
                        ) : (
                          <span>is</span>
                        )}
                        &nbsp;currently&nbsp;
                        <span className='arenaRegistrationTextBig'>
                          {nbClassifiedRegistration}
                          &nbsp;
                          {nbClassifiedRegistration > 1 ? (
                            <span>Warriors</span>
                          ) : (
                            <span>Warrior</span>
                          )}
                        </span>
                        <br />
                        registered in the Ranked Mode.
                      </div>
                    </div>
                    <div className='arenaRegistrationInfosBoxIn2'>
                      <div className='arenaRegistrationTextBox2'>
                        <div className='arenaRegistrationText'>
                          <MyTimerRest
                            expiryTimestamp={new Date(restInfos.nextRest)}
                          />
                          &nbsp;until the end of the warriors rest !
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='smallBox'>
              <div className='boxContainer topArenaContainer'>
                <div className='stripContainerRight'>
                  <div className='stripBoxRight'>
                    <div className='stripBoxRightRank'>
                      <div className='stripInfos'>
                        <div className='stripInfosIn'>
                          <div className='stripInfosText'>{userRank}</div>
                        </div>
                      </div>
                    </div>
                    <div className='stripBoxRightBtn'>
                      <div
                        className='stripBtn'
                        onClick={() => navigate(routeNames.arenaleaderboard)}
                      >
                        <div className='stripBtnIn'>
                          <div className='stripBtnText'>Leaderboard</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>
                    Ranked Mode Registration
                    <div
                      className='infosIconContainer'
                      onClick={handleShowRules}
                    >
                      <img src={`${infoIcon}`} className='infosIcon' />
                    </div>
                  </div>
                </div>
                <div className='nftBlock'>
                  {arena_registrations.map((arena, i) => {
                    let nft_hierarchy = '';
                    if (arena.attribut?.nft_hierarchy != null) {
                      nft_hierarchy = arena.attribut.nft_hierarchy;
                    }

                    const divStyle = {
                      '--rarityColor': hierarchyColor[nft_hierarchy]
                    } as React.CSSProperties;

                    let nft_number;
                    let nft_collection;
                    if (arena.attribut?.nft_name != null) {
                      const nameT = arena.attribut?.nft_name.split(' ');
                      nft_collection = nameT[0];
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }
                    }
                    const flg_blockchain = arena.attribut?.flg_blockchain;

                    let NFTpower = 0;
                    let NFTlife = 0;
                    let NFTspeed = 0;
                    if (arena.attribut != null) {
                      NFTpower = arena.attribut?.nft_power;
                    }

                    // TODO
                    switch (nft_hierarchy) {
                      case 'Warrior':
                        NFTlife = 140;
                        NFTspeed = 5;
                        break;
                      case 'Samurai':
                        NFTlife = 155;
                        NFTspeed = 10;
                        break;
                      case 'Daimyos':
                        NFTlife = 170;
                        NFTspeed = 15;
                        break;
                      case 'Shogun':
                        NFTlife = 180;
                        NFTspeed = 20;
                        NFTpower = 60;
                        break;
                      default:
                        break;
                    }

                    let skillStyle = {
                      '--skillColor': hexToRgb('#FAF7E2')
                    } as React.CSSProperties;
                    let skillName = '';
                    arena.arena_skills?.map((skill) => {
                      switch (skill.id_skill) {
                        case 1:
                          skillName = 'Sword';
                          skillStyle = {
                            '--skillColor': hexToRgb('#9e640c')
                          } as React.CSSProperties;
                          break;
                        case 2:
                          skillName = 'Dagger';
                          skillStyle = {
                            '--skillColor': hexToRgb('#d6da1c')
                          } as React.CSSProperties;
                          break;
                        case 3:
                          skillName = 'Staff';
                          skillStyle = {
                            '--skillColor': hexToRgb('#1d60f0')
                          } as React.CSSProperties;
                          break;
                        default:
                          break;
                      }
                    });

                    let isDamageBonus = false;
                    let isLifeBonus = false;
                    let isSpeedBonus = false;
                    let damageBonus = 0;
                    let lifeBonus = 0;
                    let speedBonus = 0;
                    arena.arena_items?.map((item) => {
                      const bonusValue = item.item.item_power;
                      switch (item.id_item) {
                        case idItemPowerPotion: // Power
                          isDamageBonus = true;
                          damageBonus = bonusValue;
                          NFTpower += bonusValue;
                          break;
                        case idItemLifePotion: // HP
                          isLifeBonus = true;
                          lifeBonus = bonusValue;
                          NFTlife += bonusValue;
                          break;
                        case idItemSpeedPotion: // Speed
                          isSpeedBonus = true;
                          speedBonus = bonusValue;
                          NFTspeed += bonusValue;
                          break;
                        default:
                          break;
                      }
                    });

                    const NFTpowerRounded = NFTpower.toFixed(2);

                    const damage = Math.ceil(NFTpower);
                    const damage_min = Math.round(damage * 0.9);
                    const damage_max = Math.round(damage * 1.1);

                    return (
                      <div key={i} style={divStyle} className='cardDynNFTBlock'>
                        <div className='cardDynNFTContainer'>
                          <div className='cardDynNFTBox'>
                            <div className='cardDynNFTBoxIn'>
                              <div className='cardDynNFTInfos'>
                                <div className='cardDynNFTHierarchy'>
                                  {nft_hierarchy}
                                </div>
                              </div>
                              <div
                                style={skillStyle}
                                className='cardDynNFTBoxIn2'
                              >
                                <div className='textClass'>{skillName}</div>
                              </div>
                              <div className='cardDynNFTBoxIn3'>
                                <div className='cardDynNFTInfosLine'>
                                  <span className='lineTitle'>Power</span>
                                  {isDamageBonus ? (
                                    <span className='lineAttributBonus'>
                                      {NFTpowerRounded}
                                      <span className='lineAttributBonus2'>
                                        &nbsp;(+ {damageBonus})
                                      </span>
                                    </span>
                                  ) : (
                                    <span className='lineAttribut'>
                                      {NFTpowerRounded}
                                    </span>
                                  )}
                                </div>
                                <div className='cardDynNFTInfosLine'>
                                  <span className='lineTitle'>Damage</span>
                                  {isDamageBonus ? (
                                    <span className='lineAttributBonus'>
                                      {damage_min} - {damage_max}
                                    </span>
                                  ) : (
                                    <span className='lineAttribut'>
                                      {damage_min} - {damage_max}
                                    </span>
                                  )}
                                </div>
                                <div className='cardDynNFTInfosLine'>
                                  <span className='lineTitle'>HP</span>
                                  {isLifeBonus ? (
                                    <span className='lineAttributBonus'>
                                      {NFTlife}
                                      <span className='lineAttributBonus2'>
                                        &nbsp;(+ {lifeBonus})
                                      </span>
                                    </span>
                                  ) : (
                                    <span className='lineAttribut'>
                                      {NFTlife}
                                    </span>
                                  )}
                                </div>
                                <div className='cardDynNFTInfosLine'>
                                  <span className='lineTitle'>Speed</span>
                                  {isSpeedBonus ? (
                                    <span className='lineAttributBonus'>
                                      {NFTspeed}
                                      <span className='lineAttributBonus2'>
                                        &nbsp;(+ {speedBonus})
                                      </span>
                                    </span>
                                  ) : (
                                    <span className='lineAttribut'>
                                      {NFTspeed}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*
                          <div
                            className='cardDynNFTPictureBox'
                            onClick={() => unregisterArena(arena)}
                          >
                            <div className='overlay'>
                              <div className='overlayArenaText'>Registered</div>
                            </div>
                            <div className='overlayHoverSmallB'>
                              <div className='overlayHoverTextSmallB'>
                                Unregister
                              </div>
                            </div>
                            {flg_blockchain ? (
                              <img
                                src={`${arena.attribut?.nft_url}`}
                                className='cardDynNFTPicture'
                              />
                            ) : (
                              <img
                                src={`../${arena.attribut?.nft_url}`}
                                className='cardDynNFTPicture'
                              />
                            )}
                          </div>
                          */}
                          <div className='cardDynNFTPictureBox'>
                            <div className='overlay'>
                              <div className='overlayArenaText'>Registered</div>
                            </div>
                            {flg_blockchain ? (
                              <img
                                src={`${arena.attribut?.nft_url}`}
                                className='cardDynNFTPicture'
                              />
                            ) : (
                              <img
                                src={`../${arena.attribut?.nft_url}`}
                                className='cardDynNFTPicture'
                              />
                            )}
                          </div>
                          <div className='cardDynNFTInfosBis'>
                            {!nft_number ? (
                              <div className='cardDynNFTTitle'>
                                {nft_collection}
                              </div>
                            ) : (
                              <React.Fragment>
                                <div className='cardDynNFTNumber'>
                                  {nft_number}
                                </div>
                                <div className='cardDynNFTName'>
                                  {nft_collection}
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {registrationTab.occurs.map((occur, i) => {
                    return (
                      <div
                        key={i}
                        className='arenaRegistrationContainer'
                        onClick={() => handleShowRegistration()}
                      >
                        <div className='arenaRegistrationBox'>
                          <div className='arenaRegistrationBoxIn'>
                            <div className='arenaRegistrationBoxInText'>
                              Add a fighter
                            </div>
                            <div className='arenaRegistrationBoxInVector'>
                              <VectorAdd className='vectorAdd' />
                            </div>
                            <div className='arenaRegistrationBoxInNumber'>
                              {occur}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Ranked Mode Results</div>
                </div>
                <div className='textImportant'>
                  Click on a result to see the fight details.
                </div>
                <div className='text-center'>
                  {showLatestResults ? (
                    <React.Fragment>
                      <Button
                        className='navStandardSmallReverse'
                        onClick={handleShowLatestResults}
                      >
                        Latest Results
                      </Button>
                      <Button
                        className='navStandardSmall'
                        onClick={handleShowMyResults}
                      >
                        My Results
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        className='navStandardSmall'
                        onClick={handleShowLatestResults}
                      >
                        Latest Results
                      </Button>
                      <Button
                        className='navStandardSmallReverse'
                        onClick={handleShowMyResults}
                      >
                        My Results
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className='card-body p-2 margin30'>
                {arena_results.map((result, i) => {
                  const fighter_A = result.fighter_A;
                  const fighter_B = result.fighter_B;

                  let fighter_A_factionImg = '';
                  let fighter_B_factionImg = '';

                  switch (fighter_A.user.id_faction) {
                    case 1:
                      fighter_A_factionImg = angelic;
                      break;
                    case 2:
                      fighter_A_factionImg = night;
                      break;
                    case 3:
                      fighter_A_factionImg = meiyo;
                      break;
                  }

                  switch (fighter_B.user.id_faction) {
                    case 1:
                      fighter_B_factionImg = angelic;
                      break;
                    case 2:
                      fighter_B_factionImg = night;
                      break;
                    case 3:
                      fighter_B_factionImg = meiyo;
                      break;
                  }

                  const fighter_A_pseudo = fighter_A.user.pseudo;
                  const fighter_B_pseudo = fighter_B.user.pseudo;

                  const fight_season = fighter_A.arena_ldb_data.id_leaderboard;
                  const fighter_A_points = fighter_A.arena_ldb_data.points;
                  const fighter_B_points = fighter_B.arena_ldb_data.points;

                  return (
                    <div
                      key={i}
                      className='arenaResultContainer2'
                      onClick={() => handleShowArenaResult(result.id)}
                    >
                      <div className='arenaResultSeasonBox'>
                        <div className='arenaResultSeason'>
                          <div className='arenaResultSeasonIn'>
                            <div className='arenaResultSeasonText'>
                              Season {fight_season}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arenaResultBox'>
                        <div className='arenaResultBoxIn'>
                          <div className='arenaResultBoxInfos'>
                            <div className='arenaResultBoxInfosLeft'>
                              <div>
                                <img
                                  src={`${fighter_A_factionImg}`}
                                  className='imgFactionArena'
                                />
                              </div>
                              <div className='margin10'>{fighter_A_pseudo}</div>
                            </div>
                            <div className='arenaResultBoxInfosCenter'>VS</div>
                            <div className='arenaResultBoxInfosRight'>
                              <div>
                                <img
                                  src={`${fighter_B_factionImg}`}
                                  className='imgFactionArena'
                                />
                              </div>
                              <div className='margin10'>{fighter_B_pseudo}</div>
                            </div>
                          </div>
                          <div className='arenaResultBoxIn2'>
                            <div className='arenaResultBoxInText2'>
                              fight {result.id_fight}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='arenaResultLeft'>
                        {fighter_A.flg_winner ? (
                          <div className='arenaResultLeftWinner'>
                            <div className='arenaResultLeftWinnerIn'>
                              <div className='arenaResultText'>
                                WINNER
                                <br />
                                {fighter_A.flg_winner ? <span>+</span> : null}
                                {fighter_A_points} points
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='arenaResultLeftLoser'>
                            <div className='arenaResultLeftLoserIn'>
                              <div className='arenaResultText'>
                                LOSER
                                <br />
                                {fighter_A.flg_winner ? <span>+</span> : null}
                                {fighter_A_points} points
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='arenaResultRight'>
                        {fighter_B.flg_winner ? (
                          <div className='arenaResultRightWinner'>
                            <div className='arenaResultRightWinnerIn'>
                              <div className='arenaResultText'>
                                WINNER
                                <br />
                                {fighter_B.flg_winner ? <span>+</span> : null}
                                {fighter_B_points} points
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='arenaResultRightLoser'>
                            <div className='arenaResultRightLoserIn'>
                              <div className='arenaResultText'>
                                LOSER
                                <br />
                                {fighter_B.flg_winner ? <span>+</span> : null}
                                {fighter_B_points} points
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showRegistration}
          onHide={handleCloseRegistration}
          size='lg'
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            <div className='card-body p-2'>
              <div className='infosModalTitle'>Ranked Mode Registration</div>
            </div>

            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <div className='textImportant'>
                  Each warrior can fight&nbsp;
                  <span className='textImportantRed'>
                    {maxRegistrationPerDay}
                  </span>
                  &nbsp;times per day.
                  <br />
                  After that, your warrior will be at rest for the rest of the
                  &nbsp;day.
                </div>
              </div>
            </div>

            {attributs.length > 0 ? (
              <React.Fragment>
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBoxModal'>
                      <div className='infosSubTitle'>Select your warrior</div>
                    </div>
                    <div className='infosBoxModalSub'>
                      <div className='buttonContainer'>
                        <Button
                          className='btnStandardTinyReverse'
                          onClick={handleShowSortModal}
                        >
                          Sort
                        </Button>
                      </div>
                    </div>
                    <div className='textImportant'>
                      Click on your warrior to register him in the Ranked Mode.
                    </div>
                  </div>
                </div>

                <div className='margin10'>
                  <div className='card-body p-2'>
                    {totalPages > 1 && (
                      <div className='paginBoxTop'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                    <div className='nftBlock'>
                      {listNFTsDisplay.nfts.map((token, i) => {
                        const divStyle = {
                          '--rarityColor': hierarchyColor[token.nft_hierarchy]
                        } as React.CSSProperties;

                        const nameT = token.nft_name.split(' ');
                        const nft_collection = nameT[0];
                        let nft_number;
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                        const flg_blockchain = token.flg_blockchain;

                        let NFTpower = token.nft_power;
                        let NFTlife = 0;
                        let NFTspeed = 0;

                        // TODO
                        switch (token.nft_hierarchy) {
                          case 'Warrior':
                            NFTlife = 140;
                            NFTspeed = 5;
                            break;
                          case 'Samurai':
                            NFTlife = 155;
                            NFTspeed = 10;
                            break;
                          case 'Daimyos':
                            NFTlife = 170;
                            NFTspeed = 15;
                            break;
                          case 'Shogun':
                            NFTlife = 180;
                            NFTspeed = 20;
                            NFTpower = 60;
                            break;
                          default:
                            break;
                        }

                        const NFTpowerRounded = NFTpower.toFixed(2);

                        const damage = Math.ceil(NFTpower);
                        const damage_min = Math.round(damage * 0.9);
                        const damage_max = Math.round(damage * 1.1);

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardDynNFTBlock'
                          >
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                  </div>
                                  <div className='cardDynNFTBoxIn2Empty'></div>
                                  <div className='cardDynNFTBoxIn3'>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Power</span>
                                      <span className='lineAttribut'>
                                        {NFTpowerRounded}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Damage</span>
                                      <span className='lineAttribut'>
                                        {damage_min} - {damage_max}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>HP</span>
                                      <span className='lineAttribut'>
                                        {NFTlife}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Speed</span>
                                      <span className='lineAttribut'>
                                        {NFTspeed}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardDynNFTPictureBox'
                                onClick={() =>
                                  handleShowRegistrationInfos(
                                    token.nft_identifier
                                  )
                                }
                              >
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Register
                                  </div>
                                </div>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {totalPages > 1 && (
                      <div className='paginBoxBottom'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            {flgRest ? (
              <React.Fragment>
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      {countRest > 1 ? (
                        <div className='infosSubTitle'>Warriors at rest</div>
                      ) : (
                        <div className='infosSubTitle'>Warrior at rest</div>
                      )}
                    </div>
                    {countRest > 1 ? (
                      <div className='textImportant'>
                        These warriors are at rest for the rest of the day.
                      </div>
                    ) : (
                      <div className='textImportant'>
                        This warrior is at rest for the rest of the day.
                      </div>
                    )}
                  </div>
                </div>

                <div className='margin10'>
                  <div className='card-body p-2'>
                    {totalPagesRest > 1 && (
                      <div className='paginBoxTop'>
                        <Pagination
                          className='paginContainer'
                          count={totalPagesRest}
                          defaultPage={1}
                          page={pageRest}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChangeRest}
                        />
                      </div>
                    )}
                    <div className='nftBlock'>
                      {listNFTsDisplayRest.nfts.map((token, i) => {
                        const divStyle = {
                          '--rarityColor': hierarchyColor[token.nft_hierarchy]
                        } as React.CSSProperties;

                        const nameT = token.nft_name.split(' ');
                        const nft_collection = nameT[0];
                        let nft_number;
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                        const flg_blockchain = token.flg_blockchain;

                        let NFTpower = token.nft_power;
                        let NFTlife = 0;
                        let NFTspeed = 0;

                        // TODO
                        switch (token.nft_hierarchy) {
                          case 'Warrior':
                            NFTlife = 140;
                            NFTspeed = 5;
                            break;
                          case 'Samurai':
                            NFTlife = 155;
                            NFTspeed = 10;
                            break;
                          case 'Daimyos':
                            NFTlife = 170;
                            NFTspeed = 15;
                            break;
                          case 'Shogun':
                            NFTlife = 180;
                            NFTspeed = 20;
                            NFTpower = 60;
                            break;
                          default:
                            break;
                        }

                        const NFTpowerRounded = NFTpower.toFixed(2);

                        const damage = Math.ceil(NFTpower);
                        const damage_min = Math.round(damage * 0.9);
                        const damage_max = Math.round(damage * 1.1);

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardDynNFTBlock'
                          >
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                  </div>
                                  <div className='cardDynNFTBoxIn2Empty'></div>
                                  <div className='cardDynNFTBoxIn3'>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Power</span>
                                      <span className='lineAttribut'>
                                        {NFTpowerRounded}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Damage</span>
                                      <span className='lineAttribut'>
                                        {damage_min} - {damage_max}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>HP</span>
                                      <span className='lineAttribut'>
                                        {NFTlife}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Speed</span>
                                      <span className='lineAttribut'>
                                        {NFTspeed}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardDynNFTPictureBox'>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {totalPagesRest > 1 && (
                      <div className='paginBoxBottom'>
                        <Pagination
                          className='paginContainer'
                          count={totalPagesRest}
                          defaultPage={1}
                          page={pageRest}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChangeRest}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            {attributs.length == 0 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Select your warrior</div>
                  </div>
                  {userSession.isBlockchainAccount ? (
                    <React.Fragment>
                      <div className='text-center'>
                        <div className='iconContainer'>
                          <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                        </div>
                        <p className='textImportant'>
                          Oops... it looks like you don&apos;t have any Heroes
                          at the moment !
                        </p>
                        <p className='textImportant'>
                          But ! you can find one by going here :
                        </p>
                      </div>
                      <div className='text-center'>
                        <Button
                          className='btnStandardReverse'
                          onClick={() => navigate(routeNames.mint)}
                        >
                          Mint Heroes
                        </Button>
                        <br className='smallBox' />
                        <a href={xoxnoLink} target='_blank' rel='noreferrer'>
                          <Button className='btnStandard'>
                            Heroes
                            <br />
                            on XOXNO
                          </Button>
                        </a>
                        <a href={deadrareLink} target='_blank' rel='noreferrer'>
                          <Button className='btnStandard'>
                            Heroes
                            <br />
                            on Dead Rare
                          </Button>
                        </a>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className='text-center'>
                      <div className='iconContainer'>
                        <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                      </div>
                      <p className='textImportant'>
                        Oops... it looks like you don&apos;t have any Heroes at
                        the moment !
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseRegistration}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showRegistrationInfos}
          onHide={handleCloseRegistrationInfos}
          size='lg'
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            {listNFTs.attributs.map((token, i) => {
              if (token.nft_identifier == nftIdentifier) {
                const divStyle = {
                  '--rarityColor': hierarchyColor[token.nft_hierarchy]
                } as React.CSSProperties;

                const nameT = token.nft_name.split(' ');
                const nft_collection = nameT[0];
                let nft_number;
                if (nameT[1]) {
                  nft_number = nameT[1].replace('#', '');
                }
                const flg_blockchain = token.flg_blockchain;

                let NFTpower = token.nft_power;
                let NFTlife = 0;
                let NFTspeed = 0;

                // TODO
                switch (token.nft_hierarchy) {
                  case 'Warrior':
                    NFTlife = 140;
                    NFTspeed = 5;
                    break;
                  case 'Samurai':
                    NFTlife = 155;
                    NFTspeed = 10;
                    break;
                  case 'Daimyos':
                    NFTlife = 170;
                    NFTspeed = 15;
                    break;
                  case 'Shogun':
                    NFTlife = 180;
                    NFTspeed = 20;
                    NFTpower = 60;
                    break;
                  default:
                    break;
                }

                const NFTpowerRounded = NFTpower.toFixed(2);

                const damage = Math.ceil(NFTpower);
                const damage_min = Math.round(damage * 0.9);
                const damage_max = Math.round(damage * 1.1);

                let swordStyle,
                  daggerStyle,
                  staffStyle = {
                    '--rarityColor': ''
                  } as React.CSSProperties;

                switch (selectedSkill) {
                  case 1: // Sword
                    swordStyle = {
                      '--rarityColor': 'rgb(' + hexToRgb('#00FFA3') + ')'
                    } as React.CSSProperties;
                    break;
                  case 2: // Dagger
                    daggerStyle = {
                      '--rarityColor': 'rgb(' + hexToRgb('#00FFA3') + ')'
                    } as React.CSSProperties;
                    break;
                  case 3: // Staff
                    staffStyle = {
                      '--rarityColor': 'rgb(' + hexToRgb('#00FFA3') + ')'
                    } as React.CSSProperties;
                    break;
                  default:
                    break;
                }

                return (
                  <div key={i}>
                    <div className='card-body p-2'>
                      <div className='infosModalTitle'>
                        Registration of {nft_collection} {nft_number}
                      </div>
                    </div>

                    <div className='margin10'>
                      <div className='card-body p-2'>
                        <div className='nftBlock'>
                          <div style={divStyle} className='cardDynNFTBlock'>
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                  </div>
                                  <div className='cardDynNFTBoxIn2Empty'></div>
                                  <div className='cardDynNFTBoxIn3'>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Power</span>
                                      <span className='lineAttribut'>
                                        {NFTpowerRounded}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Damage</span>
                                      <span className='lineAttribut'>
                                        {damage_min} - {damage_max}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>HP</span>
                                      <span className='lineAttribut'>
                                        {NFTlife}
                                      </span>
                                    </div>
                                    <div className='cardDynNFTInfosLine'>
                                      <span className='lineTitle'>Speed</span>
                                      <span className='lineAttribut'>
                                        {NFTspeed}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardDynNFTPictureBox'>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='infosBox'>
                          <div className='infosSubTitle'>Weapon Selection</div>
                        </div>
                        <div className='textImportant'>
                          Select a weapon to equip your warrior for the fight.
                        </div>

                        <div className='margin10'>
                          <div className='skillBlock'>
                            <div className='cardSkillBlock'>
                              <div className='cardSkillContainer'>
                                <div
                                  className='cardSkillBox'
                                  style={swordStyle}
                                >
                                  <div className='cardSkillBoxIn'>
                                    <div className='cardSkillInfos'>
                                      <div className='cardSkillTitle'>
                                        Sword
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusUp'>Strong</span>
                                        &nbsp;against Dagger
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusDown'>Weak</span>
                                        &nbsp;against Staff
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedSkill == 1 ? (
                                  <div className='cardSkillPictureBox'>
                                    <div className='overlay'>
                                      <div className='overlayText'>
                                        Selected
                                      </div>
                                    </div>
                                    <img
                                      src={`${sword}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className='cardSkillPictureBox'
                                    onClick={() => setSelectedSkill(1)}
                                  >
                                    <div className='overlayHover'>
                                      <div className='overlayHoverText'>
                                        Select
                                      </div>
                                    </div>
                                    <img
                                      src={`${sword}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='cardSkillBlock'>
                              <div className='cardSkillContainer'>
                                <div
                                  className='cardSkillBox'
                                  style={daggerStyle}
                                >
                                  <div className='cardSkillBoxIn'>
                                    <div className='cardSkillInfos'>
                                      <div className='cardSkillTitle'>
                                        Dagger
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusUp'>Strong</span>
                                        &nbsp;against Staff
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusDown'>Weak</span>
                                        &nbsp;against Sword
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedSkill == 2 ? (
                                  <div className='cardSkillPictureBox'>
                                    <div className='overlay'>
                                      <div className='overlayText'>
                                        Selected
                                      </div>
                                    </div>
                                    <img
                                      src={`${dagger}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className='cardSkillPictureBox'
                                    onClick={() => setSelectedSkill(2)}
                                  >
                                    <div className='overlayHover'>
                                      <div className='overlayHoverText'>
                                        Select
                                      </div>
                                    </div>
                                    <img
                                      src={`${dagger}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='cardSkillBlock'>
                              <div className='cardSkillContainer'>
                                <div
                                  className='cardSkillBox'
                                  style={staffStyle}
                                >
                                  <div className='cardSkillBoxIn'>
                                    <div className='cardSkillInfos'>
                                      <div className='cardSkillTitle'>
                                        Staff
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusUp'>Strong</span>
                                        &nbsp;against Sword
                                      </div>
                                      <div className='cardSkillText'>
                                        <span className='bonusDown'>Weak</span>
                                        &nbsp;against Dagger
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedSkill == 3 ? (
                                  <div className='cardSkillPictureBox'>
                                    <div className='overlay'>
                                      <div className='overlayText'>
                                        Selected
                                      </div>
                                    </div>
                                    <img
                                      src={`${staff}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className='cardSkillPictureBox'
                                    onClick={() => setSelectedSkill(3)}
                                  >
                                    <div className='overlayHover'>
                                      <div className='overlayHoverText'>
                                        Select
                                      </div>
                                    </div>
                                    <img
                                      src={`${staff}`}
                                      className='cardSkillPicture'
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='infosBox'>
                          <div className='infosSubTitle'>Items Selection</div>
                        </div>
                        <div className='textImportant'>
                          Select your items to strengthen your warrior for the
                          fight.
                        </div>

                        <div className='margin10'>
                          <div className='itemBlock'>
                            {items.map((item) => {
                              const item_key = 'item_' + item.id_item;

                              let divItemStyle = {
                                '--rarityColor': item.item_rarityColor
                              } as React.CSSProperties;

                              let itemLabel = '';
                              switch (item.id_item) {
                                case idItemPowerPotion: // Power
                                  itemLabel = 'Power';
                                  break;
                                case idItemLifePotion: // HP
                                  itemLabel = 'HP';
                                  break;
                                case idItemSpeedPotion: // Speed
                                  itemLabel = 'Speed';
                                  break;
                                default:
                                  break;
                              }

                              let available = false;
                              if (item.item_type == 5) {
                                available = true;
                              }

                              let itemIdentifier = '';
                              let itemAvailable = false;
                              let itemsAvailableCount = 0;
                              inventory.map((itemInv) => {
                                if (itemInv.id_item == item.id_item) {
                                  itemsAvailableCount =
                                    itemInv.itemsAvailableCount;

                                  if (itemInv.itemsAvailableCount > 0) {
                                    itemAvailable = true;
                                    itemIdentifier =
                                      itemInv.itemsAvailable[0].identifier;
                                  }
                                }
                              });

                              let isBonus = false;
                              selectedItems.items.map((item2) => {
                                if (item.id_item == item2.id_item) {
                                  isBonus = true;
                                  divItemStyle = {
                                    '--rarityColor':
                                      'rgb(' + hexToRgb('#00FFA3') + ')'
                                  } as React.CSSProperties;
                                }
                              });

                              if (available) {
                                if (itemAvailable) {
                                  return (
                                    <div
                                      key={item_key}
                                      style={divItemStyle}
                                      className='cardItemBlock'
                                    >
                                      <div className='cardItemContainer'>
                                        <div className='cardItemBox'>
                                          <div className='cardItemBoxIn'>
                                            <div className='cardItemInfos'>
                                              <div className='cardArenaItemName'>
                                                {item.item_name}
                                              </div>
                                              <div className='cardItemPower'>
                                                <span className='bonusUp'>
                                                  +{item.item_power}
                                                </span>
                                                &nbsp;{itemLabel}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className='cardItemPictureBox'
                                          onClick={() =>
                                            handleRegistrationItems(
                                              item.id_item,
                                              itemIdentifier
                                            )
                                          }
                                        >
                                          {isBonus ? (
                                            <div className='overlay'>
                                              <div className='overlayText'>
                                                Selected
                                              </div>
                                            </div>
                                          ) : null}
                                          {isBonus ? (
                                            <div className='overlayHoverA'>
                                              <div className='overlayHoverTextA'>
                                                Unselect
                                              </div>
                                            </div>
                                          ) : (
                                            <div className='overlayHoverA'>
                                              <div className='overlayHoverTextA'>
                                                Select
                                              </div>
                                            </div>
                                          )}
                                          <div className='overlayNumber'>
                                            <span className='overlayNumberText'>
                                              x
                                            </span>
                                            <span className='overlayNumberTextNumber'>
                                              {itemsAvailableCount}
                                            </span>
                                          </div>
                                          <img
                                            src={`../${item.item_img}`}
                                            className='cardItemPicture'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      key={item_key}
                                      style={divItemStyle}
                                      className='cardItemBlock'
                                    >
                                      <div className='cardItemContainer'>
                                        <div className='cardItemBox'>
                                          <div className='cardItemBoxIn'>
                                            <div className='cardItemInfos'>
                                              <div className='cardArenaItemName'>
                                                {item.item_name}
                                              </div>
                                              <div className='cardItemPower'>
                                                <span className='bonusUp'>
                                                  +{item.item_power}
                                                </span>
                                                &nbsp;{itemLabel}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='cardItemPictureBox'>
                                          <div className='overlayNumber'>
                                            <span className='overlayNumberText'>
                                              x
                                            </span>
                                            <span className='overlayNumberTextNumber'>
                                              {itemsAvailableCount}
                                            </span>
                                          </div>
                                          <img
                                            src={`../${item.item_img}`}
                                            className='cardItemPicture'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='buttonContainer'>
                          {selectedSkill != 0 ? (
                            <Button
                              className='btnStandardSmall'
                              onClick={registerArena}
                            >
                              Validate
                            </Button>
                          ) : (
                            <Button className='btnStandardSmallDisable'>
                              Validate
                            </Button>
                          )}
                          <Button
                            className='btnStandardSmallReverse'
                            onClick={handleCloseRegistrationInfos}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
        <Modal
          show={showArenaResult}
          onHide={handleCloseArenaResult}
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            {arena_result.map((result, i) => {
              if (result.id == arenaResultId) {
                const fighter_A = result.fighter_A;
                const fighter_B = result.fighter_B;

                const fighter_A_pseudo = fighter_A.user.pseudo;
                const NFT_A_name = fighter_A.attribut.nft_name;
                const NFT_A_url = fighter_A.attribut.nft_url;
                const NFT_A_hierarchy = fighter_A.attribut.nft_hierarchy;
                const NFT_A_style = {
                  '--rarityColor': hierarchyColor[NFT_A_hierarchy]
                } as React.CSSProperties;
                const NFT_A_flg_blockchain = fighter_A.attribut.flg_blockchain;

                const NFT_A_nameT = fighter_A.attribut.nft_name.split(' ');
                const NFT_A_collection = NFT_A_nameT[0];
                let NFT_A_number = '';
                if (NFT_A_nameT[1]) {
                  NFT_A_number = NFT_A_nameT[1].replace('#', '');
                }

                const NFT_A = fighter_A.arena_fights[0];

                let NFT_A_power = fighter_A.attribut.nft_power;
                let NFT_A_life = 0;
                let NFT_A_speed = 0;

                // TODO
                switch (NFT_A_hierarchy) {
                  case 'Warrior':
                    NFT_A_life = 140;
                    NFT_A_speed = 5;
                    break;
                  case 'Samurai':
                    NFT_A_life = 155;
                    NFT_A_speed = 10;
                    break;
                  case 'Daimyos':
                    NFT_A_life = 170;
                    NFT_A_speed = 15;
                    break;
                  case 'Shogun':
                    NFT_A_life = 180;
                    NFT_A_speed = 20;
                    NFT_A_power = 60;
                    break;
                  default:
                    break;
                }

                let skill_A_Style = {
                  '--skillColor': hexToRgb('#FAF7E2')
                } as React.CSSProperties;
                let skill_A_Name = '';
                fighter_A.arena_skills.map((skill) => {
                  switch (skill.id_skill) {
                    case 1:
                      skill_A_Name = 'Sword';
                      skill_A_Style = {
                        '--skillColor': hexToRgb('#9e640c')
                      } as React.CSSProperties;
                      break;
                    case 2:
                      skill_A_Name = 'Dagger';
                      skill_A_Style = {
                        '--skillColor': hexToRgb('#d6da1c')
                      } as React.CSSProperties;
                      break;
                    case 3:
                      skill_A_Name = 'Staff';
                      skill_A_Style = {
                        '--skillColor': hexToRgb('#1d60f0')
                      } as React.CSSProperties;
                      break;
                    default:
                      break;
                  }
                });

                let isDamageBonus_A = false;
                let isLifeBonus_A = false;
                let isSpeedBonus_A = false;
                let damageBonus_A = 0;
                let lifeBonus_A = 0;
                let speedBonus_A = 0;
                fighter_A.arena_items.map((item) => {
                  const bonusValue = item.item.item_power;
                  switch (item.id_item) {
                    case idItemPowerPotion: // Power
                      isDamageBonus_A = true;
                      damageBonus_A = bonusValue;
                      NFT_A_power += bonusValue;
                      break;
                    case idItemLifePotion: // HP
                      isLifeBonus_A = true;
                      lifeBonus_A = bonusValue;
                      NFT_A_life += bonusValue;
                      break;
                    case idItemSpeedPotion: // Speed
                      isSpeedBonus_A = true;
                      speedBonus_A = bonusValue;
                      NFT_A_speed += bonusValue;
                      break;
                    default:
                      break;
                  }
                });

                const NFT_A_powerRounded = NFT_A_power.toFixed(2);

                const damage_A = Math.ceil(NFT_A_power);
                const damage_A_min = Math.round(damage_A * 0.9);
                const damage_A_max = Math.round(damage_A * 1.1);

                // Fighter B
                const fighter_B_pseudo = fighter_B.user.pseudo;
                const NFT_B_name = fighter_B.attribut.nft_name;
                const NFT_B_url = fighter_B.attribut.nft_url;
                const NFT_B_hierarchy = fighter_B.attribut.nft_hierarchy;
                const NFT_B_style = {
                  '--rarityColor': hierarchyColor[NFT_B_hierarchy]
                } as React.CSSProperties;
                const NFT_B_flg_blockchain = fighter_B.attribut.flg_blockchain;

                const NFT_B_nameT = fighter_B.attribut.nft_name.split(' ');
                const NFT_B_collection = NFT_B_nameT[0];
                let NFT_B_number = '';
                if (NFT_B_nameT[1]) {
                  NFT_B_number = NFT_B_nameT[1].replace('#', '');
                }

                const NFT_B = fighter_B.arena_fights[0];

                let NFT_B_power = fighter_B.attribut.nft_power;
                let NFT_B_life = 0;
                let NFT_B_speed = 0;

                // TODO
                switch (NFT_B_hierarchy) {
                  case 'Warrior':
                    NFT_B_life = 140;
                    NFT_B_speed = 5;
                    break;
                  case 'Samurai':
                    NFT_B_life = 155;
                    NFT_B_speed = 10;
                    break;
                  case 'Daimyos':
                    NFT_B_life = 170;
                    NFT_B_speed = 15;
                    break;
                  case 'Shogun':
                    NFT_B_life = 180;
                    NFT_B_speed = 20;
                    NFT_B_power = 60;
                    break;
                  default:
                    break;
                }

                let skill_B_Style = {
                  '--skillColor': hexToRgb('#FAF7E2')
                } as React.CSSProperties;
                let skill_B_Name = '';
                fighter_B.arena_skills.map((skill) => {
                  switch (skill.id_skill) {
                    case 1:
                      skill_B_Name = 'Sword';
                      skill_B_Style = {
                        '--skillColor': hexToRgb('#9e640c')
                      } as React.CSSProperties;
                      break;
                    case 2:
                      skill_B_Name = 'Dagger';
                      skill_B_Style = {
                        '--skillColor': hexToRgb('#d6da1c')
                      } as React.CSSProperties;
                      break;
                    case 3:
                      skill_B_Name = 'Staff';
                      skill_B_Style = {
                        '--skillColor': hexToRgb('#1d60f0')
                      } as React.CSSProperties;
                      break;
                    default:
                      break;
                  }
                });

                let isDamageBonus_B = false;
                let isLifeBonus_B = false;
                let isSpeedBonus_B = false;
                let damageBonus_B = 0;
                let lifeBonus_B = 0;
                let speedBonus_B = 0;
                fighter_B.arena_items.map((item) => {
                  const bonusValue = item.item.item_power;
                  switch (item.id_item) {
                    case idItemPowerPotion: // Power
                      isDamageBonus_B = true;
                      damageBonus_B = bonusValue;
                      NFT_B_power += bonusValue;
                      break;
                    case idItemLifePotion: // HP
                      isLifeBonus_B = true;
                      lifeBonus_B = bonusValue;
                      NFT_B_life += bonusValue;
                      break;
                    case idItemSpeedPotion: // Speed
                      isSpeedBonus_B = true;
                      speedBonus_B = bonusValue;
                      NFT_B_speed += bonusValue;
                      break;
                    default:
                      break;
                  }
                });

                const NFT_B_powerRounded = NFT_B_power.toFixed(2);

                const damage_B = Math.ceil(NFT_B_power);
                const damage_B_min = Math.round(damage_B * 0.9);
                const damage_B_max = Math.round(damage_B * 1.1);

                // Winner
                let winner;
                if (fighter_A.flg_winner) {
                  winner = NFT_A_collection + ' ' + NFT_A_number;
                }
                if (fighter_B.flg_winner) {
                  winner = NFT_B_collection + ' ' + NFT_B_number;
                }

                return (
                  <div key={i}>
                    <div className='card-body p-2'>
                      <div className='infosModalTitle'>
                        Fight {result.id_fight} details
                      </div>
                    </div>

                    <div className='card-body p-2'>
                      <div className='arenaFightContainer'>
                        <div className='arenaFighterLeftBox'>
                          <div className='arenaFighterOut'>
                            <div className='arenaFighterIn'>
                              <div className='arenaFighterText'>
                                {fighter_A_pseudo}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='arenaFightBox'>
                          <div className='arenaFightBoxIn'>
                            <div className='arenaFightBoxInfos'>
                              <div className='roundContainerLeft'>
                                <div className='nftBlock'>
                                  <div
                                    style={NFT_A_style}
                                    className='cardDynNFTBlock'
                                  >
                                    <div className='cardDynNFTContainer'>
                                      <div className='cardDynNFTBox'>
                                        <div className='cardDynNFTBoxIn'>
                                          <div className='cardDynNFTInfos'>
                                            <div className='cardDynNFTHierarchy'>
                                              {NFT_A_hierarchy}
                                            </div>
                                          </div>
                                          <div
                                            style={skill_A_Style}
                                            className='cardDynNFTBoxIn2'
                                          >
                                            <div className='textClass'>
                                              {skill_A_Name}
                                            </div>
                                          </div>
                                          <div className='cardDynNFTBoxIn3'>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Power
                                              </span>
                                              {isDamageBonus_A ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_A_powerRounded}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {damageBonus_A})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_A_powerRounded}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Damage
                                              </span>
                                              {isDamageBonus_A ? (
                                                <span className='lineAttributBonus'>
                                                  {damage_A_min} -&nbsp;
                                                  {damage_A_max}
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {damage_A_min} -&nbsp;
                                                  {damage_A_max}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                HP
                                              </span>
                                              {isLifeBonus_A ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_A_life}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {lifeBonus_A})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_A_life}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Speed
                                              </span>
                                              {isSpeedBonus_A ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_A_speed}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {speedBonus_A})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_A_speed}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='cardDynNFTPictureBox'>
                                        {NFT_A_flg_blockchain ? (
                                          <img
                                            src={`${NFT_A_url}`}
                                            className='cardDynNFTPicture'
                                          />
                                        ) : (
                                          <img
                                            src={`../${NFT_A_url}`}
                                            className='cardDynNFTPicture'
                                          />
                                        )}
                                      </div>
                                      <div className='cardDynNFTInfosBis'>
                                        {!NFT_A_number ? (
                                          <div className='cardDynNFTTitle'>
                                            {NFT_A_collection}
                                          </div>
                                        ) : (
                                          <React.Fragment>
                                            <div className='cardDynNFTNumber'>
                                              {NFT_A_number}
                                            </div>
                                            <div className='cardDynNFTName'>
                                              {NFT_A_collection}
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='arenaFightBoxIn2'>
                              <div className='arenaFightBoxInText2'>
                                <div>
                                  {NFT_A_name} gets a speed roll of&nbsp;
                                  {NFT_A.speed}.
                                </div>
                                {NFT_A.skill_win ? (
                                  <div>
                                    {NFT_A_name} has the weapon advantage !
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='arenaFightContainer'>
                        <div className='arenaFighterRightBox'>
                          <div className='arenaFighterOut'>
                            <div className='arenaFighterIn'>
                              <div className='arenaFighterText'>
                                {fighter_B_pseudo}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='arenaFightBox'>
                          <div className='arenaFightBoxIn'>
                            <div className='arenaFightBoxInfos'>
                              <div className='roundContainerRight'>
                                <div className='nftBlock'>
                                  <div
                                    style={NFT_B_style}
                                    className='cardDynNFTBlock'
                                  >
                                    <div className='cardDynNFTContainer'>
                                      <div className='cardDynNFTBox'>
                                        <div className='cardDynNFTBoxIn'>
                                          <div className='cardDynNFTInfos'>
                                            <div className='cardDynNFTHierarchy'>
                                              {NFT_B_hierarchy}
                                            </div>
                                          </div>
                                          <div
                                            style={skill_B_Style}
                                            className='cardDynNFTBoxIn2'
                                          >
                                            <div className='textClass'>
                                              {skill_B_Name}
                                            </div>
                                          </div>
                                          <div className='cardDynNFTBoxIn3'>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Power
                                              </span>
                                              {isDamageBonus_B ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_B_powerRounded}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {damageBonus_B})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_B_powerRounded}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Damage
                                              </span>
                                              {isDamageBonus_B ? (
                                                <span className='lineAttributBonus'>
                                                  {damage_B_min} -&nbsp;
                                                  {damage_B_max}
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {damage_B_min} -&nbsp;
                                                  {damage_B_max}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                HP
                                              </span>
                                              {isLifeBonus_B ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_B_life}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {lifeBonus_B})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_B_life}
                                                </span>
                                              )}
                                            </div>
                                            <div className='cardDynNFTInfosLine'>
                                              <span className='lineTitle'>
                                                Speed
                                              </span>
                                              {isSpeedBonus_B ? (
                                                <span className='lineAttributBonus'>
                                                  {NFT_B_speed}
                                                  <span className='lineAttributBonus2'>
                                                    &nbsp;(+ {speedBonus_B})
                                                  </span>
                                                </span>
                                              ) : (
                                                <span className='lineAttribut'>
                                                  {NFT_B_speed}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='cardDynNFTPictureBox'>
                                        {NFT_B_flg_blockchain ? (
                                          <img
                                            src={`${NFT_B_url}`}
                                            className='cardDynNFTPicture'
                                          />
                                        ) : (
                                          <img
                                            src={`../${NFT_B_url}`}
                                            className='cardDynNFTPicture'
                                          />
                                        )}
                                      </div>
                                      <div className='cardDynNFTInfosBis'>
                                        {!NFT_B_number ? (
                                          <div className='cardDynNFTTitle'>
                                            {NFT_B_collection}
                                          </div>
                                        ) : (
                                          <React.Fragment>
                                            <div className='cardDynNFTNumber'>
                                              {NFT_B_number}
                                            </div>
                                            <div className='cardDynNFTName'>
                                              {NFT_B_collection}
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='arenaFightBoxIn2'>
                              <div className='arenaFightBoxInText2'>
                                <div>
                                  {NFT_B_name} gets a speed roll of&nbsp;
                                  {NFT_B.speed}.
                                </div>
                                {NFT_B.skill_win ? (
                                  <div>
                                    {NFT_B_name} has the weapon advantage !
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='marginBoth10'>
                      <div className='card-body text-center p-2'>
                        {NFT_A.speed > NFT_B.speed ? (
                          <div className='infosModalTitle'>
                            {NFT_A_collection} {NFT_A_number}
                          </div>
                        ) : (
                          <div className='infosModalTitle'>
                            {NFT_B_collection} {NFT_B_number}
                          </div>
                        )}
                        <div className='deployBoxTitle'>start the fight</div>
                      </div>
                    </div>

                    <div className='card-body text-center p-2'>
                      {fighter_A.arena_fights.map((fight_A, j) => {
                        if (j > 0) {
                          const fight_B = fighter_B.arena_fights[j];

                          if (NFT_A.speed > NFT_B.speed) {
                            const NFT_A_HP_start =
                              fighter_A.arena_fights[j - 1].hp;
                            const NFT_A_HP_end = fight_A.hp;
                            const NFT_B_HP = fight_B.hp;

                            const NFT_A_per = Math.ceil(
                              (NFT_A_HP_start / NFT_A_life) * 100
                            );
                            const NFT_B_per = Math.ceil(
                              (NFT_B_HP / NFT_B_life) * 100
                            );

                            const NFT_A_per_text = NFT_A_per + '%';
                            const NFT_B_per_text = NFT_B_per + '%';

                            const NFT_A_life_style = {
                              '--lifeAPercent': NFT_A_per_text
                            } as React.CSSProperties;
                            const NFT_B_life_style = {
                              '--lifeBPercent': NFT_B_per_text
                            } as React.CSSProperties;

                            return (
                              <React.Fragment key={j}>
                                <div className='arenaFightContainer'>
                                  <div className='arenaFighterLeftBox'>
                                    <div className='arenaFighterOut'>
                                      <div className='arenaFighterIn'>
                                        <div className='arenaFighterText'>
                                          {fighter_A_pseudo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='arenaFightBox'>
                                    <div className='arenaFightBoxIn'>
                                      <div className='arenaFightBoxInfos'>
                                        <div className='roundContainerLeft'>
                                          <div className='roundCardLeft'>
                                            {NFT_A_flg_blockchain ? (
                                              <img
                                                src={`${NFT_A_url}`}
                                                className='roundCardNFT'
                                              />
                                            ) : (
                                              <img
                                                src={`../${NFT_A_url}`}
                                                className='roundCardNFT'
                                              />
                                            )}
                                          </div>
                                          <div className='roundInfosLeft'>
                                            <div className='roundInfosName'>
                                              {!NFT_A_number ? (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_A_collection}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    &nbsp;
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_A_number}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    {NFT_A_collection}
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                            <div className='roundCardLife'>
                                              <div className='roundCardLeftLifeBox'>
                                                <div className='roundCardLeftLifeOut'>
                                                  <div
                                                    style={NFT_A_life_style}
                                                    className='roundCardLeftLifeIn'
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className='roundCardLeftLifeIcon'>
                                                <FontAwesomeIcon
                                                  icon={faHeart}
                                                />
                                              </div>
                                              <div className='roundCardLifeTextBox'>
                                                <div className='roundCardLifeText'>
                                                  {NFT_A_HP_start}
                                                  <span className='roundCardLifeTextSep'>
                                                    /
                                                  </span>
                                                  {NFT_A_life}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='arenaFightBoxIn2'>
                                        <div className='arenaFightBoxInText2'>
                                          {NFT_A_name} inflict&nbsp;
                                          {fight_A.damage}
                                          {fight_A.skill_win
                                            ? ' critical'
                                            : null}
                                          &nbsp;damage.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='arenaFightContainer'>
                                  <div className='arenaFighterRightBox'>
                                    <div className='arenaFighterOut'>
                                      <div className='arenaFighterIn'>
                                        <div className='arenaFighterText'>
                                          {fighter_B_pseudo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='arenaFightBox'>
                                    <div className='arenaFightBoxIn'>
                                      <div className='arenaFightBoxInfos'>
                                        <div className='roundContainerRight'>
                                          <div className='roundInfosRight'>
                                            <div className='roundInfosName'>
                                              {!NFT_B_number ? (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_B_collection}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    &nbsp;
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_B_number}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    {NFT_B_collection}
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                            <div className='roundCardLife'>
                                              <div className='roundCardRightLifeBox'>
                                                <div className='roundCardRightLifeOut'>
                                                  {NFT_B_HP > 0 ? (
                                                    <div
                                                      style={NFT_B_life_style}
                                                      className='roundCardRightLifeIn'
                                                    ></div>
                                                  ) : (
                                                    <div className='roundCardRightLifeIn'></div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className='roundCardRightLifeIcon'>
                                                <FontAwesomeIcon
                                                  icon={faHeart}
                                                />
                                              </div>
                                              <div className='roundCardLifeTextBox'>
                                                <div className='roundCardLifeText'>
                                                  {NFT_B_HP}
                                                  <span className='roundCardLifeTextSep'>
                                                    /
                                                  </span>
                                                  {NFT_B_life}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='roundCardRight'>
                                            {NFT_B_flg_blockchain ? (
                                              <img
                                                src={`${NFT_B_url}`}
                                                className='roundCardNFT'
                                              />
                                            ) : (
                                              <img
                                                src={`../${NFT_B_url}`}
                                                className='roundCardNFT'
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='arenaFightBoxIn2'>
                                        <div className='arenaFightBoxInText2'>
                                          {NFT_B_HP > 0 ? (
                                            <span>
                                              {NFT_B_name} inflict&nbsp;
                                              {fight_B.damage}
                                              {fight_B.skill_win
                                                ? ' critical'
                                                : null}
                                              &nbsp;damage.
                                            </span>
                                          ) : (
                                            <span>{NFT_B_name} is KO !</span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {NFT_A_HP_end == 0 ? (
                                  <div className='arenaFightContainer'>
                                    <div className='arenaFighterLeftBox'>
                                      <div className='arenaFighterOut'>
                                        <div className='arenaFighterIn'>
                                          <div className='arenaFighterText'>
                                            {fighter_A_pseudo}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBox'>
                                      <div className='arenaFightBoxIn'>
                                        <div className='arenaFightBoxInfos'>
                                          <div className='roundContainerLeft'>
                                            <div className='roundCardLeft'>
                                              {NFT_A_flg_blockchain ? (
                                                <img
                                                  src={`${NFT_A_url}`}
                                                  className='roundCardNFT'
                                                />
                                              ) : (
                                                <img
                                                  src={`../${NFT_A_url}`}
                                                  className='roundCardNFT'
                                                />
                                              )}
                                            </div>
                                            <div className='roundInfosLeft'>
                                              <div className='roundInfosName'>
                                                {!NFT_A_number ? (
                                                  <React.Fragment>
                                                    <div className='cardDynNFTNumber'>
                                                      {NFT_A_collection}
                                                    </div>
                                                    <div className='cardDynNFTName'>
                                                      &nbsp;
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <div className='cardDynNFTNumber'>
                                                      {NFT_A_number}
                                                    </div>
                                                    <div className='cardDynNFTName'>
                                                      {NFT_A_collection}
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                              </div>
                                              <div className='roundCardLife'>
                                                <div className='roundCardLeftLifeBox'>
                                                  <div className='roundCardLeftLifeOut'>
                                                    <div className='roundCardLeftLifeIn'></div>
                                                  </div>
                                                </div>
                                                <div className='roundCardLeftLifeIcon'>
                                                  <FontAwesomeIcon
                                                    icon={faHeart}
                                                  />
                                                </div>
                                                <div className='roundCardLifeTextBox'>
                                                  <div className='roundCardLifeText'>
                                                    {NFT_A_HP_end}
                                                    <span className='roundCardLifeTextSep'>
                                                      /
                                                    </span>
                                                    {NFT_A_life}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='arenaFightBoxIn2'>
                                          <div className='arenaFightBoxInText2'>
                                            {NFT_A_name} is KO !
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </React.Fragment>
                            );
                          } else {
                            const NFT_A_HP = fight_A.hp;
                            const NFT_B_HP_start =
                              fighter_B.arena_fights[j - 1].hp;
                            const NFT_B_HP_end = fight_B.hp;

                            const NFT_A_per = Math.ceil(
                              (NFT_A_HP / NFT_A_life) * 100
                            );
                            const NFT_B_per = Math.ceil(
                              (NFT_B_HP_start / NFT_B_life) * 100
                            );

                            const NFT_A_per_text = NFT_A_per + '%';
                            const NFT_B_per_text = NFT_B_per + '%';

                            const NFT_A_life_style = {
                              '--lifeAPercent': NFT_A_per_text
                            } as React.CSSProperties;
                            const NFT_B_life_style = {
                              '--lifeBPercent': NFT_B_per_text
                            } as React.CSSProperties;

                            return (
                              <React.Fragment key={j}>
                                <div className='arenaFightContainer'>
                                  <div className='arenaFighterRightBox'>
                                    <div className='arenaFighterOut'>
                                      <div className='arenaFighterIn'>
                                        <div className='arenaFighterText'>
                                          {fighter_B_pseudo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='arenaFightBox'>
                                    <div className='arenaFightBoxIn'>
                                      <div className='arenaFightBoxInfos'>
                                        <div className='roundContainerRight'>
                                          <div className='roundInfosRight'>
                                            <div className='roundInfosName'>
                                              {!NFT_B_number ? (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_B_collection}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    &nbsp;
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_B_number}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    {NFT_B_collection}
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                            <div className='roundCardLife'>
                                              <div className='roundCardRightLifeBox'>
                                                <div className='roundCardRightLifeOut'>
                                                  <div
                                                    style={NFT_B_life_style}
                                                    className='roundCardRightLifeIn'
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className='roundCardRightLifeIcon'>
                                                <FontAwesomeIcon
                                                  icon={faHeart}
                                                />
                                              </div>
                                              <div className='roundCardLifeTextBox'>
                                                <div className='roundCardLifeText'>
                                                  {NFT_B_HP_start}
                                                  <span className='roundCardLifeTextSep'>
                                                    /
                                                  </span>
                                                  {NFT_B_life}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='roundCardRight'>
                                            {NFT_B_flg_blockchain ? (
                                              <img
                                                src={`${NFT_B_url}`}
                                                className='roundCardNFT'
                                              />
                                            ) : (
                                              <img
                                                src={`../${NFT_B_url}`}
                                                className='roundCardNFT'
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='arenaFightBoxIn2'>
                                        <div className='arenaFightBoxInText2'>
                                          {NFT_B_name} inflict&nbsp;
                                          {fight_B.damage}
                                          {fight_B.skill_win
                                            ? ' critical'
                                            : null}
                                          &nbsp;damage.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='arenaFightContainer'>
                                  <div className='arenaFighterLeftBox'>
                                    <div className='arenaFighterOut'>
                                      <div className='arenaFighterIn'>
                                        <div className='arenaFighterText'>
                                          {fighter_A_pseudo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='arenaFightBox'>
                                    <div className='arenaFightBoxIn'>
                                      <div className='arenaFightBoxInfos'>
                                        <div className='roundContainerLeft'>
                                          <div className='roundCardLeft'>
                                            {NFT_A_flg_blockchain ? (
                                              <img
                                                src={`${NFT_A_url}`}
                                                className='roundCardNFT'
                                              />
                                            ) : (
                                              <img
                                                src={`../${NFT_A_url}`}
                                                className='roundCardNFT'
                                              />
                                            )}
                                          </div>
                                          <div className='roundInfosLeft'>
                                            <div className='roundInfosName'>
                                              {!NFT_A_number ? (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_A_collection}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    &nbsp;
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div className='cardDynNFTNumber'>
                                                    {NFT_A_number}
                                                  </div>
                                                  <div className='cardDynNFTName'>
                                                    {NFT_A_collection}
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                            <div className='roundCardLife'>
                                              <div className='roundCardLeftLifeBox'>
                                                <div className='roundCardLeftLifeOut'>
                                                  {NFT_A_HP > 0 ? (
                                                    <div
                                                      style={NFT_A_life_style}
                                                      className='roundCardLeftLifeIn'
                                                    ></div>
                                                  ) : (
                                                    <div className='roundCardLeftLifeIn'></div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className='roundCardLeftLifeIcon'>
                                                <FontAwesomeIcon
                                                  icon={faHeart}
                                                />
                                              </div>
                                              <div className='roundCardLifeTextBox'>
                                                <div className='roundCardLifeText'>
                                                  {NFT_A_HP}
                                                  <span className='roundCardLifeTextSep'>
                                                    /
                                                  </span>
                                                  {NFT_A_life}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='arenaFightBoxIn2'>
                                        <div className='arenaFightBoxInText2'>
                                          {NFT_A_HP > 0 ? (
                                            <span>
                                              {NFT_A_name} inflict&nbsp;
                                              {fight_A.damage}
                                              {fight_A.skill_win
                                                ? ' critical'
                                                : null}
                                              &nbsp;damage.
                                            </span>
                                          ) : (
                                            <span>{NFT_A_name} is KO !</span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {NFT_B_HP_end == 0 ? (
                                  <div className='arenaFightContainer'>
                                    <div className='arenaFighterRightBox'>
                                      <div className='arenaFighterOut'>
                                        <div className='arenaFighterIn'>
                                          <div className='arenaFighterText'>
                                            {fighter_B_pseudo}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBox'>
                                      <div className='arenaFightBoxIn'>
                                        <div className='arenaFightBoxInfos'>
                                          <div className='roundContainerRight'>
                                            <div className='roundInfosRight'>
                                              <div className='roundInfosName'>
                                                {!NFT_B_number ? (
                                                  <React.Fragment>
                                                    <div className='cardDynNFTNumber'>
                                                      {NFT_B_collection}
                                                    </div>
                                                    <div className='cardDynNFTName'>
                                                      &nbsp;
                                                    </div>
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    <div className='cardDynNFTNumber'>
                                                      {NFT_B_number}
                                                    </div>
                                                    <div className='cardDynNFTName'>
                                                      {NFT_B_collection}
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                              </div>
                                              <div className='roundCardLife'>
                                                <div className='roundCardRightLifeBox'>
                                                  <div className='roundCardRightLifeOut'>
                                                    <div className='roundCardRightLifeIn'></div>
                                                  </div>
                                                </div>
                                                <div className='roundCardRightLifeIcon'>
                                                  <FontAwesomeIcon
                                                    icon={faHeart}
                                                  />
                                                </div>
                                                <div className='roundCardLifeTextBox'>
                                                  <div className='roundCardLifeText'>
                                                    {NFT_B_HP_end}
                                                    <span className='roundCardLifeTextSep'>
                                                      /
                                                    </span>
                                                    {NFT_B_life}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='roundCardRight'>
                                              {NFT_B_flg_blockchain ? (
                                                <img
                                                  src={`${NFT_B_url}`}
                                                  className='roundCardNFT'
                                                />
                                              ) : (
                                                <img
                                                  src={`../${NFT_B_url}`}
                                                  className='roundCardNFT'
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='arenaFightBoxIn2'>
                                          <div className='arenaFightBoxInText2'>
                                            {NFT_B_name} is KO !
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </React.Fragment>
                            );
                          }
                        }
                      })}
                    </div>

                    <div className='margin10'>
                      <div className='card-body text-center p-2'>
                        <div className='infosModalTitle'>{winner}</div>
                        <div className='deployBoxTitle'>wins the fight</div>
                      </div>
                    </div>

                    <div className='margin10'>
                      <div className='card-body p-2'>
                        <div className='buttonContainer'>
                          <Button
                            className='btnStandardSmallReverse'
                            onClick={handleCloseArenaResult}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
        <Modal
          show={showSortModal}
          onHide={handleCloseSortModal}
          size='sm'
          centered
        >
          <Modal.Body className='modalContainer'>
            <div className='card-body p-2'>
              <div className='infosModalTitle'>Sort by</div>
            </div>

            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <FormControl>
                  <RadioGroup value={sortType} onChange={handleChangeSort}>
                    <FormControlLabel
                      className='radioGroupLabel'
                      value='hierarchy'
                      control={<Radio />}
                      label='Hierarchy'
                    />
                    <FormControlLabel
                      className='radioGroupLabel'
                      value='power'
                      control={<Radio />}
                      label='Power'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className='margin20'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseSortModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showRules} onHide={handleCloseRules} centered scrollable>
          <Modal.Body className='modalContainer'>
            <div className='card-body p-2'>
              <div className='infosModalTitle'>Ranked Mode Rules</div>
            </div>

            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <div className='textImportant'>
                  Fighters gain or lose&nbsp;
                  <span className='textImportantRed'>2</span>
                  &nbsp;to&nbsp;
                  <span className='textImportantRed'>30</span>
                  &nbsp;ELO points based on the opponent&apos;s rank.
                </div>
                <div className='textImportant margin20'>
                  Engage in up to&nbsp;
                  <span className='textImportantRed'>
                    {maxRegistrationPerDay}
                  </span>
                  &nbsp;fights a day per NFT.
                  <br />
                  Register&nbsp;
                  <span className='textImportantRed'>2</span>
                  &nbsp;to&nbsp;
                  <span className='textImportantRed'>5</span>
                  &nbsp;fighters from your collection.
                </div>
                <div className='textImportant margin20'>
                  The one who KO his opponent wins.
                  <br />
                  Damage is based on the fighter&apos;s power.
                  <br />
                  Choose a weapon for your fighter, the right matchup doubles
                  damage.
                </div>
                <div className='textImportant margin20'>
                  The fighter with the best speed roll, determined between his
                  speed and 50, goes first.
                </div>
                <div className='textImportant margin20'>
                  Fighters can be strengthened with potions.
                </div>
              </div>
            </div>

            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseRules}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
};

export default ArenaRanked;
