import * as React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const Profil = () => {
  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [factionImg, setFactionImg] = React.useState<string>('');

  const fetchUserInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  React.useEffect(() => {
    if (userInfos.user.id_user != 0) {
      fetchUserInfos();
    }
  }, [userInfos]);

  const { user } = userInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageTitle'>My Profile</div>
            </div>
          </div>

          {userInfos.status == 'failed' ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{userInfos.message}</div>
              </div>
            </div>
          ) : null}

          {userInfos.status == 'success' ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <p className='separtorLine'>
                  <span className='textTitle'>Pseudo : </span>
                  <span className='textAttribut'>{user.pseudo}</span>
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>Faction : </span>
                  <span className='textAttribut'>{user.faction?.name}</span>
                  <img src={`${factionImg}`} className='imgFactionProfile' />
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>
                    Delegation
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          If you activate it, you&apos;ll give your faction
                          leaders the ability to strategically redeploy to
                          another area your deployed NFTs.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                    &nbsp;&nbsp;:
                  </span>
                  &nbsp;
                  {user.flg_delegation ? (
                    <span className='textAttribut'>YES</span>
                  ) : (
                    <span className='textAttribut'>NO</span>
                  )}
                </p>

                <p className='separtorLine'>
                  <span className='textTitle'>
                    Faction contribution
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect' className='textTooltip'>
                          Percentage of conquest rewards you share with your
                          faction.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                    &nbsp;&nbsp;:
                  </span>
                  &nbsp;
                  <span className='textAttribut'>
                    {user.faction_contribution} %
                  </span>
                </p>

                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={() => navigate(routeNames.editprofile)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Profil;
