import * as React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, MenuItem } from '@mui/material';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import TournamentsDataService from '../../../services/tournaments.service';
import { TournamentsStateType } from '../../../types/tournaments.type';

const tournamentModes = [{ name: 'Special', mode: 1 }];

const tournamentSlots = [
  { name: '16', slots: 16 },
  { name: '32', slots: 32 },
  { name: '64', slots: 64 }
];

const tournamentRegistrationDuration = [
  { name: '2 min', time: 2 },
  { name: '5 min', time: 5 },
  { name: '15 min', time: 15 }
];

const tournamentFightDuration = [
  { name: '30 sec', time: 30 },
  { name: '1 min', time: 60 },
  { name: '2 min', time: 120 }
];

const Tournament = () => {
  const navigate = useNavigate();

  const { userSession } = useAuthContext();

  const [tournamentsInfos, setTournamentsInfos] =
    React.useState<TournamentsStateType>({
      tournaments: [],
      message: '',
      status: ''
    });

  const [formTournament, setFormTournament] = React.useState({
    mode: 1,
    slots: 16,
    flg_bots: true,
    registration_duration: 5,
    fight_duration: 60
  });

  const handleChange = (name: string, value: any) => {
    setFormTournament({
      ...formTournament,
      [name]: value
    });
  };

  const [isTournament, setIsTournament] = React.useState<boolean>();

  const [showCreEdit, setShowCreEdit] = React.useState(false);
  const [creationMode, setCreationMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const handleCloseCreEdit = () => {
    setCreationMode(false);
    setEditMode(false);
    setShowCreEdit(false);
  };

  const handleShowCreation = () => {
    setCreationMode(true);
    setShowCreEdit(true);
  };

  const handleTournamentCreation = () => {
    const dataTournament: any = {
      id_user: userSession.userID,
      mode: formTournament.mode,
      slots: formTournament.slots,
      flg_bots: formTournament.flg_bots,
      registration_duration: formTournament.registration_duration,
      fight_duration: formTournament.fight_duration
    };

    TournamentsDataService.createEvent(dataTournament).then(({ data }) => {
      if (data.status == 'success') {
        //setMessageSuccess(data.message);
        fetchTournamentsData();
      } else {
        //setMessage(data.message);
      }
      handleCloseCreEdit();
    });
  };

  const fetchTournamentsData = () => {
    TournamentsDataService.findAll().then(({ data }) => {
      if (data.status == 'success') {
        setTournamentsInfos({
          tournaments: data.tournaments,
          status: data.status
        });

        let flg_tournament = false;

        data.tournaments.map((tournament) => {
          if (tournament.step < 3) flg_tournament = true;
        });

        setIsTournament(flg_tournament);
      }
    });
  };

  React.useEffect(() => {
    fetchTournamentsData();
  }, []);

  const { tournaments } = tournamentsInfos;

  return (
    <>
      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Tournaments</div>
            <div className='infosTitle'>TODO</div>
          </div>
          <div className='infosBoxSub'>
            <div className='buttonsNFTContainer'>
              {isTournament ? (
                <React.Fragment>
                  <Button disabled className='btnStandardReverse'>
                    Create
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className='btnStandardReverse'
                    onClick={handleShowCreation}
                  >
                    Create
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Upcoming Tournaments</div>
          </div>
          {tournaments.map((tournament, i) => {
            let flgSel = false;

            if (tournament.step == 1) flgSel = true;

            if (flgSel) {
              return (
                <div className='squadContainer' key={i}>
                  <div className='squadInfosContainer'>
                    <div className='squadTitle'>
                      <div className='squadTextContainer'>
                        <div className='squadText'>
                          Tournament {tournament.id_tournament}
                        </div>
                      </div>
                    </div>
                    <div className='squadInfos'>
                      <div className='dataContainer'>
                        <div className='dataSepartorLine'>
                          <span className='dataTitle'>Total Slots</span>
                          <span className='dataAttribut'>
                            {tournament.nb_slots}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className='actionContainer'>
                    <div
                      className='actionIcon'
                      onClick={() => handleShowManage(squad)}
                    >
                      <FontAwesomeIcon icon={faCog} className='fa' />
                    </div>
                  </div>
                  */}
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Ongoing Tournaments</div>
          </div>
          {tournaments.map((tournament, i) => {
            let flgSel = false;

            if (tournament.step == 2) flgSel = true;

            if (flgSel) {
              return (
                <div className='squadContainer' key={i}>
                  <div className='squadInfosContainer'>
                    <div className='squadTitle'>
                      <div className='squadTextContainer'>
                        <div className='squadText'>
                          Tournament {tournament.id_tournament}
                        </div>
                      </div>
                    </div>
                    <div className='squadInfos'>
                      <div className='dataContainer'>
                        <div className='dataSepartorLine'>
                          <span className='dataTitle'>Total Slots</span>
                          <span className='dataAttribut'>
                            {tournament.nb_slots}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className='actionContainer'>
                    <div
                      className='actionIcon'
                      onClick={() => handleShowManage(squad)}
                    >
                      <FontAwesomeIcon icon={faCog} className='fa' />
                    </div>
                  </div>
                  */}
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className='boxContainer topContainer'>
        <div className='card-body p-2'>
          <div className='infosBox'>
            <div className='infosSubTitle'>Past Tournaments</div>
          </div>
          {tournaments.map((tournament, i) => {
            let flgSel = false;

            if (tournament.step == 3) flgSel = true;

            if (flgSel) {
              return (
                <div className='squadContainer' key={i}>
                  <div className='squadInfosContainer'>
                    <div className='squadTitle'>
                      <div className='squadTextContainer'>
                        <div className='squadText'>
                          Tournament {tournament.id_tournament}
                        </div>
                      </div>
                    </div>
                    <div className='squadInfos'>
                      <div className='dataContainer'>
                        <div className='dataSepartorLine'>
                          <span className='dataTitle'>Total Slots</span>
                          <span className='dataAttribut'>
                            {tournament.nb_slots}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className='actionContainer'>
                    <div
                      className='actionIcon'
                      onClick={() => handleShowManage(squad)}
                    >
                      <FontAwesomeIcon icon={faCog} className='fa' />
                    </div>
                  </div>
                  */}
                </div>
              );
            }
          })}
        </div>
      </div>

      <Modal show={showCreEdit} onHide={handleCloseCreEdit} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            {creationMode ? (
              <div className='infosModalTitle'>Tournament Creation</div>
            ) : null}
            {editMode ? (
              <div className='infosModalTitle'>Tournament Edition</div>
            ) : null}
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>Mode</Form.Label>
              </Form.Row>
              <Form.Row className='separtorLine'>
                <Col xs='auto'>
                  <TextField
                    select
                    value={formTournament.mode}
                    variant='standard'
                    onChange={(e) => handleChange('mode', e.target.value)}
                  >
                    {tournamentModes.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.mode}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>Slots</Form.Label>
              </Form.Row>
              <Form.Row className='separtorLine'>
                <Col xs='auto'>
                  <TextField
                    select
                    value={formTournament.slots}
                    variant='standard'
                    onChange={(e) => handleChange('slots', e.target.value)}
                  >
                    {tournamentSlots.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.slots}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>
                  Registration duration
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltipConnect' className='textTooltip'>
                        Registration deadlines between tournament creation and
                        first fight
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className='iconInfo' />
                  </OverlayTrigger>
                </Form.Label>
              </Form.Row>
              <Form.Row className='separtorLine'>
                <Col xs='auto'>
                  <TextField
                    select
                    value={formTournament.registration_duration}
                    variant='standard'
                    onChange={(e) =>
                      handleChange('registration_duration', e.target.value)
                    }
                  >
                    {tournamentRegistrationDuration.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.time}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>
                  Fights duration
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltipConnect' className='textTooltip'>
                        Time between fights
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className='iconInfo' />
                  </OverlayTrigger>
                </Form.Label>
              </Form.Row>
              <Form.Row className='separtorLine'>
                <Col xs='auto'>
                  <TextField
                    select
                    value={formTournament.fight_duration}
                    variant='standard'
                    onChange={(e) =>
                      handleChange('fight_duration', e.target.value)
                    }
                  >
                    {tournamentFightDuration.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.time}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>
                  Bots
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltipConnect' className='textTooltip'>
                        To fill empty slots
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className='iconInfo' />
                  </OverlayTrigger>
                </Form.Label>
              </Form.Row>
              <Form.Row>
                <Col xs='auto'>
                  <div className='switchBtn'>
                    <BootstrapSwitchButton
                      checked={formTournament.flg_bots}
                      disabled
                      onChange={(checked: boolean) =>
                        handleChange('flg_bots', checked)
                      }
                      width={60}
                      onlabel='YES'
                      offlabel='NO'
                    />
                  </div>
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              {creationMode ? (
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleTournamentCreation}
                  >
                    Create
                  </Button>
                  <Button
                    className='btnStandardSmall'
                    onClick={handleCloseCreEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
              {/*
              {editMode ? (
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleSquadEdit}
                  >
                    Save
                  </Button>
                  <Button
                    className='btnStandardSmall'
                    onClick={handleCloseCreEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
              */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tournament;
