import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';

const NavMenuItem = () => {
  const { userSession, handleLogOut } = useAuthContext();

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className='card-body p-2'>
        <div className='infosModalTitle'>Menu</div>
      </div>

      <div className='margin10'>
        <div className='card-body p-2'>
          <div className='settingsMenuBox'>
            <div className='settingsMenuTitle'>Player</div>
          </div>
          <div className='buttonContainer'>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.profile)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Profile</div>
              </div>
            </div>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.wallet)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Wallet</div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='settingsMenuBox'>
            <div className='settingsMenuTitle'>NFTs</div>
          </div>
          <div className='buttonContainer'>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.nftsearch)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>NFTs Search</div>
              </div>
            </div>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.nftswallet)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>NFTs</div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='settingsMenuBox'>
            <div className='settingsMenuTitle'>Items</div>
          </div>
          <div className='buttonContainer'>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.marketplace)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Marketplace</div>
              </div>
            </div>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.inventory)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Inventory</div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body p-2'>
          <div className='settingsMenuBox'>
            <div className='settingsMenuTitle'>Miscellaneous</div>
          </div>
          <div className='buttonContainer'>
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.factionsboard)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Factions</div>
              </div>
            </div>
            {/*
            <div
              className='btnMenu'
              onClick={() => navigate(routeNames.betafeatures)}
            >
              <div className='btnMenuIn'>
                <div className='btnMenuText'>Beta Features</div>
              </div>
            </div>
            */}
          </div>
        </div>
        {userSession.isAllowed ? (
          <div className='card-body p-2'>
            <div className='settingsMenuBox'>
              <div className='settingsMenuTitle'>Administration</div>
            </div>
            <div className='buttonContainer'>
              <div className='btnMenu' onClick={() => navigate('/jcorpgest')}>
                <div className='btnMenuIn'>
                  <div className='btnMenuText'>Administration</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className='logOffContainer'>
        <div onClick={handleLogOut} className='btnConnectSmall'>
          <div className='btnConnectSmallIn'>
            <div className='btnConnectSmallText'>LOG OUT</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavMenuItem;
