import http from '../http-common';
import {
  IArenaRegistrationData,
  ArenaRegistrationCreation,
  ArenaRegistrationsStateType,
  ArenaRegistrationsDailyStateType
} from '../types/arenaregistrations.type';

class ArenaRegistrationsDataService {
  create(data: IArenaRegistrationData) {
    return http.post<ArenaRegistrationCreation>('/arena_registrations', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/arena_registrations/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/arena_registrations/${id}`);
  }

  findAllDailyRegisteredByUser(id_user: any, params: any) {
    return http.get<ArenaRegistrationsDailyStateType>(
      `/arena_registrations/user_daily/${id_user}`,
      {
        params
      }
    );
  }

  findAllRegisteredByUser(id_user: any, params: any) {
    return http.get<ArenaRegistrationsStateType>(
      `/arena_registrations/user/${id_user}`,
      {
        params
      }
    );
  }

  findAllRegistered(params: any) {
    return http.get<ArenaRegistrationsStateType>('/arena_registrations/', {
      params
    });
  }
}

export default new ArenaRegistrationsDataService();
