import http from '../http-common';
import { FactionStateType, FactionsStateType } from '../types/faction.type';

class FactionDataService {
  update(data: any, id: any) {
    return http.put<any>(`/faction/${id}`, data);
  }

  getAll() {
    return http.get<FactionsStateType>('/faction');
  }

  findOneById(id_faction: any) {
    return http.get<FactionStateType>(`/faction/${id_faction}`);
  }
}

export default new FactionDataService();
