export const tournamentEmpty: ITournamentsData = {
  id_tournament: 0,
  tournament_mode: 0,
  step: 0,
  flg_bots: false,
  nb_slots: 0,
  fight_duration: 0,
  nb_registrations: 0,
  id_fight: 0,
  id_fight_registration: 0,
  currentPhase: new Date(),
  nextPhase: new Date(),
  tournament_phases: []
};

export interface ITournamentPhasesData {
  id?: any | null;
  id_tournament: number;
  tournament_phase: number;
  nb_slots: number;
  nb_fights: number;
}

export interface ITournamentCreationData {
  id_tournament?: any | null;
  tournament_mode: number;
  step: number;
  flg_bots: boolean;
  nb_slots: number;
  fight_duration: number;
  nb_registrations: number;
  id_fight: number;
  id_fight_registration: number;
  currentPhase: Date;
  nextPhase: Date;
}

export interface ITournamentsData {
  id_tournament?: any | null;
  tournament_mode: number;
  step: number;
  flg_bots: boolean;
  nb_slots: number;
  fight_duration: number;
  nb_registrations: number;
  id_fight: number;
  id_fight_registration: number;
  currentPhase: Date;
  nextPhase: Date;
  tournament_phases: ITournamentPhasesData[];
}

export interface TournamentsStateType {
  tournaments: ITournamentsData[];
  message?: string;
  status: string;
}

export interface TournamentStateType {
  tournament: ITournamentsData;
  message?: string;
  status: string;
}

export interface TournamentCreation {
  message?: string;
  status: string;
}
