import * as React from 'react';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import FactionDataService from '../../services/faction.service';
import FactionsInventoriesDataService from '../../services/factionsinventories.service';
import { FactionStateType, factionEmpty } from '../../types/faction.type';
import {
  FactionInventoryStateType,
  FactionInventoryInfos,
  factionInventoryEmpty
} from '../../types/factionsinventories.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const FactionInventory = () => {
  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [showPower, setShowPower] = React.useState(true);
  const [showBonus, setShowBonus] = React.useState(false);
  const handleShowPower = () => {
    setShowPower(true);
    setShowBonus(false);
  };
  const handleShowBonus = () => {
    setShowPower(false);
    setShowBonus(true);
  };

  const [factionImg, setFactionImg] = React.useState('');
  const [factionInfos, setFactionInfos] = React.useState<FactionStateType>({
    faction: factionEmpty,
    message: '',
    status: ''
  });
  const [inventoryInfos, setInventoryInfos] =
    React.useState<FactionInventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [inventoryGrpInfos, setInventoryGrpInfos] =
    React.useState<FactionInventoryInfos>({
      factionInventory: []
    });

  const fetchFactionsData = () => {
    FactionDataService.findOneById(userInfos.user.id_faction).then(
      ({ data }) => {
        if (data.status == 'success') {
          setFactionInfos({
            faction: data.faction,
            status: data.status
          });
        }
      }
    );
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const fetchInventoryData = () => {
    FactionsInventoriesDataService.findAllByFaction(
      userInfos.user.id_faction
    ).then(({ data }) => {
      if (data.status == 'success') {
        setInventoryInfos({
          inventory: data.inventory,
          status: data.status
        });
      }
    });
  };

  const handleInventoryGrpInfos = () => {
    let inventory_item_type = 0;
    if (showPower) inventory_item_type = 2;
    if (showBonus) inventory_item_type = 4;

    let prevIdItem = 0;
    let itemCount = 0;
    let dataInventory = factionInventoryEmpty;
    const inventory: FactionInventoryInfos = {
      factionInventory: []
    };
    inventoryInfos.inventory.map((item) => {
      if (item.id_item != prevIdItem) {
        if (prevIdItem != 0 && itemCount > 0) {
          inventory.factionInventory.push(dataInventory);
        }

        prevIdItem = item.id_item;
        itemCount = 0;

        dataInventory = {
          id_item: item.id_item,
          img: item.item.item_img,
          itemInfos: item.item,
          itemsAvailableCount: 0,
          itemsAvailable: []
        };
      }
      if (!item.flg_used && item.item.item_type == inventory_item_type) {
        itemCount++;
        dataInventory.itemsAvailableCount++;
        dataInventory.itemsAvailable.push({
          identifier: item.identifier
        });
      }
    });
    if (prevIdItem != 0 && itemCount > 0) {
      inventory.factionInventory.push(dataInventory);
    }
    setInventoryGrpInfos(inventory);
  };

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchFactionsData();
      fetchFactionInfos();
      fetchInventoryData();
    }
  }, []);

  React.useEffect(() => {
    if (inventoryInfos.status == 'success') {
      handleInventoryGrpInfos();
    }
  }, [inventoryInfos, showPower, showBonus]);

  const { faction } = factionInfos;
  const { factionInventory } = inventoryGrpInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestBox'>
                <div className='pageTitle'>Faction Inventory</div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightRank'>
                  <div className='stripInfos'>
                    <div className='stripInfosIn'>
                      <div className='stripInfosText'>{faction.name}</div>
                    </div>
                  </div>
                </div>
                <div className='stripBoxRightBtn'>
                  <div className='imgBoxFaction'>
                    <img src={`${factionImg}`} className='imgFactionSmall' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>{faction.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxRightBtn'>
                    <div className='imgBoxFaction'>
                      <img src={`${factionImg}`} className='imgFactionSmall' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Rise of Factions Balance</div>
                  <div className='infosBalance'>
                    {tokensListNames.map((token, i) => {
                      if (faction.token_identifier == token.token_identifier) {
                        const balanceValue = faction.amount;

                        const balance = balanceValue.toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        return (
                          <span key={i}>
                            {balance} {token.name}
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className='infosBoxSub'>
                  <div className='buttonsNFTContainer'>
                    <Button
                      className='btnStandardReverse'
                      onClick={() => navigate(routeNames.factionmarketplace)}
                    >
                      Faction Marketplace
                    </Button>
                    <Button
                      className='btnStandard'
                      onClick={() =>
                        navigate(routeNames.factionmarketplacehistory)
                      }
                    >
                      Faction Market History
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body text-center p-2'>
              {showPower ? (
                <React.Fragment>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowPower}
                  >
                    Power
                  </Button>
                  <Button
                    className='navStandardSmall'
                    onClick={handleShowBonus}
                  >
                    Bonus
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className='navStandardSmall'
                    onClick={handleShowPower}
                  >
                    Power
                  </Button>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowBonus}
                  >
                    Bonus
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>

          {inventoryInfos.status == 'success' && (
            <React.Fragment>
              {factionInventory.length > 0 ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='itemBlock'>
                      {factionInventory.map((item, i) => {
                        const divStyle = {
                          '--rarityColor': item.itemInfos.item_rarityColor
                        } as React.CSSProperties;

                        let attack = false;
                        let defense = false;
                        switch (item.itemInfos.flg_use) {
                          case 1:
                            attack = true;
                            break;
                          case 2:
                            defense = true;
                            break;
                          default:
                            attack = true;
                            defense = true;
                            break;
                        }

                        const NFTscoreRounded =
                          item.itemInfos.item_power.toFixed(2);

                        let bonusLabel = '';
                        let bonusValue = '';
                        if (item.itemInfos.bonus_value > 0) {
                          bonusLabel = 'Faction Power';
                          bonusValue = '+' + item.itemInfos.bonus_value + '%';
                        } else {
                          bonusLabel = 'Enemy Power';
                          bonusValue = item.itemInfos.bonus_value + '%';
                        }

                        const nameT = item.itemInfos.item_name.split(' ');

                        let item_name_1;
                        let item_name_2;
                        switch (nameT.length) {
                          case 1:
                            item_name_1 = nameT[0];
                            break;
                          case 2:
                            item_name_1 = nameT[0];
                            item_name_2 = nameT[1];
                            break;
                          default:
                            item_name_1 = nameT[0] + ' ' + nameT[1];
                            item_name_2 = nameT[2];
                            break;
                        }

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      {item.itemInfos.item_rarity}
                                    </div>
                                    {item.itemInfos.item_type == 2 && (
                                      <div className='cardItemPower'>
                                        Power : {NFTscoreRounded}
                                      </div>
                                    )}
                                    {item.itemInfos.item_type == 4 && (
                                      <div className='cardItemPower'>
                                        {bonusLabel} : {bonusValue}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='cardItemPictureBox'>
                                <div className='overlayNumber'>
                                  <span className='overlayNumberText'>x</span>
                                  <span className='overlayNumberTextNumber'>
                                    {item.itemsAvailableCount}
                                  </span>
                                </div>
                                <img
                                  src={`../${item.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!item_name_2 ? (
                                  <div className='cardItemTitle'>
                                    {item_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {item_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {item_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              {item.itemInfos.item_type == 2 && (
                                <div className='cardItemUsage'>
                                  {attack && (
                                    <IconContext.Provider
                                      value={{ className: 'cardUsageAttack' }}
                                    >
                                      <GiCrossedSwords />
                                    </IconContext.Provider>
                                  )}
                                  {defense && (
                                    <IconContext.Provider
                                      value={{ className: 'cardUsageDef' }}
                                    >
                                      <GiBorderedShield />
                                    </IconContext.Provider>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxContainer topContainer'>
                  <div className='card-body text-center p-2'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p>
                      Oops... it looks like your faction don&apos;t have any
                      Item at the moment !
                    </p>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default FactionInventory;
