import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as DiscordIcon } from 'assets/img/discord.svg';
import logo_purple from 'assets/img/JCORP_logo_purple.png';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { routeNames } from 'routes';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const discord_link = 'https://discord.gg/7B5ZuPmFjV';
  const twitter_link = 'https://twitter.com/JCorpBattle';

  const [isMenu, setIsMenu] = React.useState<boolean>();

  const fetchLocationPath = () => {
    const path = location.pathname.substring(1); // remove the first /
    let flgMenu;

    switch (path) {
      case 'menu':
        flgMenu = true;
        break;
      default:
        flgMenu = false;
        break;
    }

    setIsMenu(flgMenu);
  };

  React.useEffect(() => {
    fetchLocationPath();
  }, [location]);

  return (
    <div id='footerNavBar'>
      <div className='footerContent'>
        <div onClick={() => navigate(routeNames.menu)}>
          <img src={logo_purple} className='logoNav' />
        </div>
        <div className='footerNav'>
          <div className='footerNavElement socialIconsContainer'>
            <a
              href={discord_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <DiscordIcon className='mx-1' />
            </a>
            <a
              href={twitter_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <TwitterIcon className='mx-1' />
            </a>
          </div>
          {!isMenu && (
            <div className='footerNavElement'>
              <div
                className='navLink'
                onClick={() => navigate(routeNames.menu)}
              >
                Game Modes
              </div>
            </div>
          )}
          <div className='footerNavElement'>
            <div
              className='navLink'
              onClick={() => navigate(routeNames.nftsearch)}
            >
              NFTs Search
            </div>
          </div>
          <div className='footerNavElement socialIconsSmallContainer'>
            <a
              href={discord_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <DiscordIcon className='mx-1' />
            </a>
            <a
              href={twitter_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <TwitterIcon className='mx-1' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
