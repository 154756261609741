import http from '../http-common';
import { BattleStateType } from '../types/battle.type';

class BattleDataService {
  findNextBattleByConquest(id_conquest: any) {
    return http.get<BattleStateType>(`/battles/next/${id_conquest}`);
  }
}

export default new BattleDataService();
