import http from '../http-common';
import { NftsBCStateType } from '../types/nfts.type';

class NftsDataService {
  findUserNFTs(params: any) {
    return http.get<NftsBCStateType>('/nfts/findNFTs', { params });
  }
}

export default new NftsDataService();
