import http from '../http-common';
import { LeaderboardsRewardsLogsStatsStateType } from '../types/leaderboardsrewardslogs.type';

class LeaderboardsRewardsLogsDataService {
  countEGLD() {
    return http.get<LeaderboardsRewardsLogsStatsStateType>(
      '/leaderboardsrewardslogs/countEGLD'
    );
  }
}

export default new LeaderboardsRewardsLogsDataService();
