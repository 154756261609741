import * as React from 'react';
import { faFrown, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination, TextField, MenuItem } from '@mui/material';
import { Modal, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../services/attributs.service';
import NftCollectionsDataService from '../../services/nft_collections.service';
import NftsDataService from '../../services/nfts.service';
import TournamentsDataService from '../../services/tournaments.service';
import TournamentRegistrationsDataService from '../../services/tournamentregistrations.service';
import TournamentResultsDataService from '../../services/tournamentresults.service';
import {
  AttributsStateType,
  AttributsDisplayStateType
} from '../../types/attributs.type';
import { NFTCollectionsStateType } from '../../types/nft_collections.type';
import { NftsBCStateType } from '../../types/nfts.type';
import { hierarchyColor } from '../../types/token.type';
import {
  TournamentStateType,
  tournamentEmpty
} from '../../types/tournaments.type';
import {
  TournamentSkillsRegistration,
  TournamentRegistrationsStateType,
  ITournamentRegistrationData
} from '../../types/tournamentregistrations.type';
import {
  TournamentResultStateType,
  TournamentResultsStateType
} from '../../types/tournamentresults.type';
import arenawarrior from './../../assets/img/arenawarrior.png';
import dagger from './../../assets/img/dagger.png';
import infoIcon from './../../assets/img/icons/info.png';
import none from './../../assets/img/none.png';
import staff from './../../assets/img/staff.png';
import sword from './../../assets/img/sword.png';
import { ReactComponent as VectorAdd } from './../../assets/img/vector_add.svg';

const ArenaTournament = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const tournament_mode = 1;
  let phase = 0;

  const [currentTournament, setCurrentTournament] = React.useState(0);
  const [selectedPhase, setSelectedPhase] = React.useState(1);

  const { userSession, userInfos } = useAuthContext();

  const navigate = useNavigate();

  function MyTimerFights({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshArena()
    });

    return (
      <span>
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result != null) {
      const color =
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16);
      return color;
    }
  }

  const [showRules, setShowRules] = React.useState(false);
  const handleCloseRules = () => {
    setShowRules(false);
  };
  const handleShowRules = () => {
    setShowRules(true);
  };

  const maxRegistrationPerDay = 5;
  const resultsSize = 100;

  const [showLatestResults, setShowLatestResults] = React.useState(true);
  const [showMyResults, setShowMyResults] = React.useState(false);
  const handleShowLatestResults = () => {
    setShowLatestResults(true);
    setShowMyResults(false);
  };
  const handleShowMyResults = () => {
    setShowLatestResults(false);
    setShowMyResults(true);
  };

  const [collList, setCollList] = React.useState<NFTCollectionsStateType>({
    nft_collections: [],
    message: '',
    status: ''
  });

  const [isInGameCol, setIsInGameCol] = React.useState<boolean>();
  const [idCollection, setIdCollection] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isNfts, setIsNfts] = React.useState<boolean>();
  const [listNFTsBC, setListNFTsBC] = React.useState<NftsBCStateType>({
    totalItems: 0,
    totalPages: 0,
    nfts: [],
    message: '',
    nftsFetched: undefined
  });

  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });
  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });
  const [listRegistrationNFTs, setListRegistrationNFTs] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPagesAttr, setTotalPagesAttr] = React.useState(0);

  const [nbUserRegistration, setNbUserRegistration] = React.useState(0);
  const [nbRegistration, setNbRegistration] = React.useState(0);
  const [nbSkillsRegistration, setNbSkillsRegistration] = React.useState(0);

  const [tournamentInfos, setTournamentInfos] =
    React.useState<TournamentStateType>({
      tournament: tournamentEmpty,
      message: '',
      status: ''
    });
  const [tournamentRegistrationsWarrior, setTournamentRegistrationsWarrior] =
    React.useState<TournamentRegistrationsStateType>({
      tournament_registrations: [],
      message: '',
      status: ''
    });
  const [tournamentRegistrations, setTournamentRegistrations] =
    React.useState<TournamentRegistrationsStateType>({
      tournament_registrations: [],
      message: '',
      status: ''
    });
  const [tournamentResult, setTournamentResult] =
    React.useState<TournamentResultStateType>({
      tournament_result: [],
      message: '',
      status: ''
    });
  const [tournamentResults, setTournamentResults] =
    React.useState<TournamentResultsStateType>({
      tournament_results: [],
      message: '',
      status: ''
    });

  const [registrationTab, setRegistrationTab] = React.useState({
    occurs: new Array<string>()
  });

  const [showNFTsList, setShowNFTsList] = React.useState(false);
  const handleCloseNFTsList = () => {
    setShowNFTsList(false);
  };
  const handleShowNFTsList = () => {
    if (!isInGameCol && page == 0) {
      setPage(1);
      setIsLoading(true);
    }

    setShowNFTsList(true);
  };

  const [arenaResultId, setArenaResultId] = React.useState(0);

  const [showArenaResult, setShowArenaResult] = React.useState(false);
  const handleCloseArenaResult = () => {
    setShowArenaResult(false);
  };
  const handleShowArenaResult = (resultId: number) => {
    setArenaResultId(resultId);
    setShowArenaResult(true);
  };

  const [nftIdentifier, setNftIdentifier] = React.useState('');
  const [selectedSkills, setSelectedSkills] =
    React.useState<TournamentSkillsRegistration>({
      skills: [
        {
          id_round: 1,
          id_skill: 0
        },
        {
          id_round: 2,
          id_skill: 0
        },
        {
          id_round: 3,
          id_skill: 0
        }
      ]
    });

  const [showRegistrationInfos, setShowRegistrationInfos] =
    React.useState(false);
  const handleCloseRegistrationInfos = () => {
    setShowRegistrationInfos(false);
  };
  const handleShowRegistrationInfos = (nft_identifier: string) => {
    setShowNFTsList(false);
    handleRegistrationNFTsList();
    setNftIdentifier(nft_identifier);
    setSelectedSkills({
      skills: [
        {
          id_round: 1,
          id_skill: 0
        },
        {
          id_round: 2,
          id_skill: 0
        },
        {
          id_round: 3,
          id_skill: 0
        }
      ]
    });
    setShowRegistrationInfos(true);
  };

  const handleSelectedSkills = (id_round: number, id_skill: number) => {
    const tab = {
      skills: [...selectedSkills.skills]
    };

    for (let i = 0; i < selectedSkills.skills.length; i++) {
      const skill = selectedSkills.skills[i];
      if (skill.id_round == id_round) {
        tab.skills[i].id_skill = id_skill;
      }
    }

    setSelectedSkills(tab);
  };

  const [showSkillsInfos, setShowSkillsInfos] = React.useState(false);
  const handleCloseSkillsInfos = () => {
    setShowSkillsInfos(false);
  };
  const handleShowSkillsInfos = () => {
    const tab = {
      skills: [
        {
          id_round: 1,
          id_skill: 0
        },
        {
          id_round: 2,
          id_skill: 0
        },
        {
          id_round: 3,
          id_skill: 0
        }
      ]
    };

    const skills =
      tournamentRegistrations.tournament_registrations[0].tournament_skills;

    if (skills) {
      for (let i = 0; i < skills.length; i++) {
        const skill = skills[i];

        for (let j = 0; j < tab.skills.length; j++) {
          if (skill.id_round == tab.skills[j].id_round) {
            tab.skills[j].id_skill = skill.id_skill;
          }
        }
      }
    }

    setSelectedSkills(tab);

    setShowSkillsInfos(true);
  };

  const fetchNFTsData = () => {
    AttributsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setListNFTs({
          attributs: data.attributs,
          status: data.status
        });

        const nbPages = Math.ceil(data.attributs.length / pageSize);
        setPage(1);
        setTotalPagesAttr(nbPages);
      }
    });
  };

  const fetchNftCollectionsData = () => {
    NftCollectionsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setCollList({
          nft_collections: data.nft_collections,
          status: data.status
        });

        if (userSession.isBlockchainAccount) {
          setIdCollection(1);
        } else {
          setIdCollection(1);
        }
      }
    });
  };

  const fetchBCNFTsData = () => {
    let collectionIdentifier = '';
    for (let i = 0; i < collList.nft_collections.length; i++) {
      const col = collList.nft_collections[i];
      if (col.id == idCollection) {
        collectionIdentifier = col.identifier;
      }
    }

    const params: any = {};
    params['address'] = userSession.userAccount;
    params['collection'] = collectionIdentifier;
    params['page'] = page - 1;
    params['size'] = pageSize;

    NftsDataService.findUserNFTs(params).then(({ data }) => {
      if (data.nftsFetched == true) {
        setListNFTsBC({
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          nfts: data.nfts,
          nftsFetched: data.nftsFetched
        });
      } else {
        setListNFTsBC({
          totalItems: 0,
          totalPages: 0,
          nfts: [],
          nftsFetched: data.nftsFetched
        });
      }
    });
  };

  const fetchLastActiveTournamentData = () => {
    const params: any = {};
    params['tournament_mode'] = tournament_mode;

    TournamentsDataService.findLastActive(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentTournament(data.tournament.id_tournament);
      }
    });
  };

  const fetchTournamentData = () => {
    TournamentsDataService.findOneById(currentTournament).then(({ data }) => {
      if (data.status == 'success') {
        setTournamentInfos({
          tournament: data.tournament,
          status: data.status
        });

        if (data.tournament.tournament_phases.length > 0) {
          setSelectedPhase(
            data.tournament.tournament_phases[0].tournament_phase
          );
        }
      }
    });
  };

  const fetchArenaResultData = () => {
    TournamentResultsDataService.findOneResultById(arenaResultId).then(
      ({ data }) => {
        if (data.status == 'success') {
          setTournamentResult({
            tournament_result: data.tournament_result,
            status: data.status
          });
        }
      }
    );
  };

  const fetchArenaAllResultsData = () => {
    const params: any = {};
    params['id_tournament'] = currentTournament;
    params['size'] = resultsSize;

    TournamentResultsDataService.findLatestResults(params).then(({ data }) => {
      if (data.status == 'success') {
        setTournamentResults({
          tournament_results: data.tournament_results,
          status: data.status
        });
      }
    });
  };

  const fetchArenaRegistrationData = () => {
    const params: any = {};
    params['id_tournament'] = currentTournament;

    TournamentRegistrationsDataService.findUserWarrior(
      userSession.userID,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setTournamentRegistrationsWarrior({
          tournament_registrations: data.tournament_registrations,
          status: data.status
        });

        let nbUserRegistration = 0;

        data.tournament_registrations.map((tournament) => {
          nbUserRegistration++;
        });

        setNbUserRegistration(nbUserRegistration);
      }
    });

    TournamentRegistrationsDataService.findAllRegisteredByUser(
      userSession.userID,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setTournamentRegistrations({
          tournament_registrations: data.tournament_registrations,
          status: data.status
        });

        if (
          data.tournament_registrations.length > 0 &&
          data.tournament_registrations[0].tournament_skills != null
        ) {
          setNbSkillsRegistration(
            data.tournament_registrations[0].tournament_skills.length
          );
        } else {
          setNbSkillsRegistration(0);
        }
      }
    });

    // TODO
    TournamentRegistrationsDataService.findAllRegistered(params).then(
      ({ data }) => {
        if (data.status == 'success') {
          let nbRegistration = 0;

          data.tournament_registrations.map((tournament) => {
            nbRegistration++;
          });

          setNbRegistration(nbRegistration);
        }
      }
    );
  };

  const registerRanked = (nft_identifier: string) => {
    listRegistrationNFTs.nfts.map((token) => {
      if (token.nft_identifier == nft_identifier) {
        const dataRegistration: ITournamentRegistrationData = {
          id_tournament: currentTournament,
          tournament_phase: 1,
          id_user: userSession.userID,
          identifier: '',
          id_nft: token.id_nft,
          nft_identifier: token.nft_identifier,
          id_fight: 0,
          flg_winner: false,
          flg_fight: false
        };

        const dataTournament: any = {
          id_tournament: currentTournament,
          registration: dataRegistration,
          skills: selectedSkills.skills
        };

        TournamentRegistrationsDataService.create(dataTournament).then(
          ({ data }) => {
            if (data.status == 'success') {
              fetchArenaRegistrationData();
            }
          }
        );
      }
    });
  };

  const registerArena = () => {
    registerRanked(nftIdentifier);
    handleCloseRegistrationInfos();
  };

  const registerSkills = (registration: ITournamentRegistrationData) => {
    const dataTournament: any = {
      id_registration: registration.id_registration,
      skills: selectedSkills.skills
    };

    TournamentRegistrationsDataService.manageSkills(dataTournament).then(
      ({ data }) => {
        if (data.status == 'success') {
          fetchArenaRegistrationData();
        }
      }
    );

    handleCloseSkillsInfos();
  };

  const unregisterArena = (tournament: ITournamentRegistrationData) => {
    const id_registration = tournament.id_registration;

    TournamentRegistrationsDataService.delete(id_registration).then(
      ({ data }) => {
        if (data.status == 'success') {
          fetchArenaRegistrationData();
        }
      }
    );
  };

  const handleRegistration = () => {
    const nbMaxRegistration = 1;

    const tab = {
      occurs: new Array<string>()
    };

    for (let i = nbUserRegistration; i < nbMaxRegistration; i++) {
      const number = i + 1;
      let occur = number.toString();
      if (number < 10) {
        occur = '0' + number;
      }
      tab.occurs.push(occur);
    }

    setRegistrationTab(tab);
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTs.attributs.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTs.attributs[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };

  const handleRegistrationNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    if (isInGameCol) {
      for (let i = 0; i < listNFTsDisplay.nfts.length; i++) {
        nftsList.nfts.push(listNFTsDisplay.nfts[i]);
      }
    } else {
      for (let i = 0; i < listNFTsBC.nfts.length; i++) {
        nftsList.nfts.push(listNFTsBC.nfts[i]);
      }
    }

    setListRegistrationNFTs(nftsList);
  };

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleCollectionChange = (event: any) => {
    setIdCollection(event.target.value);
    setPage(1);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleRefreshArena = () => {
    fetchTournamentData();
    fetchArenaRegistrationData();
    fetchArenaAllResultsData();
  };

  const handleLoadNFTs = () => {
    handleUpdateNFTs();
    fetchBCNFTsData();
  };

  const handleUpdateNFTs = () => {
    setIsLoading(true);
    setListNFTsBC({
      totalItems: 0,
      totalPages: 0,
      nfts: [],
      message: '',
      nftsFetched: undefined
    });
  };

  React.useEffect(fetchLastActiveTournamentData, []);

  React.useEffect(() => {
    if (currentTournament != 0) {
      fetchTournamentData();
      fetchNFTsData();
      fetchNftCollectionsData();
      fetchArenaRegistrationData();
      fetchArenaAllResultsData();
    }
  }, [currentTournament]);

  React.useEffect(() => {
    if (userSession.isBlockchainAccount) {
      if (page != 0) {
        switch (idCollection) {
          case 1:
            setIsInGameCol(true);
            break;
          default:
            setIsInGameCol(false);
            handleLoadNFTs();
            break;
        }
      }
    } else {
      setIsInGameCol(true);
    }
  }, [page, idCollection]);

  React.useEffect(() => {
    handleRegistration();
  }, [nbUserRegistration]);

  React.useEffect(() => {
    if (arenaResultId != 0) {
      fetchArenaResultData();
    }
  }, [arenaResultId]);

  React.useEffect(() => {
    if (userSession.isBlockchainAccount) {
      if (listNFTsBC.nftsFetched != undefined) {
        setIsLoading(false);

        if (listNFTsBC.nftsFetched) {
          setIsNfts(true);
        } else {
          setIsNfts(false);
        }
      }
    }
  }, [listNFTsBC]);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0 && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTs, page]);

  const { totalPages, nfts } = listNFTsBC;
  const { tournament } = tournamentInfos;
  const { tournament_registrations } = tournamentRegistrations;
  const { tournament_results } = tournamentResults;
  const { tournament_result } = tournamentResult;

  if (userInfos.status == 'success') {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosConquestContainer'>
                  <div className='infosConquestBox'>
                    <div className='conquestTitleBox'>
                      <div className='conquestTitle'>Special</div>
                    </div>
                    <div className='phaseTitle'>Tournament</div>
                  </div>
                </div>

                {nbUserRegistration > 0 ? (
                  <div className='infosBoxSubSpecial'>
                    <div className='buttonsNFTContainer'>
                      <div className='nftBlock'>
                        {tournamentRegistrationsWarrior.tournament_registrations.map(
                          (arena, i) => {
                            let nft_hierarchy = '';
                            if (arena.attribut?.nft_hierarchy != null) {
                              nft_hierarchy = arena.attribut.nft_hierarchy;
                            }

                            const divStyle = {
                              '--rarityColor': hierarchyColor[nft_hierarchy]
                            } as React.CSSProperties;

                            let nft_number;
                            let nft_collection;
                            if (arena.attribut?.nft_name != null) {
                              const nameT = arena.attribut?.nft_name.split(' ');
                              nft_collection = nameT[0];
                              if (nameT[1]) {
                                nft_number = nameT[1].replace('#', '');
                              }
                            }
                            const flg_blockchain =
                              arena.attribut?.flg_blockchain;

                            return (
                              <div
                                key={i}
                                style={divStyle}
                                className='cardDynNFTBlock'
                              >
                                <div className='cardDynNFTContainer'>
                                  <div className='cardDynNFTBox'>
                                    <div className='cardDynNFTBoxIn'>
                                      <div className='cardDynNFTInfos'>
                                        <div className='cardDynNFTHierarchy'>
                                          &nbsp;
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='cardDynNFTPictureBox'>
                                    {flg_blockchain ? (
                                      <img
                                        src={`${arena.attribut?.nft_url}`}
                                        className='cardDynNFTPicture'
                                      />
                                    ) : (
                                      <img
                                        src={`../${arena.attribut?.nft_url}`}
                                        className='cardDynNFTPicture'
                                      />
                                    )}
                                  </div>
                                  <div className='cardDynNFTInfosBis'>
                                    {!nft_number ? (
                                      <div className='cardDynNFTTitle'>
                                        {nft_collection}
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <div className='cardDynNFTNumber'>
                                          {nft_number}
                                        </div>
                                        <div className='cardDynNFTName'>
                                          {nft_collection}
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {tournament.step == 1 ? (
                      <React.Fragment>
                        {tournament.nb_registrations < tournament.nb_slots ? (
                          <div className='infosBoxSub'>
                            <div className='buttonsNFTContainer'>
                              <React.Fragment>
                                <Button
                                  className='btnStandardReverse'
                                  onClick={() => handleShowNFTsList()}
                                >
                                  Register
                                </Button>
                              </React.Fragment>
                            </div>
                          </div>
                        ) : (
                          <div className='infosBoxSub'>
                            <div className='buttonsNFTContainer'>
                              <React.Fragment>
                                <Button disabled className='btnStandardReverse'>
                                  Register
                                </Button>
                              </React.Fragment>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusWarning'>Feature in beta version</div>
              </div>
            </div>

            {currentTournament > 0 ? (
              <div className='boxContainer topArenaContainer'>
                <div className='stripContainerLeft'>
                  <div className='stripBoxLeft'>
                    <div className='stripBoxLeftRank'>
                      <div className='stripInfos'>
                        <div className='stripInfosIn'>
                          <div className='stripInfosText'>
                            Tournament {currentTournament}
                          </div>
                        </div>
                      </div>
                    </div>
                    {tournament.step == 1 ? (
                      <div className='stripBoxLeftTimerBox'>
                        <div className='stripBoxLeftTimer'>
                          <div className='infosTimerText'>Registration</div>
                          <div className='infosTimerTimer'>
                            <MyTimerFights
                              expiryTimestamp={new Date(tournament.nextPhase)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tournament.step == 2 ? (
                      <div className='stripBoxLeftTimerBox'>
                        <div className='stripBoxLeftTimer'>
                          <div className='infosTimerText'>Next Fight</div>
                          <div className='infosTimerTimer'>
                            <MyTimerFights
                              expiryTimestamp={new Date(tournament.nextPhase)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tournament.step == 3 ? (
                      <div className='stripBoxLeftTimerBox'>
                        <div className='stripBoxLeftTimer'>
                          <div className='infosTimerText'>Finished</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {tournament.step == 1 ? (
              <div className='boxContainer topArenaContainer'>
                <div className='arenaRegistrationInfosContainer'>
                  <div className='arenaRegistrationInfosPictureBox'>
                    <img
                      src={`${arenawarrior}`}
                      className='arenaRegistrationInfosPicture'
                    />
                  </div>
                  <div className='arenaRegistrationInfosBox'>
                    <div className='arenaRegistrationInfosBoxIn'>
                      <div className='arenaRegistrationTextBox'>
                        <div className='arenaRegistrationText'>
                          There&nbsp;
                          {nbRegistration > 1 ? (
                            <span>are</span>
                          ) : (
                            <span>is</span>
                          )}
                          &nbsp;currently&nbsp;
                          <span className='arenaRegistrationTextBig'>
                            {nbRegistration}
                            &nbsp;
                            {nbRegistration > 1 ? (
                              <span>Warriors</span>
                            ) : (
                              <span>Warrior</span>
                            )}
                          </span>
                          <br />
                          registered for the Tournament.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {tournament.step > 10 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>
                      Tournament Registration
                      {/*
                      <div
                        className='infosIconContainer'
                        onClick={handleShowRules}
                      >
                        <img src={`${infoIcon}`} className='infosIcon' />
                      </div>
                      */}
                    </div>
                  </div>
                  <div className='nftBlock'>
                    {tournament_registrations.map((arena, i) => {
                      let nft_hierarchy = '';
                      if (arena.attribut?.nft_hierarchy != null) {
                        nft_hierarchy = arena.attribut.nft_hierarchy;
                      }

                      const divStyle = {
                        '--rarityColor': hierarchyColor[nft_hierarchy]
                      } as React.CSSProperties;

                      let nft_number;
                      let nft_collection;
                      if (arena.attribut?.nft_name != null) {
                        const nameT = arena.attribut?.nft_name.split(' ');
                        nft_collection = nameT[0];
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                      }
                      const flg_blockchain = arena.attribut?.flg_blockchain;

                      return (
                        <div
                          key={i}
                          style={divStyle}
                          className='cardDynNFTBlock'
                        >
                          <div className='cardDynNFTContainer'>
                            <div className='cardDynNFTBox'>
                              <div className='cardDynNFTBoxIn'>
                                <div className='cardDynNFTInfos'>
                                  <div className='cardDynNFTHierarchy'>
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className='cardDynNFTPictureBox'
                              onClick={() => unregisterArena(arena)}
                            >
                              <div className='overlay'>
                                <div className='overlayArenaText'>
                                  Registered
                                </div>
                              </div>
                              <div className='overlayHoverSmallB'>
                                <div className='overlayHoverTextSmallB'>
                                  Unregister
                                </div>
                              </div>
                              {flg_blockchain ? (
                                <img
                                  src={`${arena.attribut?.nft_url}`}
                                  className='cardDynNFTPicture'
                                />
                              ) : (
                                <img
                                  src={`../${arena.attribut?.nft_url}`}
                                  className='cardDynNFTPicture'
                                />
                              )}
                            </div>
                            <div className='cardDynNFTInfosBis'>
                              {!nft_number ? (
                                <div className='cardDynNFTTitle'>
                                  {nft_collection}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div className='cardDynNFTNumber'>
                                    {nft_number}
                                  </div>
                                  <div className='cardDynNFTName'>
                                    {nft_collection}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {registrationTab.occurs.map((occur, i) => {
                      return (
                        <div
                          key={i}
                          className='arenaRegistrationContainer'
                          onClick={() => handleShowNFTsList()}
                        >
                          <div className='arenaRegistrationBox'>
                            <div className='arenaRegistrationBoxIn'>
                              <div className='arenaRegistrationBoxInText'>
                                Add a fighter
                              </div>
                              <div className='arenaRegistrationBoxInVector'>
                                <VectorAdd className='vectorAdd' />
                              </div>
                              <div className='arenaRegistrationBoxInNumber'>
                                {occur}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {tournament_registrations.length > 0 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Next Fight Weapons</div>
                  </div>
                  <div className='tournamentSkillsContainer'>
                    <div className='tournamentSkillsInfosContainer'>
                      <div className='tournamentSkillsInfos'>
                        {nbSkillsRegistration > 0 ? (
                          <div className='tournamentSkillsDataContainer'>
                            {tournament_registrations[0].tournament_skills?.map(
                              (skill, i) => {
                                let skillImg;
                                switch (skill.id_skill) {
                                  case 1:
                                    skillImg = sword;
                                    break;
                                  case 2:
                                    skillImg = dagger;
                                    break;
                                  case 3:
                                    skillImg = staff;
                                    break;
                                }

                                return (
                                  <div
                                    key={i}
                                    className='tournamentSkillsDataBox'
                                  >
                                    <div className='dataTitle'>
                                      Round {skill.id_round}
                                    </div>
                                    <div className='tournamentSkillPictureBox'>
                                      <img
                                        src={`${skillImg}`}
                                        className='tournamentSkillPicture'
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <div className='tournamentSkillsDataContainer'>
                            <div className='tournamentSkillsDataBox'>
                              <div className='dataTitle'>Round 1</div>
                              <div className='tournamentSkillPictureBox'>
                                <img
                                  src={`${none}`}
                                  className='tournamentSkillPicture'
                                />
                              </div>
                            </div>
                            <div className='tournamentSkillsDataBox'>
                              <div className='dataTitle'>Round 2</div>
                              <div className='tournamentSkillPictureBox'>
                                <img
                                  src={`${none}`}
                                  className='tournamentSkillPicture'
                                />
                              </div>
                            </div>
                            <div className='tournamentSkillsDataBox'>
                              <div className='dataTitle'>Round 3</div>
                              <div className='tournamentSkillPictureBox'>
                                <img
                                  src={`${none}`}
                                  className='tournamentSkillPicture'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='tournamentSkillsActionContainer'>
                      <div
                        className='tournamentSkillsActionIcon'
                        onClick={() => handleShowSkillsInfos()}
                      >
                        <FontAwesomeIcon icon={faCog} className='fa' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {tournament.step > 1 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Tournament Results</div>
                  </div>
                  <div className='textImportant'>
                    Click on a result to see the fight details.
                  </div>
                  <div className='infosBoxSub'>
                    <div className='phaseContainer'>
                      <div className='textImportant'>Choose a phase</div>
                      <div className='historySelection'>
                        <Form.Group className='leadMenu'>
                          <Form.Row>
                            <Col xs='auto'>
                              <TextField
                                select
                                value={selectedPhase}
                                variant='standard'
                                onChange={(e) =>
                                  setSelectedPhase(parseInt(e.target.value))
                                }
                              >
                                {tournament_results.map((result, i) => {
                                  if (phase != result.tournament_phase) {
                                    phase = result.tournament_phase;
                                    return (
                                      <MenuItem key={i} value={phase}>
                                        Phase {phase}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </TextField>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-body p-2 margin30'>
                  {tournament_results.map((result, i) => {
                    if (result.tournament_phase != selectedPhase) {
                      return;
                    }

                    const flg_fight = result.flg_fight;
                    const fighter_A = result.fighter_A;
                    const fighter_B = result.fighter_B;

                    let fighter_A_pseudo = 'BOT';
                    let fighter_A_nftImg;

                    if (!fighter_A) fighter_A_pseudo = '?';

                    if (fighter_A && fighter_A.user) {
                      fighter_A_pseudo = fighter_A.user.pseudo;
                    }

                    if (fighter_A && fighter_A.attribut) {
                      fighter_A_nftImg = fighter_A.attribut.nft_url;
                      if (!fighter_A.attribut.flg_blockchain) {
                        fighter_A_nftImg = '../' + fighter_A_nftImg;
                      }
                    }

                    let fighter_B_pseudo = 'BOT';
                    let fighter_B_nftImg;

                    if (!fighter_B) fighter_B_pseudo = '?';

                    if (fighter_B && fighter_B.user) {
                      fighter_B_pseudo = fighter_B.user.pseudo;
                    }

                    if (fighter_B && fighter_B.attribut) {
                      fighter_B_nftImg = fighter_B.attribut.nft_url;
                      if (!fighter_B.attribut.flg_blockchain) {
                        fighter_B_nftImg = '../' + fighter_B_nftImg;
                      }
                    }

                    // TODO

                    if (flg_fight) {
                      return (
                        <div
                          key={i}
                          className='arenaResultContainer2'
                          onClick={() => handleShowArenaResult(result.id)}
                        >
                          <div className='arenaResultSeasonBox'>
                            <div className='arenaResultSeason'>
                              <div className='arenaResultSeasonIn'>
                                <div className='arenaResultSeasonText'>
                                  Phase {result.tournament_phase}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='arenaResultBox'>
                            <div className='arenaResultBoxIn'>
                              <div className='arenaResultBoxInfos'>
                                <div className='arenaResultBoxInfosLeft'>
                                  {fighter_A_nftImg ? (
                                    <div>
                                      <img
                                        src={`${fighter_A_nftImg}`}
                                        className='imgFighterArena'
                                      />
                                    </div>
                                  ) : null}
                                  <div className='margin10'>
                                    {fighter_A_pseudo}
                                  </div>
                                </div>
                                <div className='arenaResultBoxInfosCenter'>
                                  VS
                                </div>
                                <div className='arenaResultBoxInfosRight'>
                                  {fighter_B_nftImg ? (
                                    <div>
                                      <img
                                        src={`${fighter_B_nftImg}`}
                                        className='imgFighterArena'
                                      />
                                    </div>
                                  ) : null}
                                  <div className='margin10'>
                                    {fighter_B_pseudo}
                                  </div>
                                </div>
                              </div>
                              <div className='arenaResultBoxIn2'>
                                <div className='arenaResultBoxInText2'>
                                  fight {result.id_fight}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='arenaResultLeft'>
                            {fighter_A.flg_winner ? (
                              <div className='arenaResultLeftWinner'>
                                <div className='arenaResultLeftWinnerIn'>
                                  <div className='arenaResultText'>WINNER</div>
                                </div>
                              </div>
                            ) : (
                              <div className='arenaResultLeftLoser'>
                                <div className='arenaResultLeftLoserIn'>
                                  <div className='arenaResultText'>LOSER</div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='arenaResultRight'>
                            {fighter_B.flg_winner ? (
                              <div className='arenaResultRightWinner'>
                                <div className='arenaResultRightWinnerIn'>
                                  <div className='arenaResultText'>WINNER</div>
                                </div>
                              </div>
                            ) : (
                              <div className='arenaResultRightLoser'>
                                <div className='arenaResultRightLoserIn'>
                                  <div className='arenaResultText'>LOSER</div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={i} className='arenaResultContainer2'>
                          <div className='arenaResultSeasonBox'>
                            <div className='arenaResultSeason'>
                              <div className='arenaResultSeasonIn'>
                                <div className='arenaResultSeasonText'>
                                  Phase {result.tournament_phase}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='arenaResultBox'>
                            <div className='arenaResultBoxIn'>
                              <div className='arenaResultBoxInfos'>
                                <div className='arenaResultBoxInfosLeft'>
                                  {fighter_A_nftImg ? (
                                    <div>
                                      <img
                                        src={`${fighter_A_nftImg}`}
                                        className='imgFighterArena'
                                      />
                                    </div>
                                  ) : null}
                                  <div className='margin10'>
                                    {fighter_A_pseudo}
                                  </div>
                                </div>
                                <div className='arenaResultBoxInfosCenter'>
                                  VS
                                </div>
                                <div className='arenaResultBoxInfosRight'>
                                  {fighter_B_nftImg ? (
                                    <div>
                                      <img
                                        src={`${fighter_B_nftImg}`}
                                        className='imgFighterArena'
                                      />
                                    </div>
                                  ) : null}
                                  <div className='margin10'>
                                    {fighter_B_pseudo}
                                  </div>
                                </div>
                              </div>
                              <div className='arenaResultBoxIn2'>
                                <div className='arenaResultBoxInText2'>
                                  fight {result.id_fight}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='arenaResultLeft'>
                            <div className='arenaResultLeftPending'>
                              <div className='arenaResultLeftPendingIn'>
                                <div className='arenaResultText'>PENDING</div>
                              </div>
                            </div>
                          </div>
                          <div className='arenaResultRight'>
                            <div className='arenaResultRightPending'>
                              <div className='arenaResultRightPendingIn'>
                                <div className='arenaResultText'>PENDING</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          show={showNFTsList}
          onHide={handleCloseNFTsList}
          size='lg'
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            <div className='card-body p-2'>
              <div className='infosModalTitle'>Tournament Registration</div>
            </div>

            {userSession.isBlockchainAccount ? (
              <div className='boxContainer topContainer'>
                <div className='stripModalContainerRight'>
                  <div className='stripModalBoxRight'>
                    <div className='stripBoxRightRank'>
                      <div className='stripInfos'>
                        <div className='stripInfosIn'>
                          <div className='stripInfosText'>Collection</div>
                        </div>
                      </div>
                    </div>
                    <div className='stripBoxRightList'>
                      <div className='text-center'>
                        <Form.Group>
                          <Form.Row>
                            <Col xs='auto'>
                              <TextField
                                select
                                value={idCollection}
                                variant='standard'
                                onChange={(e) => handleCollectionChange(e)}
                              >
                                {collList.nft_collections.map((coll, i) => {
                                  return (
                                    <MenuItem key={i} value={coll.id}>
                                      {coll.name} ({coll.identifier})
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {!isInGameCol && isLoading ? (
              <div className='boxContainer topContainer'>
                <div className='card-body text-center p-2'>
                  <div className='lds-facebook'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className='boxContainer'>
                    <div className='infosModalTx'>Loading ...</div>
                  </div>
                </div>
              </div>
            ) : null}

            {!isInGameCol && !isLoading && !isNfts ? (
              <div className='boxContainer topContainer'>
                <div className='card-body text-center p-2'>
                  <Button
                    className='btnStandardReverse'
                    onClick={handleLoadNFTs}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            ) : null}

            {!isInGameCol && !isLoading && isNfts && nfts.length > 0 ? (
              <React.Fragment>
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBoxModal'>
                      <div className='infosSubTitle'>Select your Fighter</div>
                    </div>
                    <div className='textImportant'>
                      Click on your fighter to register him for the tournament.
                    </div>
                  </div>
                </div>

                <div className='margin10'>
                  <div className='card-body p-2'>
                    {totalPages > 1 && (
                      <div className='paginBoxTop'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                    <div className='nftBlock'>
                      {nfts.map((token, i) => {
                        const divStyle = {
                          '--rarityColor': hierarchyColor[token.nft_hierarchy]
                        } as React.CSSProperties;

                        const nameT = token.nft_name.split(' ');
                        const nft_collection = nameT[0];
                        let nft_number;
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                        const flg_blockchain = token.flg_blockchain;

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardDynNFTBlock'
                          >
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardDynNFTPictureBox'
                                onClick={() =>
                                  handleShowRegistrationInfos(
                                    token.nft_identifier
                                  )
                                }
                              >
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Register
                                  </div>
                                </div>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {totalPages > 1 && (
                      <div className='paginBoxBottom'>
                        <Pagination
                          className='paginContainer'
                          count={totalPages}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : null}

            {!isInGameCol && !isLoading && isNfts && nfts.length == 0 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Select your Fighter</div>
                  </div>
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any NFT from
                      this collection at the moment !
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {isInGameCol && listNFTsDisplay.nfts.length > 0 ? (
              <React.Fragment>
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBoxModal'>
                      <div className='infosSubTitle'>Select your Fighter</div>
                    </div>
                    <div className='textImportant'>
                      Click on your fighter to register him for the tournament.
                    </div>
                  </div>
                </div>

                <div className='margin10'>
                  <div className='card-body p-2'>
                    {totalPagesAttr > 1 && (
                      <div className='paginBoxTop'>
                        <Pagination
                          className='paginContainer'
                          count={totalPagesAttr}
                          defaultPage={1}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          shape='rounded'
                          onChange={handlePageChange}
                        />
                      </div>
                    )}
                    <div className='nftBlock'>
                      {listNFTsDisplay.nfts.map((token, i) => {
                        const divStyle = {
                          '--rarityColor': hierarchyColor[token.nft_hierarchy]
                        } as React.CSSProperties;

                        const nameT = token.nft_name.split(' ');
                        const nft_collection = nameT[0];
                        let nft_number;
                        if (nameT[1]) {
                          nft_number = nameT[1].replace('#', '');
                        }
                        const flg_blockchain = token.flg_blockchain;

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardDynNFTBlock'
                          >
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardDynNFTPictureBox'
                                onClick={() =>
                                  handleShowRegistrationInfos(
                                    token.nft_identifier
                                  )
                                }
                              >
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Register
                                  </div>
                                </div>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {totalPagesAttr > 1 && (
                    <div className='paginBoxBottom'>
                      <Pagination
                        className='paginContainer'
                        count={totalPagesAttr}
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ) : null}

            {isInGameCol && listNFTsDisplay.nfts.length == 0 ? (
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Select your Fighter</div>
                  </div>
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any J-Corp
                      Heroes at the moment !
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseNFTsList}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showRegistrationInfos}
          onHide={handleCloseRegistrationInfos}
          size='lg'
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            {listRegistrationNFTs.nfts.map((token, i) => {
              if (token.nft_identifier == nftIdentifier) {
                const divStyle = {
                  '--rarityColor': hierarchyColor[token.nft_hierarchy]
                } as React.CSSProperties;

                const nameT = token.nft_name.split(' ');
                const nft_collection = nameT[0];
                let nft_number;
                if (nameT[1]) {
                  nft_number = nameT[1].replace('#', '');
                }
                const flg_blockchain = token.flg_blockchain;

                let skillsSelected = false;
                let isSkills = true;
                selectedSkills.skills.map((skill) => {
                  if (skill.id_skill == 0) {
                    isSkills = false;
                  }
                });
                if (isSkills) skillsSelected = true;

                return (
                  <div key={i}>
                    <div className='card-body p-2'>
                      <div className='infosModalTitle'>
                        Registration of {nft_collection} {nft_number}
                      </div>
                    </div>

                    <div className='margin10'>
                      <div className='card-body p-2'>
                        <div className='nftBlock'>
                          <div style={divStyle} className='cardDynNFTBlock'>
                            <div className='cardDynNFTContainer'>
                              <div className='cardDynNFTBox'>
                                <div className='cardDynNFTBoxIn'>
                                  <div className='cardDynNFTInfos'>
                                    <div className='cardDynNFTHierarchy'>
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardDynNFTPictureBox'>
                                {flg_blockchain ? (
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                ) : (
                                  <img
                                    src={`../${token.nft_url}`}
                                    className='cardDynNFTPicture'
                                  />
                                )}
                              </div>
                              <div className='cardDynNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardDynNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardDynNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardDynNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='infosBox'>
                          <div className='infosSubTitle'>Weapons Selection</div>
                        </div>
                        <div className='textImportant'>
                          Select weapons to equip your warrior for the fight.
                        </div>

                        {selectedSkills.skills.map((skill, j) => {
                          const selectedRound = skill.id_round;
                          const selectedSkill = skill.id_skill;

                          let swordStyle,
                            daggerStyle,
                            staffStyle = {
                              '--rarityColor': ''
                            } as React.CSSProperties;

                          switch (selectedSkill) {
                            case 1: // Sword
                              swordStyle = {
                                '--rarityColor':
                                  'rgb(' + hexToRgb('#00FFA3') + ')'
                              } as React.CSSProperties;
                              break;
                            case 2: // Dagger
                              daggerStyle = {
                                '--rarityColor':
                                  'rgb(' + hexToRgb('#00FFA3') + ')'
                              } as React.CSSProperties;
                              break;
                            case 3: // Staff
                              staffStyle = {
                                '--rarityColor':
                                  'rgb(' + hexToRgb('#00FFA3') + ')'
                              } as React.CSSProperties;
                              break;
                            default:
                              break;
                          }

                          return (
                            <div className='margin20' key={j}>
                              <div className='infosBox'>
                                <div className='infosSubTitle'>
                                  Round {selectedRound}
                                </div>
                              </div>
                              <div className='skillBlock'>
                                <div className='cardSkillBlock'>
                                  <div className='cardSkillContainer'>
                                    <div
                                      className='cardSkillBox'
                                      style={swordStyle}
                                    >
                                      <div className='cardSkillBoxIn'>
                                        <div className='cardSkillInfos'>
                                          <div className='cardSkillTitle'>
                                            Sword
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusUp'>
                                              Strong
                                            </span>
                                            &nbsp;against Dagger
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusDown'>
                                              Weak
                                            </span>
                                            &nbsp;against Staff
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {selectedSkill == 1 ? (
                                      <div className='cardSkillPictureBox'>
                                        <div className='overlay'>
                                          <div className='overlayText'>
                                            Selected
                                          </div>
                                        </div>
                                        <img
                                          src={`${sword}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className='cardSkillPictureBox'
                                        onClick={() =>
                                          handleSelectedSkills(selectedRound, 1)
                                        }
                                      >
                                        <div className='overlayHover'>
                                          <div className='overlayHoverText'>
                                            Select
                                          </div>
                                        </div>
                                        <img
                                          src={`${sword}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className='cardSkillBlock'>
                                  <div className='cardSkillContainer'>
                                    <div
                                      className='cardSkillBox'
                                      style={daggerStyle}
                                    >
                                      <div className='cardSkillBoxIn'>
                                        <div className='cardSkillInfos'>
                                          <div className='cardSkillTitle'>
                                            Dagger
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusUp'>
                                              Strong
                                            </span>
                                            &nbsp;against Staff
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusDown'>
                                              Weak
                                            </span>
                                            &nbsp;against Sword
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {selectedSkill == 2 ? (
                                      <div className='cardSkillPictureBox'>
                                        <div className='overlay'>
                                          <div className='overlayText'>
                                            Selected
                                          </div>
                                        </div>
                                        <img
                                          src={`${dagger}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className='cardSkillPictureBox'
                                        onClick={() =>
                                          handleSelectedSkills(selectedRound, 2)
                                        }
                                      >
                                        <div className='overlayHover'>
                                          <div className='overlayHoverText'>
                                            Select
                                          </div>
                                        </div>
                                        <img
                                          src={`${dagger}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className='cardSkillBlock'>
                                  <div className='cardSkillContainer'>
                                    <div
                                      className='cardSkillBox'
                                      style={staffStyle}
                                    >
                                      <div className='cardSkillBoxIn'>
                                        <div className='cardSkillInfos'>
                                          <div className='cardSkillTitle'>
                                            Staff
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusUp'>
                                              Strong
                                            </span>
                                            &nbsp;against Sword
                                          </div>
                                          <div className='cardSkillText'>
                                            <span className='bonusDown'>
                                              Weak
                                            </span>
                                            &nbsp;against Dagger
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {selectedSkill == 3 ? (
                                      <div className='cardSkillPictureBox'>
                                        <div className='overlay'>
                                          <div className='overlayText'>
                                            Selected
                                          </div>
                                        </div>
                                        <img
                                          src={`${staff}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className='cardSkillPictureBox'
                                        onClick={() =>
                                          handleSelectedSkills(selectedRound, 3)
                                        }
                                      >
                                        <div className='overlayHover'>
                                          <div className='overlayHoverText'>
                                            Select
                                          </div>
                                        </div>
                                        <img
                                          src={`${staff}`}
                                          className='cardSkillPicture'
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='buttonContainer'>
                          {skillsSelected ? (
                            <Button
                              className='btnStandardSmall'
                              onClick={registerArena}
                            >
                              Validate
                            </Button>
                          ) : (
                            <Button className='btnStandardSmallDisable'>
                              Validate
                            </Button>
                          )}
                          <Button
                            className='btnStandardSmallReverse'
                            onClick={handleCloseRegistrationInfos}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
        <Modal
          show={showSkillsInfos}
          onHide={handleCloseSkillsInfos}
          size='lg'
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            {tournament_registrations.map((registration, i) => {
              let skillsSelected = false;
              let isSkills = true;
              selectedSkills.skills.map((skill) => {
                if (skill.id_skill == 0) {
                  isSkills = false;
                }
              });
              if (isSkills) skillsSelected = true;

              return (
                <div key={i}>
                  <div className='card-body p-2'>
                    <div className='infosModalTitle'>
                      Tournament Phase {registration.tournament_phase}
                    </div>
                  </div>

                  <div className='boxContainer'>
                    <div className='card-body p-2'>
                      <div className='infosBox'>
                        <div className='infosSubTitle'>Weapons Selection</div>
                      </div>
                      <div className='textImportant'>
                        Select weapons to equip your warrior for the fight.
                      </div>

                      {selectedSkills.skills.map((skill, j) => {
                        const selectedRound = skill.id_round;
                        const selectedSkill = skill.id_skill;

                        let swordStyle,
                          daggerStyle,
                          staffStyle = {
                            '--rarityColor': ''
                          } as React.CSSProperties;

                        switch (selectedSkill) {
                          case 1: // Sword
                            swordStyle = {
                              '--rarityColor':
                                'rgb(' + hexToRgb('#00FFA3') + ')'
                            } as React.CSSProperties;
                            break;
                          case 2: // Dagger
                            daggerStyle = {
                              '--rarityColor':
                                'rgb(' + hexToRgb('#00FFA3') + ')'
                            } as React.CSSProperties;
                            break;
                          case 3: // Staff
                            staffStyle = {
                              '--rarityColor':
                                'rgb(' + hexToRgb('#00FFA3') + ')'
                            } as React.CSSProperties;
                            break;
                          default:
                            break;
                        }

                        return (
                          <div className='margin20' key={j}>
                            <div className='infosBox'>
                              <div className='infosSubTitle'>
                                Round {selectedRound}
                              </div>
                            </div>
                            <div className='skillBlock'>
                              <div className='cardSkillBlock'>
                                <div className='cardSkillContainer'>
                                  <div
                                    className='cardSkillBox'
                                    style={swordStyle}
                                  >
                                    <div className='cardSkillBoxIn'>
                                      <div className='cardSkillInfos'>
                                        <div className='cardSkillTitle'>
                                          Sword
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusUp'>
                                            Strong
                                          </span>
                                          &nbsp;against Dagger
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusDown'>
                                            Weak
                                          </span>
                                          &nbsp;against Staff
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedSkill == 1 ? (
                                    <div className='cardSkillPictureBox'>
                                      <div className='overlay'>
                                        <div className='overlayText'>
                                          Selected
                                        </div>
                                      </div>
                                      <img
                                        src={`${sword}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className='cardSkillPictureBox'
                                      onClick={() =>
                                        handleSelectedSkills(selectedRound, 1)
                                      }
                                    >
                                      <div className='overlayHover'>
                                        <div className='overlayHoverText'>
                                          Select
                                        </div>
                                      </div>
                                      <img
                                        src={`${sword}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='cardSkillBlock'>
                                <div className='cardSkillContainer'>
                                  <div
                                    className='cardSkillBox'
                                    style={daggerStyle}
                                  >
                                    <div className='cardSkillBoxIn'>
                                      <div className='cardSkillInfos'>
                                        <div className='cardSkillTitle'>
                                          Dagger
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusUp'>
                                            Strong
                                          </span>
                                          &nbsp;against Staff
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusDown'>
                                            Weak
                                          </span>
                                          &nbsp;against Sword
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedSkill == 2 ? (
                                    <div className='cardSkillPictureBox'>
                                      <div className='overlay'>
                                        <div className='overlayText'>
                                          Selected
                                        </div>
                                      </div>
                                      <img
                                        src={`${dagger}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className='cardSkillPictureBox'
                                      onClick={() =>
                                        handleSelectedSkills(selectedRound, 2)
                                      }
                                    >
                                      <div className='overlayHover'>
                                        <div className='overlayHoverText'>
                                          Select
                                        </div>
                                      </div>
                                      <img
                                        src={`${dagger}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='cardSkillBlock'>
                                <div className='cardSkillContainer'>
                                  <div
                                    className='cardSkillBox'
                                    style={staffStyle}
                                  >
                                    <div className='cardSkillBoxIn'>
                                      <div className='cardSkillInfos'>
                                        <div className='cardSkillTitle'>
                                          Staff
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusUp'>
                                            Strong
                                          </span>
                                          &nbsp;against Sword
                                        </div>
                                        <div className='cardSkillText'>
                                          <span className='bonusDown'>
                                            Weak
                                          </span>
                                          &nbsp;against Dagger
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedSkill == 3 ? (
                                    <div className='cardSkillPictureBox'>
                                      <div className='overlay'>
                                        <div className='overlayText'>
                                          Selected
                                        </div>
                                      </div>
                                      <img
                                        src={`${staff}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className='cardSkillPictureBox'
                                      onClick={() =>
                                        handleSelectedSkills(selectedRound, 3)
                                      }
                                    >
                                      <div className='overlayHover'>
                                        <div className='overlayHoverText'>
                                          Select
                                        </div>
                                      </div>
                                      <img
                                        src={`${staff}`}
                                        className='cardSkillPicture'
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className='boxContainer'>
                    <div className='card-body p-2'>
                      <div className='buttonContainer'>
                        {skillsSelected ? (
                          <Button
                            className='btnStandardSmall'
                            onClick={() => registerSkills(registration)}
                          >
                            Validate
                          </Button>
                        ) : (
                          <Button className='btnStandardSmallDisable'>
                            Validate
                          </Button>
                        )}
                        <Button
                          className='btnStandardSmallReverse'
                          onClick={handleCloseSkillsInfos}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </Modal>
        <Modal
          show={showArenaResult}
          onHide={handleCloseArenaResult}
          centered
          scrollable
        >
          <Modal.Body className='modalContainer'>
            {tournament_result.map((result, i) => {
              if (result.id == arenaResultId) {
                const fighter_A = result.fighter_A;
                const fighter_B = result.fighter_B;

                // TODO

                // Fighter A
                let fighter_A_pseudo = 'BOT';
                if (fighter_A && fighter_A.user) {
                  fighter_A_pseudo = fighter_A.user.pseudo;
                }

                const NFT_A_name = fighter_A.attribut.nft_name;
                const NFT_A_url = fighter_A.attribut.nft_url;
                const NFT_A_hierarchy = fighter_A.attribut.nft_hierarchy;
                const NFT_A_style = {
                  '--rarityColor': hierarchyColor[NFT_A_hierarchy]
                } as React.CSSProperties;
                const NFT_A_flg_blockchain = fighter_A.attribut.flg_blockchain;

                const NFT_A_nameT = fighter_A.attribut.nft_name.split(' ');
                const NFT_A_collection = NFT_A_nameT[0];
                let NFT_A_number = '';
                if (NFT_A_nameT[1]) {
                  NFT_A_number = NFT_A_nameT[1].replace('#', '');
                }

                // Fighter B
                let fighter_B_pseudo = 'BOT';
                if (fighter_B && fighter_B.user) {
                  fighter_B_pseudo = fighter_B.user.pseudo;
                }

                const NFT_B_name = fighter_B.attribut.nft_name;
                const NFT_B_url = fighter_B.attribut.nft_url;
                const NFT_B_hierarchy = fighter_B.attribut.nft_hierarchy;
                const NFT_B_style = {
                  '--rarityColor': hierarchyColor[NFT_B_hierarchy]
                } as React.CSSProperties;
                const NFT_B_flg_blockchain = fighter_B.attribut.flg_blockchain;

                const NFT_B_nameT = fighter_B.attribut.nft_name.split(' ');
                const NFT_B_collection = NFT_B_nameT[0];
                let NFT_B_number = '';
                if (NFT_B_nameT[1]) {
                  NFT_B_number = NFT_B_nameT[1].replace('#', '');
                }

                // Winner
                let winner;
                if (fighter_A.flg_winner) {
                  winner = NFT_A_collection + ' ' + NFT_A_number;
                }
                if (fighter_B.flg_winner) {
                  winner = NFT_B_collection + ' ' + NFT_B_number;
                }

                // Points
                let points_A = 0;
                let points_B = 0;

                return (
                  <div key={i}>
                    <div className='card-body p-2'>
                      <div className='infosModalTitle'>
                        Fight {result.id_fight} details
                      </div>
                    </div>

                    <div className='card-body text-center p-2'>
                      {fighter_A.tournament_fights.map((fight_A, j) => {
                        const idRound = j + 1;

                        const fight_B = fighter_B.tournament_fights[j];

                        let NFT_A_skill = 0;
                        let NFT_B_skill = 0;

                        fighter_A.tournament_skills.map((skill_A) => {
                          if (fight_A.id_round == skill_A.id_round) {
                            NFT_A_skill = skill_A.id_skill;
                          }
                        });

                        fighter_B.tournament_skills.map((skill_B) => {
                          if (fight_B.id_round == skill_B.id_round) {
                            NFT_B_skill = skill_B.id_skill;
                          }
                        });

                        const NFT_A_skill_win = fight_A.skill_win;
                        const NFT_B_skill_win = fight_B.skill_win;

                        if (NFT_A_skill_win) points_A++;
                        if (NFT_B_skill_win) points_B++;

                        let skill_A_Style = {
                          '--skillColor': hexToRgb('#FAF7E2')
                        } as React.CSSProperties;
                        let skill_A_Name = '';
                        let skill_A_Img;
                        switch (NFT_A_skill) {
                          case 1:
                            skill_A_Img = sword;
                            skill_A_Name = 'Sword';
                            skill_A_Style = {
                              '--skillColor': hexToRgb('#9e640c')
                            } as React.CSSProperties;
                            break;
                          case 2:
                            skill_A_Img = dagger;
                            skill_A_Name = 'Dagger';
                            skill_A_Style = {
                              '--skillColor': hexToRgb('#d6da1c')
                            } as React.CSSProperties;
                            break;
                          case 3:
                            skill_A_Img = staff;
                            skill_A_Name = 'Staff';
                            skill_A_Style = {
                              '--skillColor': hexToRgb('#1d60f0')
                            } as React.CSSProperties;
                            break;
                          default:
                            break;
                        }

                        let skill_B_Style = {
                          '--skillColor': hexToRgb('#FAF7E2')
                        } as React.CSSProperties;
                        let skill_B_Name = '';
                        let skill_B_Img;
                        switch (NFT_B_skill) {
                          case 1:
                            skill_B_Img = sword;
                            skill_B_Name = 'Sword';
                            skill_B_Style = {
                              '--skillColor': hexToRgb('#9e640c')
                            } as React.CSSProperties;
                            break;
                          case 2:
                            skill_B_Img = dagger;
                            skill_B_Name = 'Dagger';
                            skill_B_Style = {
                              '--skillColor': hexToRgb('#d6da1c')
                            } as React.CSSProperties;
                            break;
                          case 3:
                            skill_B_Img = staff;
                            skill_B_Name = 'Staff';
                            skill_B_Style = {
                              '--skillColor': hexToRgb('#1d60f0')
                            } as React.CSSProperties;
                            break;
                          default:
                            break;
                        }

                        if (j < 3) {
                          return (
                            <React.Fragment key={j}>
                              <div className='margin20'>
                                <div className='card-body text-center p-2'>
                                  <div className='infosModalTitle'>
                                    Round {idRound}
                                  </div>
                                </div>
                              </div>

                              <div className='arenaFightContainer'>
                                <div className='arenaFighterLeftBox'>
                                  <div className='arenaFighterOut'>
                                    <div className='arenaFighterIn'>
                                      <div className='arenaFighterText'>
                                        {fighter_A_pseudo}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='arenaFightBox'>
                                  <div className='arenaFightBoxIn'>
                                    <div className='arenaFightBoxInfos'>
                                      <div className='roundContainerLeft'>
                                        <div className='roundCardLeft'>
                                          {NFT_A_flg_blockchain ? (
                                            <img
                                              src={`${NFT_A_url}`}
                                              className='roundCardNFT'
                                            />
                                          ) : (
                                            <img
                                              src={`../${NFT_A_url}`}
                                              className='roundCardNFT'
                                            />
                                          )}
                                        </div>
                                        <div className='roundInfosLeft'>
                                          <div className='roundInfosName'>
                                            {!NFT_A_number ? (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_A_collection}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  &nbsp;
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_A_number}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  {NFT_A_collection}
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                        <div className='roundSkillLeft'>
                                          <img
                                            src={`${skill_A_Img}`}
                                            className='roundCardSkill'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBoxIn2'>
                                      <div className='arenaFightBoxInText2'>
                                        Skill : {skill_A_Name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='arenaFightContainer'>
                                <div className='arenaFighterRightBox'>
                                  <div className='arenaFighterOut'>
                                    <div className='arenaFighterIn'>
                                      <div className='arenaFighterText'>
                                        {fighter_B_pseudo}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='arenaFightBox'>
                                  <div className='arenaFightBoxIn'>
                                    <div className='arenaFightBoxInfos'>
                                      <div className='roundContainerRight'>
                                        <div className='roundSkillRight'>
                                          <img
                                            src={`${skill_B_Img}`}
                                            className='roundCardSkill'
                                          />
                                        </div>
                                        <div className='roundInfosRight'>
                                          <div className='roundInfosName'>
                                            {!NFT_B_number ? (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_B_collection}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  &nbsp;
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_B_number}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  {NFT_B_collection}
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                        <div className='roundCardRight'>
                                          {NFT_B_flg_blockchain ? (
                                            <img
                                              src={`${NFT_B_url}`}
                                              className='roundCardNFT'
                                            />
                                          ) : (
                                            <img
                                              src={`../${NFT_B_url}`}
                                              className='roundCardNFT'
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBoxIn2'>
                                      <div className='arenaFightBoxInText2'>
                                        Skill : {skill_B_Name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='margin10'>
                                <div className='card-body text-center p-2'>
                                  <div>
                                    <span className='deployBoxTitle'>
                                      {fighter_A_pseudo}
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='arenaFightBoxInText2'>
                                      :
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='cardDynNFTNumber'>
                                      {points_A}
                                    </span>
                                  </div>
                                  <div>
                                    <span className='deployBoxTitle'>
                                      {fighter_B_pseudo}
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='arenaFightBoxInText2'>
                                      :
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='cardDynNFTNumber'>
                                      {points_B}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={j}>
                              <div className='margin20'>
                                <div className='card-body text-center p-2'>
                                  <div className='infosModalTitle'>
                                    Sudden Death
                                  </div>
                                </div>
                              </div>

                              <div className='arenaFightContainer'>
                                <div className='arenaFighterLeftBox'>
                                  <div className='arenaFighterOut'>
                                    <div className='arenaFighterIn'>
                                      <div className='arenaFighterText'>
                                        {fighter_A_pseudo}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='arenaFightBox'>
                                  <div className='arenaFightBoxIn'>
                                    <div className='arenaFightBoxInfos'>
                                      <div className='roundContainerLeft'>
                                        <div className='roundCardLeft'>
                                          {NFT_A_flg_blockchain ? (
                                            <img
                                              src={`${NFT_A_url}`}
                                              className='roundCardNFT'
                                            />
                                          ) : (
                                            <img
                                              src={`../${NFT_A_url}`}
                                              className='roundCardNFT'
                                            />
                                          )}
                                        </div>
                                        <div className='roundInfosLeft'>
                                          <div className='roundInfosName'>
                                            {!NFT_A_number ? (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_A_collection}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  &nbsp;
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_A_number}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  {NFT_A_collection}
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBoxIn2'>
                                      <div className='arenaFightBoxInText2'>
                                        {NFT_A_skill_win ? 'WINNER' : 'LOSER'}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='arenaFightContainer'>
                                <div className='arenaFighterRightBox'>
                                  <div className='arenaFighterOut'>
                                    <div className='arenaFighterIn'>
                                      <div className='arenaFighterText'>
                                        {fighter_B_pseudo}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='arenaFightBox'>
                                  <div className='arenaFightBoxIn'>
                                    <div className='arenaFightBoxInfos'>
                                      <div className='roundContainerRight'>
                                        <div className='roundInfosRight'>
                                          <div className='roundInfosName'>
                                            {!NFT_B_number ? (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_B_collection}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  &nbsp;
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <div className='cardDynNFTNumber'>
                                                  {NFT_B_number}
                                                </div>
                                                <div className='cardDynNFTName'>
                                                  {NFT_B_collection}
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                        <div className='roundCardRight'>
                                          {NFT_B_flg_blockchain ? (
                                            <img
                                              src={`${NFT_B_url}`}
                                              className='roundCardNFT'
                                            />
                                          ) : (
                                            <img
                                              src={`../${NFT_B_url}`}
                                              className='roundCardNFT'
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='arenaFightBoxIn2'>
                                      <div className='arenaFightBoxInText2'>
                                        {NFT_B_skill_win ? 'WINNER' : 'LOSER'}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='margin10'>
                                <div className='card-body text-center p-2'>
                                  <div>
                                    <span className='deployBoxTitle'>
                                      {fighter_A_pseudo}
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='arenaFightBoxInText2'>
                                      :
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='cardDynNFTNumber'>
                                      {points_A}
                                    </span>
                                  </div>
                                  <div>
                                    <span className='deployBoxTitle'>
                                      {fighter_B_pseudo}
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='arenaFightBoxInText2'>
                                      :
                                    </span>
                                    &nbsp;&nbsp;
                                    <span className='cardDynNFTNumber'>
                                      {points_B}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>

                    <div className='margin10'>
                      <div className='card-body text-center p-2'>
                        <div className='infosModalTitle'>{winner}</div>
                        <div className='deployBoxTitle'>wins the fight</div>
                      </div>
                    </div>

                    <div className='margin10'>
                      <div className='card-body p-2'>
                        <div className='buttonContainer'>
                          <Button
                            className='btnStandardSmallReverse'
                            onClick={handleCloseArenaResult}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
        <Modal show={showRules} onHide={handleCloseRules} centered scrollable>
          <Modal.Body className='modalContainer'>
            <div className='card-body p-2'>
              <div className='infosModalTitle'>Tournament Rules</div>
            </div>

            <div className='margin10'>
              <div className='card-body text-center p-2'>
                <div className='textImportant'>
                  Fighters gain or lose&nbsp;
                  <span className='textImportantRed'>2</span>
                  &nbsp;to&nbsp;
                  <span className='textImportantRed'>30</span>
                  &nbsp;ELO points based on the opponent&apos;s rank.
                </div>
                <div className='textImportant margin20'>
                  Engage in up to&nbsp;
                  <span className='textImportantRed'>
                    {maxRegistrationPerDay}
                  </span>
                  &nbsp;fights a day per NFT.
                  <br />
                  Register&nbsp;
                  <span className='textImportantRed'>2</span>
                  &nbsp;to&nbsp;
                  <span className='textImportantRed'>5</span>
                  &nbsp;fighters from your collection.
                </div>
                <div className='textImportant margin20'>
                  The one who KO his opponent wins.
                  <br />
                  Damage is based on the fighter&apos;s power.
                  <br />
                  Choose a weapon for your fighter, the right matchup doubles
                  damage.
                </div>
                <div className='textImportant margin20'>
                  The fighter with the best speed roll, determined between his
                  speed and 50, goes first.
                </div>
                <div className='textImportant margin20'>
                  Fighters can be strengthened with potions.
                </div>
              </div>
            </div>

            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseRules}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
};

export default ArenaTournament;
