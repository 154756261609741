import http from '../http-common';
import { LeaderboardsBonusStateType } from '../types/leaderboardsbonus.type';

class LeaderboardsBonusDataService {
  findOneByUser(params: any) {
    return http.get<LeaderboardsBonusStateType>('/leaderboardsbonus/user/', {
      params
    });
  }
}

export default new LeaderboardsBonusDataService();
