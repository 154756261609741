export const battleEmpty: IBattleData = {
  id: '',
  id_conquest: 0,
  id_area: 0,
  battleOrder: 0,
  battleAt: new Date()
};

export interface IBattleData {
  id?: any | null;
  id_conquest: number;
  id_area: number;
  battleOrder: number;
  battleAt: Date;
}

export interface BattleStateType {
  battle: IBattleData;
  message?: string;
  status: string;
}

export interface BattlesStateType {
  battles: IBattleData[];
  message?: string;
  status: string;
}
