import http from '../http-common';
import {
  TournamentResultStateType,
  TournamentResultsStateType
} from '../types/tournamentresults.type';

class TournamentResultsDataService {
  findAllUserResults(id_user: any, params: any) {
    return http.get<TournamentResultsStateType>(
      `/tournament_results/user/${id_user}`,
      {
        params
      }
    );
  }

  findLatestResults(params: any) {
    return http.get<TournamentResultsStateType>('/tournament_results/latest/', {
      params
    });
  }

  findOneResultById(id: any) {
    return http.get<TournamentResultStateType>(`/tournament_results/${id}`);
  }
}

export default new TournamentResultsDataService();
