import * as React from 'react';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import InventoriesDataService from '../../services/inventories.service';
import WalletsDataService from '../../services/wallets.service';
import {
  InventoryStateType,
  InventoryInfos,
  inventoryEmpty
} from '../../types/inventories.type';
import { WalletsStateType } from '../../types/wallets.type';

const Inventory = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const [showConquest, setShowConquest] = React.useState(false);
  const [showArena, setShowArena] = React.useState(false);
  const handleShowConquest = () => {
    setShowConquest(true);
    setShowArena(false);
  };
  const handleShowArena = () => {
    setShowConquest(false);
    setShowArena(true);
  };

  const [showRankedArena, setShowRankedArena] = React.useState(true);
  const [showChallengerArena, setShowChallengerArena] = React.useState(false);
  const handleShowRankedArena = () => {
    setShowRankedArena(true);
    setShowChallengerArena(false);
  };
  const handleShowChallengerArena = () => {
    setShowRankedArena(false);
    setShowChallengerArena(true);
  };

  const [walletsInfos, setWalletsInfos] = React.useState<WalletsStateType>({
    wallets: [],
    message: '',
    status: ''
  });
  const [inventoryInfos, setInventoryInfos] =
    React.useState<InventoryStateType>({
      inventory: [],
      message: '',
      status: ''
    });
  const [inventoryGrpInfos, setInventoryGrpInfos] =
    React.useState<InventoryInfos>({
      inventory: []
    });

  const fetchWalletsData = () => {
    WalletsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setWalletsInfos({
          wallets: data.wallets,
          status: data.status
        });
      }
    });
  };

  const fetchInventoryData = () => {
    InventoriesDataService.findAllByUser(userSession.userID).then(
      ({ data }) => {
        if (data.status == 'success') {
          setInventoryInfos({
            inventory: data.inventory,
            status: data.status
          });
        }
      }
    );
  };

  const handleInventoryGrpInfos = () => {
    let inventory_item_type = 0;
    if (showConquest) inventory_item_type = 2;
    if (showArena) {
      if (showRankedArena) inventory_item_type = 5;
      if (showChallengerArena) inventory_item_type = 3;
    }

    let prevIdItem = 0;
    let itemCount = 0;
    let dataInventory = inventoryEmpty;
    const inventory: InventoryInfos = {
      inventory: []
    };
    inventoryInfos.inventory.map((item) => {
      if (item.id_item != prevIdItem) {
        if (prevIdItem != 0 && itemCount > 0) {
          inventory.inventory.push(dataInventory);
        }

        prevIdItem = item.id_item;
        itemCount = 0;

        dataInventory = {
          id_item: item.id_item,
          img: item.item.item_img,
          itemInfos: item.item,
          itemsAvailableCount: 0,
          itemsAvailable: []
        };
      }
      if (!item.flg_used && item.item.item_type == inventory_item_type) {
        itemCount++;
        dataInventory.itemsAvailableCount++;
        dataInventory.itemsAvailable.push({
          identifier: item.identifier
        });
      }
    });
    if (prevIdItem != 0 && itemCount > 0) {
      inventory.inventory.push(dataInventory);
    }
    setInventoryGrpInfos(inventory);
  };

  React.useEffect(() => {
    switch (userSession.gameMode) {
      case 'conquest':
        handleShowConquest();
        break;
      case 'arenaranked':
        handleShowArena();
        break;
      default:
        handleShowConquest();
        break;
    }
  }, []);

  React.useEffect(() => {
    fetchWalletsData();
    fetchInventoryData();
  }, []);

  React.useEffect(() => {
    if (inventoryInfos.status == 'success') {
      handleInventoryGrpInfos();
    }
  }, [
    inventoryInfos,
    showConquest,
    showArena,
    showRankedArena,
    showChallengerArena
  ]);

  const { wallets } = walletsInfos;
  const { inventory } = inventoryGrpInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageTitle'>My Inventory</div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Rise of Factions Balance</div>
                <div className='infosBalance'>
                  {wallets.map((wallet, i) => {
                    let tokenName = '';

                    tokensListNames.map((token) => {
                      if (token.token_identifier == wallet.token_identifier) {
                        tokenName = token.name;
                      }
                    });

                    const balance = wallet.amount.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    });

                    if (i == 0) {
                      return (
                        <span key={i}>
                          {balance} {tokenName}
                        </span>
                      );
                    } else {
                      return (
                        <span key={i}>
                          &nbsp;&nbsp; | &nbsp;&nbsp;{balance} {tokenName}
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
              <div className='infosBoxSub'>
                <div className='buttonsNFTContainer'>
                  <Button
                    className='btnStandardReverse'
                    onClick={() => navigate(routeNames.marketplace)}
                  >
                    Marketplace
                  </Button>
                  <Button
                    className='btnStandard'
                    onClick={() => navigate(routeNames.marketplacehistory)}
                  >
                    Marketplace History
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body text-center p-2'>
              {showConquest ? (
                <React.Fragment>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowConquest}
                  >
                    Conquest
                  </Button>
                  <Button
                    className='navStandardSmall'
                    onClick={handleShowArena}
                  >
                    Arena
                  </Button>
                </React.Fragment>
              ) : null}
              {showArena ? (
                <React.Fragment>
                  <Button
                    className='navStandardSmall'
                    onClick={handleShowConquest}
                  >
                    Conquest
                  </Button>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowArena}
                  >
                    Arena
                  </Button>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          {/*
          {showArena ? (
            <div className='boxContainer'>
              <div className='card-body text-center p-2'>
                {showRankedArena ? (
                  <React.Fragment>
                    <Button
                      className='navStandardSmallReverse'
                      onClick={handleShowRankedArena}
                    >
                      Ranked Mode
                    </Button>
                    <Button
                      className='navStandardSmall'
                      onClick={handleShowChallengerArena}
                    >
                      Challenger Mode
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      className='navStandardSmall'
                      onClick={handleShowRankedArena}
                    >
                      Ranked Mode
                    </Button>
                    <Button
                      className='navStandardSmallReverse'
                      onClick={handleShowChallengerArena}
                    >
                      Challenger Mode
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : null}
          */}

          {showConquest && inventoryInfos.status == 'success' ? (
            <React.Fragment>
              {inventory.length > 0 ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='itemBlock'>
                      {inventory.map((item, i) => {
                        const divStyle = {
                          '--rarityColor': item.itemInfos.item_rarityColor
                        } as React.CSSProperties;

                        let attack = false;
                        let defense = false;
                        switch (item.itemInfos.flg_use) {
                          case 1:
                            attack = true;
                            break;
                          case 2:
                            defense = true;
                            break;
                          default:
                            attack = true;
                            defense = true;
                            break;
                        }

                        const NFTscoreRounded =
                          item.itemInfos.item_power.toFixed(2);

                        const nameT = item.itemInfos.item_name.split(' ');

                        let item_name_1;
                        let item_name_2;
                        switch (nameT.length) {
                          case 1:
                            item_name_1 = nameT[0];
                            break;
                          case 2:
                            item_name_1 = nameT[0];
                            item_name_2 = nameT[1];
                            break;
                          default:
                            item_name_1 = nameT[0] + ' ' + nameT[1];
                            item_name_2 = nameT[2];
                            break;
                        }

                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      {item.itemInfos.item_rarity}
                                    </div>
                                    <div className='cardItemPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardItemPictureBox'>
                                <div className='overlayNumber'>
                                  <span className='overlayNumberText'>x</span>
                                  <span className='overlayNumberTextNumber'>
                                    {item.itemsAvailableCount}
                                  </span>
                                </div>
                                <img
                                  src={`${item.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!item_name_2 ? (
                                  <div className='cardItemTitle'>
                                    {item_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {item_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {item_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className='cardItemUsage'>
                                {attack && (
                                  <IconContext.Provider
                                    value={{ className: 'cardUsageAttack' }}
                                  >
                                    <GiCrossedSwords />
                                  </IconContext.Provider>
                                )}
                                {defense && (
                                  <IconContext.Provider
                                    value={{ className: 'cardUsageDef' }}
                                  >
                                    <GiBorderedShield />
                                  </IconContext.Provider>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxContainer'>
                  <div className='card-body text-center p-2'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p>
                      Oops... it looks like you don&apos;t have any Conquest
                      Item at the moment !
                    </p>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : null}

          {showArena && inventoryInfos.status == 'success' ? (
            <React.Fragment>
              {showRankedArena ? (
                <React.Fragment>
                  {inventory.length > 0 ? (
                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='itemBlock'>
                          {inventory.map((item, i) => {
                            const divStyle = {
                              '--rarityColor': item.itemInfos.item_rarityColor
                            } as React.CSSProperties;

                            const nameT = item.itemInfos.item_name.split(' ');

                            let item_name_1;
                            let item_name_2;
                            switch (nameT.length) {
                              case 1:
                                item_name_1 = nameT[0];
                                break;
                              case 2:
                                item_name_1 = nameT[0];
                                item_name_2 = nameT[1];
                                break;
                              default:
                                break;
                            }

                            if (item.itemInfos.id_item == 12) {
                              item_name_1 = 'HP';
                            }

                            return (
                              <div
                                key={i}
                                style={divStyle}
                                className='cardItemBlock'
                              >
                                <div className='cardItemContainer'>
                                  <div className='cardItemBox'>
                                    <div className='cardItemBoxIn'>
                                      <div className='cardItemInfos'>
                                        <div className='cardArenaItemName'>
                                          {item.itemInfos.item_name}
                                        </div>
                                        <div className='cardItemPower'>
                                          <span className='bonusUp'>
                                            +{item.itemInfos.item_power}
                                          </span>
                                          &nbsp;{item_name_1}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='cardItemPictureBox'>
                                    <div className='overlayNumber'>
                                      <span className='overlayNumberText'>
                                        x
                                      </span>
                                      <span className='overlayNumberTextNumber'>
                                        {item.itemsAvailableCount}
                                      </span>
                                    </div>
                                    <img
                                      src={`${item.img}`}
                                      className='cardItemPicture'
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='boxContainer'>
                      <div className='card-body text-center p-2'>
                        <div className='iconContainer'>
                          <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                        </div>
                        <p>
                          Oops... it looks like you don&apos;t have any Arena
                          Item at the moment !
                        </p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : null}

              {showChallengerArena ? (
                <React.Fragment>
                  {inventory.length > 0 ? (
                    <div className='boxContainer'>
                      <div className='card-body p-2'>
                        <div className='itemBlock'>
                          {inventory.map((item, i) => {
                            const divStyle = {
                              '--rarityColor': item.itemInfos.item_rarityColor
                            } as React.CSSProperties;

                            const nameT = item.itemInfos.item_name.split(' ');

                            let item_name_1;
                            let item_name_2;
                            switch (nameT.length) {
                              case 1:
                                item_name_1 = nameT[0];
                                break;
                              case 2:
                                item_name_1 = nameT[0];
                                item_name_2 = nameT[1];
                                break;
                              default:
                                item_name_1 = nameT[0] + ' ' + nameT[1];
                                item_name_2 = nameT[2];
                                break;
                            }

                            return (
                              <div
                                key={i}
                                style={divStyle}
                                className='cardItemBlock'
                              >
                                <div className='cardItemContainer'>
                                  <div className='cardItemBox'>
                                    <div className='cardItemBoxIn'></div>
                                  </div>
                                  <div className='cardItemPictureBox'>
                                    <div className='overlayNumber'>
                                      <span className='overlayNumberText'>
                                        x
                                      </span>
                                      <span className='overlayNumberTextNumber'>
                                        {item.itemsAvailableCount}
                                      </span>
                                    </div>
                                    <img
                                      src={`${item.img}`}
                                      className='cardItemPicture'
                                    />
                                  </div>
                                  <div className='cardItemInfosLite'>
                                    {!item_name_2 ? (
                                      <div className='cardItemTitle'>
                                        {item_name_1}
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <div className='cardItemNumber'>
                                          {item_name_1}
                                        </div>
                                        <div className='cardItemName'>
                                          {item_name_2}
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='boxContainer'>
                      <div className='card-body text-center p-2'>
                        <div className='iconContainer'>
                          <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                        </div>
                        <p>
                          Oops... it looks like you don&apos;t have any Arena
                          Item at the moment !
                        </p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Inventory;
