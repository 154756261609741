export const colorsEmpty: Colors = {
  color: [],
  colorHover: []
};

export interface Colors {
  color: Array<string>;
  colorHover: Array<string>;
}

export interface ColorsStateType {
  colors: Colors;
}

export interface IAreaData {
  id_area: number;
  name: string;
  flg_battle: boolean;
  id_faction: number;
  faction: IFactionData;
  updatedAt: Date;
}

export interface IFactionData {
  name: string;
  color: string;
  colorHover: string;
}

export interface AreasStateType {
  areas: IAreaData[];
  message?: string;
  status: string;
}
