export interface IConquestsLogsData {
  id?: any | null;
  id_conquest: number;
  id_area: number;
  id_faction_winner: number;
  id_faction: number;
  bonus_value: number;
  malus_value: number;
  tot_power: number;
  faction: IFactionData;
  area: IAreaData;
}

export interface IFactionData {
  name: string;
  color: string;
  colorHover: string;
}

export interface IAreaData {
  name: string;
}

export interface ConquestsLogsStateType {
  conquests_logs: IConquestsLogsData[];
  message?: string;
  status: string;
}

export const conquestEmpty: IConquestsLogsInfos = {
  id_area: 0,
  name_area: '',
  id_faction: 0,
  name_faction: '',
  color: '',
  colorHover: '',
  infos: [],
  showResult: false
};

export interface IConquestsLogsInfos {
  id_area: number;
  name_area: string;
  id_faction: number;
  name_faction: string;
  color: string;
  colorHover: string;
  infos: IPowerData[];
  showResult: boolean;
}

export interface IPowerData {
  id_faction: number;
  name: string;
  img: string;
  bonus_value: number;
  malus_value: number;
  tot_power: number;
}

export interface ConquestsLogsInfos {
  conquests_logs: IConquestsLogsInfos[];
}
