import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import General from './General';
import GeneralModerator from './GeneralModerator';
import Tournament from './Tournament';

const navigationAdmin = [
  { name: 'Général', href: '/jcorpgest' },
  { name: 'Tournament', href: '/jcorpgest/tournament' }
];

const Admin = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { userSession } = useAuthContext();

  const [adminContent, setAdminContent] = React.useState<string>('');

  const handleChangeMenu = (params: any) => {
    if (params.category != null) setAdminContent(params.category);
    else setAdminContent('');
  };

  React.useEffect(() => {
    handleChangeMenu(params);
  }, [params]);

  React.useEffect(() => {
    if (!userSession.isAllowed) {
      navigate(routeNames.conquest);
    }
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='pageTitle'>Administration Panel</div>
              </div>
              <div className='infosBoxSub'>
                <div className='buttonsNFTContainer'>
                  {adminContent != '' ? (
                    <Button
                      className='btnStandardReverse'
                      onClick={() => navigate('/jcorpgest')}
                    >
                      Menu
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {adminContent != null ? (
            <React.Fragment>
              {adminContent == '' && userSession.isAdmin ? <General /> : null}
              {adminContent == '' && userSession.isModerator ? (
                <GeneralModerator />
              ) : null}
              {adminContent == 'tournament' ? <Tournament /> : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Admin;
