import http from '../http-common';
import {
  IFactionShopData,
  FactionShopsStateType,
  FactionShopCreation
} from '../types/factionsshops.type';

class FactionsShopsDataService {
  create(data: IFactionShopData) {
    return http.post<IFactionShopData>('/factions_shops', data);
  }

  createEvent(data: any) {
    return http.post<FactionShopCreation>('/factions_shops/new', data);
  }

  findAllByFaction(id_faction: any, params: any) {
    return http.get<FactionShopsStateType>(
      `/factions_shops/faction/${id_faction}`,
      {
        params
      }
    );
  }
}

export default new FactionsShopsDataService();
