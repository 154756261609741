import http from '../http-common';
import {
  IShopData,
  ShopStateType,
  ShopsStateType,
  ShopsStatsStateType,
  ShopCreation,
  ShopClaim
} from '../types/shops.type';

class ShopsDataService {
  create(data: IShopData) {
    return http.post<IShopData>('/shops', data);
  }

  createEvent(data: any) {
    return http.post<ShopCreation>('/shops/new', data);
  }

  createClaim(data: any) {
    return http.post<ShopClaim>('/shops/claim', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/shops/${id}`, data);
  }

  findOneByTxHash(params: any) {
    return http.get<ShopStateType>('/shops/', { params });
  }

  findAllByUser(id_user: any, params: any) {
    return http.get<ShopsStateType>(`/shops/user/${id_user}`, {
      params
    });
  }

  findAllByToken() {
    return http.get<ShopsStatsStateType>('/shops/stats');
  }
}

export default new ShopsDataService();
