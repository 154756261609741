export const factionEmpty: IFactionData = {
  id_faction: 0,
  name: '',
  color: '',
  colorHover: '',
  id_user: 0,
  num_item: 0,
  amount: 0,
  token_identifier: ''
};

export interface IFactionData {
  id_faction: number;
  name: string;
  color: string;
  colorHover: string;
  id_user: number;
  num_item: number;
  amount: number;
  token_identifier: string;
}

export interface FactionStateType {
  faction: IFactionData;
  message?: string;
  status: string;
}

export interface FactionsStateType {
  factions: IFactionData[];
  message?: string;
  status: string;
}
