import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import SettingDataService from '../../services/setting.service';
import arena from './../../assets/img/arena.png';
import city from './../../assets/img/city.png';
import infoIcon from './../../assets/img/icons/info.png';
import marketplace from './../../assets/img/marketplace.png';
import titan from './../../assets/img/titan.png';
import tower from './../../assets/img/tower.png';
import universe from './../../assets/img/universe.png';

const Menu = () => {
  const navigate = useNavigate();

  const { userSession, userInfos } = useAuthContext();

  // TODO - launch
  /*
  function MyTimerLaunch({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours, days } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshArena()
    });

    return (
      <span>
        <span>{days < 10 ? '0' + days : days}</span>:
        <span>{hours < 10 ? '0' + hours : hours}</span>:
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
      </span>
    );
  }

  const [isLaunched, setIsLaunched] = React.useState<boolean>();
  const [rankedArenaLaunch, setRankedArenaLaunch] = React.useState({
    time: new Date()
  });
  */

  // TODO - launch
  /*
  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'arenaLaunch';

    // Fetch of the current fights infos in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setRankedArenaLaunch({
          time: data.setting.nextPhase
        });

        const date = new Date();
        const launchDate = new Date(data.setting.nextPhase);
        if (launchDate > date) {
          setIsLaunched(false);
        } else {
          setIsLaunched(true);
        }
      }
    });
  };
  */

  /*
  const handleRefreshArena = () => {
    fetchSettingData();
  };

  React.useEffect(fetchSettingData, []);
  */

  const { user } = userInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageTitle'>Game Modes</div>
            </div>
          </div>

          {userSession.isBlockchainAccount ? (
            <div className='boxContainer topContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightBtn'>
                    <div
                      className='stripBtn'
                      onClick={() => navigate(routeNames.mint)}
                    >
                      <div className='stripBtnIn'>
                        <div className='stripBtnText'>Mint</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {userInfos.status == 'failed' && (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{userInfos.message}</div>
              </div>
            </div>
          )}

          {userInfos.status == 'success' && (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='stripContainerLeft'>
                  <div className='stripBoxLeft'>
                    {/*
                    <div className='stripBoxLeftBtn'>
                      <div
                        className='stripBtn'
                        onClick={() => navigate(routeNames.nftsearch)}
                      >
                        <div className='stripBtnIn'>
                          <div className='stripBtnText'>NFTs Search</div>
                        </div>
                      </div>
                    </div>
                    */}
                    <div className='stripBoxLeftTimerBox'>
                      <div className='stripBoxLeftTimer'>
                        <div className='infosTimerText'>{user.pseudo}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='boxContainer topContainer'>
                <div className='menuContainer'>
                  <div className='menuBox'>
                    <div
                      className='menuBoxIn'
                      onClick={() => navigate(routeNames.conquest)}
                    >
                      <div className='menuBoxImgContainer'>
                        <img src={`${universe}`} className='menuBoxImg' />
                      </div>
                    </div>
                    <div className='menuBoxIn2'>
                      <div className='menuBoxIn2Text'>Conquest</div>
                      {/*
                      <div className='menuBoxIn2Text'>
                        Conquest
                        <div
                          className='menuInfosContainer'
                          onClick={handleShowArenaInfos}
                        >
                          <img src={`${infoIcon}`} className='menuInfosIcon' />
                        </div>
                      </div>
                      */}
                    </div>
                  </div>

                  <div className='menuBox'>
                    <div
                      className='menuBoxIn'
                      onClick={() => navigate(routeNames.arenaranked)}
                    >
                      <div className='menuBoxImgContainer'>
                        <img src={`${arena}`} className='menuBoxImg' />
                      </div>
                    </div>
                    <div className='menuBoxIn2'>
                      <div className='menuBoxIn2Text'>Ranked Arena</div>
                    </div>
                  </div>

                  {/*
                  {isLaunched ? (
                    <div className='menuBox'>
                      <div
                        className='menuBoxIn'
                        onClick={() => navigate(routeNames.arenaranked)}
                      >
                        <div className='menuBoxImgContainer'>
                          <img src={`${arena}`} className='menuBoxImg' />
                        </div>
                      </div>
                      <div className='menuBoxIn2'>
                        <div className='menuBoxIn2Text'>Ranked Arena</div>
                      </div>
                    </div>
                  ) : (
                    <div className='menuBox'>
                      <div className='menuBoxIn'>
                        <div className='menuBoxImgContainer'>
                          <img src={`${arena}`} className='menuBoxImg' />
                        </div>
                      </div>
                      <div className='menuBoxIn2'>
                        <div className='menuBoxIn2Text'>Ranked Arena</div>
                      </div>
                      <div className='menuBoxIn3'>
                        <div className='menuBoxIn3Text'>
                          Launch in&nbsp;
                          <MyTimerLaunch
                            expiryTimestamp={new Date(rankedArenaLaunch.time)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  */}
                </div>
              </div>

              <div className='boxContainer topContainer'>
                <div className='menuContainer'>
                  <div className='menuBox'>
                    <div
                      className='menuBoxIn'
                      onClick={() => navigate(routeNames.arenatournament)}
                    >
                      <div className='menuBoxImgContainer'>
                        <img src={`${arena}`} className='menuBoxImg' />
                      </div>
                    </div>
                    <div className='menuBoxIn2'>
                      <div className='menuBoxIn2Text'>Special Tournaments</div>
                    </div>
                    <div className='menuBoxIn3'>
                      <div className='menuBoxIn3Text'>Coming soon ...</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='boxContainer topContainer'>
                <div className='menuContainer'>
                  <div className='menuBox'>
                    <div className='menuBoxIn'>
                      <div className='menuBoxImgContainer'>
                        <img src={`${titan}`} className='menuBoxImg' />
                      </div>
                    </div>
                    <div className='menuBoxIn2'>
                      <div className='menuBoxIn2Text'>Titans</div>
                    </div>
                    <div className='menuBoxIn3'>
                      <div className='menuBoxIn3Text'>Coming soon ...</div>
                    </div>
                  </div>

                  <div className='menuBox'>
                    <div className='menuBoxIn'>
                      <div className='menuBoxImgContainer'>
                        <img src={`${tower}`} className='menuBoxImg' />
                      </div>
                    </div>
                    <div className='menuBoxIn2'>
                      <div className='menuBoxIn2Text'>Infinite Tower</div>
                    </div>
                    <div className='menuBoxIn3'>
                      <div className='menuBoxIn3Text'>Coming soon ...</div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
