import http from '../http-common';
import {
  ArenasRankedRewardsStateType,
  ArenasChallengerRewardsTotalStateType
} from '../types/arenasrewardslogs.type';

class ArenasRewardsLogsDataService {
  findAllRankedByUser(params: any) {
    return http.get<ArenasRankedRewardsStateType>(
      '/arenasrewardslogs/ranked/',
      {
        params
      }
    );
  }

  findTotalByUser(id_user: any) {
    return http.get<ArenasChallengerRewardsTotalStateType>(
      `/arenasrewardslogs/challengertotal/${id_user}`
    );
  }
}

export default new ArenasRewardsLogsDataService();
