import http from '../http-common';
import { NFTCollectionsStateType } from '../types/nft_collections.type';

class NftCollectionsDataService {
  getAll() {
    return http.get<NFTCollectionsStateType>('/nft_collections');
  }
}

export default new NftCollectionsDataService();
