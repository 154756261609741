import React from 'react';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { walletConnectV2Token } from 'config';
import AuthContextProvider from 'context/auth-context';
import PageNotFound from 'pages/PageNotFound';
import UnlockPage from 'pages/UnlockPage';
import { routeNames } from 'routes';
import routes from 'routes';

const App = () => {
  return (
    <Router>
      <AuthContextProvider>
        <DappProvider
          environment={EnvironmentsEnum.mainnet}
          customNetworkConfig={{
            name: 'customConfig',
            walletConnectV2ProjectId: walletConnectV2Token
          }}
        >
          <Layout>
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals className='custom-class-for-modals' />
            <Routes>
              <Route path={routeNames.unlock} element={<UnlockPage />} />
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </DappProvider>
      </AuthContextProvider>
    </Router>
  );
};

export default App;
