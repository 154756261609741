import http from '../http-common';
import {
  RewardsLogsStateType,
  RewardsLogsTotalStateType,
  RewardsLogsStatsStateType
} from '../types/rewardslogs.type';

class RewardsLogsDataService {
  findAllByUser(id_user: any, params: any) {
    return http.get<RewardsLogsStateType>(`/rewardslogs/user/${id_user}`, {
      params
    });
  }

  findTotalByUser(id_user: any) {
    return http.get<RewardsLogsTotalStateType>(
      `/rewardslogs/usertotal/${id_user}`
    );
  }

  findAllByToken() {
    return http.get<RewardsLogsStatsStateType>('/rewardslogs/stats');
  }
}

export default new RewardsLogsDataService();
