import * as React from 'react';
import {
  faInfoCircle,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';
import AreaDataService from '../../../../services/area.service';
import ConquestDataService from '../../../../services/conquest.service';
import FactionDataService from '../../../../services/faction.service';
import SettingDataService from '../../../../services/setting.service';
import { AreasStateType } from '../../../../types/area.type';
import {
  ConquestsStateType,
  IConquestData
} from '../../../../types/conquest.type';
import { FactionsStateType } from '../../../../types/faction.type';
import { UserStateType } from '../../../../types/user.type';
import angelic from './../../../../assets/img/angelic.png';
import meiyo from './../../../../assets/img/meiyo.png';
import night from './../../../../assets/img/night.png';

const Deployment = ({
  userInfos,
  userLeader
}: {
  userInfos: UserStateType;
  userLeader: boolean;
}) => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const [showDeployment, setShowDeployment] = React.useState(true);
  const [showAllocation, setShowAllocation] = React.useState(false);
  const handleShowDeployment = () => {
    setShowDeployment(true);
    setShowAllocation(false);
  };
  const handleShowAllocation = () => {
    setShowDeployment(false);
    setShowAllocation(true);
  };

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [currentPhase, setCurrentPhase] = React.useState(0);

  let count = 0;
  let countItems = 0;
  let countFactionItems = 0;
  const [filteredArea, setFilteredArea] = React.useState({
    id_area: 0,
    name: '',
    ratio: '',
    numDeployNFTs: 0,
    NFTsPower: '',
    NFTsPowerPercent: '',
    numDeployItems: 0,
    totalPower: ''
  });

  const [factionBonus, setFactionBonus] = React.useState(false);
  const [factionImg, setFactionImg] = React.useState('');

  const [factionAreas, setFactionAreas] = React.useState([0]);
  const [factionAllocations, setFactionAllocations] = React.useState([
    {
      pseudo: '',
      delegation: true,
      id_area: [0],
      nb_nfts: [0],
      allocation: true
    }
  ]);

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });
  const [conquestInfos, setConquestInfos] = React.useState<ConquestsStateType>({
    conquests: [],
    message: '',
    status: ''
  });
  const [conquestRedeploy, setConquestRedeploy] =
    React.useState<ConquestsStateType>({
      conquests: [],
      message: '',
      status: ''
    });
  const [AreasInfos, setAreasInfos] = React.useState<AreasStateType>({
    areas: [],
    message: '',
    status: ''
  });

  //const [ratioValue, setRatioValue] = React.useState(0);
  const [selectRedeploy, setSelectRedeploy] = React.useState({
    nbSelected: 0,
    totalPower: 0
  });

  const [phaseError, setPhaseError] = React.useState(false);
  const [startArea, setStartArea] = React.useState(0);
  const [arrivalArea, setArrivalArea] = React.useState(0);

  const [showRedeploy, setShowRedeploy] = React.useState(false);
  const handleCloseRedeploy = () => {
    setShowRedeploy(false);
    setStartArea(0);
    setArrivalArea(0);
  };
  const handleShowRedeploy = () => {
    setShowRedeploy(true);
    setStartArea(0);
    setArrivalArea(0);
  };
  const handleValidateRedeploy = async () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    await SettingDataService.findByName(params).then(async function ({ data }) {
      if (data.status == 'success') {
        if (data.setting.value == 2) {
          if (arrivalArea != 0) {
            for (let i = 0; i < conquestRedeploy.conquests.length; i++) {
              const selConquest = conquestRedeploy.conquests[i];

              const dataConquest: any = {
                id_area: arrivalArea
              };

              await ConquestDataService.update(dataConquest, selConquest.id);

              fetchConquestData();
            }
          }
        } else {
          setCurrentPhase(data.setting.value);
          setPhaseError(true);
        }
      }
    });

    setConquestRedeploy({
      conquests: [],
      status: ''
    });
    setSelectRedeploy({
      nbSelected: 0,
      totalPower: 0
    });
    setShowRedeploy(false);
  };

  const [showMassiveRedeploy, setShowMassiveRedeploy] = React.useState(false);
  const handleCloseMassiveRedeploy = () => {
    setShowMassiveRedeploy(false);
    setStartArea(0);
    setArrivalArea(0);
  };
  const handleShowMassiveRedeploy = () => {
    setShowMassiveRedeploy(true);
    setStartArea(0);
    setArrivalArea(0);
  };
  const handleValidateMassiveRedeploy = async () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    await SettingDataService.findByName(params).then(async function ({ data }) {
      if (data.status == 'success') {
        if (data.setting.value == 2) {
          if (startArea != 0 && arrivalArea != 0) {
            const params2: any = {};
            params2['id_conquest'] = currentConquest;
            params2['id_faction'] = userInfos.user.id_faction;
            params2['id_area_start'] = startArea;
            params2['id_area_arrival'] = arrivalArea;

            await ConquestDataService.massiveFactionRedeploy(params2);

            fetchConquestData();
          }
        } else {
          setCurrentPhase(data.setting.value);
          setPhaseError(true);
        }
      }
    });

    setConquestRedeploy({
      conquests: [],
      status: ''
    });
    setSelectRedeploy({
      nbSelected: 0,
      totalPower: 0
    });
    setShowMassiveRedeploy(false);
  };

  const handleSelectNFT = (selConquest: IConquestData) => {
    let isDeployed = false;
    const redeploy = { ...conquestRedeploy };
    conquestRedeploy.conquests.map((conquest) => {
      if (conquest.id == selConquest.id) {
        isDeployed = true;
        const index = redeploy.conquests.indexOf(selConquest);
        console.log('index : ', index);
        redeploy.conquests.splice(index, 1);
      }
    });
    if (!isDeployed) redeploy.conquests.push(selConquest);

    setConquestRedeploy({
      conquests: redeploy.conquests,
      status: redeploy.status
    });

    let nbSelected = 0;
    let totalPower = 0;
    redeploy.conquests.map((conquest) => {
      nbSelected++;
      if (conquest.attribut != null) {
        //totalPower += conquest.attribut.nft_power * ratioValue;
        totalPower += conquest.attribut.nft_power;
      }
    });

    setSelectRedeploy({
      nbSelected: nbSelected,
      totalPower: totalPower
    });
  };

  const handleFilterArea = (id_area: any, flgToTop: boolean) => {
    let areaFilteredName = '';
    AreasInfos.areas.map((area) => {
      if (area.id_area == id_area) {
        areaFilteredName = area.name;
      }
    });

    const areaPower = new Array(factionsInfos.factions.length);
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      areaPower[i] = 0;
    }
    conquestInfos.conquests.map((conquest) => {
      if (conquest.nft_type == 1) {
        if (conquest.attribut != null) {
          areaPower[conquest.id_faction - 1] += conquest.attribut.nft_power;
        }
      }
    });

    let faction_power_max = 0;
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      if (faction_power_max == 0 || areaPower[i] > faction_power_max) {
        faction_power_max = areaPower[i];
      }
    }

    let ratio = 0;
    if (areaPower[userInfos.user.id_faction - 1] > 0) {
      ratio = faction_power_max / areaPower[userInfos.user.id_faction - 1];
    } else {
      ratio = 1;
    }

    let NFTsPowerTotal = 0;
    let nbDeployNFTs = 0;
    let nbDeployItems = 0;
    let NFTsPower = 0;
    let NFTsPowerPercent = 0;
    let totalPower = 0;
    let isBonus = false;
    let totalBonus = 0;
    conquestInfos.conquests.map((conquest) => {
      if (conquest.id_faction == userInfos.user.id_faction) {
        let available = true;
        if (id_area != 0 && conquest.id_area != id_area) {
          available = false;
        }
        if (conquest.nft_type == 1) {
          if (conquest.attribut != null) {
            NFTsPowerTotal += conquest.attribut.nft_power;
          }
        }
        if (available) {
          if (conquest.nft_type == 1) {
            if (conquest.attribut != null) {
              nbDeployNFTs++;
              NFTsPower += conquest.attribut.nft_power;
              totalPower += conquest.attribut.nft_power * ratio;
            }
          } else {
            if (userLeader && conquest.item?.item_type == 2) {
              nbDeployItems++;
              totalPower += conquest.item.item_power;
            }

            if (
              conquest.item?.item_type == 4 &&
              conquest.item.bonus_value > 0
            ) {
              totalBonus += conquest.item.bonus_value;
              isBonus = true;
            }
          }
        }
      }
    });
    if (isBonus) {
      totalPower += (totalPower * totalBonus) / 100;
    }
    const totalPowerRounded = totalPower.toFixed(2);

    const NFTsPowerRounded = NFTsPower.toFixed(2);
    if (NFTsPower > 0 && NFTsPowerTotal > 0) {
      NFTsPowerPercent = (NFTsPower / NFTsPowerTotal) * 100;
    }
    const NFTsPowerPercentRounded = NFTsPowerPercent.toFixed(2);

    //setRatioValue(ratio);

    setFilteredArea({
      id_area: id_area,
      name: areaFilteredName,
      ratio: ratio.toFixed(6),
      numDeployNFTs: nbDeployNFTs,
      NFTsPower: NFTsPowerRounded,
      NFTsPowerPercent: NFTsPowerPercentRounded,
      numDeployItems: nbDeployItems,
      totalPower: totalPowerRounded
    });

    if (flgToTop) {
      refToTop.current &&
        refToTop.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
    }
  };

  const fetchAllocation = () => {
    let nbFactionAreas = 0;
    const factionArea = [];
    let areaPrev = 0;
    let areaPrev2 = 0;

    for (let i = 0; i < conquestInfos.conquests.length; i++) {
      const conquest = conquestInfos.conquests[i];

      if (
        conquest.id_faction == userInfos.user.id_faction &&
        conquest.nft_type == 1 &&
        conquest.id_area != areaPrev &&
        conquest.user?.flg_delegation
      ) {
        areaPrev = conquest.id_area;
        //factionArea.push(conquest.id_area);
        nbFactionAreas++;
      }

      if (
        conquest.id_faction == userInfos.user.id_faction &&
        conquest.nft_type == 1 &&
        conquest.id_area != areaPrev2
      ) {
        areaPrev2 = conquest.id_area;
        factionArea.push(conquest.id_area);
      }
    }

    const conquestData = [...conquestInfos.conquests];
    conquestData.sort((a, b) => a.id_user - b.id_user || a.id_area - b.id_area);

    const factionAllocation = [];
    let userAllocation = [];
    let userPrev = 0;
    areaPrev = 0;
    let nbNFTs = 0;
    let nbAreas = 0;
    let nbNFTsArea = 0;

    for (let i = 0; i < conquestData.length; i++) {
      const conquest = conquestData[i];

      if (
        conquest.id_faction == userInfos.user.id_faction &&
        conquest.nft_type == 1
      ) {
        if (conquest.id_user != userPrev) {
          if (userPrev != 0) {
            userAllocation[2].push(nbNFTsArea);
            if (
              userAllocation[4] &&
              nbFactionAreas > nbAreas &&
              nbAreas < nbNFTs
            ) {
              userAllocation[3] = false;
            }
            factionAllocation.push({
              pseudo: userAllocation[0],
              delegation: userAllocation[4],
              id_area: userAllocation[1],
              nb_nfts: userAllocation[2],
              allocation: userAllocation[3]
            });
          }
          userPrev = conquest.id_user;
          areaPrev = 0;
          nbNFTs = 0;
          nbAreas = 0;
          userAllocation = new Array(5);
          userAllocation[0] = conquest.user?.pseudo;
          userAllocation[1] = [];
          userAllocation[2] = [];
          userAllocation[3] = true;
          userAllocation[4] = conquest.user?.flg_delegation;
        }

        if (conquest.id_area != areaPrev) {
          if (areaPrev != 0) {
            userAllocation[2].push(nbNFTsArea);
          }
          areaPrev = conquest.id_area;
          nbNFTsArea = 0;
          userAllocation[1].push(conquest.id_area);
          nbAreas++;
        }

        nbNFTsArea++;
        nbNFTs++;
      }
    }

    if (userPrev != 0) {
      userAllocation[2].push(nbNFTsArea);
      if (userAllocation[4] && nbFactionAreas > nbAreas && nbAreas < nbNFTs) {
        userAllocation[3] = false;
      }
      factionAllocation.push({
        pseudo: userAllocation[0],
        delegation: userAllocation[4],
        id_area: userAllocation[1],
        nb_nfts: userAllocation[2],
        allocation: userAllocation[3]
      });
    }

    setFactionAreas(factionArea);
    setFactionAllocations(factionAllocation);
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });

    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentPhase(data.setting.value);
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchConquestData = () => {
    ConquestDataService.findAllByConquest(currentConquest).then(({ data }) => {
      if (data.status == 'success') {
        setConquestInfos({
          conquests: data.conquests,
          status: data.status
        });

        let bonus = false;
        data.conquests.map((conquest) => {
          if (
            conquest.nft_type == 2 &&
            conquest.id_faction == userInfos.user.id_faction &&
            conquest.item?.item_type == 4
          ) {
            bonus = true;
          }
        });
        setFactionBonus(bonus);
      }
    });
  };

  const fetchAreasData = () => {
    AreaDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setAreasInfos({
          areas: data.areas,
          status: data.status
        });
      }
    });
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (currentConquest != 0) {
      fetchAreasData();
      fetchFactionsData();
      fetchConquestData();
      fetchFactionInfos();
    }
  }, [currentConquest]);
  React.useEffect(() => {
    if (
      AreasInfos.status == 'success' &&
      factionsInfos.status == 'success' &&
      conquestInfos.status == 'success'
    ) {
      handleFilterArea(filteredArea.id_area, false);
    }
  }, [AreasInfos, factionsInfos, conquestInfos]);

  React.useEffect(() => {
    if (conquestInfos.status == 'success') {
      fetchAllocation();
    }
  }, [conquestInfos]);

  const { user } = userInfos;
  const { conquests } = conquestInfos;
  const { areas } = AreasInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestContainer'>
                <div className='infosConquestBox'>
                  <div className='conquestTitleBox'>
                    <div className='conquestTitle'>Conquest</div>
                  </div>
                  <div className='conquestNumber'>{currentConquest}</div>
                  <div className='phaseTitle'>Deployment Board</div>
                </div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightRank'>
                  <div className='stripInfos'>
                    <div className='stripInfosIn'>
                      <div className='stripInfosText'>{user.faction?.name}</div>
                    </div>
                  </div>
                </div>
                <div className='stripBoxRightBtn'>
                  <div className='imgBoxFaction'>
                    <img src={`${factionImg}`} className='imgFactionSmall' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>
                          {user.faction?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxRightBtn'>
                    <div className='imgBoxFaction'>
                      <img src={`${factionImg}`} className='imgFactionSmall' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {userLeader ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='bigBoxMargin80'>
                  <div className='infosBoxSub'>
                    <div className='buttonsNFTContainerBis'>
                      {showDeployment ? (
                        <React.Fragment>
                          <Button
                            className='btnStandardReverse'
                            onClick={handleShowDeployment}
                          >
                            Deployment
                          </Button>
                          <Button
                            className='btnStandard'
                            onClick={handleShowAllocation}
                          >
                            Allocation
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Button
                            className='btnStandard'
                            onClick={handleShowDeployment}
                          >
                            Deployment
                          </Button>
                          <Button
                            className='btnStandardReverse'
                            onClick={handleShowAllocation}
                          >
                            Allocation
                          </Button>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showDeployment && factionBonus ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Faction Bonuses</div>
                </div>
                <div className='text-center'>
                  {conquests.map((conquest, i) => {
                    if (
                      conquest.nft_type == 2 &&
                      conquest.id_faction == user.id_faction &&
                      conquest.item?.item_type == 4
                    ) {
                      let bonusLabel = '';
                      let bonusType = '';
                      let bonusValue = '';
                      let iconName = faArrowCircleRight;
                      let colorName = '';

                      const itemName = conquest.item.item_name;
                      if (conquest.item.bonus_value > 0) {
                        bonusLabel = 'Faction Power';
                        bonusType = 'increased';
                        bonusValue = '+' + conquest.item.bonus_value + '%';
                        colorName = 'bonusUp';
                        iconName = faArrowCircleUp;
                      } else {
                        bonusLabel = 'Enemy Power';
                        bonusType = 'decreased';
                        bonusValue = conquest.item.bonus_value + '%';
                        colorName = 'bonusDown';
                        iconName = faArrowCircleDown;
                      }

                      return (
                        <div key={i} className='textImportant'>
                          <span className='textImportantRed'>{itemName}</span>
                          &nbsp;deployed in the&nbsp;
                          <span className='textImportantRed'>
                            {conquest.area?.name}
                          </span>
                          &nbsp;! The {bonusLabel} in this area will be&nbsp;
                          {bonusType} by&nbsp;
                          <span className={colorName}>
                            <FontAwesomeIcon
                              icon={iconName}
                              className='iconBonusSize'
                            />
                            &nbsp;{bonusValue}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          ) : null}

          {showDeployment ? (
            <React.Fragment>
              <div className='boxContainer topContainer' ref={refToTop}>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Deployment Information</div>
                  </div>
                  <div className='whiteBox text-center'>
                    {filteredArea.id_area == 0 ? (
                      <Button
                        className='navStandardSmallReverse'
                        onClick={() => handleFilterArea(0, true)}
                      >
                        All
                      </Button>
                    ) : (
                      <Button
                        className='navStandardSmall'
                        onClick={() => handleFilterArea(0, true)}
                      >
                        All
                      </Button>
                    )}
                    {areas.map((area, i) => {
                      let visible = false;
                      switch (area.id_area) {
                        case 1:
                        case 2:
                        case 3:
                          visible = false;
                          break;
                        default:
                          visible = true;
                          break;
                      }

                      if (visible) {
                        if (filteredArea.id_area == area.id_area) {
                          return (
                            <Button
                              className='navStandardSmallReverse'
                              key={i}
                              onClick={() =>
                                handleFilterArea(area.id_area, true)
                              }
                            >
                              {area.name}
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              className='navStandardSmall'
                              key={i}
                              onClick={() =>
                                handleFilterArea(area.id_area, true)
                              }
                            >
                              {area.name}
                            </Button>
                          );
                        }
                      }
                    })}
                  </div>
                  <div className='dataContainer margin10'>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>NFTs deployed</span>
                      <span className='dataAttribut'>
                        {filteredArea.numDeployNFTs}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>
                        NFTs Power
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='tooltipConnect'>
                              Without ratio and bonus
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className='iconInfo'
                          />
                        </OverlayTrigger>
                      </span>
                      <span className='dataAttribut'>
                        {filteredArea.NFTsPower}
                        &nbsp;({filteredArea.NFTsPowerPercent} %)
                      </span>
                    </div>
                    {userLeader ? (
                      <div className='dataSepartorLine'>
                        <span className='dataTitle'>Items deployed</span>
                        <span className='dataAttribut'>
                          {userLeader ? (
                            <span>{filteredArea.numDeployItems}</span>
                          ) : (
                            <span>?</span>
                          )}
                        </span>
                      </div>
                    ) : null}
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Balancing ratio</span>
                      <span className='dataAttribut'>{filteredArea.ratio}</span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>
                        {userLeader ? (
                          <span>
                            Total power
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltipConnect'>
                                  With items
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className='iconInfo'
                              />
                            </OverlayTrigger>
                          </span>
                        ) : (
                          <span>
                            Total power
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id='tooltipConnect'>
                                  Without items
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className='iconInfo'
                              />
                            </OverlayTrigger>
                          </span>
                        )}
                      </span>
                      <span className='dataAttribut'>
                        {filteredArea.totalPower}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {userLeader && phaseError ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='statusAlert'>
                      Redeployment only available in Deployment Phase !
                    </div>
                  </div>
                </div>
              ) : null}

              {userLeader && currentPhase == 2 ? (
                <div className='boxContainer'>
                  <div className='card-body text-center p-2'>
                    <div className='buttonContainer'>
                      {conquestRedeploy.conquests.length > 0 ? (
                        <Button
                          className='btnStandardReverse'
                          onClick={handleShowRedeploy}
                        >
                          Selection Redeployment
                        </Button>
                      ) : (
                        <Button disabled className='btnStandardReverse'>
                          Selection Redeployment
                        </Button>
                      )}
                      <Button
                        className='btnStandardReverse'
                        onClick={handleShowMassiveRedeploy}
                      >
                        Massive Redeployment
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              {userLeader && currentPhase == 2 ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>NFTS</div>
                    </div>
                    <div className='dataContainer'>
                      <div className='dataSepartorLine'>
                        <span className='dataTitle'>NFTs selected</span>
                        <span className='dataAttribut'>
                          {selectRedeploy.nbSelected}
                        </span>
                      </div>
                      <div className='dataSepartorLine'>
                        <span className='dataTitle'>
                          NFTs Power
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip id='tooltipConnect'>
                                Without ratio
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className='iconInfo'
                            />
                          </OverlayTrigger>
                        </span>
                        <span className='dataAttribut'>
                          {selectRedeploy.totalPower.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className='margin20'>
                      <div className='textImportant'>
                        Click on a row to select it for redeployment.
                      </div>
                      <div className='textImportantYellow'>
                        Yellow row mean that the delegation is not active.
                      </div>
                    </div>
                    <div className='text-center margin10'>
                      <div className='table-responsive'>
                        <table className='transactions table pb-3'>
                          <thead>
                            <tr className='headTable'>
                              <th className='border-0'>User</th>
                              <th className='border-0'>Name</th>
                              <th className='border-0'>Power</th>
                              <th className='border-0'>Area</th>
                            </tr>
                          </thead>
                          <tbody>
                            {conquests.map((conquest, i) => {
                              let available = false;
                              let selected = false;
                              let delegation = false;

                              if (
                                conquest.nft_type == 1 &&
                                conquest.id_faction == user.id_faction
                              ) {
                                available = true;

                                if (
                                  filteredArea.id_area != 0 &&
                                  conquest.id_area != filteredArea.id_area
                                ) {
                                  available = false;
                                }

                                if (conquest.user?.flg_delegation) {
                                  delegation = true;
                                }
                              }

                              if (available) {
                                count++;

                                conquestRedeploy.conquests.map((sel) => {
                                  if (conquest.id == sel.id) {
                                    selected = true;
                                  }
                                });
                              }

                              if (available) {
                                if (delegation) {
                                  if (selected) {
                                    return (
                                      <tr
                                        key={i}
                                        className='rowTableSelected textResult'
                                        onClick={() =>
                                          handleSelectNFT(conquest)
                                        }
                                      >
                                        <td>
                                          <span>{conquest.user?.pseudo}</span>
                                        </td>
                                        <td>
                                          <span>
                                            {conquest.attribut?.nft_name}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {conquest.attribut?.nft_power.toFixed(
                                              2
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span>{conquest.area?.name}</span>
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr
                                        key={i}
                                        className='rowTableResult textResult'
                                        onClick={() =>
                                          handleSelectNFT(conquest)
                                        }
                                      >
                                        <td>
                                          <span>{conquest.user?.pseudo}</span>
                                        </td>
                                        <td>
                                          <span>
                                            {conquest.attribut?.nft_name}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {conquest.attribut?.nft_power.toFixed(
                                              2
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span>{conquest.area?.name}</span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                } else {
                                  return (
                                    <tr
                                      key={i}
                                      className='rowTableNoSelect textResult'
                                    >
                                      <td>
                                        <span>{conquest.user?.pseudo}</span>
                                      </td>
                                      <td>
                                        <span>
                                          {conquest.attribut?.nft_name}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {conquest.attribut?.nft_power.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span>{conquest.area?.name}</span>
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                            })}
                            {count == 0 && (
                              <tr className='rowTableContent textResult'>
                                {filteredArea.id_area == 0 ? (
                                  <td colSpan={4}>
                                    <span>No NFT deployed</span>
                                  </td>
                                ) : (
                                  <td colSpan={4}>
                                    <span>
                                      No NFT deployed to the&nbsp;
                                      {filteredArea.name}
                                    </span>
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>NFTS</div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Name</th>
                            <th className='border-0'>Power</th>
                            <th className='border-0'>Area</th>
                          </tr>
                        </thead>
                        <tbody>
                          {conquests.map((conquest, i) => {
                            let available = false;

                            if (
                              conquest.nft_type == 1 &&
                              conquest.id_faction == user.id_faction
                            ) {
                              available = true;

                              if (
                                filteredArea.id_area != 0 &&
                                conquest.id_area != filteredArea.id_area
                              ) {
                                available = false;
                              }
                            }
                            if (available) count++;

                            if (available) {
                              return (
                                <tr
                                  key={i}
                                  className='rowTableContent textResult'
                                >
                                  <td>
                                    <span>{conquest.user?.pseudo}</span>
                                  </td>
                                  <td>
                                    <span>{conquest.attribut?.nft_name}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {conquest.attribut?.nft_power.toFixed(2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{conquest.area?.name}</span>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                          {count == 0 && (
                            <tr className='rowTableContent textResult'>
                              {filteredArea.id_area == 0 ? (
                                <td colSpan={4}>
                                  <span>No NFT deployed</span>
                                </td>
                              ) : (
                                <td colSpan={4}>
                                  <span>
                                    No NFT deployed to the&nbsp;
                                    {filteredArea.name}
                                  </span>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {userLeader ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>Player Items</div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Name</th>
                            <th className='border-0'>Power</th>
                            <th className='border-0'>Area</th>
                          </tr>
                        </thead>
                        <tbody>
                          {conquests.map((conquest, i) => {
                            let available = false;

                            if (
                              conquest.nft_type == 2 &&
                              conquest.id_faction == user.id_faction &&
                              conquest.item?.item_type == 2
                            ) {
                              available = true;

                              if (
                                filteredArea.id_area != 0 &&
                                conquest.id_area != filteredArea.id_area
                              ) {
                                available = false;
                              }

                              const identifier =
                                conquest.nft_identifier.split('-');

                              if (identifier[1].substring(0, 1) == 'f') {
                                available = false;
                              }
                            }
                            if (available) countItems++;

                            if (available) {
                              return (
                                <tr
                                  key={i}
                                  className='rowTableContent textResult'
                                >
                                  <td>
                                    <span>{conquest.user?.pseudo}</span>
                                  </td>
                                  <td>
                                    <span>{conquest.item?.item_name}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {conquest.item?.item_power.toFixed(2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{conquest.area?.name}</span>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                          {countItems == 0 && (
                            <tr className='rowTableContent textResult'>
                              {filteredArea.id_area == 0 ? (
                                <td colSpan={4}>
                                  <span>No item deployed</span>
                                </td>
                              ) : (
                                <td colSpan={4}>
                                  <span>
                                    No item deployed to the&nbsp;
                                    {filteredArea.name}
                                  </span>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {userLeader ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>Faction Items</div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Name</th>
                            <th className='border-0'>Power</th>
                            <th className='border-0'>Area</th>
                          </tr>
                        </thead>
                        <tbody>
                          {conquests.map((conquest, i) => {
                            let available = false;

                            if (
                              conquest.nft_type == 2 &&
                              conquest.id_faction == user.id_faction &&
                              conquest.item?.item_type == 2
                            ) {
                              available = true;

                              if (
                                filteredArea.id_area != 0 &&
                                conquest.id_area != filteredArea.id_area
                              ) {
                                available = false;
                              }

                              const identifier =
                                conquest.nft_identifier.split('-');

                              if (identifier[1].substring(0, 1) != 'f') {
                                available = false;
                              }
                            }
                            if (available) countFactionItems++;

                            if (available) {
                              return (
                                <tr
                                  key={i}
                                  className='rowTableContent textResult'
                                >
                                  <td>
                                    <span>{conquest.user?.pseudo}</span>
                                  </td>
                                  <td>
                                    <span>{conquest.item?.item_name}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {conquest.item?.item_power.toFixed(2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{conquest.area?.name}</span>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                          {countFactionItems == 0 && (
                            <tr className='rowTableContent textResult'>
                              {filteredArea.id_area == 0 ? (
                                <td colSpan={4}>
                                  <span>No item deployed</span>
                                </td>
                              ) : (
                                <td colSpan={4}>
                                  <span>
                                    No item deployed to the&nbsp;
                                    {filteredArea.name}
                                  </span>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {!userLeader ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>Items</div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Name</th>
                            <th className='border-0'>Power</th>
                            <th className='border-0'>Area</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='rowTableContent textResult'>
                            <td colSpan={4}>
                              <span>
                                Only faction leaders can see the deployed items
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}

          {showAllocation && userLeader ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Allocation Information</div>
                </div>
                <div className='textImportantYellow'>
                  Yellow row mean that the delegation is not active.
                </div>
                {factionAreas.length > 0 ? (
                  <div className='text-center margin10'>
                    <div className='table-responsive'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Allocation</th>
                            {factionAreas.map((id_area, i) => {
                              let areaName = '';

                              areas.map((area) => {
                                if (id_area == area.id_area) {
                                  areaName = area.name;
                                }
                              });

                              return (
                                <th key={i} className='border-0'>
                                  {areaName}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {factionAllocations.map((allocation, i) => {
                            if (allocation.delegation) {
                              const classInfos = 'rowTableContent textResult';

                              return (
                                <tr key={i} className={classInfos}>
                                  <td>
                                    <span>{allocation.pseudo}</span>
                                  </td>
                                  <td>
                                    {allocation.allocation ? (
                                      <span>YES</span>
                                    ) : (
                                      <span>NO</span>
                                    )}
                                  </td>
                                  {factionAreas.map((id_area, j) => {
                                    let available = false;
                                    let index = 0;
                                    let nb_nfts = 0;

                                    allocation.id_area.map((zone, k) => {
                                      if (id_area == zone) {
                                        index = k;
                                        available = true;
                                      }
                                    });

                                    allocation.nb_nfts.map((nfts, l) => {
                                      if (l == index) {
                                        nb_nfts = nfts;
                                      }
                                    });

                                    if (available) {
                                      return <td key={j}>{nb_nfts}</td>;
                                    } else {
                                      return <td key={j}></td>;
                                    }
                                  })}
                                </tr>
                              );
                            }
                          })}
                          {factionAllocations.map((allocation, i) => {
                            if (!allocation.delegation) {
                              const classInfos = 'rowTableNoSelect textResult';

                              return (
                                <tr key={i} className={classInfos}>
                                  <td>
                                    <span>{allocation.pseudo}</span>
                                  </td>
                                  <td>
                                    <span>/</span>
                                  </td>
                                  {factionAreas.map((id_area, j) => {
                                    let available = false;
                                    let index = 0;
                                    let nb_nfts = 0;

                                    allocation.id_area.map((zone, k) => {
                                      if (id_area == zone) {
                                        index = k;
                                        available = true;
                                      }
                                    });

                                    allocation.nb_nfts.map((nfts, l) => {
                                      if (l == index) {
                                        nb_nfts = nfts;
                                      }
                                    });

                                    if (available) {
                                      return <td key={j}>{nb_nfts}</td>;
                                    } else {
                                      return <td key={j}></td>;
                                    }
                                  })}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className='text-center margin10'>
                    <div className='table-responsive'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>User</th>
                            <th className='border-0'>Allocation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='rowTableContent textResult'>
                            <td colSpan={2}>
                              <span>No deployment</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        show={showRedeploy}
        onHide={handleCloseRedeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Selection Redeployment</div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Selection Information</div>
              </div>
              <div className='dataContainer'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs selected</span>
                  <span className='dataAttribut'>
                    {selectRedeploy.nbSelected}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>
                    NFTs Power
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltipConnect'>Without ratio</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className='iconInfo'
                      />
                    </OverlayTrigger>
                  </span>
                  <span className='dataAttribut'>
                    {selectRedeploy.totalPower.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Area Selection</div>
              </div>
              <div className='textImportant'>
                Select an area to redeploy the selected Heroes of your faction
                for the Conquest.
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    visible = true;
                    break;
                }

                if (visible) {
                  if (arrivalArea == area.id_area) {
                    return (
                      <Button
                        className='navStandardSmallReverse'
                        key={i}
                        onClick={() => setArrivalArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        className='navStandardSmall'
                        key={i}
                        onClick={() => setArrivalArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  }
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                {arrivalArea == 0 ? (
                  <Button disabled className='btnStandardSmallReverse'>
                    Validate
                  </Button>
                ) : (
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleValidateRedeploy}
                  >
                    Validate
                  </Button>
                )}
                <Button
                  className='btnStandardSmall'
                  onClick={handleCloseRedeploy}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showMassiveRedeploy}
        onHide={handleCloseMassiveRedeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Massive Redeployment</div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Area Selection</div>
              </div>
              <div className='textImportant'>
                Select a Start and End area to redeploy the Heroes of your
                faction for the Conquest.
              </div>
            </div>
          </div>

          <div className='margin10'>
            <div className='deployBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>Start Area</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;

                conquestInfos.conquests.map((conquest) => {
                  if (
                    conquest.id_faction == user.id_faction &&
                    conquest.id_area == area.id_area
                  ) {
                    visible = true;
                  }
                });

                if (visible) {
                  if (startArea == area.id_area) {
                    return (
                      <Button
                        className='navStandardSmallReverse'
                        key={i}
                        onClick={() => setStartArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        className='navStandardSmall'
                        key={i}
                        onClick={() => setStartArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  }
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='deployBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>End Area</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    visible = true;
                    break;
                }

                if (visible) {
                  if (arrivalArea == area.id_area) {
                    return (
                      <Button
                        className='navStandardSmallReverse'
                        key={i}
                        onClick={() => setArrivalArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        className='navStandardSmall'
                        key={i}
                        onClick={() => setArrivalArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  }
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                {startArea == 0 || arrivalArea == 0 ? (
                  <Button disabled className='btnStandardSmallReverse'>
                    Validate
                  </Button>
                ) : (
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleValidateMassiveRedeploy}
                  >
                    Validate
                  </Button>
                )}
                <Button
                  className='btnStandardSmall'
                  onClick={handleCloseMassiveRedeploy}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Deployment;
