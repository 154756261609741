import React from 'react';
import { logout } from '@multiversx/sdk-dapp/utils';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import {
  setAccountData,
  getAccountData,
  resetAccountData
} from 'common/user-account';
import { routeNames } from 'routes';
import AdminDataService from 'services/admin.service';
import UserDataService from 'services/user.service';
import { UserStateType, userEmpty, IUserSession } from 'types/user.type';

type AuthContextType = {
  userSession: IUserSession;
  userInfos: UserStateType;
  setUserInfos: (userInfos: UserStateType) => void;
  handleLogOut: () => void;
};

export const AuthContext = React.createContext({} as AuthContextType);

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const userSession = getAccountData();

  const [userInfos, setUserInfos] = React.useState<UserStateType>({
    user: userEmpty,
    message: '',
    status: ''
  });

  const handleLogOut = async () => {
    setUserInfos({
      user: userEmpty,
      message: '',
      status: ''
    });

    resetAccountData();

    switch (userSession.userAccountProvider) {
      case 'multiversx':
        //logout(`${routeNames.home}`);
        //logout(`${window.location.origin}`);
        logout();
        navigate(routeNames.home);
        break;
      case 'google':
        googleLogout();
        navigate(routeNames.home);
        break;
    }
  };

  const fetchUserData = () => {
    const params: any = {};
    params['address'] = userSession.userAccount;

    UserDataService.findByAddress(params).then(({ data }) => {
      if (data.status == 'success') {
        setAccountData('id', data.user.id_user);
        setUserInfos({
          user: data.user,
          status: data.status
        });
      } else {
        setUserInfos({
          user: userEmpty,
          message: data.message,
          status: data.status
        });
      }
    });
  };

  const fetchAdminData = () => {
    const params: any = {};
    params['address'] = userSession.userAccount;

    AdminDataService.findByAddress(params).then(({ data }) => {
      // Admin & Moderator
      if (data.status == 'success') {
        setAccountData('accountIsAdmin', data.admin.flg_admin);
        setAccountData('accountIsModerator', data.admin.flg_moderator);
      } else {
        setAccountData('accountIsAdmin', false);
        setAccountData('accountIsModerator', false);
      }

      // Whitelist
      /*
      let flg_whitelist = false;
      if (data.status == 'success') {
        setIsWhitelisted(data.admin.flg_whitelist);
        flg_whitelist = data.admin.flg_whitelist;
      } else {
        setIsWhitelisted(false);
      }
      if (!flg_whitelist) {
        setShowModal(false);
        handleLogout();
      }
      */
    });
  };

  React.useEffect(() => {
    if (userSession.userAccount) {
      fetchUserData();
      fetchAdminData();
    }
  }, [userSession.userAccount]);

  return (
    <AuthContext.Provider
      value={{
        userSession,
        userInfos,
        setUserInfos,
        handleLogOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
