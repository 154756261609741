import http from '../http-common';
import { IArenaItemData } from '../types/arenaitems.type';

class ArenaItemsDataService {
  create(data: IArenaItemData) {
    return http.post<IArenaItemData>('/arena_items', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/arena_items/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/arena_items/${id}`);
  }
}

export default new ArenaItemsDataService();
