import React from 'react';
import { Navbar as BsNavbar, Nav, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as DiscordIcon } from 'assets/img/discord.svg';
import logo from 'assets/img/JCORP_logo.png';
import logo_purple from 'assets/img/JCORP_logo_purple.png';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { ReactComponent as VectorCross } from 'assets/img/vector_cross.svg';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';

const NavbarHome = () => {
  const discord_link = 'https://discord.gg/7B5ZuPmFjV';
  const twitter_link = 'https://twitter.com/JCorpBattle';
  const whitepaper_link = 'https://jcorp-battleofgods.gitbook.io/whitepaper/';

  const { userSession, handleLogOut } = useAuthContext();

  const isLoggedIn = Boolean(userSession.userAccount);

  const navigate = useNavigate();
  const location = useLocation();

  const handleConnect = () => {
    navigate(routeNames.unlock);
  };

  const handlePlay = () => {
    navigate(routeNames.conquest);
  };

  const [navCollapsed, setNavCollapsed] = React.useState(false);
  const [navGroupClass, setNavGroupClass] = React.useState('navGroup');
  const handleCollapse = () => {
    if (navCollapsed) {
      setNavGroupClass('navGroup');
      setNavCollapsed(false);
    } else {
      setNavGroupClass('navGroupCollapsed');
      setNavCollapsed(true);
    }
  };

  React.useEffect(() => {
    setNavGroupClass('navGroup');
    setNavCollapsed(false);
  }, [location]);

  return (
    <React.Fragment>
      <div onClick={() => navigate(routeNames.home)}>
        <img src={logo} className='logoSmall' />
      </div>
      <BsNavbar expand='lg' id={navGroupClass}>
        <Container>
          <BsNavbar.Brand
            className='logoContainer'
            onClick={() => navigate(routeNames.home)}
          >
            <img src={logo_purple} className='logoNav' />
          </BsNavbar.Brand>
          {!navCollapsed ? (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              __
              <br />
              __
              <br />
              __
            </BsNavbar.Toggle>
          ) : (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              <div className='navToggle'>
                <div className='navToggleIn'>
                  <VectorCross className='navToggleVector' />
                </div>
              </div>
            </BsNavbar.Toggle>
          )}
          <BsNavbar.Collapse
            className='justify-content-end'
            id='navbarResponsive'
          >
            <Nav className='ms-auto navbarScrollable' navbarScroll>
              <Nav.Item className='fantasy socialIconsContainer'>
                <a
                  href={discord_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <DiscordIcon className='mx-1' />
                </a>
                <a
                  href={twitter_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <TwitterIcon className='mx-1' />
                </a>
              </Nav.Item>
              <BsNavbar.Brand
                className='logoSmallContainer'
                onClick={() => navigate(routeNames.home)}
              >
                <img src={logo_purple} className='logoNav' />
              </BsNavbar.Brand>
              <Nav.Item className='fantasy'>
                <Nav.Link href='/#universeItem'>Universe</Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy'>
                <Nav.Link href='/#roadmapItem'>Roadmap</Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy'>
                <Nav.Link href='/#teamItem'>Team</Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy'>
                <Nav.Link href='/#partnersItem'>Partners</Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy'>
                <Nav.Link href='/#FAQItem'>FAQ</Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy'>
                <Nav.Link
                  href={whitepaper_link}
                  target='_blank'
                  rel='noreferrer'
                >
                  Whitepaper
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='fantasy socialIconsSmallContainer'>
                <a
                  href={discord_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <DiscordIcon className='mx-1' />
                </a>
                <a
                  href={twitter_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <TwitterIcon className='mx-1' />
                </a>
              </Nav.Item>
              {!isLoggedIn ? (
                <div className='navBtnContainerSmall'>
                  <Nav.Item>
                    <div onClick={handleConnect} className='btnConnect'>
                      <div className='btnConnectIn'>
                        <div className='btnConnectText'>CONNECT</div>
                      </div>
                    </div>
                  </Nav.Item>
                </div>
              ) : (
                <div className='navBtnContainerSmall'>
                  <Nav.Item>
                    <div onClick={handlePlay} className='btnConnect'>
                      <div className='btnConnectIn'>
                        <div className='btnConnectText'>PLAY</div>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <div onClick={handleLogOut} className='btnConnect'>
                      <div className='btnConnectIn'>
                        <div className='btnConnectText'>LOG OUT</div>
                      </div>
                    </div>
                  </Nav.Item>
                </div>
              )}
            </Nav>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
      {!isLoggedIn ? (
        <div className='navBtnContainer'>
          <div onClick={handleConnect} className='btnConnect'>
            <div className='btnConnectIn'>
              <div className='btnConnectText'>CONNECT</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='navBtnContainer'>
          <div onClick={handlePlay} className='btnConnect'>
            <div className='btnConnectIn'>
              <div className='btnConnectText'>PLAY</div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NavbarHome;
