import * as React from 'react';
import {
  faFrown,
  faPencil,
  faTrash,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import ImageMapper from 'react-img-mapper';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { ResizeObserver } from 'resize-observer';
import { xoxnoLink, deadrareLink } from 'config';
import { routeNames } from 'routes';
import AreasFonctions from '../../../common/areas';
import AreaDataService from '../../../services/area.service';
import ConquestDataService from '../../../services/conquest.service';
import FactionDataService from '../../../services/faction.service';
import SettingDataService from '../../../services/setting.service';
import {
  ColorsStateType,
  colorsEmpty,
  AreasStateType,
  IAreaData
} from '../../../types/area.type';
import {
  AttributsStateType,
  AttributsDisplayStateType,
  IAttributsData
} from '../../../types/attributs.type';
import { GameBonusesStateType } from '../../../types/bonuses.type';
import {
  IConquestData,
  ConquestsStateType
} from '../../../types/conquest.type';
import { FactionsStateType } from '../../../types/faction.type';
import {
  SquadsStateType,
  ISquadData,
  squadEmpty,
  SquadsGlobalInfos,
  squadGlobalInfosEmpty
} from '../../../types/squads.type';
import { hierarchyColor } from '../../../types/token.type';
import { UserStateType } from '../../../types/user.type';
import infoIcon from './../../../assets/img/icons/info.png';
import marketplace from './../../../assets/img/icons/marketplace.png';
import merchantIcon from './../../../assets/img/icons/merchant.png';
import squadsIcon from './../../../assets/img/icons/squads.png';
import reiwa from './../../../assets/img/reiwa.png';

const Deployment = ({
  userInfos,
  gameBonusesInfos,
  conquestInfosInit,
  listNFTs,
  squadsInfos,
  userLeader,
  isBlockchainAccount
}: {
  userInfos: UserStateType;
  gameBonusesInfos: GameBonusesStateType;
  conquestInfosInit: ConquestsStateType;
  listNFTs: AttributsStateType;
  squadsInfos: SquadsStateType;
  userLeader: boolean;
  isBlockchainAccount: boolean;
}) => {
  const navigate = useNavigate();

  function MyTimerPhase({ expiryTimestamp }: { expiryTimestamp: Date }) {
    const { seconds, minutes, hours } = useTimer({
      expiryTimestamp,
      onExpire: () => handleRefreshPhase()
    });

    return (
      <span>
        <span className='timerNumber'>{hours < 10 ? '0' + hours : hours}</span>
        <span className='timerSep'>:</span>
        <span className='timerNumber'>
          {minutes < 10 ? '0' + minutes : minutes}
        </span>
        <span className='timerSep'>:</span>
        <span className='timerNumber'>
          {seconds < 10 ? '0' + seconds : seconds}
        </span>
      </span>
    );
  }

  const iconsPath = 'icons/';
  const iconsExtention = '.png';

  const [showSettings, setShowSettings] = React.useState(false);
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const handleShowSettings = () => {
    setShowSettings(true);
  };

  const [showNFTs, setShowNFTs] = React.useState(true);
  const [showSquads, setShowSquads] = React.useState(false);
  const handleShowNFTs = () => {
    setShowNFTs(true);
    setShowSquads(false);
  };
  const handleShowSquads = () => {
    setShowNFTs(false);
    setShowSquads(true);
  };

  const [listNFTsGlobalArea, setListNFTsGlobalArea] =
    React.useState<AttributsDisplayStateType>({
      nfts: [],
      status: ''
    });
  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });
  const [listNFTsDisplayArea, setListNFTsDisplayArea] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageArea, setPageArea] = React.useState(0);
  const [totalPagesArea, setTotalPagesArea] = React.useState(0);

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageChangeArea = (event: any, value: any) => {
    setPageArea(value);
  };

  const fetchNFTsInfos = () => {
    const nbPages = Math.ceil(listNFTs.attributs.length / pageSize);
    setPage(1);
    setTotalPages(nbPages);
  };

  const fetchNFTsAreaInfos = (id_area: number) => {
    const params: any = {};
    params['id_conquest'] = currentConquest;
    params['id_user'] = userInfos.user.id_user;

    ConquestDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        const nftsListArea: AttributsDisplayStateType = {
          nfts: [],
          status: 'success'
        };

        listNFTs.attributs.map((token) => {
          let available = true;
          data.conquests.map((conquest) => {
            if (
              conquest.nft_identifier == token.nft_identifier &&
              conquest.id_area != id_area
            ) {
              available = false;
            }
          });

          if (available) {
            nftsListArea.nfts.push(token);
          }
        });

        const nbPagesArea = Math.ceil(nftsListArea.nfts.length / pageSize);
        setPageArea(1);
        setTotalPagesArea(nbPagesArea);
        setListNFTsGlobalArea(nftsListArea);
      }
    });
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTs.attributs.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTs.attributs[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };

  const handleRefreshNFTsListArea = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = pageArea - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTsGlobalArea.nfts.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTsGlobalArea.nfts[i]);
      }
    }

    setListNFTsDisplayArea(nftsList);
  };

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [phaseInfos, setPhaseInfos] = React.useState({
    nextPhase: new Date()
  });

  const [gameBonus, setGameBonus] = React.useState(false);
  const [merchantBonus, setMerchantBonus] = React.useState({
    merchant: false,
    merchantIdAreas: [0],
    merchantAreas: [''],
    merchantBonusValue: 0
  });

  let count = 0;
  const [filteredArea, setFilteredArea] = React.useState({
    id_area: 0,
    name: '',
    numDeployNFTs: 0,
    totalPower: ''
  });

  const [totDeployedArea, setTotDeployedArea] = React.useState(0);
  const [totPowerArea, setTotPowerArea] = React.useState(0);
  const [selectedArea, setSelectedArea] = React.useState({
    id_area: 0,
    name: '',
    id_faction: 0
  });
  const [selectedNFT, setSelectedNFT] = React.useState({
    divStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties,
    nft_owner: '',
    idConquest: 0,
    id_nft: 0,
    nft_identifier: '',
    nft_url: '',
    nft_name: '',
    nft_number: '',
    nft_collection: '',
    nft_hierarchy: '',
    nft_power: 0,
    inSquad: false,
    squadIcon: '',
    squadDivStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties
  });
  const [selectedSquad, setSelectedSquad] = React.useState({
    squad: squadEmpty,
    divStyle: {
      '--rarityColor': '#000'
    } as React.CSSProperties,
    icon: '',
    nbNfts: 0,
    totPower: 0
  });

  const mapMaxWidth = 2048;
  const mapMaxHeight = mapMaxWidth * 0.75;
  const [mapDimensions, setMapDimensions] = React.useState({
    width: 0,
    height: 0,
    style: {
      '--mapWidth': '0px',
      '--mapHeight': '0px',
      '--mapRatioWidth': 0,
      '--mapRatioHeight': 0
    } as React.CSSProperties
  });
  const mapFieldSelector = document.querySelector('.mapField');
  const mapContainerSize = React.useRef<HTMLDivElement>(null);

  const [AreasInfos, setAreasInfos] = React.useState<AreasStateType>({
    areas: [],
    message: '',
    status: ''
  });
  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });
  const [AREAS_MAP, setAreasMap] = React.useState<any>({
    name: '',
    areas: []
  });
  const [conquestInfos, setConquestInfos] =
    React.useState<ConquestsStateType>(conquestInfosInit);
  const [squadsGlobalInfos, setSquadsGlobalInfos] =
    React.useState<SquadsGlobalInfos>({
      squads: []
    });

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result != null) {
      return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      };
    }
  }

  const handleFilterArea = (id_area: any) => {
    let areaFilteredName = '';
    AreasInfos.areas.map((area) => {
      if (area.id_area == id_area) {
        areaFilteredName = area.name;
      }
    });

    let nbDeployNFTs = 0;
    let totalPower = 0;

    conquestInfos.conquests.map((conquest) => {
      let available = true;
      if (id_area != 0 && conquest.id_area != id_area) {
        available = false;
      }
      if (available && conquest.nft_type == 1) {
        if (conquest.attribut != null) {
          nbDeployNFTs++;
          totalPower += conquest.attribut.nft_power;
        }
      }
    });

    setFilteredArea({
      id_area: id_area,
      name: areaFilteredName,
      numDeployNFTs: nbDeployNFTs,
      totalPower: totalPower.toFixed(2)
    });
  };

  const [showMapBonus, setShowMapBonus] = React.useState(false);
  const handleCloseMapBonus = () => {
    setShowMapBonus(false);
  };
  const handleShowMapBonus = () => {
    setShowMapBonus(true);
  };

  const [showDeploy, setShowDeploy] = React.useState(false);
  const handleCloseDeploy = () => {
    setShowDeploy(false);
  };
  const handleShowDeploy = (areaId: any) => {
    switch (areaId) {
      case 1:
      case 2:
      case 3:
        setShowDeploy(false);
        navigate(routeNames.conquestboard);
        break;
      default:
        AreasInfos.areas.map((area) => {
          if (area.id_area == areaId) {
            setSelectedArea({
              id_area: area.id_area,
              name: area.name,
              id_faction: area.id_faction
            });
            fetchNFTsAreaInfos(area.id_area);
          }
        });
        setShowDeploy(true);
        break;
    }
  };

  const [showAreaSquad, setShowAreaSquad] = React.useState(false);
  const handleCloseAreaSquad = () => {
    setShowAreaSquad(false);
  };

  const handleShowAreaSquad = (squad: ISquadData) => {
    const divStyle = {
      '--squadColor': squad.color
    } as React.CSSProperties;

    const icon = iconsPath + squad.icon + iconsExtention;

    let nbNfts = 0;
    let totPower = 0;

    squadsGlobalInfos.squads.map((squadInfos) => {
      if (squadInfos.id_squad == squad.id_squad) {
        nbNfts = squadInfos.nb_nfts;
        totPower = squadInfos.tot_power;
      }
    });

    setSelectedSquad({
      squad: squad,
      divStyle: divStyle,
      icon: icon,
      nbNfts: nbNfts,
      totPower: totPower
    });

    setShowAreaSquad(true);
  };

  const [flgUpdate, setFlgUpdate] = React.useState(false);

  const [showArea, setShowArea] = React.useState(false);
  const handleCloseArea = () => {
    setShowArea(false);
  };

  const handleShowAreaD = (token: IAttributsData) => {
    const divStyle = {
      '--rarityColor': hierarchyColor[token.nft_hierarchy]
    } as React.CSSProperties;

    const nameT = token.nft_name.split(' ');
    const nft_collection = nameT[0];
    let nft_number = '';
    if (nameT[1]) {
      nft_number = nameT[1].replace('#', '');
    }

    let inSquad = false;
    if (token.id_squad != 0) {
      inSquad = true;
    }

    const squadDivStyle = {
      '--squadColor': token.squad?.color
    } as React.CSSProperties;

    const squadIcon = iconsPath + token.squad?.icon + iconsExtention;

    setSelectedNFT({
      divStyle: divStyle,
      nft_owner: '',
      idConquest: 0,
      id_nft: token.id_nft,
      nft_identifier: token.nft_identifier,
      nft_url: token.nft_url,
      nft_name: token.nft_name,
      nft_number: nft_number,
      nft_collection: nft_collection,
      nft_hierarchy: token.nft_hierarchy,
      nft_power: token.nft_power,
      inSquad: inSquad,
      squadIcon: squadIcon,
      squadDivStyle: squadDivStyle
    });

    setFlgUpdate(false);
    setShowArea(true);
  };

  const handleShowAreaU = (conquest: IConquestData) => {
    const idConquest = conquest.id;
    const id_nft = conquest.id_nft;
    const identifier = conquest.nft_identifier;

    let divStyle = {
      '--rarityColor': '#000'
    } as React.CSSProperties;
    let url = '';
    let name = '';
    let number = '';
    let collection = '';
    let hierarchy = '';
    let power = 0;

    if (conquest.attribut != null) {
      divStyle = {
        '--rarityColor': hierarchyColor[conquest.attribut.nft_hierarchy]
      } as React.CSSProperties;
      url = conquest.attribut.nft_url;
      name = conquest.attribut.nft_name;
      const nameT = conquest.attribut.nft_name.split(' ');
      collection = nameT[0];
      if (nameT[1]) {
        number = nameT[1].replace('#', '');
      }
      hierarchy = conquest.attribut.nft_hierarchy;
      power = conquest.attribut.nft_power;
    }

    let inSquad = false;
    if (conquest.attribut?.id_squad != 0) {
      inSquad = true;
    }

    const squadDivStyle = {
      '--squadColor': conquest.attribut?.squad?.color
    } as React.CSSProperties;

    const squadIcon =
      iconsPath + conquest.attribut?.squad?.icon + iconsExtention;

    setSelectedNFT({
      divStyle: divStyle,
      nft_owner: '',
      idConquest: idConquest,
      id_nft: id_nft,
      nft_identifier: identifier,
      nft_url: url,
      nft_name: name,
      nft_number: number,
      nft_collection: collection,
      nft_hierarchy: hierarchy,
      nft_power: power,
      inSquad: inSquad,
      squadIcon: squadIcon,
      squadDivStyle: squadDivStyle
    });

    setFlgUpdate(true);
    setShowArea(true);
  };

  const fetchAreasData = () => {
    AreaDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setAreasInfos({
          areas: data.areas,
          status: data.status
        });

        const colorNeutre = '#95A5A6';
        const colorHoverNeutre = '#BFC9CA';
        const colorArea: ColorsStateType = {
          colors: colorsEmpty
        };
        data.areas.map((area) => {
          if (area.id_faction != 0) {
            colorArea.colors.color[area.id_area] = area.faction.color;
            colorArea.colors.colorHover[area.id_area] = area.faction.colorHover;
          } else {
            colorArea.colors.color[area.id_area] = colorNeutre;
            colorArea.colors.colorHover[area.id_area] = colorHoverNeutre;
          }
        });
        setAreasMap(AreasFonctions.getAreasMap(colorArea));
      }
    });
  };

  const fetchConquestData = () => {
    const params: any = {};
    params['id_conquest'] = currentConquest;
    params['id_user'] = userInfos.user.id_user;

    ConquestDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setConquestInfos({
          conquests: data.conquests,
          status: data.status
        });
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchDimensions = () => {
    if (mapContainerSize.current) {
      setMapDimensions({
        width: mapContainerSize.current.offsetWidth,
        height: mapContainerSize.current.offsetHeight,
        style: {
          '--mapWidth': mapContainerSize.current.offsetWidth + 'px',
          '--mapHeight': mapContainerSize.current.offsetHeight + 'px',
          '--mapRatioWidth': 0,
          '--mapRatioHeight': 0
        } as React.CSSProperties
      });
    }
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setPhaseInfos({
          nextPhase: data.setting.nextPhase
        });
      }
    });

    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const [flgSquadDeployment, setFlgSquadDeployment] = React.useState(false);
  const [squadNFTsArea, setSquadNFTsArea] = React.useState('');
  const [squadNFTsQty, setSquadNFTsQty] = React.useState(0);
  const [squadNFTsDeployed, setSquadNFTsDeployed] = React.useState(0);
  const [squadNFTsPower, setSquadNFTsPower] = React.useState(0);

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setFlgSquadDeployment(false);
    setSquadNFTsArea('');
    setSquadNFTsQty(0);
    setSquadNFTsDeployed(0);
    fetchConquestData();
  };

  const deploySquad = async (area: IAreaData) => {
    const squad = selectedSquad.squad;

    if (squad.attributs) {
      let NFTsDeployed = 0;
      let NFTsPower = 0;
      const nbNfts = squad.attributs.length;

      handleCloseAreaSquad();
      setShowModal(true);
      setFlgSquadDeployment(true);
      setSquadNFTsArea(area.name);
      setSquadNFTsQty(nbNfts);

      for (let i = 0; i < nbNfts; i++) {
        const attribut = squad.attributs[i];

        const dataConquest: IConquestData = {
          id_conquest: currentConquest,
          id_user: userInfos.user.id_user,
          id_faction: userInfos.user.id_faction,
          id_area: area.id_area,
          nft_type: 1,
          id_nft: attribut.id_nft,
          id_item: 0,
          nft_identifier: attribut.nft_identifier
        };

        await ConquestDataService.createOrUpdate(dataConquest);

        NFTsDeployed++;
        NFTsPower += attribut.nft_power;
        setSquadNFTsDeployed(NFTsDeployed);
        setSquadNFTsPower(NFTsPower);
      }
    }
  };

  const deployNFT = (id_area: any) => {
    if (flgUpdate) {
      const dataConquest: any = {
        id_area: id_area
      };

      ConquestDataService.update(dataConquest, selectedNFT.idConquest).then(
        () => {
          fetchConquestData();
        }
      );
    } else {
      const dataConquest: IConquestData = {
        id_conquest: currentConquest,
        id_user: userInfos.user.id_user,
        id_faction: userInfos.user.id_faction,
        id_area: id_area,
        nft_type: 1,
        id_nft: selectedNFT.id_nft,
        id_item: 0,
        nft_identifier: selectedNFT.nft_identifier
      };

      ConquestDataService.create(dataConquest).then(() => {
        fetchConquestData();
      });
    }

    handleCloseArea();
  };

  const selectNFT = (id_nft: any, nft_identifier: string) => {
    const dataConquest: IConquestData = {
      id_conquest: currentConquest,
      id_user: userInfos.user.id_user,
      id_faction: userInfos.user.id_faction,
      id_area: selectedArea.id_area,
      nft_type: 1,
      id_nft: id_nft,
      id_item: 0,
      nft_identifier: nft_identifier
    };

    ConquestDataService.create(dataConquest).then(() => {
      fetchConquestData();
    });
  };

  const unselectNFT = (idConquest: any) => {
    ConquestDataService.delete(idConquest).then(() => {
      fetchConquestData();
    });
  };

  const handleRefreshPhase = () => {
    window.location.reload();
  };

  const fetchInfosArea = () => {
    let nbDeployed = 0;
    let power = 0;

    conquestInfos.conquests.map((conquest) => {
      if (
        conquest.id_area == selectedArea.id_area &&
        conquest.attribut != null
      ) {
        nbDeployed++;
        power += conquest.attribut?.nft_power;
      }
    });

    setTotDeployedArea(nbDeployed);
    setTotPowerArea(power);
  };

  const fetchBonusesInfos = () => {
    let merchant = false;
    const merchantIdAreas = new Array(0);
    const merchantAreas = new Array(0);
    let merchantBonusValue = 0;

    gameBonusesInfos.bonuses.map((bonus) => {
      let areaId = 0;
      let areaName = '';
      AreasInfos.areas.map((area) => {
        if (area.id_area == bonus.id_area) {
          areaId = area.id_area;
          areaName = area.name;
        }
      });

      switch (bonus.id_item) {
        case 5:
          merchant = true;
          merchantIdAreas.push(areaId);
          merchantAreas.push(areaName);
          merchantBonusValue = bonus.item.bonus_value;
          break;
        default:
          break;
      }
    });

    if (merchant) {
      setGameBonus(true);
    }

    if (merchant) {
      setMerchantBonus({
        merchant: merchant,
        merchantIdAreas: merchantIdAreas,
        merchantAreas: merchantAreas,
        merchantBonusValue: merchantBonusValue
      });
    }
  };

  const handleSquadsGlobalInfos = () => {
    let prevIdSquad = 0;

    const infos: SquadsGlobalInfos = {
      squads: []
    };

    let dataSquadsGlobalInfos = squadGlobalInfosEmpty;
    let nbNfts = 0;
    let totPower = 0;

    squadsInfos.squads.map((squad) => {
      if (squad.id_squad != prevIdSquad) {
        if (dataSquadsGlobalInfos.id_squad != 0) {
          infos.squads.push(dataSquadsGlobalInfos);
        }

        dataSquadsGlobalInfos = squadGlobalInfosEmpty;
        nbNfts = 0;
        totPower = 0;

        if (squad.attributs != null) {
          squad.attributs.map((attribut) => {
            nbNfts++;
            totPower += attribut.nft_power;
          });
        }

        dataSquadsGlobalInfos = {
          id_squad: squad.id_squad,
          nb_nfts: nbNfts,
          tot_power: totPower
        };

        prevIdSquad = squad.id_squad;
      }
    });

    if (dataSquadsGlobalInfos.id_squad != 0) {
      infos.squads.push(dataSquadsGlobalInfos);
    }

    setSquadsGlobalInfos(infos);
  };

  const ro = new ResizeObserver((entries: any) => {
    for (const entry of entries) {
      switch (entry.target.className) {
        case 'mapField':
          const mapWidth = entry.contentRect.width;
          const mapHeight = entry.contentRect.width * 0.75;
          const mapRatioWidth = mapWidth / mapMaxWidth;
          const mapRatioHeight = mapHeight / mapMaxHeight;

          setMapDimensions({
            width: mapWidth,
            height: mapHeight,
            style: {
              '--mapWidth': mapWidth + 'px',
              '--mapHeight': mapHeight + 'px',
              '--mapRatioWidth': mapRatioWidth,
              '--mapRatioHeight': mapRatioHeight
            } as React.CSSProperties
          });
          break;
      }
    }
  });

  React.useEffect(() => {
    if (mapFieldSelector) {
      ro.observe(mapFieldSelector);
    }
  }, [mapFieldSelector, document.onresize]);

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (currentConquest != 0) {
      fetchAreasData();
      //fetchDimensions();
      fetchNFTsInfos();
      fetchFactionsData();
      handleSquadsGlobalInfos();
    }
  }, [currentConquest]);
  React.useEffect(() => {
    if (AreasInfos.status == 'success') {
      fetchBonusesInfos();
    }
  }, [AreasInfos]);
  React.useEffect(() => {
    if (
      AreasInfos.status == 'success' &&
      factionsInfos.status == 'success' &&
      conquestInfos.status == 'success'
    ) {
      handleFilterArea(filteredArea.id_area);
    }
  }, [AreasInfos, factionsInfos, conquestInfos]);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0 && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTs, page]);

  React.useEffect(() => {
    if (listNFTsGlobalArea.status == 'success' && page != 0) {
      handleRefreshNFTsListArea();
    }
  }, [listNFTsGlobalArea, pageArea]);

  React.useEffect(() => {
    if (selectedArea.id_area != 0) {
      fetchInfosArea();
    }
  }, [selectedArea, conquestInfos]);

  const { user } = userInfos;
  const { attributs } = listNFTs;
  const { squads } = squadsInfos;
  const { conquests } = conquestInfos;
  const { factions } = factionsInfos;
  const { areas } = AreasInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestContainer'>
                <div className='infosConquestBox'>
                  <div className='conquestTitleBox'>
                    <div className='conquestTitle'>Conquest</div>
                  </div>
                  <div className='conquestNumber'>{currentConquest}</div>
                  <div className='phaseTitle'>Deployment Phase</div>
                </div>
                <div className='infosTimerContainer'>
                  <div className='infosTimerBox'>
                    <MyTimerPhase
                      expiryTimestamp={new Date(phaseInfos.nextPhase)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*
          {gameBonus ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Game Bonuses</div>
                </div>
                {merchantBonus.merchant ? (
                  <div className='text-center textImportant'>
                    Merchants have appeared in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[0]}
                    </span>
                    &nbsp;and in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[1]}
                    </span>
                    &nbsp;!
                    <br />
                    <span className='textImportantRed'>
                      +{merchantBonus.merchantBonusValue}% JCORP
                    </span>
                    &nbsp;rewards for your entire faction for each area with
                    merchants you conquer
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          */}

          <div className='boxContainer topContainer'>
            {/*<div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>The City of Reiwa</div>
              </div>
              <div className='factionsContainer'>
                {factions.map((faction, i) => {
                  const color = hexToRgb(faction.color);
                  const factionColor =
                    color?.red + ',' + color?.green + ',' + color?.blue;
                  const divStyle = {
                    '--factionColor': factionColor
                  } as React.CSSProperties;

                  return (
                    <div className='factionsInfos' style={divStyle} key={i}>
                      {faction.name}
                    </div>
                  );
                })}
              </div>
              </div>*/}
            <div
              className='mapField'
              ref={mapContainerSize}
              style={mapDimensions.style}
            >
              <ImageMapper
                src={reiwa}
                map={AREAS_MAP}
                width={mapDimensions.width}
                imgWidth={2048}
                onClick={(area) => handleShowDeploy(area.id)}
              />
              <div className='mapInfosContainerLeft'>
                <div
                  className='mapBoxIconContainer'
                  onClick={handleShowSettings}
                >
                  <div className='mapBoxIcon'>
                    <FontAwesomeIcon icon={faCog} className='mapBoxIconFa' />
                  </div>
                </div>
              </div>
              <div className='mapInfosContainerRight'>
                <div
                  className='mapBoxIconContainer'
                  onClick={handleShowMapBonus}
                >
                  <img src={`${infoIcon}`} className='mapBoxIcon' />
                </div>
                <div
                  className='mapBoxIconContainer'
                  onClick={() => navigate(routeNames.marketplace)}
                >
                  <img src={`${marketplace}`} className='mapBoxIcon' />
                </div>
                <div
                  className='mapBoxIconContainer'
                  onClick={() => navigate(routeNames.squads)}
                >
                  <img src={`${squadsIcon}`} className='mapBoxIcon' />
                </div>
              </div>
              <div className='factionsContainer1'>
                {factions.map((faction, i) => {
                  const color = hexToRgb(faction.color);
                  const factionColor =
                    color?.red + ',' + color?.green + ',' + color?.blue;
                  const divStyle = {
                    '--factionColor': factionColor
                  } as React.CSSProperties;

                  return (
                    <div className='factionsInfos1' style={divStyle} key={i}>
                      {faction.name}
                    </div>
                  );
                })}
              </div>
              {merchantBonus.merchant ? (
                <React.Fragment>
                  {areas.map((area, i) => {
                    let isBonusMerchant = false;
                    let bonusClassName = '';

                    for (
                      let j = 0;
                      j < merchantBonus.merchantIdAreas.length;
                      j++
                    ) {
                      const bonusIdArea = merchantBonus.merchantIdAreas[j];
                      if (bonusIdArea == area.id_area) {
                        isBonusMerchant = true;
                        const name = area.name.split(' ');
                        bonusClassName = 'map' + name[0];
                      }
                    }

                    if (isBonusMerchant) {
                      return (
                        <div
                          key={i}
                          className={bonusClassName}
                          onClick={() => handleShowDeploy(area.id_area)}
                        >
                          <img
                            src={`${merchantIcon}`}
                            className='mapInfosIcon'
                          />
                        </div>
                      );
                    }
                  })}
                </React.Fragment>
              ) : null}
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body text-center p-2'>
              {showNFTs ? (
                <React.Fragment>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowNFTs}
                  >
                    My NFTs
                  </Button>
                  <Button
                    className='navStandardSmall'
                    onClick={handleShowSquads}
                  >
                    My Squads
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button className='navStandardSmall' onClick={handleShowNFTs}>
                    My NFTs
                  </Button>
                  <Button
                    className='navStandardSmallReverse'
                    onClick={handleShowSquads}
                  >
                    My Squads
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>

          {showSquads && squads.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My Squads</div>
                  </div>
                  <div className='textImportant'>
                    Click on your squads to deploy them for the Conquest.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  {squads.map((squad, i) => {
                    const divStyle = {
                      '--squadColor': squad.color
                    } as React.CSSProperties;

                    const icon = iconsPath + squad.icon + iconsExtention;

                    let nbNfts = 0;
                    let totPower = 0;

                    squadsGlobalInfos.squads.map((squadInfos) => {
                      if (squadInfos.id_squad == squad.id_squad) {
                        nbNfts = squadInfos.nb_nfts;
                        totPower = squadInfos.tot_power;
                      }
                    });

                    let hasNFTs = false;
                    if (squad.attributs != null && squad.attributs.length > 0) {
                      hasNFTs = true;
                    }

                    let squadName = squad.name;
                    if (squadName.length > 12) {
                      squadName = squadName.substring(0, 12) + '...';
                    }
                    const squadTotPowerRounded = totPower.toFixed(2);

                    if (hasNFTs) {
                      return (
                        <div
                          className='conquestSquadContainer'
                          key={i}
                          onClick={() => handleShowAreaSquad(squad)}
                        >
                          <div className='conquestSquadInfosContainer'>
                            <div className='overlayHover'>
                              <div className='overlayHoverText'>Deploy</div>
                            </div>
                            <div className='conquestSquadTitle'>
                              <div className='conquestSquadIcon'>
                                <img
                                  src={`${icon}`}
                                  style={divStyle}
                                  className='imgSquadIcon'
                                />
                              </div>
                              <div className='conquestSquadTextContainer'>
                                <div className='conquestSquadText'>
                                  {squadName}
                                </div>
                              </div>
                            </div>
                            <div className='conquestSquadInfos'>
                              <div className='dataContainer'>
                                <div className='dataSepartorLine'>
                                  <span className='dataTitle'>Total NFTs</span>
                                  <span className='dataAttribut'>{nbNfts}</span>
                                </div>
                                <div className='dataSepartorLine'>
                                  <span className='dataTitle'>Total Power</span>
                                  <span className='dataAttribut'>
                                    {squadTotPowerRounded}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className='conquestSquadContainer' key={i}>
                          <div className='conquestSquadInfosContainer'>
                            <div className='conquestSquadTitle'>
                              <div className='conquestSquadIcon'>
                                <img
                                  src={`${icon}`}
                                  style={divStyle}
                                  className='imgSquadIcon'
                                />
                              </div>
                              <div className='conquestSquadTextContainer'>
                                <div className='conquestSquadText'>
                                  {squad.name}
                                </div>
                              </div>
                            </div>
                            <div className='conquestSquadInfos'>
                              <div className='dataContainer'>
                                <div className='dataSepartorLine'>
                                  <span className='dataTitle'>Total NFTs</span>
                                  <span className='dataAttribut'>{nbNfts}</span>
                                </div>
                                <div className='dataSepartorLine'>
                                  <span className='dataTitle'>Total Power</span>
                                  <span className='dataAttribut'>
                                    {squadTotPowerRounded}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showSquads && squads.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My Squads</div>
                </div>
                <div className='text-center'>
                  <div className='iconContainer'>
                    <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                  </div>
                  <p className='textImportant'>
                    Oops... it looks like you don&apos;t have any squad at the
                    moment !
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {showNFTs && attributs.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My NFTs Wallet</div>
                  </div>
                  <div className='textImportant'>
                    Click on your NFTs to deploy them for the Conquest.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  {totalPages > 1 && (
                    <div className='paginBoxTop'>
                      <Pagination
                        className='paginContainer'
                        count={totalPages}
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                  <div className='nftBlock'>
                    {listNFTsDisplay.nfts.map((token, i) => {
                      let flg_deployed = false;
                      let inSquad = false;
                      let idConquest = 0;

                      conquests.map((conquest) => {
                        if (conquest.nft_identifier == token.nft_identifier) {
                          flg_deployed = true;
                          idConquest = conquest.id;
                        }
                      });

                      if (token.id_squad != 0) {
                        inSquad = true;
                      }

                      const divStyle = {
                        '--rarityColor': hierarchyColor[token.nft_hierarchy]
                      } as React.CSSProperties;

                      const NFTscoreRounded = token.nft_power.toFixed(2);
                      const nameT = token.nft_name.split(' ');
                      const nft_collection = nameT[0];
                      let nft_number;
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }

                      const squadDivStyle = {
                        '--squadColor': token.squad?.color
                      } as React.CSSProperties;

                      const icon =
                        iconsPath + token.squad?.icon + iconsExtention;

                      if (flg_deployed) {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardNFTPictureBox'
                                onClick={() => unselectNFT(idConquest)}
                              >
                                <div className='overlay'>
                                  <div className='overlayText'>Deployed</div>
                                </div>
                                {inSquad ? (
                                  <div className='overlaySquadIcon'>
                                    <img
                                      src={`${icon}`}
                                      style={squadDivStyle}
                                      className='overlayImgSquadIcon'
                                    />
                                  </div>
                                ) : null}
                                <div className='overlayHoverB'>
                                  <div className='overlayHoverTextB'>
                                    Remove
                                  </div>
                                </div>
                                <img
                                  src={`${token.nft_url}`}
                                  className='cardNFTPicture'
                                />
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardNFTPictureBox'
                                onClick={() => handleShowAreaD(token)}
                              >
                                {inSquad ? (
                                  <div className='overlaySquadIcon'>
                                    <img
                                      src={`${icon}`}
                                      style={squadDivStyle}
                                      className='overlayImgSquadIcon'
                                    />
                                  </div>
                                ) : null}
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Deploy
                                  </div>
                                </div>
                                <img
                                  src={`${token.nft_url}`}
                                  className='cardNFTPicture'
                                />
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {totalPages > 1 && (
                    <div className='paginBoxBottom'>
                      <Pagination
                        className='paginContainer'
                        count={totalPages}
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {showNFTs && attributs.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My NFTs Wallet</div>
                </div>
                {isBlockchainAccount ? (
                  <React.Fragment>
                    <div className='text-center'>
                      <div className='iconContainer'>
                        <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                      </div>
                      <p className='textImportant'>
                        Oops... it looks like you don&apos;t have any Heroes at
                        the moment !
                      </p>
                      <p className='textImportant'>
                        But ! you can find one by going here :
                      </p>
                    </div>
                    <div className='text-center'>
                      <Button
                        className='btnStandardReverse'
                        onClick={() => navigate(routeNames.mint)}
                      >
                        Mint Heroes
                      </Button>
                      <br className='smallBox' />
                      <a href={xoxnoLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on XOXNO
                        </Button>
                      </a>
                      <a href={deadrareLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on Dead Rare
                        </Button>
                      </a>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any Heroes at
                      the moment !
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>My Deployment</div>
              </div>
              <div className='whiteBox text-center'>
                {filteredArea.id_area == 0 ? (
                  <Button
                    className='navStandardSmallReverse'
                    onClick={() => handleFilterArea(0)}
                  >
                    All
                  </Button>
                ) : (
                  <Button
                    className='navStandardSmall'
                    onClick={() => handleFilterArea(0)}
                  >
                    All
                  </Button>
                )}
                {areas.map((area, i) => {
                  let visible = false;
                  switch (area.id_area) {
                    case 1:
                    case 2:
                    case 3:
                      visible = false;
                      break;
                    default:
                      visible = true;
                      break;
                  }

                  if (visible) {
                    if (filteredArea.id_area == area.id_area) {
                      return (
                        <Button
                          className='navStandardSmallReverse'
                          key={i}
                          onClick={() => handleFilterArea(area.id_area)}
                        >
                          {area.name}
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          className='navStandardSmall'
                          key={i}
                          onClick={() => handleFilterArea(area.id_area)}
                        >
                          {area.name}
                        </Button>
                      );
                    }
                  }
                })}
              </div>
              <div className='dataContainer margin10'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs deployed</span>
                  <span className='dataAttribut'>
                    {filteredArea.numDeployNFTs}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total power</span>
                  <span className='dataAttribut'>
                    {filteredArea.totalPower}
                  </span>
                </div>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              <div className='table-responsive'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0'>Name</th>
                      <th className='border-0'>Power</th>
                      <th className='border-0'>Area</th>
                      <th className='border-0'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conquests.map((conquest, i) => {
                      let areaName = '';
                      let available = false;

                      let inSquad = false;
                      let squadDivStyle = {
                        '--squadColor': '#000'
                      } as React.CSSProperties;
                      let icon = '';

                      attributs.map((token) => {
                        if (conquest.nft_identifier == token.nft_identifier) {
                          if (conquest.nft_type == 1) {
                            available = true;

                            if (token.id_squad != 0) {
                              inSquad = true;

                              squadDivStyle = {
                                '--squadColor': token.squad?.color
                              } as React.CSSProperties;

                              icon =
                                iconsPath + token.squad?.icon + iconsExtention;
                            }

                            if (
                              filteredArea.id_area != 0 &&
                              conquest.id_area != filteredArea.id_area
                            ) {
                              available = false;
                            }
                          }
                        }
                      });

                      if (available) {
                        count++;

                        areas.map((area) => {
                          if (area.id_area == conquest.id_area) {
                            areaName = area.name;
                          }
                        });
                      }

                      if (available) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>
                                {inSquad ? (
                                  <img
                                    src={`${icon}`}
                                    style={squadDivStyle}
                                    className='rowImgSquadIcon'
                                  />
                                ) : null}
                              </span>
                              <span>{conquest.attribut?.nft_name}</span>
                            </td>
                            <td>
                              <span>
                                {conquest.attribut?.nft_power.toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span>{areaName}</span>
                            </td>
                            <td>
                              <div
                                className='rowActionIcon'
                                onClick={() => handleShowAreaU(conquest)}
                              >
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  className='fa'
                                />
                              </div>
                              <div
                                className='rowActionIcon'
                                onClick={() => unselectNFT(conquest.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className='fa'
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {count == 0 && (
                      <tr className='rowTableContent textResult'>
                        {filteredArea.id_area == 0 ? (
                          <td colSpan={4}>
                            <span>No NFT deployed</span>
                          </td>
                        ) : (
                          <td colSpan={4}>
                            <span>
                              No NFT deployed to the {filteredArea.name}
                            </span>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDeploy}
        onHide={handleCloseDeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          {user.id_faction == selectedArea.id_faction ? (
            <div className='defenseBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>
                    Defend the {selectedArea.name}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='attackBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>
                    Attack the {selectedArea.name}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Deployment Information</div>
              </div>
              <div className='dataContainer'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs deployed</span>
                  <span className='dataAttribut'>{totDeployedArea}</span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total power</span>
                  <span className='dataAttribut'>
                    {totPowerArea.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {attributs.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My NFTs Wallet</div>
                  </div>
                  <div className='textImportant'>
                    Click on your NFTs to deploy them to the&nbsp;
                    <span className='textImportantRed'>
                      {selectedArea.name}
                    </span>
                    &nbsp;for the Conquest.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  {totalPagesArea > 1 && (
                    <div className='paginBoxTop'>
                      <Pagination
                        className='paginContainer'
                        count={totalPagesArea}
                        defaultPage={1}
                        page={pageArea}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChangeArea}
                      />
                    </div>
                  )}
                  <div className='nftBlock'>
                    {listNFTsDisplayArea.nfts.map((token, i) => {
                      let flg_deployed = false;
                      let inSquad = false;
                      let idConquest = 0;

                      conquests.map((conquest) => {
                        if (conquest.nft_identifier == token.nft_identifier) {
                          flg_deployed = true;
                          idConquest = conquest.id;
                        }
                      });

                      if (token.id_squad != 0) {
                        inSquad = true;
                      }

                      const divStyle = {
                        '--rarityColor': hierarchyColor[token.nft_hierarchy]
                      } as React.CSSProperties;

                      const NFTscoreRounded = token.nft_power.toFixed(2);
                      const nameT = token.nft_name.split(' ');
                      const nft_collection = nameT[0];
                      let nft_number;
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }

                      const squadDivStyle = {
                        '--squadColor': token.squad?.color
                      } as React.CSSProperties;

                      const icon =
                        iconsPath + token.squad?.icon + iconsExtention;

                      if (flg_deployed) {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardNFTPictureBox'
                                onClick={() => unselectNFT(idConquest)}
                              >
                                <div className='overlay'>
                                  <div className='overlayText'>Deployed</div>
                                </div>
                                {inSquad ? (
                                  <div className='overlaySquadIcon'>
                                    <img
                                      src={`${icon}`}
                                      style={squadDivStyle}
                                      className='overlayImgSquadIcon'
                                    />
                                  </div>
                                ) : null}
                                <div className='overlayHoverB'>
                                  <div className='overlayHoverTextB'>
                                    Remove
                                  </div>
                                </div>
                                <img
                                  src={`${token.nft_url}`}
                                  className='cardNFTPicture'
                                />
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardNFTPictureBox'
                                onClick={() =>
                                  selectNFT(token.id_nft, token.nft_identifier)
                                }
                              >
                                {inSquad ? (
                                  <div className='overlaySquadIcon'>
                                    <img
                                      src={`${icon}`}
                                      style={squadDivStyle}
                                      className='overlayImgSquadIcon'
                                    />
                                  </div>
                                ) : null}
                                <div className='overlayHoverA'>
                                  <div className='overlayHoverTextA'>
                                    Deploy
                                  </div>
                                </div>
                                <img
                                  src={`${token.nft_url}`}
                                  className='cardNFTPicture'
                                />
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {totalPagesArea > 1 && (
                    <div className='paginBoxBottom'>
                      <Pagination
                        className='paginContainer'
                        count={totalPagesArea}
                        defaultPage={1}
                        page={pageArea}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChangeArea}
                      />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {attributs.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My NFTs Wallet</div>
                </div>
                {isBlockchainAccount ? (
                  <React.Fragment>
                    <div className='text-center'>
                      <div className='iconContainer'>
                        <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                      </div>
                      <p className='textImportant'>
                        Oops... it looks like you don&apos;t have any Heroes at
                        the moment !
                      </p>
                      <p className='textImportant'>
                        But ! you can find one by going here :
                      </p>
                    </div>
                    <div className='text-center'>
                      <Button
                        className='btnStandardReverse'
                        onClick={() => navigate(routeNames.mint)}
                      >
                        Mint Heroes
                      </Button>
                      <br className='smallBox' />
                      <a href={xoxnoLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on XOXNO
                        </Button>
                      </a>
                      <a href={deadrareLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on Dead Rare
                        </Button>
                      </a>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any Heroes at
                      the moment !
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseDeploy}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showArea}
        onHide={handleCloseArea}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>
              Deployment of {selectedNFT.nft_collection}&nbsp;
              {selectedNFT.nft_number}
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='nftBlock'>
                <div style={selectedNFT.divStyle} className='cardNFTBlock'>
                  <div className='cardNFTContainer'>
                    <div className='cardNFTBox'>
                      <div className='cardNFTBoxIn'>
                        <div className='cardNFTInfos'>
                          <div className='cardNFTHierarchy'>
                            {selectedNFT.nft_hierarchy}
                          </div>
                          <div className='cardNFTPower'>
                            Power : {selectedNFT.nft_power.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='cardNFTPictureBox'>
                      {selectedNFT.inSquad ? (
                        <div className='overlaySquadIcon'>
                          <img
                            src={`${selectedNFT.squadIcon}`}
                            style={selectedNFT.squadDivStyle}
                            className='overlayImgSquadIcon'
                          />
                        </div>
                      ) : null}
                      <img
                        src={`${selectedNFT.nft_url}`}
                        className='cardNFTPicture'
                      />
                    </div>
                    <div className='cardNFTInfosBis'>
                      {!selectedNFT.nft_number ? (
                        <div className='cardNFTTitle'>
                          {selectedNFT.nft_collection}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className='cardNFTNumber'>
                            {selectedNFT.nft_number}
                          </div>
                          <div className='cardNFTName'>
                            {selectedNFT.nft_collection}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Area Selection</div>
              </div>
              <div className='textImportant'>
                Click on an area to deploy&nbsp;
                <span className='textImportantRed'>{selectedNFT.nft_name}</span>
                &nbsp;for the Conquest.
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='attackBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>Attack</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    if (user.id_faction != area.id_faction) {
                      visible = true;
                    }
                    break;
                }

                if (visible) {
                  return (
                    <Button
                      className='navStandardSmall'
                      key={i}
                      onClick={() => deployNFT(area.id_area)}
                    >
                      {area.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='defenseBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>Defend</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    if (user.id_faction == area.id_faction) {
                      visible = true;
                    }
                    break;
                }

                if (visible) {
                  return (
                    <Button
                      className='navStandardSmall'
                      key={i}
                      onClick={() => deployNFT(area.id_area)}
                    >
                      {area.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseArea}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAreaSquad}
        onHide={handleCloseAreaSquad}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Squad Deployment</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='squadTitle'>
                <div className='squadIcon'>
                  <img
                    src={`${selectedSquad.icon}`}
                    style={selectedSquad.divStyle}
                    className='imgSquadIcon'
                  />
                </div>
                <div className='squadTextContainer'>
                  <div className='squadTextModal'>
                    {selectedSquad.squad.name.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='squadInfosModal'>
                <div className='dataContainer'>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total NFTs</span>
                    <span className='dataAttribut'>{selectedSquad.nbNfts}</span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Power</span>
                    <span className='dataAttribut'>
                      {selectedSquad.totPower.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Area Selection</div>
              </div>
              <div className='textImportant'>
                Click on an area to deploy the squad&nbsp;
                <span className='textImportantRed'>
                  {selectedSquad.squad.name.toUpperCase()}
                </span>
                &nbsp;for the Conquest.
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='attackBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>Attack</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    if (user.id_faction != area.id_faction) {
                      visible = true;
                    }
                    break;
                }

                if (visible) {
                  return (
                    <Button
                      className='navStandardSmall'
                      key={i}
                      onClick={() => deploySquad(area)}
                    >
                      {area.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='defenseBox'>
              <div className='card-body text-center p-2'>
                <div className='infosBox'>
                  <div className='deployBoxTitle'>Defend</div>
                </div>
              </div>
            </div>

            <div className='text-center margin10'>
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    if (user.id_faction == area.id_faction) {
                      visible = true;
                    }
                    break;
                }

                if (visible) {
                  return (
                    <Button
                      className='navStandardSmall'
                      key={i}
                      onClick={() => deploySquad(area)}
                    >
                      {area.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseAreaSquad}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showMapBonus}
        onHide={handleCloseMapBonus}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Information</div>
          </div>

          {merchantBonus.merchant ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Game Bonuses</div>
                </div>
                <div className='text-center'>
                  <div className='textImportant'>
                    Merchants have appeared in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[0]}
                    </span>
                    &nbsp;and in the&nbsp;
                    <span className='textImportantRed'>
                      {merchantBonus.merchantAreas[1]}
                    </span>
                    &nbsp;!
                    <br />
                    <span className='textImportantRed'>
                      +{merchantBonus.merchantBonusValue}% JCORP
                    </span>
                    &nbsp;rewards for your entire faction for each area with
                    merchants you conquer
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {merchantBonus.merchant ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Legend</div>
                </div>
                <div className='legendContainer'>
                  <div className='legendBox'>
                    <div className='legendBoxIcon'>
                      <img src={`${merchantIcon}`} className='legendMapIcon' />
                    </div>
                    <div className='legendBoxText'>Merchant</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {user.flg_delegation ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='statusWarning'>
                  You have given your faction leaders the ability to
                  strategically redeploy to another area your deployed NFTs.
                  <div className='margin10'>
                    You can update this choice in your&nbsp;
                    <a href={routeNames.profile} rel='noreferrer'>
                      profile
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseMapBonus}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Squad Deployment</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='squadTitle'>
                <div className='squadIcon'>
                  <img
                    src={`${selectedSquad.icon}`}
                    style={selectedSquad.divStyle}
                    className='imgSquadIcon'
                  />
                </div>
                <div className='squadTextContainer'>
                  <div className='squadTextModal'>
                    {selectedSquad.squad.name.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='squadInfosModal'>
                <div className='dataContainer'>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total NFTs</span>
                    <span className='dataAttribut'>{selectedSquad.nbNfts}</span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Power</span>
                    <span className='dataAttribut'>
                      {selectedSquad.totPower.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Deployment Information</div>
              </div>
              <div className='dataContainer'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Area</span>
                  <span className='dataAttribut'>{squadNFTsArea}</span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs deployed</span>
                  <span className='dataAttribut'>
                    {squadNFTsDeployed} / {squadNFTsQty}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total power</span>
                  <span className='dataAttribut'>
                    {squadNFTsPower.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {flgSquadDeployment && squadNFTsDeployed == squadNFTsQty ? (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal
        show={showSettings}
        onHide={handleCloseSettings}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Conquest Menu</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>Conquest</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.conquestboard)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Deployment</div>
                  </div>
                </div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.historyboard)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>History</div>
                  </div>
                </div>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.rewards)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Rewards</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>NFTs</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.squads)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Squads</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='settingsMenuBox'>
                <div className='settingsMenuTitle'>Miscellaneous</div>
              </div>
              <div className='buttonContainer'>
                <div
                  className='btnMenu'
                  onClick={() => navigate(routeNames.rules)}
                >
                  <div className='btnMenuIn'>
                    <div className='btnMenuText'>Rules</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseSettings}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Deployment;
