import http from '../http-common';
import { AreasStateType } from '../types/area.type';

class AreaDataService {
  getAll() {
    return http.get<AreasStateType>('/area');
  }
}

export default new AreaDataService();
