import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

export const _resetAccountData = () =>
  function ResetAccountData() {
    localStorage.clear();
  };

export const _setAccountData = () =>
  function SetAccountData(typeData: string, valueData: any) {
    //console.log('SetAccountData : ', typeData, ' | ', valueData);
    switch (typeData) {
      case 'id':
        localStorage.setItem(typeData, valueData);
        break;
      case 'account':
        localStorage.setItem(typeData, valueData);
        break;
      case 'provider':
        localStorage.setItem(typeData, valueData);
        break;
      case 'accountIsAdmin':
        localStorage.setItem(typeData, valueData);
        break;
      case 'accountIsModerator':
        localStorage.setItem(typeData, valueData);
        break;
      case 'gameMode':
        localStorage.setItem(typeData, valueData);
        break;
      default:
        break;
    }
  };

export const _getAccountData = () =>
  function GetAccountData() {
    let userID = 0;
    let userAccount = '';
    let userAlias = '';
    let userAccountProvider = '';
    let isBlockchainAccount = false;
    let userGameMode = '';
    let isAdmin = false;
    let isModerator = false;
    let isAllowed = false;

    const id = localStorage.getItem('id');
    const account = localStorage.getItem('account');
    const provider = localStorage.getItem('provider');
    const gameMode = localStorage.getItem('gameMode');
    const accountIsAdmin = localStorage.getItem('accountIsAdmin');
    const accountIsModerator = localStorage.getItem('accountIsModerator');

    const mvx = useGetAccountInfo();

    // userID
    if (id != null && id != '') {
      userID = parseInt(id);
    }

    // userAccountProvider
    if (provider != null && provider != '') {
      userAccountProvider = provider;
    }

    // userAccount + userAlias + isBlockchainAccount
    switch (userAccountProvider) {
      case 'multiversx':
        userAccount = mvx.account.address;
        isBlockchainAccount = true;
        if (mvx.account.username != null && mvx.account.username != '') {
          const getUsernameMVX = mvx.account.username.split('.elrond');
          userAlias = '@' + getUsernameMVX[0];
        }
        break;

      default:
        if (account != null && account != '') {
          userAccount = account;
        }
        break;
    }

    // userGameMode
    if (gameMode != null && gameMode != '') {
      userGameMode = gameMode;
    }

    // isAdmin
    if (accountIsAdmin == 'true') isAdmin = true;

    // isModerator
    if (accountIsModerator == 'true') isModerator = true;

    // isAllowed
    switch (true) {
      case isAdmin:
      case isModerator:
        isAllowed = true;
        break;
      default:
        break;
    }

    const _userAccount = {
      userID: userID,
      userAccount: userAccount,
      userAlias: userAlias,
      userAccountProvider: userAccountProvider,
      isBlockchainAccount: isBlockchainAccount,
      isAllowed: isAllowed,
      isAdmin: isAdmin,
      isModerator: isModerator,
      gameMode: userGameMode
    };

    //console.log('GetAccountData : ', _userAccount);

    return _userAccount;
  };

export const resetAccountData = _resetAccountData();
export const setAccountData = _setAccountData();
export const getAccountData = _getAccountData();
