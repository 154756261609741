import http from '../http-common';
import { ItemsStateType } from '../types/items.type';

class ItemsDataService {
  getAll() {
    return http.get<ItemsStateType>('/items');
  }
}

export default new ItemsDataService();
