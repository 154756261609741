import http from '../http-common';
import {
  IConquestData,
  ConquestStateType,
  ConquestsStateType
} from '../types/conquest.type';

class ConquestDataService {
  create(data: IConquestData) {
    return http.post<ConquestStateType>('/conquest', data);
  }

  update(data: any, id: any) {
    return http.put<ConquestStateType>(`/conquest/${id}`, data);
  }

  delete(id: any) {
    return http.delete<ConquestStateType>(`/conquest/${id}`);
  }

  createOrUpdate(data: IConquestData) {
    return http.post<ConquestStateType>('/conquest/deploysquad', data);
  }

  findAllByUser(params: any) {
    return http.get<ConquestsStateType>('/conquest/user', { params });
  }

  findAllByFaction(params: any) {
    return http.get<ConquestsStateType>('/conquest/faction', { params });
  }

  findAllByConquest(id_conquest: any) {
    return http.get<ConquestsStateType>(`/conquest/all/${id_conquest}`);
  }

  massiveFactionRedeploy(params: any) {
    return http.get<any>('/conquest/redeploy', { params });
  }
}

export default new ConquestDataService();
