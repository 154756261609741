import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DiscordIcon } from 'assets/img/discord.svg';
import logo_purple from 'assets/img/JCORP_logo_purple.png';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { routeNames } from 'routes';

const FooterHome = () => {
  const navigate = useNavigate();

  const discord_link = 'https://discord.gg/7B5ZuPmFjV';
  const twitter_link = 'https://twitter.com/JCorpBattle';

  return (
    <div id='footerNavBar'>
      <div className='footerContent'>
        <div onClick={() => navigate(routeNames.home)}>
          <img src={logo_purple} className='logoNav' />
        </div>
        <div className='footerNav'>
          <div className='footerNavElement socialIconsContainer'>
            <a
              href={discord_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <DiscordIcon className='mx-1' />
            </a>
            <a
              href={twitter_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <TwitterIcon className='mx-1' />
            </a>
          </div>
          <div className='footerNavElement'>
            <a href='/#universeItem'>Universe</a>
          </div>
          <div className='footerNavElement'>
            <a href='/#roadmapItem'>Roadmap</a>
          </div>
          <div className='footerNavElement'>
            <a href='/#teamItem'>Team</a>
          </div>
          <div className='footerNavElement'>
            <a href='/#partnersItem'>Partners</a>
          </div>
          <div className='footerNavElement'>
            <a href='/#FAQItem'>FAQ</a>
          </div>
          <div className='footerNavElement socialIconsSmallContainer'>
            <a
              href={discord_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <DiscordIcon className='mx-1' />
            </a>
            <a
              href={twitter_link}
              target='_blank'
              rel='noreferrer'
              className='socialIcons'
            >
              <TwitterIcon className='mx-1' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterHome;
