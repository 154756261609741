import http from '../http-common';
import {
  ArenaResultStateType,
  ArenaResultsStateType
} from '../types/arenaresults.type';

class ArenaResultsDataService {
  findAllUserResults(id_user: any, params: any) {
    return http.get<ArenaResultsStateType>(`/arena_results/user/${id_user}`, {
      params
    });
  }

  findLatestResults(params: any) {
    return http.get<ArenaResultsStateType>('/arena_results/latest/', {
      params
    });
  }

  findOneResultById(id: any) {
    return http.get<ArenaResultStateType>(`/arena_results/${id}`);
  }
}

export default new ArenaResultsDataService();
