export const metaEmpty: IMetaData = {
  name: '',
  str: 0,
  dex: 0,
  int: 0
};

export interface IMetaData {
  name: string;
  str: number;
  dex: number;
  int: number;
}

export interface MetaStateType {
  meta: IMetaData;
  message?: string;
  status: string;
}
