import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import SettingDataService from '../../../services/setting.service';
import discord_logo from './../../../assets/img/discord.svg';
import Deployment from './Deployment';

const Conquest = () => {
  const discord_link = 'https://discord.gg/7B5ZuPmFjV';

  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [currentPhase, setCurrentPhase] = React.useState(0);

  const [flgAccess, setFlgAccess] = React.useState<boolean>();
  const [flgAccessTime, setFlgAccessTime] = React.useState<boolean>();
  const [userLeader, setUserLeader] = React.useState(false);

  const timeLimit = 20; // 8pm UTC
  const currentDate = new Date();
  const currentHours = currentDate.getUTCHours();

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentPhase(data.setting.value);
      }
    });

    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const fetchUserInfos = () => {
    if (
      userInfos.user.id_user == userInfos.user.faction?.id_user ||
      userInfos.user.flg_leader
    ) {
      setUserLeader(true);
    } else {
      setUserLeader(false);
    }
  };

  const fetchAccess = () => {
    if (currentPhase == 2) {
      if (
        userInfos.user.id_user == userInfos.user.faction?.id_user ||
        userInfos.user.flg_leader ||
        userInfos.user.nb_nfts >= 10 ||
        userInfos.user.participation >= 20
      ) {
        setFlgAccess(true);
      } else {
        setFlgAccess(false);
      }

      if (
        userInfos.user.id_user == userInfos.user.faction?.id_user ||
        userInfos.user.flg_leader
      ) {
        setFlgAccessTime(true);
      } else {
        if (currentHours >= timeLimit) {
          setFlgAccessTime(false);
        } else {
          setFlgAccessTime(true);
        }
      }
    }

    if (currentPhase == 3) {
      setFlgAccessTime(true);

      if (
        userInfos.user.id_user == userInfos.user.faction?.id_user ||
        userInfos.user.flg_leader
      ) {
        setFlgAccess(true);
      } else {
        setFlgAccess(false);
      }
    }

    /*
    if (
      userInfos.user.id_user == userInfos.user.faction?.id_user ||
      userInfos.user.flg_leader ||
      userInfos.user.nb_nfts >= 10 ||
      userInfos.user.participation >= 20
    ) {
      setFlgAccess(true);
    } else {
      setFlgAccess(false);
    }

    if (currentPhase == 2) {
      if (
        userInfos.user.id_user == userInfos.user.faction?.id_user ||
        userInfos.user.flg_leader
      ) {
        setFlgAccessTime(true);
      } else {
        if (currentHours >= timeLimit) {
          setFlgAccessTime(false);
        } else {
          setFlgAccessTime(true);
        }
      }
    } else {
      setFlgAccessTime(true);
    }
    */
  };

  const handleRefreshNFTsWallet = () => {
    navigate(routeNames.nftswallet);
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchUserInfos();
      fetchAccess();
    }
  }, [currentPhase]);

  if (currentPhase == 2 && !flgAccessTime) {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosConquestContainer'>
                  <div className='infosConquestBox'>
                    <div className='conquestTitleBox'>
                      <div className='conquestTitle'>Conquest</div>
                    </div>
                    <div className='conquestNumber'>{currentConquest}</div>
                    <div className='phaseTitle'>Deployment Board</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topBigContainer'>
              <div className='card-body text-center p-2'>
                <p className='textImportant'>
                  Oops... it looks like you are not a leader of your faction !
                </p>
                <p className='textImportant'>
                  Only faction leaders can see the Deployment Board after 8pm
                  UTC.
                </p>
                <p className='textImportant'>
                  You can follow your faction&apos;s deployment call in&nbsp;
                  <a href={discord_link} target='_blank' rel='noreferrer'>
                    <img src={discord_logo} width='32px' />
                    &nbsp;Discord
                  </a>
                  &nbsp;on your faction channel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (currentPhase == 2 && !flgAccess) {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosConquestContainer'>
                  <div className='infosConquestBox'>
                    <div className='conquestTitleBox'>
                      <div className='conquestTitle'>Conquest</div>
                    </div>
                    <div className='conquestNumber'>{currentConquest}</div>
                    <div className='phaseTitle'>Deployment Board</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topBigContainer'>
              <div className='card-body text-center p-2'>
                <p className='textImportant'>
                  Oops... it looks like you don&apos;t have enough J-Corp Heroes
                  at the moment or you don&apos;t participated to enough
                  Conquests !
                </p>
                <p className='textImportant'>
                  You must have at least&nbsp;
                  <span className='textImportantRed'>10 J-Corp Heroes</span>
                  &nbsp;or participate to&nbsp;
                  <span className='textImportantRed'>20 Conquest phases</span>
                  &nbsp;to see the Deployment Board.
                </p>
                <p className='textImportant'>
                  Anyway, you can follow your faction&apos;s deployment call
                  in&nbsp;
                  <a href={discord_link} target='_blank' rel='noreferrer'>
                    <img src={discord_logo} width='32px' />
                    &nbsp;Discord
                  </a>
                  &nbsp;on your faction channel.
                </p>
              </div>
            </div>

            <div className='boxContainer topContainer'>
              <div className='card-body text-center p-2'>
                <div className='dataContainer'>
                  <div className='dataBox'>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>J-Corp Heroes</span>
                      <span className='dataAttribut'>
                        {userInfos.user.nb_nfts}
                      </span>
                    </div>
                    <div className='dataSepartorLine'>
                      <span className='dataTitle'>Participation</span>
                      <span className='dataAttribut'>
                        {userInfos.user.participation}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topContainer'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleRefreshNFTsWallet}
                >
                  My NFTs
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (currentPhase == 3 && !flgAccess) {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='infosConquestContainer'>
                  <div className='infosConquestBox'>
                    <div className='conquestTitleBox'>
                      <div className='conquestTitle'>Conquest</div>
                    </div>
                    <div className='conquestNumber'>{currentConquest}</div>
                    <div className='phaseTitle'>Deployment Board</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='boxContainer topBigContainer'>
              <div className='card-body text-center p-2'>
                <p className='textImportant'>
                  Oops... it looks like you are not a leader of your faction !
                </p>
                <p className='textImportant'>
                  Only faction leaders can see the Deployment Board during the
                  Battle Phase.
                </p>
                <p className='textImportant'>
                  You can follow your faction&apos;s deployment call in&nbsp;
                  <a href={discord_link} target='_blank' rel='noreferrer'>
                    <img src={discord_logo} width='32px' />
                    &nbsp;Discord
                  </a>
                  &nbsp;on your faction channel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (flgAccess && flgAccessTime) {
    return (
      <React.Fragment>
        <Deployment userInfos={userInfos} userLeader={userLeader} />
      </React.Fragment>
    );
  }
};

export default Conquest;
