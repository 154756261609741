import http from '../http-common';
import {
  ISquadData,
  SquadStateType,
  SquadsStateType
} from '../types/squads.type';

class SquadsDataService {
  create(data: ISquadData) {
    return http.post<SquadStateType>('/squads', data);
  }

  update(data: any, id: any) {
    return http.put<SquadStateType>(`/squads/${id}`, data);
  }

  delete(id: any) {
    return http.delete<SquadStateType>(`/squads/${id}`);
  }

  findOne(id: any) {
    return http.get<SquadStateType>(`/squads/${id}`);
  }

  findAllByUser(id: any) {
    return http.get<SquadsStateType>(`/squads/user/${id}`);
  }
}

export default new SquadsDataService();
