import axios from 'axios';
import { network, default_power, nft_power_max } from 'config';
import AttributsDataService from '../services/attributs.service';
import { IAttributsData, attributsEmpty } from '../types/attributs.type';
import { NFTsData, NFTType, INFTData } from '../types/token.type';

const { apiAddress } = network;
const elrond = axios.create({
  baseURL: apiAddress,
  headers: {
    'Content-type': 'application/json'
  }
});

const timeout = 3000;
const size = 25;

async function fetchMetadata(file: string) {
  const api_call = await fetch(file);
  const data = await api_call.json();
  return data;
}

async function fetchHERO(token: NFTType) {
  let attributsNFT: IAttributsData = attributsEmpty;

  const params: any = {};
  params['identifier'] = token.identifier;

  await AttributsDataService.findByIdentifier(params).then(async function (
    attributData
  ) {
    if (attributData.data.status != 'success') {
      let tokenPower = 0;
      let tokenIdHierarchy = 0;
      let tokenHierarchy = '';

      if (
        token.metadata != null &&
        token.metadata.rarity != null &&
        token.metadata.rarity.rarityScore != null
      ) {
        tokenPower = token.metadata.rarity.rarityScore / 10;
      }

      if (token.metadata != null && token.metadata.attributes != null) {
        for (let j = 0; j < token.metadata.attributes.length; j++) {
          const attribut = token.metadata.attributes[j];
          if (attribut.trait_type == 'Hierarchy') {
            tokenHierarchy = attribut.value;
          }
        }
      }

      if (tokenPower == 0 || tokenHierarchy == '') {
        try {
          const file = token.url.replace('.png', '.json');
          const metadata = await fetchMetadata(file);

          const rarity = metadata.rarity.rarityScore;
          tokenPower = rarity / 10;

          for (let j = 0; j < metadata.attributes.length; j++) {
            const attribut = metadata.attributes[j];
            if (attribut.trait_type == 'Hierarchy') {
              tokenHierarchy = attribut.value;
            }
          }
        } catch (err) {
          tokenPower = default_power;
          tokenHierarchy = '';
        }
      }
      if (tokenPower > nft_power_max) tokenPower = nft_power_max;

      switch (tokenHierarchy) {
        case 'Warrior':
          tokenIdHierarchy = 1;
          break;
        case 'Samurai':
          tokenIdHierarchy = 2;
          break;
        case 'Daimyos':
          tokenIdHierarchy = 3;
          break;
        case 'Shogun':
          tokenIdHierarchy = 4;
          break;
        default:
          break;
      }

      const dataAttributs: IAttributsData = {
        id_user: 0,
        nft_identifier: token.identifier,
        nft_name: token.name,
        nft_url: token.url,
        nft_hierarchy: tokenHierarchy,
        nft_id_hierarchy: tokenIdHierarchy,
        nft_power: tokenPower,
        id_squad: 0,
        flg_blockchain: true
      };

      await AttributsDataService.create(dataAttributs);

      await AttributsDataService.findByIdentifier(params).then(async function (
        attributDataBis
      ) {
        if (attributDataBis.data.status == 'success') {
          attributsNFT = attributDataBis.data.attribut;
        }
      });
    } else {
      attributsNFT = attributData.data.attribut;
    }
  });

  return attributsNFT;
}

async function fetchNFTsData(data: any) {
  const nftsApiData: NFTsData = {
    nfts: data
  };

  try {
    const nftsData = new Array(0);

    for (let i = 0; i < nftsApiData.nfts.length; i++) {
      const token = nftsApiData.nfts[i];

      const attributsNFT: IAttributsData = await fetchHERO(token);

      const nftData: INFTData = {
        id_nft: attributsNFT.id_nft,
        identifier: attributsNFT.nft_identifier,
        name: attributsNFT.nft_name,
        url: attributsNFT.nft_url,
        hierarchy: attributsNFT.nft_hierarchy,
        id_hierarchy: attributsNFT.nft_id_hierarchy,
        power: attributsNFT.nft_power
      };

      nftsData.push(nftData);
    }

    // sorting power DESC
    nftsData.sort((a, b) => b.power - a.power);

    return {
      data: nftsData,
      success: nftsData !== undefined
    };
  } catch (err) {
    return {
      data: [],
      success: false
    };
  }
}

interface GetLatestNFTsType {
  address: string;
  collections: string;
}

async function readAccountNFTsCount({
  address,
  collections
}: GetLatestNFTsType) {
  try {
    const { data } = await elrond.get(`/accounts/${address}/nfts/count`, {
      params: {
        collections: collections
      },
      timeout
    });

    return {
      count: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      count: 0,
      success: false
    };
  }
}

const fetchNFTs = () =>
  async function getNFTs({ address, collections }: GetLatestNFTsType) {
    try {
      let flg_specific = false;

      if (
        address ==
        'erd1r7szr7lhms6ed78pyqmywn9yt0927smgjsf9lzc74zqmfnr0ul5qau35lh'
      ) {
        flg_specific = true;
      }

      const nftList = [];

      if (flg_specific) {
        const { data } = await elrond.get(`/accounts/${address}/nfts`, {
          params: {
            collections: collections,
            size: 50
          },
          timeout
        });

        for (let j = 0; j < data.length; j++) {
          nftList.push(data[j]);
        }
      } else {
        const collectionCount = await readAccountNFTsCount({
          address,
          collections
        });
        const nbPages = Math.ceil(collectionCount.count / size);

        for (let i = 0; i < nbPages; i++) {
          const position = size * i;

          const { data } = await elrond.get(`/accounts/${address}/nfts`, {
            params: {
              collections: collections,
              from: position,
              size: size
            },
            timeout
          });

          for (let j = 0; j < data.length; j++) {
            nftList.push(data[j]);
          }
        }
      }

      const nftsData = await fetchNFTsData(nftList);

      return {
        data: nftsData.data,
        success: nftsData.data !== undefined
      };
    } catch (err) {
      return {
        data: [],
        success: false
      };
    }
  };

interface GetTokensList {
  tokensList: string;
}

const fetchTokensList = () =>
  async function getTokensList({ tokensList }: GetTokensList) {
    try {
      const { data } = await elrond.get('/tokens', {
        params: {
          identifiers: tokensList
        },
        timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

interface GetTokensWallet {
  address: string;
  tokensWallet: string;
}

const fetchTokens = () =>
  async function getTokens({ address, tokensWallet }: GetTokensWallet) {
    try {
      const { data } = await elrond.get(`/accounts/${address}/tokens`, {
        params: {
          identifiers: tokensWallet
        },
        timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getNFTs = fetchNFTs();
export const getTokensList = fetchTokensList();
export const getTokens = fetchTokens();
