import http from '../http-common';
import { LeaderboardsDatasStateType } from '../types/leaderboardsdatas.type';

class LeaderboardsDatasDataService {
  findAllByUser(params: any) {
    return http.get<LeaderboardsDatasStateType>('/leaderboardsdatas/user/', {
      params
    });
  }
}

export default new LeaderboardsDatasDataService();
