export interface IUserSession {
  userID: number;
  userAccount: string;
  userAlias: string;
  userAccountProvider: string;
  isBlockchainAccount: boolean;
  isAllowed: boolean;
  isAdmin: boolean;
  isModerator: boolean;
  gameMode: string;
}

export const userEmpty: IUserData = {
  id_user: 0,
  address: '',
  pseudo: '',
  id_faction: 0,
  flg_leader: false,
  flg_delegation: false,
  nb_nfts: 0,
  tot_power: 0,
  nb_slots_arena_ranked: 0,
  participation: 0,
  num_item: 0,
  flg_claim: false,
  flg_claim_rewards: 0,
  faction_contribution: 0
};

export interface IUserData {
  id_user?: any | null;
  address: string;
  pseudo: string;
  id_faction: number;
  flg_leader: boolean;
  flg_delegation: boolean;
  nb_nfts: number;
  tot_power: number;
  nb_slots_arena_ranked: number;
  participation: number;
  num_item: number;
  flg_claim: boolean;
  flg_claim_rewards: number;
  faction_contribution: number;
  updatedAt?: Date;
  faction?: IFactionData;
}

export interface IFactionData {
  name: string;
  id_user: number;
}

export interface UserStateType {
  user: IUserData;
  message?: string;
  status: string;
}

export interface UsersStateType {
  users: IUserData[];
  message?: string;
  status: string;
}

export interface UsersStatsStateType {
  nbUsers: number;
  message?: string;
  status: string;
}
