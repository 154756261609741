import http from '../http-common';
import { ArenaLdbDatasStateType } from '../types/arenaldbdatas.type';

class ArenaLdbDatasDataService {
  findAllByUser(params: any) {
    return http.get<ArenaLdbDatasStateType>('/arenaldbdatas/user/', {
      params
    });
  }
}

export default new ArenaLdbDatasDataService();
