import http from '../http-common';
import { ConquestsLogsStateType } from '../types/conquestslogs.type';

class ConquestLogsDataService {
  findAllByConquest(id_conquest: any) {
    return http.get<ConquestsLogsStateType>(
      `/conquestslogs/conquest/${id_conquest}`
    );
  }
}

export default new ConquestLogsDataService();
