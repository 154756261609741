import * as React from 'react';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, Radio, RadioGroup, Pagination } from '@mui/material';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { Modal, Button, Col, Form } from 'react-bootstrap';
import { getTokensList, getTokens } from 'apiRequests';
import {
  jcorpWallet,
  tokensList,
  tokensWallet,
  tokensListNames,
  network
} from 'config';
import { useAuthContext } from 'context/auth-context';
import SettingDataService from '../../services/setting.service';
import WalletsDataService from '../../services/wallets.service';
import WalletsEventsDataService from '../../services/wallets_events.service';
import { TokensStateType } from '../../types/token.type';
import { WalletsStateType, ITokenInfos } from '../../types/wallets.type';
import {
  WalletsEventsStateType,
  IWalletEventData
} from '../../types/wallets_events.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const Wallet = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userSession, userInfos } = useAuthContext();

  let userAccountMVX;
  if (userSession.isBlockchainAccount) {
    if (userSession.userAlias != '') {
      userAccountMVX = userSession.userAlias;
    } else {
      userAccountMVX =
        userSession.userAccount.substring(0, 3) +
        '...' +
        userSession.userAccount.substring(56, 62);
    }
  }

  const [isLoading, setIsLoading] = React.useState(true);

  const [message, setMessage] = React.useState<string>();

  const [walletAvailable, setWalletAvailable] = React.useState<boolean>();

  const { explorerAddress } = network;
  const [groupOperation, setGroupOperation] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const pageSize = 50;

  const [actionName, setActionName] = React.useState('');
  const [actionSuccess, setActionSuccess] = React.useState(false);

  const [showWallet, setShowWallet] = React.useState(true);
  const [showOperations, setShowOperations] = React.useState(false);
  const handleShowWallet = () => {
    setMessage('');
    setActionSuccess(false);
    setShowWallet(true);
    setShowOperations(false);
  };
  const handleShowOperations = () => {
    setMessage('');
    setActionSuccess(false);
    setShowWallet(false);
    setShowOperations(true);
  };

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    if (userSession.isBlockchainAccount) fetchTokensBalanceData();
    fetchWalletsData();
    fetchWalletsEventsData();
  };

  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);
  const [transactionTokenInfos, setTransactionTokenInfos] =
    React.useState<ITokenInfos | null>(null);

  function numHex(s: any) {
    let a = s.toString(16);
    if (a.length % 2 > 0) {
      a = '0' + a;
    }
    return a;
  }

  function strHex(s: any) {
    let a = '';
    for (let i = 0; i < s.length; i++) {
      a = a + numHex(s.charCodeAt(i));
    }
    return a;
  }

  const [walletsEventsInfos, setWalletsEventsInfos] =
    React.useState<WalletsEventsStateType>({
      totalItems: 0,
      totalPages: 0,
      wallets_events: [],
      message: '',
      status: ''
    });

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const handleGroupOperationChange = (value: any) => {
    setGroupOperation(value);
    setPage(1);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const [listTokens, setListTokens] = React.useState<TokensStateType>({
    tokens: [],
    tokensFetched: undefined
  });
  //const [accountBalance, setAccountBalance] = React.useState<any>();
  const [balanceTokens, setBalanceTokens] = React.useState<TokensStateType>({
    tokens: [],
    tokensFetched: undefined
  });
  const [isBalance, setIsBalance] = React.useState<boolean>();

  const [factionImg, setFactionImg] = React.useState('');
  const [walletsInfos, setWalletsInfos] = React.useState<WalletsStateType>({
    wallets: [],
    message: '',
    status: ''
  });

  const [sendType, setSendType] = React.useState('faction');

  const handleChangeSend = (event: any, value: any) => {
    setSendType(value);
  };

  const [tokenList, setTokenList] = React.useState<any>({
    tokenInfos: [],
    status: false
  });

  const [actionType, setActionType] = React.useState<number>();
  const [amountToken, setAmountToken] = React.useState<any>();
  const [amountTokenIdentifier, setAmountTokenIdentifier] =
    React.useState<any>();
  const [amountTokenName, setAmountTokenName] = React.useState<any>();
  const [amountTokenError, setAmountTokenError] = React.useState('');
  const validAmountToken = /^[0-9\b]+$/;

  const handleAmountToken = (token_identifier: string, e: any) => {
    const infos = {
      tokenInfos: tokenList.tokenInfos,
      status: tokenList.status
    };

    const amount = e.target.value;
    setAmountToken(amount);

    let isError = false;

    if (amount.length > 0 && !validAmountToken.test(amount)) {
      isError = true;
      setAmountTokenError('The amount must be numeric and integer');
    } else {
      setAmountTokenError('');
    }

    if (!isError) {
      let amountMax = 0;
      switch (actionType) {
        case 1:
          for (let i = 0; i < tokenList.tokenInfos.length; i++) {
            if (tokenList.tokenInfos[i][2] == token_identifier) {
              amountMax = tokenList.tokenInfos[i][3];
            }
          }
          break;
        case 2:
        case 3:
          for (let i = 0; i < tokenList.tokenInfos.length; i++) {
            if (tokenList.tokenInfos[i][2] == token_identifier) {
              amountMax = tokenList.tokenInfos[i][1];
            }
          }
          break;
      }

      if (amount > amountMax) {
        isError = true;
        const amountMaxFmt = amountMax.toLocaleString('en-GB', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        setAmountTokenError(
          'Maximum amount : ' + amountMaxFmt + ' ' + amountTokenName
        );
      } else {
        setAmountTokenError('');
      }
    }

    for (let i = 0; i < tokenList.tokenInfos.length; i++) {
      if (tokenList.tokenInfos[i][2] == token_identifier) {
        if (isError || amount.length == 0 || amount == 0) {
          tokenList.tokenInfos[i][4] = false;
        } else {
          tokenList.tokenInfos[i][4] = true;
        }
      }
    }

    setTokenList(infos);
  };

  const handleAmountTokenPercent = (
    token_identifier: string,
    percent: number
  ) => {
    const infos = {
      tokenInfos: tokenList.tokenInfos,
      status: tokenList.status
    };

    let amountMax = 0;
    switch (actionType) {
      case 1:
        for (let i = 0; i < tokenList.tokenInfos.length; i++) {
          if (tokenList.tokenInfos[i][2] == token_identifier) {
            amountMax = tokenList.tokenInfos[i][3];
            tokenList.tokenInfos[i][4] = true;
          }
        }
        break;
      case 2:
      case 3:
        for (let i = 0; i < tokenList.tokenInfos.length; i++) {
          if (tokenList.tokenInfos[i][2] == token_identifier) {
            amountMax = tokenList.tokenInfos[i][1];
            tokenList.tokenInfos[i][4] = true;
          }
        }
        break;
    }

    const amount = Math.floor((amountMax * percent) / 100);
    setAmountToken(amount);
    setAmountTokenError('');
    setTokenList(infos);
  };

  const handleInitTokenAction = () => {
    const infos = {
      tokenInfos: tokenList.tokenInfos,
      status: true
    };

    if (userSession.isBlockchainAccount) {
      for (let i = 0; i < walletsInfos.wallets.length; i++) {
        const wallet = walletsInfos.wallets[i];

        let balanceValue = 0;
        if (balanceTokens.tokensFetched) {
          balanceTokens.tokens.map((balanceToken) => {
            if (balanceToken.identifier == wallet.token_identifier) {
              const decimals = 10 ** balanceToken.decimals;
              balanceValue = balanceToken.balance / decimals;
            }
          });
        }

        infos.tokenInfos[i] = new Array(5);
        infos.tokenInfos[i][0] = false;
        infos.tokenInfos[i][1] = wallet.amount;
        infos.tokenInfos[i][2] = wallet.token_identifier;
        infos.tokenInfos[i][3] = balanceValue;
        infos.tokenInfos[i][4] = false;
      }
    } else {
      for (let i = 0; i < walletsInfos.wallets.length; i++) {
        const wallet = walletsInfos.wallets[i];
        infos.tokenInfos[i] = new Array(5);
        infos.tokenInfos[i][0] = false;
        infos.tokenInfos[i][1] = wallet.amount;
        infos.tokenInfos[i][2] = wallet.token_identifier;
        infos.tokenInfos[i][3] = 0;
        infos.tokenInfos[i][4] = false;
      }
    }

    setTokenList(infos);
  };

  const handleTokenAction = (token_identifier: string, type_action: number) => {
    setMessage('');
    setActionSuccess(false);

    setAmountTokenIdentifier(token_identifier);
    for (let i = 0; i < tokensListNames.length; i++) {
      const token = tokensListNames[i];
      if (token.token_identifier == token_identifier) {
        setAmountTokenName(token.name);
      }
    }

    const infos = {
      tokenInfos: tokenList.tokenInfos,
      status: tokenList.status
    };
    for (let i = 0; i < tokenList.tokenInfos.length; i++) {
      if (tokenList.tokenInfos[i][2] == token_identifier) {
        if (actionType == type_action) {
          if (infos.tokenInfos[i][0] == true) {
            infos.tokenInfos[i][0] = false;
          } else {
            infos.tokenInfos[i][0] = true;
          }
        } else {
          infos.tokenInfos[i][0] = true;
          infos.tokenInfos[i][4] = false;
          setAmountToken('');
          setAmountTokenError('');
        }
        switch (type_action) {
          case 1:
            setActionName('deposit');
            break;
          case 2:
            setActionName('withdraw');
            break;
          case 3:
            setActionName('send');
            break;
        }
      }
    }
    setTokenList(infos);
    setActionType(type_action);
  };

  const handleAction = () => {
    switch (actionType) {
      case 1:
        handleDeposit();
        break;
      case 2:
        handleWithdraw();
        break;
      case 3:
        handleSend();
        break;
    }
  };

  const handleDeposit = async () => {
    let tokenIdentifier = '';
    let tokenDecimals = 0;
    let tokenName = '';
    listTokens.tokens.map((token) => {
      if (amountTokenIdentifier == token.identifier) {
        tokenIdentifier = token.identifier;
        tokenDecimals = token.decimals;
        tokenName = token.name;
      }
    });

    // ==> MAINNET
    const number = amountToken * 10 ** tokenDecimals;

    // ==> MAINNET TEST
    /*
    const testWallet =
      'erd1ed88txejyqfdehutrrz5j5ss2acstr59pcxljcqueey62080ggwq8qhrgh';
      */

    const ESDT_hex = strHex(tokenIdentifier);
    const amount_hex = numHex(number);
    const dataESDT = 'ESDTTransfer' + '@' + ESDT_hex + '@' + amount_hex;

    const tokenData: ITokenInfos = {
      identifier: amountTokenIdentifier,
      name: tokenName,
      amount: parseInt(amountToken)
    };

    await refreshAccount();
    const { sessionId } = await sendTransactions({
      transactions: [
        {
          value: '0',
          gasLimit: 500000,
          data: btoa(dataESDT),
          receiver: jcorpWallet // ==> MAINNET
          //receiver: testWallet // ==> MAINNET
        }
      ],
      //callbackRoute: routeNames.wallet,
      //signWithoutSending: true,
      /*sessionInformation: {
        userId: userInfos.user.id_user,
        type: 'item',
        data: item
      },*/
      transactionsDisplayInfo: {
        processingMessage: `Deposit of ${amountToken} ${tokenName}`,
        successMessage: `${amountToken} ${tokenName} deposited`
      },
      redirectAfterSign: false
    });

    if (sessionId != null) {
      setTransactionTokenInfos(tokenData);
      setTransactionSessionId(sessionId);
    }
  };

  CheckStatus();

  async function CheckStatus() {
    const transactionStatus = useTrackTransactionStatus({
      transactionId: transactionSessionId
    });

    if (
      transactionSessionId != null &&
      transactionTokenInfos != null &&
      transactionStatus.transactions != null
    ) {
      if (!showModal) setShowModal(true);

      if (transactionStatus.isSuccessful) {
        setTransactionSessionId(null);
        txCreation(transactionStatus.transactions[0], transactionTokenInfos);
      }

      if (transactionStatus.isFailed || transactionStatus.isCancelled) {
        setTransactionSessionId(null);
        setShowModal(false);
      }
    }
  }

  function txCreation(transaction: any, tokenInfos: any) {
    const dataWalletEvent: IWalletEventData = {
      id_user: userSession.userID,
      group_operation: 300,
      type_operation: 310,
      num_event: 0,
      amount: tokenInfos.amount,
      token_identifier: tokenInfos.identifier,
      tx: transaction.hash,
      status: transaction.status,
      flg_blockchain: true
    };

    WalletsEventsDataService.createEvent(dataWalletEvent).then(({ data }) => {
      if (data.status == 'success') {
        setActionSuccess(true);
        handleCloseModal();

        refToTop.current &&
          refToTop.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
      } else {
        setMessage(data.message);
      }
    });
  }

  const handleWithdraw = () => {
    const dataWalletEvent: IWalletEventData = {
      id_user: userSession.userID,
      group_operation: 400,
      type_operation: 410,
      num_event: 0,
      amount: parseInt(amountToken),
      token_identifier: amountTokenIdentifier,
      tx: '',
      status: 'processing',
      flg_blockchain: true
    };

    WalletsEventsDataService.createEvent(dataWalletEvent).then(({ data }) => {
      if (data.status == 'success') {
        setActionSuccess(true);
        fetchWalletsData();
        fetchWalletsEventsData();

        refToTop.current &&
          refToTop.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
      } else {
        setMessage(data.message);
      }
    });
  };

  const handleSend = () => {
    const dataWalletEvent: IWalletEventData = {
      id_user: userSession.userID,
      group_operation: 400,
      type_operation: 420,
      num_event: 0,
      amount: parseInt(amountToken),
      token_identifier: amountTokenIdentifier,
      tx: '',
      status: 'processing',
      flg_blockchain: false
    };

    WalletsEventsDataService.createEvent(dataWalletEvent).then(({ data }) => {
      if (data.status == 'success') {
        setActionSuccess(true);
        fetchWalletsData();
        fetchWalletsEventsData();

        refToTop.current &&
          refToTop.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
      } else {
        setMessage(data.message);
      }
    });
  };

  const fetchTokensInfosData = () => {
    getTokensList({
      tokensList
    }).then(({ data, success }) => {
      setListTokens({
        tokens: data,
        tokensFetched: success
      });
    });
  };

  const fetchTokensBalanceData = async () => {
    /*
    const refreshAccountData = await refreshAccount();
    let refreshBalance = '0';
    if (refreshAccountData != null) {
      refreshBalance = refreshAccountData.balance;
    }

    let balance = '';
    const decimals = 10 ** 18;
    const balanceValue = parseInt(refreshBalance) / decimals;
    balance = balanceValue.toLocaleString('en-GB', {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    });
    setAccountBalance(balance);
    */

    getTokens({
      address: userSession.userAccount,
      tokensWallet
    }).then(({ data, success }) => {
      setBalanceTokens({
        tokens: data,
        tokensFetched: success
      });
    });
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'walletAvailable';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.setting.value == 0) {
          setWalletAvailable(false);
        } else {
          setWalletAvailable(true);
        }
      }
    });
  };

  const fetchWalletsData = () => {
    WalletsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setWalletsInfos({
          wallets: data.wallets,
          status: data.status
        });
      }
    });
  };

  const fetchWalletsEventsData = () => {
    const params: any = {};
    params['group_operation'] = groupOperation;
    params['page'] = page - 1;
    params['size'] = pageSize;

    WalletsEventsDataService.findAllByUser(userSession.userID, params).then(
      ({ data }) => {
        if (data.status == 'success') {
          setWalletsEventsInfos({
            totalItems: data.totalItems,
            totalPages: data.totalPages,
            wallets_events: data.wallets_events,
            status: data.status
          });
        }
      }
    );
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const handleCheckBalance = () => {
    if (
      listTokens.tokensFetched != undefined &&
      balanceTokens.tokensFetched != undefined
    ) {
      if (listTokens.tokensFetched && balanceTokens.tokensFetched) {
        setIsBalance(true);
      } else {
        setIsBalance(false);
      }
    }
  };

  const handleRefreshBalance = () => {
    fetchTokensInfosData();
    fetchTokensBalanceData();
  };

  React.useEffect(fetchSettingData, []);
  React.useEffect(() => {
    if (userSession.isBlockchainAccount) {
      fetchTokensInfosData();
      fetchTokensBalanceData();
    } else {
      //setAccountBalance(0);
    }
    fetchWalletsData();
    fetchFactionInfos();
  }, []);

  React.useEffect(() => {
    fetchWalletsEventsData();
  }, [page, groupOperation]);

  React.useEffect(() => {
    if (userSession.isBlockchainAccount) {
      if (
        walletsInfos.status == 'success' &&
        balanceTokens.tokensFetched != undefined
      ) {
        handleInitTokenAction();
      }
    } else {
      if (walletsInfos.status == 'success') {
        handleInitTokenAction();
      }
    }
  }, [walletsInfos, balanceTokens]);

  React.useEffect(() => {
    handleCheckBalance();
  }, [listTokens, balanceTokens]);

  React.useEffect(() => {
    if (userSession.isBlockchainAccount) {
      if (
        walletsInfos.status == 'success' &&
        balanceTokens.tokensFetched != undefined &&
        tokenList.status == true
      ) {
        setIsLoading(false);
      }
    } else {
      if (walletsInfos.status == 'success' && tokenList.status == true) {
        setIsLoading(false);
      }
    }
  }, [walletsInfos, balanceTokens, tokenList]);

  const { wallets } = walletsInfos;
  const { tokenInfos } = tokenList;
  const { totalPages, wallets_events } = walletsEventsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='pageTitle'>My Wallet</div>
              </div>
              <div className='infosBoxSub'>
                {!userSession.isBlockchainAccount ? (
                  <div className='buttonsNFTContainer'>
                    {showWallet ? (
                      <React.Fragment>
                        <Button
                          className='btnStandardReverse'
                          onClick={handleShowWallet}
                        >
                          Wallet
                        </Button>
                        <Button
                          className='btnStandard'
                          onClick={handleShowOperations}
                        >
                          Operations
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          className='btnStandard'
                          onClick={handleShowWallet}
                        >
                          Wallet
                        </Button>
                        <Button
                          className='btnStandardReverse'
                          onClick={handleShowOperations}
                        >
                          Operations
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {userSession.isBlockchainAccount ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div>
                  <div className='infosBox'>
                    <div className='infosWallet'>{userAccountMVX}</div>
                  </div>
                  <div className='infosBoxSub'>
                    <div className='buttonsNFTContainer'>
                      {showWallet ? (
                        <React.Fragment>
                          <Button
                            className='btnStandardReverse'
                            onClick={handleShowWallet}
                          >
                            Wallet
                          </Button>
                          <Button
                            className='btnStandard'
                            onClick={handleShowOperations}
                          >
                            Operations
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Button
                            className='btnStandard'
                            onClick={handleShowWallet}
                          >
                            Wallet
                          </Button>
                          <Button
                            className='btnStandardReverse'
                            onClick={handleShowOperations}
                          >
                            Operations
                          </Button>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showWallet ? (
            <React.Fragment>
              {isLoading ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body text-center p-2'>
                    <div className='lds-facebook'>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className='boxContainer'>
                      <div className='infosModalTx'>Wallet loading ...</div>
                    </div>
                  </div>
                </div>
              ) : null}

              {message ? (
                <div className='boxContainer topContainer' ref={refToTop}>
                  <div className='card-body p-2'>
                    <div className='statusAlert'>
                      An error has occurred :
                      <br />
                      {message}
                    </div>
                  </div>
                </div>
              ) : null}

              {actionSuccess ? (
                <React.Fragment>
                  {actionType == 1 ? (
                    <div className='boxContainer topContainer' ref={refToTop}>
                      <div className='card-body p-2'>
                        <div className='statusSuccess'>
                          {parseInt(amountToken).toLocaleString('en-GB', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })}
                          &nbsp;{amountTokenName} successfully deposited !
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {actionType == 2 ? (
                    <div className='boxContainer topContainer' ref={refToTop}>
                      <div className='card-body p-2'>
                        <div className='statusSuccess'>
                          Withdrawal of&nbsp;
                          {parseInt(amountToken).toLocaleString('en-GB', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })}
                          &nbsp;{amountTokenName} in queue.
                          <br />
                          The withdrawal will be processed within the next 10
                          &nbsp;minutes.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {actionType == 3 ? (
                    <div className='boxContainer topContainer' ref={refToTop}>
                      <div className='card-body p-2'>
                        <div className='statusSuccess'>
                          {parseInt(amountToken).toLocaleString('en-GB', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })}
                          &nbsp;{amountTokenName} successfully sent !
                        </div>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}

              {userSession.isBlockchainAccount && walletAvailable == false && (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='statusWarning'>
                      Deposit and Withdraw are currently paused.
                      <br />
                      Thank you for your understanding.
                    </div>
                  </div>
                </div>
              )}

              {!isLoading && userSession.isBlockchainAccount ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>MultiversX Balance</div>
                    </div>
                    {isBalance ? (
                      <React.Fragment>
                        {wallets.map((wallet, i) => {
                          let available = false;
                          let tokenName = '';
                          let balanceValue = 0;

                          tokensListNames.map((token) => {
                            if (
                              token.token_identifier == wallet.token_identifier
                            ) {
                              available = true;
                              tokenName = token.name;
                            }
                          });

                          balanceTokens.tokens.map((balanceToken) => {
                            if (
                              balanceToken.identifier == wallet.token_identifier
                            ) {
                              const decimals = 10 ** balanceToken.decimals;
                              const formatBalance =
                                balanceToken.balance / decimals;
                              balanceValue = formatBalance;
                            }
                          });

                          const balance = balanceValue.toLocaleString('en-GB', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          let tokenInfo: any;
                          for (let j = 0; j < tokenInfos.length; j++) {
                            if (tokenInfos[j][2] == wallet.token_identifier) {
                              tokenInfo = tokenInfos[j];
                            }
                          }

                          if (available) {
                            return (
                              <div className='walletContainer' key={i}>
                                <div className='walletText'>
                                  <div className='infosBalance'>
                                    {balance}&nbsp;
                                    {tokenName}
                                  </div>
                                </div>
                                <div className='walletButton'>
                                  {balanceValue > 0 && walletAvailable ? (
                                    <Button
                                      className='btnStandardSmallReverse'
                                      onClick={() =>
                                        handleTokenAction(
                                          wallet.token_identifier,
                                          1
                                        )
                                      }
                                    >
                                      Deposit
                                    </Button>
                                  ) : (
                                    <Button
                                      disabled
                                      className='btnStandardSmallReverse'
                                    >
                                      Deposit
                                    </Button>
                                  )}
                                </div>
                                {tokenInfo[0] == true && actionType == 1 ? (
                                  <div className='walletForm'>
                                    <Form.Group>
                                      <Form.Row>
                                        <Form.Label className='textTitle formLabel'>
                                          Amount to {actionName}
                                        </Form.Label>
                                      </Form.Row>
                                      {amountTokenError ? (
                                        <Form.Row>
                                          <Form.Label className='textError formLabel'>
                                            {amountTokenError}
                                          </Form.Label>
                                        </Form.Row>
                                      ) : null}
                                      <Form.Row>
                                        <Col>
                                          <Form.Control
                                            className='textAttribut'
                                            type='text'
                                            value={amountToken}
                                            onChange={(e) =>
                                              handleAmountToken(
                                                wallet.token_identifier,
                                                e
                                              )
                                            }
                                          />
                                        </Col>
                                      </Form.Row>
                                      <div className='walletFormButtons'>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              25
                                            )
                                          }
                                        >
                                          25 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              50
                                            )
                                          }
                                        >
                                          50 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              75
                                            )
                                          }
                                        >
                                          75 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              100
                                            )
                                          }
                                        >
                                          100 %
                                        </Button>
                                      </div>
                                    </Form.Group>
                                    {tokenInfo[4] == true ? (
                                      <Button
                                        className='navStandardSmallReverse'
                                        onClick={handleAction}
                                      >
                                        Confirm
                                      </Button>
                                    ) : (
                                      <Button
                                        disabled
                                        className='navStandardSmallReverse'
                                      >
                                        Confirm
                                      </Button>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            );
                          }
                        })}
                      </React.Fragment>
                    ) : (
                      <div>
                        {isBalance == false && (
                          <Button
                            className='btnStandardReverse'
                            onClick={handleRefreshBalance}
                          >
                            Refresh Balance
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {!isLoading ? (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>
                        Rise of Factions Balance
                      </div>
                    </div>
                    {wallets.map((wallet, i) => {
                      let available = false;
                      let tokenName = '';
                      let balanceValue = 0;

                      tokensListNames.map((token) => {
                        if (token.token_identifier == wallet.token_identifier) {
                          available = true;
                          balanceValue = wallet.amount;
                          tokenName = token.name;
                        }
                      });

                      const balance = balanceValue.toLocaleString('en-GB', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      });

                      let tokenInfo: any;
                      for (let j = 0; j < tokenInfos.length; j++) {
                        if (tokenInfos[j][2] == wallet.token_identifier) {
                          tokenInfo = tokenInfos[j];
                        }
                      }

                      if (available) {
                        return (
                          <div className='walletContainer' key={i}>
                            <div className='walletText'>
                              <div className='infosBalance'>
                                {balance}&nbsp;
                                {tokenName}
                              </div>
                            </div>
                            {userSession.isBlockchainAccount ? (
                              <div className='walletButton'>
                                {balanceValue > 0 && walletAvailable ? (
                                  <Button
                                    className='btnStandardSmallReverse'
                                    onClick={() =>
                                      handleTokenAction(
                                        wallet.token_identifier,
                                        2
                                      )
                                    }
                                  >
                                    Withdraw
                                  </Button>
                                ) : (
                                  <Button
                                    disabled
                                    className='btnStandardSmallReverse'
                                  >
                                    Withdraw
                                  </Button>
                                )}
                                {balanceValue > 0 ? (
                                  <Button
                                    className='btnStandardSmall'
                                    onClick={() =>
                                      handleTokenAction(
                                        wallet.token_identifier,
                                        3
                                      )
                                    }
                                  >
                                    Send
                                  </Button>
                                ) : (
                                  <Button disabled className='btnStandardSmall'>
                                    Send
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div className='walletButton'>
                                {balanceValue > 0 ? (
                                  <Button
                                    className='btnStandardSmallReverse'
                                    onClick={() =>
                                      handleTokenAction(
                                        wallet.token_identifier,
                                        3
                                      )
                                    }
                                  >
                                    Send
                                  </Button>
                                ) : (
                                  <Button
                                    disabled
                                    className='btnStandardSmallReverse'
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            )}
                            {tokenInfo[0] == true ? (
                              <React.Fragment>
                                {actionType == 2 || actionType == 3 ? (
                                  <div className='walletForm'>
                                    <Form.Group>
                                      <Form.Row>
                                        <Form.Label className='textTitle formLabel'>
                                          Amount to {actionName}
                                        </Form.Label>
                                      </Form.Row>
                                      {amountTokenError ? (
                                        <Form.Row>
                                          <Form.Label className='textError formLabel'>
                                            {amountTokenError}
                                          </Form.Label>
                                        </Form.Row>
                                      ) : null}
                                      <Form.Row>
                                        <Col>
                                          <Form.Control
                                            className='textAttribut'
                                            type='text'
                                            value={amountToken}
                                            onChange={(e) =>
                                              handleAmountToken(
                                                wallet.token_identifier,
                                                e
                                              )
                                            }
                                          />
                                        </Col>
                                      </Form.Row>
                                      <div className='walletFormButtons'>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              25
                                            )
                                          }
                                        >
                                          25 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              50
                                            )
                                          }
                                        >
                                          50 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              75
                                            )
                                          }
                                        >
                                          75 %
                                        </Button>
                                        <Button
                                          className='btnStandardTiny'
                                          onClick={() =>
                                            handleAmountTokenPercent(
                                              wallet.token_identifier,
                                              100
                                            )
                                          }
                                        >
                                          100 %
                                        </Button>
                                      </div>
                                      {actionType == 3 ? (
                                        <React.Fragment>
                                          <Form.Row className='margin10'>
                                            <Form.Label className='textTitle formLabel'>
                                              Send to
                                            </Form.Label>
                                          </Form.Row>
                                          <Form.Row>
                                            <RadioGroup
                                              value={sendType}
                                              onChange={handleChangeSend}
                                            >
                                              <div className='radioFormRow'>
                                                <FormControlLabel
                                                  className='radioFormButton'
                                                  value='faction'
                                                  control={<Radio />}
                                                  label=''
                                                />
                                                <Form.Label className='radioFormImg'>
                                                  <img
                                                    src={`${factionImg}`}
                                                    className='imgFactionProfile'
                                                  />
                                                </Form.Label>
                                                <Form.Label className='textTitle radioFormLabel'>
                                                  <div>
                                                    {
                                                      userInfos.user.faction
                                                        ?.name
                                                    }
                                                  </div>
                                                  <div>Faction Bank</div>
                                                </Form.Label>
                                              </div>
                                            </RadioGroup>
                                          </Form.Row>
                                        </React.Fragment>
                                      ) : null}
                                    </Form.Group>
                                    {tokenInfo[4] == true ? (
                                      <Button
                                        className='navStandardSmallReverse'
                                        onClick={handleAction}
                                      >
                                        Confirm
                                      </Button>
                                    ) : (
                                      <Button
                                        disabled
                                        className='navStandardSmallReverse'
                                      >
                                        Confirm
                                      </Button>
                                    )}
                                  </div>
                                ) : null}
                              </React.Fragment>
                            ) : null}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}

          {showOperations ? (
            <div className='boxContainer topContainer' ref={refToTop}>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>Operations Information</div>
                </div>
                <div className='whiteBox text-center'>
                  {groupOperation == 0 ? (
                    <Button
                      className='navStandardSmallReverse'
                      onClick={() => handleGroupOperationChange(0)}
                    >
                      All
                    </Button>
                  ) : (
                    <Button
                      className='navStandardSmall'
                      onClick={() => handleGroupOperationChange(0)}
                    >
                      All
                    </Button>
                  )}
                  {groupOperation == 100 ? (
                    <Button
                      className='navStandardSmallReverse'
                      onClick={() => handleGroupOperationChange(100)}
                    >
                      Rewards
                    </Button>
                  ) : (
                    <Button
                      className='navStandardSmall'
                      onClick={() => handleGroupOperationChange(100)}
                    >
                      Rewards
                    </Button>
                  )}
                  {groupOperation == 200 ? (
                    <Button
                      className='navStandardSmallReverse'
                      onClick={() => handleGroupOperationChange(200)}
                    >
                      Marketplace
                    </Button>
                  ) : (
                    <Button
                      className='navStandardSmall'
                      onClick={() => handleGroupOperationChange(200)}
                    >
                      Marketplace
                    </Button>
                  )}
                  <React.Fragment>
                    {groupOperation == 300 ? (
                      <Button
                        className='navStandardSmallReverse'
                        onClick={() => handleGroupOperationChange(300)}
                      >
                        Incoming
                      </Button>
                    ) : (
                      <Button
                        className='navStandardSmall'
                        onClick={() => handleGroupOperationChange(300)}
                      >
                        Incoming
                      </Button>
                    )}
                  </React.Fragment>
                  {groupOperation == 400 ? (
                    <Button
                      className='navStandardSmallReverse'
                      onClick={() => handleGroupOperationChange(400)}
                    >
                      Outgoing
                    </Button>
                  ) : (
                    <Button
                      className='navStandardSmall'
                      onClick={() => handleGroupOperationChange(400)}
                    >
                      Outgoing
                    </Button>
                  )}
                </div>
              </div>
              <div className='card-body text-center p-2'>
                <div className='table-responsive'>
                  {userSession.isBlockchainAccount ? (
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>Date</th>
                          <th className='border-0'>Type</th>
                          <th className='border-0'>Event</th>
                          <th className='border-0'>Amount</th>
                          <th className='border-0'>Status</th>
                          <th className='border-0'>Transaction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wallets_events.map((wallets_event, i) => {
                          let timeDate = '';
                          if (wallets_event.createdAt != null) {
                            const time = new Date(wallets_event.createdAt);
                            timeDate =
                              time.toLocaleDateString() +
                              ' ' +
                              time.toLocaleTimeString();
                          }

                          let shop;
                          if (wallets_event.shop != null) {
                            shop = wallets_event.shop;
                          }

                          let type = '';
                          let iconName = faPlusSquare;
                          let colorName = '';
                          switch (wallets_event.type_operation) {
                            case 110:
                            case 120:
                            case 130:
                              type = 'Rewards';
                              colorName = 'bonusUp';
                              iconName = faPlusSquare;
                              break;
                            case 210:
                              type = 'Marketplace';
                              colorName = 'bonusDown';
                              iconName = faMinusSquare;
                              break;
                            case 310:
                              type = 'Deposit';
                              colorName = 'bonusUp';
                              iconName = faPlusSquare;
                              break;
                            case 410:
                              type = 'Withdraw';
                              colorName = 'bonusDown';
                              iconName = faMinusSquare;
                              break;
                            case 420:
                              type = 'Send';
                              colorName = 'bonusDown';
                              iconName = faMinusSquare;
                              break;
                          }

                          let event = '';
                          switch (wallets_event.type_operation) {
                            case 110:
                              event = 'Conquest #' + wallets_event.num_event;
                              break;
                            case 120:
                              event =
                                'Conquest Leaderboard Season ' +
                                wallets_event.num_event;
                              break;
                            case 130:
                              event =
                                'Arena Leaderboard Season ' +
                                wallets_event.num_event;
                              break;
                            case 210:
                              if (shop) {
                                event =
                                  shop.item.item_name + ' x' + shop.quantity;
                              }
                              break;
                            case 420:
                              event = 'Faction Bank';
                              break;
                          }

                          let token_name = '';
                          if (wallets_event.token_identifier == 'EGLD') {
                            token_name = 'EGLD';
                          } else {
                            tokensListNames.map((token) => {
                              if (
                                wallets_event.token_identifier ==
                                token.token_identifier
                              ) {
                                token_name = token.name;
                              }
                            });
                          }

                          const amount = wallets_event.amount.toLocaleString(
                            'en-GB',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          );

                          let status = wallets_event.status.toLowerCase();
                          let badgeClass = '';
                          switch (status) {
                            case 'queue':
                              status = 'in queue';
                              badgeClass = 'badgeDefault';
                              break;
                            case 'pending':
                              badgeClass = 'badgePending';
                              break;
                            case 'success':
                              badgeClass = 'badgeSuccess';
                              break;
                            case 'fail':
                            case 'failed':
                              badgeClass = 'badgeFail';
                              break;
                            default:
                              badgeClass = 'badgeDefault';
                              break;
                          }

                          let link = '';
                          if (
                            wallets_event.flg_blockchain &&
                            wallets_event.tx != ''
                          ) {
                            link =
                              explorerAddress +
                              '/transactions/' +
                              wallets_event.tx;
                          }

                          return (
                            <tr key={i} className='rowTableContent textResult'>
                              <td>
                                <span>{timeDate}</span>
                              </td>
                              <td>
                                <span>{type}</span>
                              </td>
                              <td>
                                <span>{event}</span>
                              </td>
                              <td>
                                <span className={colorName}>
                                  <FontAwesomeIcon icon={iconName} />
                                </span>
                                &nbsp;
                                <span>
                                  {amount} {token_name}
                                </span>
                              </td>
                              <td>
                                <span className={badgeClass}>
                                  <span className='badgeInfos'>{status}</span>
                                </span>
                              </td>
                              <td>
                                {link != '' ? (
                                  <a
                                    href={link}
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    <Button className='navStandardTiny'>
                                      Open
                                    </Button>
                                  </a>
                                ) : (
                                  <span>/</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {wallets_events.length == 0 && (
                          <tr className='rowTableContent textResult'>
                            <td colSpan={6}>
                              <span>No operation</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <table className='transactions table pb-3'>
                      <thead>
                        <tr className='headTable'>
                          <th className='border-0'>Date</th>
                          <th className='border-0'>Type</th>
                          <th className='border-0'>Event</th>
                          <th className='border-0'>Amount</th>
                          <th className='border-0'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wallets_events.map((wallets_event, i) => {
                          let timeDate = '';
                          if (wallets_event.createdAt != null) {
                            const time = new Date(wallets_event.createdAt);
                            timeDate =
                              time.toLocaleDateString() +
                              ' ' +
                              time.toLocaleTimeString();
                          }

                          let shop;
                          if (wallets_event.shop != null) {
                            shop = wallets_event.shop;
                          }

                          let type = '';
                          let iconName = faPlusSquare;
                          let colorName = '';
                          switch (wallets_event.type_operation) {
                            case 110:
                            case 120:
                            case 130:
                              type = 'Rewards';
                              colorName = 'bonusUp';
                              iconName = faPlusSquare;
                              break;
                            case 210:
                              type = 'Marketplace';
                              colorName = 'bonusDown';
                              iconName = faMinusSquare;
                              break;
                            case 420:
                              type = 'Send';
                              colorName = 'bonusDown';
                              iconName = faMinusSquare;
                              break;
                          }

                          let event = '';
                          switch (wallets_event.type_operation) {
                            case 110:
                              event = 'Conquest #' + wallets_event.num_event;
                              break;
                            case 120:
                              event =
                                'Conquest Leaderboard Season ' +
                                wallets_event.num_event;
                              break;
                            case 130:
                              event =
                                'Arena Leaderboard Season ' +
                                wallets_event.num_event;
                              break;
                            case 210:
                              if (shop) {
                                event =
                                  shop.item.item_name + ' x' + shop.quantity;
                              }
                              break;
                            case 420:
                              event = 'Faction Bank';
                              break;
                          }

                          let token_name = '';
                          tokensListNames.map((token) => {
                            if (
                              wallets_event.token_identifier ==
                              token.token_identifier
                            ) {
                              token_name = token.name;
                            }
                          });

                          const amount = wallets_event.amount.toLocaleString(
                            'en-GB',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          );

                          let status = wallets_event.status.toLowerCase();
                          let badgeClass = '';
                          switch (status) {
                            case 'queue':
                              status = 'in queue';
                              badgeClass = 'badgeDefault';
                              break;
                            case 'pending':
                              badgeClass = 'badgePending';
                              break;
                            case 'success':
                              badgeClass = 'badgeSuccess';
                              break;
                            case 'fail':
                            case 'failed':
                              badgeClass = 'badgeFail';
                              break;
                            default:
                              badgeClass = 'badgeDefault';
                              break;
                          }

                          return (
                            <tr key={i} className='rowTableContent textResult'>
                              <td>
                                <span>{timeDate}</span>
                              </td>
                              <td>
                                <span>{type}</span>
                              </td>
                              <td>
                                <span>{event}</span>
                              </td>
                              <td>
                                <span className={colorName}>
                                  <FontAwesomeIcon icon={iconName} />
                                </span>
                                &nbsp;
                                <span>
                                  {amount} {token_name}
                                </span>
                              </td>
                              <td>
                                <span className={badgeClass}>
                                  <span className='badgeInfos'>{status}</span>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        {wallets_events.length == 0 && (
                          <tr className='rowTableContent textResult'>
                            <td colSpan={5}>
                              <span>No operation</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

              <React.Fragment>
                {totalPages > 1 && (
                  <div className='paginBoxBottom'>
                    <Pagination
                      className='paginContainer'
                      count={totalPages}
                      defaultPage={1}
                      page={page}
                      siblingCount={1}
                      boundaryCount={1}
                      shape='rounded'
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </React.Fragment>
            </div>
          ) : null}
        </div>
      </div>
      <Modal show={showModal} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body text-center p-2'>
            <div className='lds-facebook'>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className='boxContainer'>
              <div className='infosModalTx'>Transaction processing ...</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Wallet;
