import http from '../http-common';
import { MetaStateType } from '../types/meta.type';

class MetaDataService {
  update(params: any) {
    return http.get<any>('/meta/update', { params });
  }

  read(params: any) {
    return http.get<MetaStateType>('/meta/read', { params });
  }
}

export default new MetaDataService();
