import * as React from 'react';
import {
  faChevronUp,
  faChevronDown,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, MenuItem } from '@mui/material';
import { Modal, Button, Col, Form } from 'react-bootstrap';
import ImageMapper from 'react-img-mapper';
import { ResizeObserver } from 'resize-observer';
import AreasFonctions from '../../../common/areas';
import AreaDataService from '../../../services/area.service';
import ConquestLogsDataService from '../../../services/conquestslogs.service';
import FactionDataService from '../../../services/faction.service';
import SettingDataService from '../../../services/setting.service';
import {
  ColorsStateType,
  colorsEmpty,
  AreasStateType
} from '../../../types/area.type';
import {
  ConquestsLogsInfos,
  conquestEmpty
} from '../../../types/conquestslogs.type';
import { FactionsStateType } from '../../../types/faction.type';
import angelic from './../../../assets/img/angelic.png';
import meiyo from './../../../assets/img/meiyo.png';
import night from './../../../assets/img/night.png';
import reiwa from './../../../assets/img/reiwa.png';

const History = () => {
  const nbHistory = 5;

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [selectedConquest, setSelectedConquest] = React.useState(0);
  const [latestConquests, setLatestConquests] = React.useState({
    id_conquest: []
  });

  const [selectedArea, setSelectedArea] = React.useState({
    id_area: 0,
    name: '',
    id_faction: 0
  });

  const mapMaxWidth = 2048;
  const mapMaxHeight = mapMaxWidth * 0.75;
  const [mapDimensions, setMapDimensions] = React.useState({
    width: 0,
    height: 0,
    style: {
      '--mapWidth': '0px',
      '--mapHeight': '0px',
      '--mapRatioWidth': 0,
      '--mapRatioHeight': 0
    } as React.CSSProperties
  });
  const mapFieldSelector = document.querySelector('.mapField');
  const mapContainerSize = React.useRef<HTMLDivElement>(null);

  const [AreasInfos, setAreasInfos] = React.useState<AreasStateType>({
    areas: [],
    message: '',
    status: ''
  });
  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });

  const [AREAS_MAP, setAreasMap] = React.useState<any>({
    name: '',
    areas: []
  });
  const [conquestLogsInfos, setConquestLogsInfos] =
    React.useState<ConquestsLogsInfos>({
      conquests_logs: []
    });

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result != null) {
      return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      };
    }
  }

  const [showResults, setShowResults] = React.useState(false);
  const handleCloseDeploy = () => {
    setShowResults(false);
  };
  const handleShowDeploy = (areaId: any) => {
    switch (areaId) {
      case 1:
      case 2:
      case 3:
        setShowResults(false);
        break;
      default:
        AreasInfos.areas.map((area) => {
          if (area.id_area == areaId) {
            setSelectedArea({
              id_area: area.id_area,
              name: area.name,
              id_faction: area.id_faction
            });
          }
        });
        setShowResults(true);
        break;
    }
  };

  const fetchAreasData = () => {
    AreaDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setAreasInfos({
          areas: data.areas,
          status: data.status
        });
      }
    });
  };

  const fetchConquestLogsData = () => {
    ConquestLogsDataService.findAllByConquest(selectedConquest).then(
      ({ data }) => {
        if (data.status == 'success') {
          let prevArea = 0;
          let imgFaction = '';
          let dataConquest = conquestEmpty;
          const logs: ConquestsLogsInfos = {
            conquests_logs: []
          };
          const colorArea: ColorsStateType = {
            colors: colorsEmpty
          };
          AreasInfos.areas.map((area) => {
            switch (area.id_area) {
              case 1:
              case 2:
              case 3:
                colorArea.colors.color[area.id_area] = area.faction.color;
                colorArea.colors.colorHover[area.id_area] =
                  area.faction.colorHover;
                break;
              default:
                break;
            }
          });
          data.conquests_logs.map((logsInfos) => {
            if (logsInfos.id_area != prevArea) {
              if (prevArea != 0) {
                logs.conquests_logs.push(dataConquest);

                colorArea.colors.color[dataConquest.id_area] =
                  dataConquest.color;
                colorArea.colors.colorHover[dataConquest.id_area] =
                  dataConquest.colorHover;
              }

              prevArea = logsInfos.id_area;

              dataConquest = {
                id_area: logsInfos.id_area,
                name_area: logsInfos.area.name,
                id_faction: 0,
                name_faction: 'Bandits',
                color: '#95A5A6',
                colorHover: '#BFC9CA',
                infos: [],
                showResult: false
              };
            }
            if (logsInfos.id_faction_winner == logsInfos.id_faction) {
              dataConquest.id_faction = logsInfos.id_faction;
              dataConquest.name_faction = logsInfos.faction.name;
              dataConquest.color = logsInfos.faction.color;
              dataConquest.colorHover = logsInfos.faction.colorHover;
            }
            switch (logsInfos.id_faction) {
              case 1:
                imgFaction = angelic;
                break;
              case 2:
                imgFaction = night;
                break;
              case 3:
                imgFaction = meiyo;
                break;
            }
            dataConquest.infos.push({
              id_faction: logsInfos.id_faction,
              name: logsInfos.faction.name,
              img: imgFaction,
              bonus_value: logsInfos.bonus_value,
              malus_value: logsInfos.malus_value,
              tot_power: logsInfos.tot_power
            });
          });
          if (dataConquest.id_area != 0) {
            logs.conquests_logs.push(dataConquest);

            colorArea.colors.color[dataConquest.id_area] = dataConquest.color;
            colorArea.colors.colorHover[dataConquest.id_area] =
              dataConquest.colorHover;
          }
          setConquestLogsInfos(logs);
          setAreasMap(AreasFonctions.getAreasMap(colorArea));
        }
      }
    );
  };

  const handleShowResult = (id_area: any) => {
    const logs: ConquestsLogsInfos = {
      conquests_logs: conquestLogsInfos.conquests_logs
    };
    logs.conquests_logs.map((infos) => {
      if (infos.id_area == id_area) {
        if (infos.showResult == true) {
          infos.showResult = false;
        } else {
          infos.showResult = true;
        }
      }
    });
    setConquestLogsInfos(logs);
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  /*const fetchDimensions = () => {
    if (mapContainerSize.current) {
      setMapDimensions({
        width: mapContainerSize.current.offsetWidth,
        height: mapContainerSize.current.offsetHeight,
        style: {
          '--mapWidth': mapContainerSize.current.offsetWidth + 'px',
          '--mapHeight': mapContainerSize.current.offsetHeight + 'px',
          '--mapRatioWidth': 0,
          '--mapRatioHeight': 0
        } as React.CSSProperties
      });
    }
  };*/

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const fetchLatestConquests = () => {
    let num = currentConquest;
    const conquests: any = {
      id_conquest: []
    };

    for (let i = 0; i < nbHistory; i++) {
      num--;
      if (num > 0) {
        if (i == 0) setSelectedConquest(num);
        conquests.id_conquest.push(num);
      }
    }
    setLatestConquests(conquests);
  };

  const ro = new ResizeObserver((entries: any) => {
    for (const entry of entries) {
      switch (entry.target.className) {
        case 'mapField':
          const mapWidth = entry.contentRect.width;
          const mapHeight = entry.contentRect.width * 0.75;
          const mapRatioWidth = mapWidth / mapMaxWidth;
          const mapRatioHeight = mapHeight / mapMaxHeight;

          setMapDimensions({
            width: mapWidth,
            height: mapHeight,
            style: {
              '--mapWidth': mapWidth + 'px',
              '--mapHeight': mapHeight + 'px',
              '--mapRatioWidth': mapRatioWidth,
              '--mapRatioHeight': mapRatioHeight
            } as React.CSSProperties
          });
          break;
      }
    }
  });

  React.useEffect(() => {
    if (mapFieldSelector) {
      ro.observe(mapFieldSelector);
    }
  }, [mapFieldSelector, document.onresize]);

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (currentConquest != 0) {
      fetchAreasData();
      //fetchDimensions();
      fetchFactionsData();
      fetchLatestConquests();
    }
  }, [currentConquest]);

  React.useEffect(() => {
    if (selectedConquest != 0) {
      fetchConquestLogsData();
    }
  }, [AreasInfos, selectedConquest]);

  const { factions } = factionsInfos;
  const { conquests_logs } = conquestLogsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='pageTitle'>History of Conquests</div>
              </div>
              <div className='infosBoxSub'>
                <div className='historyContainer'>
                  <div className='textImportant'>
                    Choose one of the last conquests to see its history
                  </div>
                  <div className='historySelection'>
                    <Form.Group className='leadMenu'>
                      <Form.Row>
                        <Col xs='auto'>
                          <TextField
                            select
                            value={selectedConquest}
                            variant='standard'
                            onChange={(e) =>
                              setSelectedConquest(parseInt(e.target.value))
                            }
                          >
                            {latestConquests.id_conquest.map(
                              (id_conquest, i) => {
                                return (
                                  <MenuItem key={i} value={id_conquest}>
                                    Conquest #{id_conquest}
                                  </MenuItem>
                                );
                              }
                            )}
                          </TextField>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div
              className='mapField'
              ref={mapContainerSize}
              style={mapDimensions.style}
            >
              <ImageMapper
                src={reiwa}
                map={AREAS_MAP}
                width={mapDimensions.width}
                imgWidth={2048}
                onClick={(area) => handleShowDeploy(area.id)}
              />
              <div className='factionsContainer1'>
                {factions.map((faction, i) => {
                  const color = hexToRgb(faction.color);
                  const factionColor =
                    color?.red + ',' + color?.green + ',' + color?.blue;
                  const divStyle = {
                    '--factionColor': factionColor
                  } as React.CSSProperties;

                  return (
                    <div className='factionsInfos1' style={divStyle} key={i}>
                      {faction.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Results</div>
              </div>
              <div className='table-responsive text-center'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0 iconCol'></th>
                      <th className='border-0'>Area</th>
                      <th className='border-0'>Faction</th>
                    </tr>
                  </thead>
                  {conquests_logs.length == 0 ? (
                    <tbody>
                      <tr className='rowTableContent textResult'>
                        <td colSpan={3}>
                          <span>No battles were fought</span>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {conquests_logs.map((logs, i) => {
                        return (
                          <React.Fragment key={i}>
                            <tr
                              className='rowTableResult textResult'
                              onClick={() => handleShowResult(logs.id_area)}
                            >
                              <td className='iconCol'>
                                {logs.showResult == true ? (
                                  <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                  <FontAwesomeIcon icon={faChevronUp} />
                                )}
                              </td>
                              <td>
                                <span>{logs.name_area}</span>
                              </td>
                              <td>
                                <span>{logs.name_faction}</span>
                              </td>
                            </tr>
                            {logs.showResult == true && (
                              <tr className='rowTableContent textResult'>
                                <td colSpan={3}>
                                  <div className='card-body p-2'>
                                    {logs.id_faction == 0 ? (
                                      <p className='textImportant'>
                                        The {logs.name_faction} have conquered
                                        the {logs.name_area} !
                                      </p>
                                    ) : (
                                      <p className='textImportant'>
                                        The {logs.name_faction} faction has
                                        conquered the {logs.name_area} !
                                      </p>
                                    )}
                                    <div className='standardBlock'>
                                      {logs.infos.map((info, j) => {
                                        let divStyle = {
                                          '--rarityColor': '#000'
                                        } as React.CSSProperties;

                                        factions.map((faction) => {
                                          if (
                                            faction.id_faction ==
                                            info.id_faction
                                          ) {
                                            divStyle = {
                                              '--rarityColor': faction.color
                                            } as React.CSSProperties;
                                          }
                                        });

                                        const tot_power =
                                          info.tot_power.toFixed(2);

                                        const tot_bonus =
                                          info.bonus_value + info.malus_value;

                                        let tot_bonusLabel = tot_bonus + '%';

                                        let iconName;
                                        let colorName;

                                        if (tot_bonus == 0) {
                                          colorName = 'noBonus';
                                          iconName = faArrowCircleRight;
                                        } else {
                                          if (tot_bonus > 0) {
                                            colorName = 'bonusUp';
                                            tot_bonusLabel =
                                              '+' + tot_bonus + '%';
                                            iconName = faArrowCircleUp;
                                          } else {
                                            colorName = 'bonusDown';
                                            iconName = faArrowCircleDown;
                                          }
                                        }

                                        const nameT = info.name.split(' ');

                                        let faction_name_1;
                                        let faction_name_2;
                                        switch (nameT.length) {
                                          case 1:
                                            faction_name_1 = nameT[0];
                                            break;
                                          default:
                                            faction_name_1 = nameT[0];
                                            faction_name_2 = nameT[1];
                                            break;
                                        }

                                        return (
                                          <div
                                            key={j}
                                            style={divStyle}
                                            className='cardItemBlock'
                                          >
                                            <div className='cardItemContainer'>
                                              <div className='cardItemBox'>
                                                <div className='cardItemBoxIn'>
                                                  <div className='cardItemInfos'>
                                                    <div className='cardItemHierarchy'>
                                                      <span
                                                        className={colorName}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={iconName}
                                                          className='iconToken'
                                                        />
                                                        &nbsp;
                                                        <span className='bonusLabel'>
                                                          {tot_bonusLabel}
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div className='cardItemPower'>
                                                      Power : {tot_power}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='cardItemPictureBox'>
                                                <img
                                                  src={`${info.img}`}
                                                  className='cardItemPicture'
                                                />
                                              </div>
                                              <div className='cardItemInfosBis'>
                                                {!faction_name_2 ? (
                                                  <div className='cardItemName'>
                                                    {faction_name_1}
                                                  </div>
                                                ) : (
                                                  <React.Fragment>
                                                    <div className='cardItemNumber'>
                                                      {faction_name_1}
                                                    </div>
                                                    <div className='cardItemName'>
                                                      {faction_name_2}
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showResults}
        onHide={handleCloseDeploy}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body text-center p-2'>
            <div className='infosBox'>
              <div className='deployBoxTitle'>{selectedArea.name}</div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Result of the battle</div>
              </div>
              <div className='text-center'>
                {conquests_logs.map((logs, i) => {
                  if (logs.id_area == selectedArea.id_area) {
                    if (logs.id_faction == 0) {
                      return (
                        <div key={i} className='textImportant'>
                          The&nbsp;
                          <span className='textImportantRed'>
                            {logs.name_faction}
                          </span>
                          &nbsp;have conquered the {selectedArea.name} !
                        </div>
                      );
                    } else {
                      return (
                        <div key={i} className='textImportant'>
                          The&nbsp;
                          <span className='textImportantRed'>
                            {logs.name_faction}
                          </span>
                          &nbsp;faction has conquered the&nbsp;
                          {selectedArea.name} !
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
            <div className='text-center margin10'>
              {conquests_logs.map((logs, i) => {
                if (logs.id_area == selectedArea.id_area) {
                  return (
                    <div key={i} className='itemBlock'>
                      {logs.infos.map((info, j) => {
                        let divStyle = {
                          '--rarityColor': '#000'
                        } as React.CSSProperties;

                        factions.map((faction) => {
                          if (faction.id_faction == info.id_faction) {
                            divStyle = {
                              '--rarityColor': faction.color
                            } as React.CSSProperties;
                          }
                        });

                        const tot_power = info.tot_power.toFixed(2);

                        const tot_bonus = info.bonus_value + info.malus_value;

                        let tot_bonusLabel = tot_bonus + '%';

                        let iconName;
                        let colorName;

                        if (tot_bonus == 0) {
                          colorName = 'noBonus';
                          iconName = faArrowCircleRight;
                        } else {
                          if (tot_bonus > 0) {
                            colorName = 'bonusUp';
                            tot_bonusLabel = '+' + tot_bonus + '%';
                            iconName = faArrowCircleUp;
                          } else {
                            colorName = 'bonusDown';
                            iconName = faArrowCircleDown;
                          }
                        }

                        const nameT = info.name.split(' ');

                        let faction_name_1;
                        let faction_name_2;
                        switch (nameT.length) {
                          case 1:
                            faction_name_1 = nameT[0];
                            break;
                          default:
                            faction_name_1 = nameT[0];
                            faction_name_2 = nameT[1];
                            break;
                        }

                        return (
                          <div
                            key={j}
                            style={divStyle}
                            className='cardItemBlock'
                          >
                            <div className='cardItemContainer'>
                              <div className='cardItemBox'>
                                <div className='cardItemBoxIn'>
                                  <div className='cardItemInfos'>
                                    <div className='cardItemHierarchy'>
                                      <span className={colorName}>
                                        <FontAwesomeIcon
                                          icon={iconName}
                                          className='iconToken'
                                        />
                                        &nbsp;
                                        <span className='bonusLabel'>
                                          {tot_bonusLabel}
                                        </span>
                                      </span>
                                    </div>
                                    <div className='cardItemPower'>
                                      Power : {tot_power}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='cardItemPictureBox'>
                                <img
                                  src={`${info.img}`}
                                  className='cardItemPicture'
                                />
                              </div>
                              <div className='cardItemInfosBis'>
                                {!faction_name_2 ? (
                                  <div className='cardItemName'>
                                    {faction_name_1}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardItemNumber'>
                                      {faction_name_1}
                                    </div>
                                    <div className='cardItemName'>
                                      {faction_name_2}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseDeploy}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default History;
