import * as React from 'react';
import { IconContext } from 'react-icons';
import { GiBorderedShield, GiCrossedSwords } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';

const Rules = () => {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestContainer'>
                <div className='infosConquestBox'>
                  <div className='conquestTitleBox'>
                    <div className='conquestTitle'>Conquest</div>
                  </div>
                  <div className='phaseTitle'>Rules</div>
                </div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightBtn'>
                  <div
                    className='stripBtn'
                    onClick={() => navigate(routeNames.conquest)}
                  >
                    <div className='stripBtnIn'>
                      <div className='stripBtnText'>Conquest</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightBtn'>
                    <div
                      className='stripBtn'
                      onClick={() => navigate(routeNames.conquest)}
                    >
                      <div className='stripBtnIn'>
                        <div className='stripBtnText'>Conquest</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='statusWarning'>
                Rules of Rise of Factions Beta version.
                <br />
                These rules are subject to change in order to improve the game.
              </div>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>1</span>
              <span className='rulesTitle'>&nbsp;Objective</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                Territory Conquests. The factions compete for Reiwa&apos;s
                territories to take advantage of its resources.
                <br />
                Each conquest the factions must use their heroes and bonuses to
                attack or defend a territory.
                <br />
                At the end of the conquest the factions will loot the resources
                of the conquered territories.
              </p>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>2</span>
              <span className='rulesTitle'>&nbsp;Course of the conquests</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                Each conquest takes place in two phases :<br />
                1 - Deployment (details below)
                <br />
                2 - Battle (details below)
                <br />
                As our members are from different time zones, each of these
                phases will last 24 hours to give everyone the opportunity to
                play.
              </p>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>3</span>
              <span className='rulesTitle'>&nbsp;Deployment of heroes</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                During the deployment phase each faction sets up a strategy to
                place their heroes on the different points of interest to attack
                or defend them. It&apos;s all about managing your heroes well to
                keep and conquer new territories.
                <br />
                The faction discord channels will be your places to discuss
                strategy between faction members. Each faction plays its turn
                without knowing the movements of the others.
              </p>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>4</span>
              <span className='rulesTitle'>&nbsp;Battle and results</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                During the battle phase, battles will take place in the
                different districts (randomly).
                <br />
                A battle lasts 2 hours. As long as a battle is not finished or
                has not taken place, you can use the items in your inventory.
                Don&apos;t hesitate to call Shinobi to help you win the battle
                (the claim of Call of Shinobi in the Marketplace is renewed at
                the start of each deployment phase).
                <br />
                <IconContext.Provider value={{ className: 'iconAttack' }}>
                  <GiCrossedSwords />
                </IconContext.Provider>
                icon on an object means that the object can be used in attack.
                <br />
                <IconContext.Provider value={{ className: 'iconDef' }}>
                  <GiBorderedShield />
                </IconContext.Provider>
                icon on an object means that the object can be used in defense.
                <br />
                If no heroes or items have been deployed in a district, the
                bandits take control of the district and no faction can benefit
                from the looted resources.
                <br />
                The results are displayed after each battle and the map is
                updated throughout the day. Don&apos;t hesitate to adapt your
                strategy to win.
              </p>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>5</span>
              <span className='rulesTitle'>&nbsp;Power statistic</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                The power shown on the game card of the heroes is calculated
                according to the rarity score of the hero divided by 10.
                <br />
                The power of the items in your inventory is shown on the item
                card.
                <br />
                The faction that wins a battle is the faction that has the most
                cumulative power deployed on the territory. To avoid a too
                important imbalance, the power of legendary heroes is capped at
                90.
              </p>
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>6</span>
              <span className='rulesTitle'>&nbsp;Balancing</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                In order to guarantee a chance for everyone to win and balance
                the fights, a &quot;Balancing Ratio&quot; system is in place to
                better balance the forces involved.
                <br />
                This balancing ratio is calculated according to the strongest
                faction, but it does not take into account the power of items,
                and is not applied to the power of items.
              </p>
              {/*<p className='textInfos'>
                In order to guarantee a chance for everyone to win and balance
                the fights, a balancing calculation can be performed.
                <br />
                When the difference in the number of heroes deployed in a
                territory is at least 3 for one faction compared to the others,
                the power of the other factions is made up according to the
                following rule :
                <br />
                We complete the sub-numbered factions by adding X times the
                power of the weakest hero engaged in the area (with X being the
                number of heroes of the difference).
              </p>
              <p className='textInfos'>
                This rule allows to keep the advantage of the faction that has
                deployed the most heroes, but balances the game and allows
                during the battle phase the use of items to change the outcome
                of the fights.
                <br />
                The power of the items is added at the end of the calculation.
              </p>
              <br />
              <p className='textInfos'>
                Examples of 2 factions fighting for the temple :
              </p>
              <p className='textInfos'>
                1. Faction 1 with 5 heroes placed, with respectively the
                following power 30, 40, 40, 50, 50 (total 210) VS faction 2 with
                2 heroes of power 50 and 60 (total 110) by applying the above
                rule it gives for faction 2 : 50 + 60 + (30 + 30 + 30) for a
                total of (200).
                <br />
                -&gt; 210 vs 200 : Faction 1 wins !
              </p>
              <p className='textInfos'>
                2. Faction 1 with 5 heroes placed, with respectively the
                following power 30, 40, 40, 50, 50 (total 210) VS faction 2 with
                2 heroes of power 50 and 60 (total 110) by applying the above
                rule it gives for faction 2 : 50 + 60 + (30 + 30 + 30) for a
                total of (200).
                <br />
                Faction 2 decides to play one Call of Shinobi item (power 20) so
                the total is now 200 + 20 = 220.
                <br />
                -&gt; 210 vs 220 : Faction 2 wins !
              </p>
              <p className='textInfos'>
                3. Faction 1 with 5 heroes placed, with respectively the
                following power 30, 40, 40, 50, 50 (total 210) VS faction 2 with
                2 high power heroes 80 and 90 (total 170) by applying the above
                rule it gives for faction 2 : 80 + 90 + (30 + 30 + 30) for a
                total of (260).
                <br />
                -&gt; 210 vs 260 : At 5 vs 2, faction 2 with very strong heroes
                wins !
              </p>
              <p className='textInfos'>
                4. Faction 1 with 10 heroes placed, with respectively the
                following power 30, 30, 30, 35, 40, 50, 50, 50, 55, 60 (total
                430) VS faction 2 with 2 high power heroes 80 and 90 (total 170)
                by applying the above rule it gives for faction 2 : 80 + 90 +
                (30 + 30 + 30 + 30 + 30 + 30 + 30 + 30) for a total of (410).
                <br />
                -&gt; 430 vs 410 : Facing a large army at 10 vs 2, faction 1
                wins !
              </p>*/}
            </div>
          </div>
          <div className='boxContainer topContainer'>
            <div className='card-body p-2 marginBot20'>
              <span className='rulesNumber'>7</span>
              <span className='rulesTitle'>&nbsp;Earnings</span>
            </div>
            <div className='card-body p-2'>
              <p className='textInfos'>
                Each territory allows you to earn JCORP tokens. Each hero placed
                on a winning territory allows to generate a gain of 1000 JCORP
                in the bank of his faction.
                <br />
                At the end of a conquest the winnings of the faction bank are
                distributed to all participants proportionally to the number of
                heroes played.
                <br />
                If 500 heroes are played, and 300 have won territories then the
                faction wins 300&apos;000 JCORP which will be shared between the
                500 heroes of the faction having participated. The players will
                then receive 600 JCORP / heroes played.
              </p>
              {/*<p className='textInfos'>
                Rewards are available each day after a Battle Phase, and must be
                claimed in the&nbsp;
                <a href={routeNames.claimpanel} rel='noreferrer'>
                  Claim Panel
                </a>
                .
              </p>*/}
              <p className='textInfos'>
                Rewards are available each day after a Battle Phase.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
