import http from '../http-common';
import {
  ITournamentRegistrationData,
  TournamentRegistrationCreation,
  TournamentRegistrationsStateType
} from '../types/tournamentregistrations.type';

class TournamentRegistrationsDataService {
  create(data: any) {
    return http.post<TournamentRegistrationCreation>(
      '/tournament_registrations',
      data
    );
  }

  manageSkills(data: any) {
    return http.post<TournamentRegistrationCreation>(
      '/tournament_registrations/skills',
      data
    );
  }

  update(data: any, id: any) {
    return http.put<any>(`/tournament_registrations/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/tournament_registrations/${id}`);
  }

  findUserWarrior(id_user: any, params: any) {
    return http.get<TournamentRegistrationsStateType>(
      `/tournament_registrations/user_warrior/${id_user}`,
      {
        params
      }
    );
  }

  findAllRegisteredByUser(id_user: any, params: any) {
    return http.get<TournamentRegistrationsStateType>(
      `/tournament_registrations/user/${id_user}`,
      {
        params
      }
    );
  }

  findAllRegistered(params: any) {
    return http.get<TournamentRegistrationsStateType>(
      '/tournament_registrations/',
      {
        params
      }
    );
  }
}

export default new TournamentRegistrationsDataService();
