import * as React from 'react';
import {
  faFrown,
  faCog,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, Radio, RadioGroup, Pagination } from '@mui/material';
import { hsvaToHex, hexToHsva } from '@uiw/color-convert';
import Wheel from '@uiw/react-color-wheel';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { maxSquads, maxNFTsPerSquad, xoxnoLink, deadrareLink } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../services/attributs.service';
import SquadsDataService from '../../services/squads.service';
import {
  AttributsStateType,
  AttributsDisplayStateType,
  IAttributsData
} from '../../types/attributs.type';
import {
  SquadsStateType,
  ISquadData,
  squadEmpty,
  SquadsGlobalInfos,
  squadGlobalInfosEmpty
} from '../../types/squads.type';
import { hierarchyColor } from '../../types/token.type';
import axe from './../../assets/img/icons/axe.png';
import bow from './../../assets/img/icons/bow.png';
import shuriken from './../../assets/img/icons/shuriken.png';
import spear from './../../assets/img/icons/spear.png';
import sword from './../../assets/img/icons/sword.png';

const Squads = () => {
  const { userSession } = useAuthContext();

  const navigate = useNavigate();

  const iconsPath = 'icons/';
  const iconsExtention = '.png';
  const defaultColor = '#FF003D';
  const defaultIcon = 'axe';

  const [nbSquads, setNbSquads] = React.useState(0);
  const [squadsInfos, setSquadsInfos] = React.useState<SquadsStateType>({
    squads: [],
    message: '',
    status: ''
  });
  const [squadsGlobalInfos, setSquadsGlobalInfos] =
    React.useState<SquadsGlobalInfos>({
      squads: []
    });

  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });
  const [listNFTsDisplay, setListNFTsDisplay] =
    React.useState<AttributsDisplayStateType>({
      nfts: []
    });

  const pageSize = 25;
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const [selectedSquadData, setSelectedSquadData] =
    React.useState<ISquadData>(squadEmpty);
  const [squadNbNFTs, setSquadNbNFTs] = React.useState(0);
  const [squadTotPower, setSquadTotPower] = React.useState(0);

  const [hsva, setHsva] = React.useState(hexToHsva(defaultColor));
  const [squadName, setSquadName] = React.useState('');
  const [squadIcon, setSquadIcon] = React.useState(defaultIcon);
  const [squadColor, setSquadColor] = React.useState({
    color: defaultColor,
    divStyle: {
      '--squadColor': defaultColor
    } as React.CSSProperties
  });

  const [showCreEdit, setShowCreEdit] = React.useState(false);
  const [creationMode, setCreationMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const handleCloseCreEdit = () => {
    setCreationMode(false);
    setEditMode(false);
    setShowCreEdit(false);
  };

  const handleShowCreation = () => {
    setSelectedSquadData(squadEmpty);
    setHsva(hexToHsva(defaultColor));
    setSquadName('');
    setSquadIcon(defaultIcon);
    setSquadColor({
      color: defaultColor,
      divStyle: {
        '--squadColor': defaultColor
      } as React.CSSProperties
    });

    setCreationMode(true);
    setShowCreEdit(true);
  };

  const handleShowEdit = (squad: ISquadData) => {
    setSelectedSquadData(squad);
    setHsva(hexToHsva(squad.color));
    setSquadName(squad.name);
    setSquadIcon(squad.icon);
    setSquadColor({
      color: squad.color,
      divStyle: {
        '--squadColor': squad.color
      } as React.CSSProperties
    });

    setEditMode(true);
    setShowCreEdit(true);
  };

  const [showDelete, setShowDelete] = React.useState(false);
  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = (squad: ISquadData) => {
    const icon = iconsPath + squad.icon + iconsExtention;

    setSelectedSquadData(squad);
    setSquadIcon(icon);
    setSquadColor({
      color: squad.color,
      divStyle: {
        '--squadColor': squad.color
      } as React.CSSProperties
    });

    setShowDelete(true);
  };

  const [showManage, setShowManage] = React.useState(false);
  const handleCloseManage = () => {
    setShowManage(false);
  };
  const handleShowManage = (squad: ISquadData) => {
    const icon = iconsPath + squad.icon + iconsExtention;

    let nb_nfts = 0;
    let tot_power = 0;
    squadsGlobalInfos.squads.map((squadInfos) => {
      if (squadInfos.id_squad == squad.id_squad) {
        nb_nfts = squadInfos.nb_nfts;
        tot_power = squadInfos.tot_power;
      }
    });

    setSquadNbNFTs(nb_nfts);
    setSquadTotPower(tot_power);
    setSelectedSquadData(squad);
    setSquadIcon(icon);
    setSquadColor({
      color: squad.color,
      divStyle: {
        '--squadColor': squad.color
      } as React.CSSProperties
    });

    setPage(1);
    setShowManage(true);
  };

  const handleSquadColor = (hsvaColor: any) => {
    setHsva(hsvaColor);
    setSquadColor({
      color: hsvaToHex(hsvaColor),
      divStyle: {
        '--squadColor': hsvaToHex(hsvaColor)
      } as React.CSSProperties
    });
  };

  const handleSquadCreation = () => {
    let name = squadName;
    if (name.length == 0) name = 'Squad';

    const dataSquad: ISquadData = {
      id_user: userSession.userID,
      name: name,
      icon: squadIcon,
      color: squadColor.color
    };

    SquadsDataService.create(dataSquad).then(() => {
      fetchSquadsData();
      handleCloseCreEdit();
    });
  };

  const handleSquadEdit = () => {
    const dataSquad: any = {
      name: squadName,
      icon: squadIcon,
      color: squadColor.color
    };

    SquadsDataService.update(dataSquad, selectedSquadData.id_squad).then(() => {
      fetchNFTsData();
      fetchSquadsData();
      handleCloseCreEdit();
    });
  };

  const handleSquadDelete = () => {
    SquadsDataService.delete(selectedSquadData.id_squad).then(() => {
      fetchNFTsData();
      fetchSquadsData();
      handleCloseDelete();
    });
  };

  const selectNFT = (token: IAttributsData) => {
    const dataAttribut: any = {
      id_squad: selectedSquadData.id_squad
    };

    AttributsDataService.update(dataAttribut, token.id_nft).then(() => {
      fetchNFTsData();
      fetchSquadsData();
    });
  };

  const unselectNFT = (token: IAttributsData) => {
    const dataAttribut: any = {
      id_squad: 0
    };

    AttributsDataService.update(dataAttribut, token.id_nft).then(() => {
      fetchNFTsData();
      fetchSquadsData();
    });
  };

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handleSquadsGlobalInfos = () => {
    let prevIdSquad = 0;

    const infos: SquadsGlobalInfos = {
      squads: []
    };

    let dataSquadsGlobalInfos = squadGlobalInfosEmpty;
    let nbNfts = 0;
    let totPower = 0;

    squadsInfos.squads.map((squad) => {
      if (squad.id_squad != prevIdSquad) {
        if (dataSquadsGlobalInfos.id_squad != 0) {
          infos.squads.push(dataSquadsGlobalInfos);
        }

        dataSquadsGlobalInfos = squadGlobalInfosEmpty;
        nbNfts = 0;
        totPower = 0;

        if (squad.attributs != null) {
          squad.attributs.map((attribut) => {
            nbNfts++;
            totPower += attribut.nft_power;
          });
        }

        dataSquadsGlobalInfos = {
          id_squad: squad.id_squad,
          nb_nfts: nbNfts,
          tot_power: totPower
        };

        prevIdSquad = squad.id_squad;
      }
    });

    if (dataSquadsGlobalInfos.id_squad != 0) {
      infos.squads.push(dataSquadsGlobalInfos);
    }

    setSquadsGlobalInfos(infos);

    if (selectedSquadData.id_squad != 0) {
      let nb_nfts = 0;
      let tot_power = 0;
      infos.squads.map((squadInfos) => {
        if (squadInfos.id_squad == selectedSquadData.id_squad) {
          nb_nfts = squadInfos.nb_nfts;
          tot_power = squadInfos.tot_power;
        }
      });

      setSquadNbNFTs(nb_nfts);
      setSquadTotPower(tot_power);
    }
  };

  const fetchSquadsData = () => {
    SquadsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setSquadsInfos({
          squads: data.squads,
          status: data.status
        });

        setNbSquads(data.squads.length);
      }
    });
  };

  const fetchNFTsData = () => {
    AttributsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setListNFTs({
          attributs: data.attributs,
          status: data.status
        });

        const nbPages = Math.ceil(data.attributs.length / pageSize);
        setTotalPages(nbPages);
      }
    });
  };

  const handleRefreshNFTsList = () => {
    const nftsList: AttributsDisplayStateType = {
      nfts: []
    };

    const calcPage = page - 1;
    const position = pageSize * calcPage;
    let nbNFT = 0;

    for (let i = position; i < listNFTs.attributs.length; i++) {
      if (nbNFT < pageSize) {
        nbNFT++;
        nftsList.nfts.push(listNFTs.attributs[i]);
      }
    }

    setListNFTsDisplay(nftsList);
  };
  React.useEffect(() => {
    fetchSquadsData();
    fetchNFTsData();
  }, []);

  React.useEffect(() => {
    if (listNFTs.attributs.length > 0 && page != 0) {
      handleRefreshNFTsList();
    }
  }, [listNFTs, page]);

  React.useEffect(() => {
    handleSquadsGlobalInfos();
  }, [squadsInfos]);

  const { squads } = squadsInfos;
  const { attributs } = listNFTs;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestContainer'>
                <div className='infosConquestBox'>
                  <div className='conquestTitleBox'>
                    <div className='conquestTitle'>Conquest</div>
                  </div>
                  <div className='phaseTitle'>Squads</div>
                </div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightBtn'>
                  <div
                    className='stripBtn'
                    onClick={() => navigate(routeNames.conquest)}
                  >
                    <div className='stripBtnIn'>
                      <div className='stripBtnText'>Conquest</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightBtn'>
                    <div
                      className='stripBtn'
                      onClick={() => navigate(routeNames.conquest)}
                    >
                      <div className='stripBtnIn'>
                        <div className='stripBtnText'>Conquest</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Total Squads</div>
                <div className='infosTitle'>
                  {nbSquads} / {maxSquads}
                </div>
              </div>
              <div className='infosBoxSub'>
                <div className='buttonsNFTContainer'>
                  {nbSquads == maxSquads ? (
                    <React.Fragment>
                      <Button disabled className='btnStandardReverse'>
                        Create
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        className='btnStandardReverse'
                        onClick={handleShowCreation}
                      >
                        Create
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>My Squads</div>
              </div>
              {squads.map((squad, i) => {
                const divStyle = {
                  '--squadColor': squad.color
                } as React.CSSProperties;

                const icon = iconsPath + squad.icon + iconsExtention;

                let nbNfts = 0;
                let totPower = 0;

                squadsGlobalInfos.squads.map((squadInfos) => {
                  if (squadInfos.id_squad == squad.id_squad) {
                    nbNfts = squadInfos.nb_nfts;
                    totPower = squadInfos.tot_power;
                  }
                });

                const squadTotPowerRounded = totPower.toFixed(2);

                return (
                  <div className='squadContainer' key={i}>
                    <div className='squadInfosContainer'>
                      <div className='squadTitle'>
                        <div className='squadIcon'>
                          <img
                            src={`${icon}`}
                            style={divStyle}
                            className='imgSquadIcon'
                          />
                        </div>
                        <div className='squadTextContainer'>
                          <div className='squadText'>{squad.name}</div>
                        </div>
                      </div>
                      <div className='squadInfos'>
                        <div className='dataContainer'>
                          <div className='dataSepartorLine'>
                            <span className='dataTitle'>Total NFTs</span>
                            <span className='dataAttribut'>
                              {nbNfts} / {maxNFTsPerSquad}
                            </span>
                          </div>
                          <div className='dataSepartorLine'>
                            <span className='dataTitle'>Total Power</span>
                            <span className='dataAttribut'>
                              {squadTotPowerRounded}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='actionContainer'>
                      <div
                        className='actionIcon'
                        onClick={() => handleShowManage(squad)}
                      >
                        <FontAwesomeIcon icon={faCog} className='fa' />
                      </div>
                      <div
                        className='actionIcon'
                        onClick={() => handleShowEdit(squad)}
                      >
                        <FontAwesomeIcon icon={faPencil} className='fa' />
                      </div>
                      <div
                        className='actionIcon'
                        onClick={() => handleShowDelete(squad)}
                      >
                        <FontAwesomeIcon icon={faTrash} className='fa' />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showCreEdit} onHide={handleCloseCreEdit} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            {creationMode ? (
              <div className='infosModalTitle'>Squad Creation</div>
            ) : null}
            {editMode ? (
              <div className='infosModalTitle'>Squad Edition</div>
            ) : null}
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>Name</Form.Label>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control
                    className='textAttribut'
                    type='text'
                    maxLength={50}
                    value={squadName}
                    onChange={(e) => setSquadName(e.target.value)}
                  />
                </Col>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>Icon</Form.Label>
              </Form.Row>
              <Form.Row>
                <RadioGroup
                  value={squadIcon}
                  onChange={(e) => setSquadIcon(e.target.value)}
                >
                  <div className='radioFormRow'>
                    <Form.Label>
                      <FormControlLabel
                        className='radioFormButton'
                        value='axe'
                        control={<Radio />}
                        label=''
                      />
                      <span className='radioFormIcon'>
                        <img
                          src={`${axe}`}
                          style={squadColor.divStyle}
                          className='imgSquadIcon'
                        />
                      </span>
                    </Form.Label>
                    <Form.Label>
                      <FormControlLabel
                        className='radioFormButton'
                        value='bow'
                        control={<Radio />}
                        label=''
                      />
                      <span className='radioFormIcon'>
                        <img
                          src={`${bow}`}
                          style={squadColor.divStyle}
                          className='imgSquadIcon'
                        />
                      </span>
                    </Form.Label>
                    <Form.Label>
                      <FormControlLabel
                        className='radioFormButton'
                        value='shuriken'
                        control={<Radio />}
                        label=''
                      />
                      <span className='radioFormIcon'>
                        <img
                          src={`${shuriken}`}
                          style={squadColor.divStyle}
                          className='imgSquadIcon'
                        />
                      </span>
                    </Form.Label>
                    <Form.Label>
                      <FormControlLabel
                        className='radioFormButton'
                        value='spear'
                        control={<Radio />}
                        label=''
                      />
                      <span className='radioFormIcon'>
                        <img
                          src={`${spear}`}
                          style={squadColor.divStyle}
                          className='imgSquadIcon'
                        />
                      </span>
                    </Form.Label>
                    <Form.Label>
                      <FormControlLabel
                        className='radioFormButton'
                        value='sword'
                        control={<Radio />}
                        label=''
                      />
                      <span className='radioFormIcon'>
                        <img
                          src={`${sword}`}
                          style={squadColor.divStyle}
                          className='imgSquadIcon'
                        />
                      </span>
                    </Form.Label>
                  </div>
                </RadioGroup>
              </Form.Row>
            </div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <Form.Row>
                <Form.Label className='textTitle formLabel'>Color</Form.Label>
              </Form.Row>
              <Form.Row>
                <Wheel
                  className='wheelColorBox'
                  color={hsva}
                  onChange={(color) => {
                    handleSquadColor(color.hsva);
                  }}
                />
              </Form.Row>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              {creationMode ? (
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleSquadCreation}
                  >
                    Create
                  </Button>
                  <Button
                    className='btnStandardSmall'
                    onClick={handleCloseCreEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
              {editMode ? (
                <div className='buttonContainer'>
                  <Button
                    className='btnStandardSmallReverse'
                    onClick={handleSquadEdit}
                  >
                    Save
                  </Button>
                  <Button
                    className='btnStandardSmall'
                    onClick={handleCloseCreEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} centered scrollable>
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Squad Deletion</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='squadTitle'>
                <div className='squadIcon'>
                  <img
                    src={`${squadIcon}`}
                    style={squadColor.divStyle}
                    className='imgSquadIcon'
                  />
                </div>
                <div className='squadTextContainer'>
                  <div className='squadTextModal'>
                    {selectedSquadData.name.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='margin20'>
            <div className='card-body text-center p-2'>
              <div className='statusWarning'>
                By deleting this squad, the associated NFTs will no longer have
                a squad.
              </div>
            </div>
          </div>

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleSquadDelete}
                >
                  Delete
                </Button>
                <Button
                  className='btnStandardSmall'
                  onClick={handleCloseDelete}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showManage}
        onHide={handleCloseManage}
        size='lg'
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <div className='card-body p-2'>
            <div className='infosModalTitle'>Squad Management</div>
          </div>

          <div className='margin10'>
            <div className='card-body p-2'>
              <div className='squadTitle'>
                <div className='squadIcon'>
                  <img
                    src={`${squadIcon}`}
                    style={squadColor.divStyle}
                    className='imgSquadIcon'
                  />
                </div>
                <div className='squadTextContainer'>
                  <div className='squadTextModal'>
                    {selectedSquadData.name.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='squadInfosModal'>
                <div className='dataContainer'>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total NFTs</span>
                    <span className='dataAttribut'>
                      {squadNbNFTs} / {maxNFTsPerSquad}
                    </span>
                  </div>
                  <div className='dataSepartorLine'>
                    <span className='dataTitle'>Total Power</span>
                    <span className='dataAttribut'>
                      {squadTotPower.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {attributs.length > 0 ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>My NFTs Wallet</div>
                  </div>
                  <div className='textImportant'>
                    Click on your NFTs to add them to the squad.
                  </div>
                </div>
              </div>

              <div className='margin10'>
                <div className='card-body p-2'>
                  {totalPages > 1 && (
                    <div className='paginBoxTop'>
                      <Pagination
                        className='paginContainer'
                        count={totalPages}
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                  <div className='nftBlock'>
                    {listNFTsDisplay.nfts.map((token, i) => {
                      let flg_deployed = false;
                      let action = true;
                      let inSquad = false;

                      squadsGlobalInfos.squads.map((squadInfos) => {
                        if (squadInfos.id_squad == selectedSquadData.id_squad) {
                          if (squadInfos.id_squad == token.id_squad) {
                            flg_deployed = true;
                          }
                          if (squadInfos.nb_nfts >= maxNFTsPerSquad) {
                            action = false;
                          }
                        }
                      });

                      if (token.id_squad != 0) {
                        inSquad = true;
                      }

                      const divStyle = {
                        '--rarityColor': hierarchyColor[token.nft_hierarchy]
                      } as React.CSSProperties;

                      const NFTscoreRounded = token.nft_power.toFixed(2);
                      const nameT = token.nft_name.split(' ');
                      const nft_collection = nameT[0];
                      let nft_number;
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }

                      const squadDivStyle = {
                        '--squadColor': token.squad?.color
                      } as React.CSSProperties;

                      const icon =
                        iconsPath + token.squad?.icon + iconsExtention;

                      if (flg_deployed) {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='cardNFTPictureBox'
                                onClick={() => unselectNFT(token)}
                              >
                                <div className='overlay'>
                                  <div className='overlayText'>Selected</div>
                                </div>
                                {inSquad ? (
                                  <div className='overlaySquadIcon'>
                                    <img
                                      src={`${icon}`}
                                      style={squadDivStyle}
                                      className='overlayImgSquadIcon'
                                    />
                                  </div>
                                ) : null}
                                <div className='overlayHoverB'>
                                  <div className='overlayHoverTextB'>
                                    Remove
                                  </div>
                                </div>
                                <img
                                  src={`${token.nft_url}`}
                                  className='cardNFTPicture'
                                />
                              </div>
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            style={divStyle}
                            className='cardNFTBlock'
                          >
                            <div className='cardNFTContainer'>
                              <div className='cardNFTBox'>
                                <div className='cardNFTBoxIn'>
                                  <div className='cardNFTInfos'>
                                    <div className='cardNFTHierarchy'>
                                      {token.nft_hierarchy}
                                    </div>
                                    <div className='cardNFTPower'>
                                      Power : {NFTscoreRounded}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {action ? (
                                <div
                                  className='cardNFTPictureBox'
                                  onClick={() => selectNFT(token)}
                                >
                                  {inSquad ? (
                                    <div className='overlaySquadIcon'>
                                      <img
                                        src={`${icon}`}
                                        style={squadDivStyle}
                                        className='overlayImgSquadIcon'
                                      />
                                    </div>
                                  ) : null}
                                  <div className='overlayHoverA'>
                                    <div className='overlayHoverTextA'>Add</div>
                                  </div>
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardNFTPicture'
                                  />
                                </div>
                              ) : (
                                <div className='cardNFTPictureBox'>
                                  {inSquad ? (
                                    <div className='overlaySquadIcon'>
                                      <img
                                        src={`${icon}`}
                                        style={squadDivStyle}
                                        className='overlayImgSquadIcon'
                                      />
                                    </div>
                                  ) : null}
                                  <img
                                    src={`${token.nft_url}`}
                                    className='cardNFTPicture'
                                  />
                                </div>
                              )}
                              <div className='cardNFTInfosBis'>
                                {!nft_number ? (
                                  <div className='cardNFTTitle'>
                                    {nft_collection}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className='cardNFTNumber'>
                                      {nft_number}
                                    </div>
                                    <div className='cardNFTName'>
                                      {nft_collection}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {totalPages > 1 && (
                    <div className='paginBoxBottom'>
                      <Pagination
                        className='paginContainer'
                        count={totalPages}
                        defaultPage={1}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        shape='rounded'
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : null}

          {attributs.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='infosBox'>
                  <div className='infosSubTitle'>My NFTs Wallet</div>
                </div>
                {userSession.isBlockchainAccount ? (
                  <React.Fragment>
                    <div className='text-center'>
                      <div className='iconContainer'>
                        <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                      </div>
                      <p className='textImportant'>
                        Oops... it looks like you don&apos;t have any Heroes at
                        the moment !
                      </p>
                      <p className='textImportant'>
                        But ! you can find one by going here :
                      </p>
                    </div>
                    <div className='text-center'>
                      <Button
                        className='btnStandardReverse'
                        onClick={() => navigate(routeNames.mint)}
                      >
                        Mint Heroes
                      </Button>
                      <br className='smallBox' />
                      <a href={xoxnoLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on XOXNO
                        </Button>
                      </a>
                      <a href={deadrareLink} target='_blank' rel='noreferrer'>
                        <Button className='btnStandard'>
                          Heroes
                          <br />
                          on Dead Rare
                        </Button>
                      </a>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='text-center'>
                    <div className='iconContainer'>
                      <FontAwesomeIcon icon={faFrown} className='fa-4x' />
                    </div>
                    <p className='textImportant'>
                      Oops... it looks like you don&apos;t have any Heroes at
                      the moment !
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseManage}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Squads;
