interface rarityInfos {
  rarityScore?: number;
}

interface attributesInfos {
  value: string;
  trait_type: string;
}

interface metadataType {
  rarity?: rarityInfos;
  attributes: attributesInfos[];
}

export interface NFTType {
  creator: string;
  name: string;
  nonce: number;
  collection: string;
  identifier: string;
  ticker: string;
  url: string;
  metadata?: metadataType;
}

export interface NFTsData {
  nfts: NFTType[];
}

export interface INFTData {
  id_nft: number;
  identifier: string;
  name: string;
  url: string;
  hierarchy: string;
  id_hierarchy: number;
  power: number;
}

export interface NFTsStateType {
  nfts: INFTData[];
  nftsFetched: boolean | undefined;
}

export interface NFTsDisplayStateType {
  nfts: INFTData[];
}

type colorOptions = {
  [key: string]: string;
};

export const hierarchyColor: colorOptions = {
  Warrior: '#6c757d',
  Samurai: '#278ed1',
  Daimyos: '#ffc107',
  Shogun: '#9B59B6'
};

interface assetsType {
  pngUrl?: string;
  svgUrl?: string;
}

export interface TokenType {
  identifier: string;
  name: string;
  balance: number;
  decimals: number;
  assets?: assetsType;
}

export interface TokensStateType {
  tokens: TokenType[];
  tokensFetched: boolean | undefined;
}
