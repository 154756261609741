import http from '../http-common';
import { ArenaLdbLogsStateType } from '../types/arenaldblogs.type';

class ArenaLdbLogsDataService {
  findAllByLeaderboard(id_leaderboard: any) {
    return http.get<ArenaLdbLogsStateType>(
      `/arenaldblogs/leaderboard/${id_leaderboard}`
    );
  }

  findAllLeaderboardId() {
    return http.get<ArenaLdbLogsStateType>('/arenaldblogs/id_leaderboard/');
  }
}

export default new ArenaLdbLogsDataService();
