import * as React from 'react';
import { Button } from '@mui/material';
import { useGetSuccessfulTransactions } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { Button as ReactButton } from 'react-bootstrap';
import { useAuthContext } from 'context/auth-context';
import SettingDataService from '../../services/setting.service';
import daimyos from './../../assets/img/daimyos.png';
import samurai from './../../assets/img/samurai.png';
import shogun from './../../assets/img/shogun.png';

const Mint = () => {
  const { userSession } = useAuthContext();

  let userAccountMVX;
  if (userSession.isBlockchainAccount) {
    if (userSession.userAlias != '') {
      userAccountMVX = userSession.userAlias;
    } else {
      userAccountMVX =
        userSession.userAccount.substring(0, 3) +
        '...' +
        userSession.userAccount.substring(56, 62);
    }
  }

  const nftIdentifier = 'JCORP';
  const nftCost = 0.3;

  const SuccessfulTx = useGetSuccessfulTransactions();

  const [mintAvailable, setMintAvailable] = React.useState<boolean>();

  const [buyDisabled, setBuyDisabled] = React.useState(false);
  const [minusDisabled, setMinusDisabled] = React.useState(true);
  const [plusDisabled, setPlusDisabled] = React.useState(false);
  const [mintQuantity, setMintQuantity] = React.useState(1);
  const [mintPrice, setMintPrice] = React.useState(nftCost.toFixed(2));

  const [accountBalance, setAccountBalance] = React.useState<any>();

  function numHex(s: any) {
    let a = s.toString(16);
    if (a.length % 2 > 0) {
      a = '0' + a;
    }
    return a;
  }

  function strHex(s: any) {
    let a = '';
    for (let i = 0; i < s.length; i++) {
      a = a + numHex(s.charCodeAt(i));
    }
    return a;
  }

  const fetchTokensBalanceData = async () => {
    const refreshAccountData = await refreshAccount();
    let refreshBalance = '0';
    if (refreshAccountData != null) {
      refreshBalance = refreshAccountData.balance;
    }

    let balance = '';
    const decimals = 10 ** 18;
    const balanceValue = parseInt(refreshBalance) / decimals;
    balance = balanceValue.toLocaleString('en-GB', {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    });
    setAccountBalance(balance);
    if (mintPrice > balance) {
      setBuyDisabled(true);
    }
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'mintAvailable';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.setting.value == 0) {
          setMintAvailable(false);
        } else {
          setMintAvailable(true);
        }
      }
    });
  };

  const handleIncrementQuantity = () => {
    const quantity = mintQuantity + 1;
    const price = (nftCost * quantity).toFixed(2);
    if (quantity > 1) {
      setMinusDisabled(false);
    }
    if (quantity == 10) {
      setPlusDisabled(true);
    }
    if (price > accountBalance) {
      setBuyDisabled(true);
    } else {
      setBuyDisabled(false);
    }
    setMintQuantity(quantity);
    setMintPrice(price);
  };

  const handleDecrementQuantity = () => {
    const quantity = mintQuantity - 1;
    const price = (nftCost * quantity).toFixed(2);
    if (quantity == 1) {
      setMinusDisabled(true);
    }
    if (quantity < 10) {
      setPlusDisabled(false);
    }
    if (price > accountBalance) {
      setBuyDisabled(true);
    } else {
      setBuyDisabled(false);
    }
    setMintQuantity(quantity);
    setMintPrice(price);
  };

  const handleMint = async () => {
    const amountValue = parseFloat(mintPrice) * 10 ** 18;
    const gasLimitValue = 41000000 + 6000000 * (mintQuantity - 1);

    const mintData: any = {
      mintType: 'Heroes',
      mintQuantity: mintQuantity,
      mintPrice: mintPrice,
      mintToken: 'EGLD'
    };

    let processingLabel;
    let successLabel;
    if (mintQuantity > 1) {
      processingLabel = 'Mint of Heroes';
      successLabel = 'Heroes minted';
    } else {
      processingLabel = 'Mint of Hero';
      successLabel = 'Hero minted';
    }

    const NFT_hex = strHex(nftIdentifier);
    const quantity_hex = numHex(mintQuantity);
    //const dataNFT = 'buy' + '@' + NFT_hex + '@' + quantity_hex;
    const dataNFT = 'buy' + '@' + NFT_hex + '@' + NFT_hex + '@' + quantity_hex;

    const addressSC =
      'erd1qqqqqqqqqqqqqpgqjh7wm3nzxgp7t68tm8gadpakc07any2fys5s69alpy';

    await refreshAccount();
    await sendTransactions({
      transactions: [
        {
          value: amountValue,
          gasLimit: gasLimitValue,
          data: btoa(dataNFT),
          receiver: addressSC
        }
      ],
      sessionInformation: {
        userId: userSession.userID,
        type: 'mint',
        data: mintData
      },
      transactionsDisplayInfo: {
        processingMessage: processingLabel,
        successMessage: successLabel
      },
      redirectAfterSign: false
    });
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    fetchTokensBalanceData();
  }, [SuccessfulTx.successfulTransactionsArray.length]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='pageTitle'>Mint</div>
              </div>
              <div className='infosBoxSub'>
                {userSession.isBlockchainAccount ? (
                  <div className='buttonsNFTContainer'>
                    <div className='infosWallet'>{userAccountMVX}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {!userSession.isBlockchainAccount ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusWarning'>
                  Mint is only available for MultiversX users
                </div>
              </div>
            </div>
          ) : null}

          {userSession.isBlockchainAccount ? (
            <React.Fragment>
              <div className='boxContainer topContainer'>
                <div className='card-body p-2'>
                  <div className='infosBox'>
                    <div className='infosSubTitle'>Balance</div>
                    <div className='infosBalance'>{accountBalance} EGLD</div>
                  </div>
                </div>
              </div>

              {mintAvailable == false && (
                <div className='boxContainer topContainer'>
                  <div className='card-body p-2'>
                    <div className='statusWarning'>
                      The mint is currently paused.
                      <br />
                      Thank you for your understanding.
                    </div>
                  </div>
                </div>
              )}

              <div className='boxContainer topContainer'>
                <div className='card-body text-center p-2'>
                  <p className='textImportant'>
                    Your faction needs you to take control of Reiwa and its
                    treasures.
                  </p>
                  <p className='textImportant'>
                    Recruit your heroes and build your army !
                  </p>
                  <p className='textImportant'>
                    Join the battles and start earning now.
                  </p>

                  <div className='mintPictureBox'>
                    <div className='cardContainerHome1'>
                      <div className='cardBox'>
                        <div className='cardBoxIn'>
                          <div className='cardInfos'>
                            <div className='cardHierarchy'>Shogun</div>
                            <div className='cardPower'>Power : 90.00</div>
                          </div>
                        </div>
                      </div>
                      <div className='cardGamePictureBox'>
                        <img src={shogun} className='cardGamePicture' />
                      </div>
                      <div className='cardInfosBis'>
                        <div className='cardNumber'>1652</div>
                        <div className='cardName'>Hero</div>
                      </div>
                    </div>
                    <div className='cardContainerHome2'>
                      <div className='cardBox'>
                        <div className='cardBoxIn'>
                          <div className='cardInfos'>
                            <div className='cardHierarchy'>Daimyos</div>
                            <div className='cardPower'>Power : 50.88</div>
                          </div>
                        </div>
                      </div>
                      <div className='cardGamePictureBox'>
                        <img src={daimyos} className='cardGamePicture' />
                      </div>
                      <div className='cardInfosBis'>
                        <div className='cardNumber'>1105</div>
                        <div className='cardName'>Hero</div>
                      </div>
                    </div>
                    <div className='cardContainerHome3'>
                      <div className='cardBox'>
                        <div className='cardBoxIn'>
                          <div className='cardInfos'>
                            <div className='cardHierarchy'>Samurai</div>
                            <div className='cardPower'>Power : 42.05</div>
                          </div>
                        </div>
                      </div>
                      <div className='cardGamePictureBox'>
                        <img src={samurai} className='cardGamePicture' />
                      </div>
                      <div className='cardInfosBis'>
                        <div className='cardNumber'>2797</div>
                        <div className='cardName'>Hero</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='boxContainer topContainer'>
                <div className='card-body text-center p-2'>
                  <div className='contentShopBox textImportant'>
                    {minusDisabled ? (
                      <Button className='btnDisabled btnNoBorder' disabled>
                        -
                      </Button>
                    ) : (
                      <Button
                        className='btnNoBorder'
                        onClick={() => handleDecrementQuantity()}
                      >
                        -
                      </Button>
                    )}
                    <span className='mintQuantity'>{mintQuantity}</span>
                    {plusDisabled ? (
                      <Button className='btnDisabled btnNoBorder' disabled>
                        +
                      </Button>
                    ) : (
                      <Button
                        className='btnNoBorder'
                        onClick={() => handleIncrementQuantity()}
                      >
                        +
                      </Button>
                    )}
                  </div>
                  <div className='contentShopBox textImportant'>
                    Price :&nbsp;
                    <span>{mintPrice} EGLD</span>
                  </div>
                  {!buyDisabled && mintAvailable ? (
                    <ReactButton
                      className='btnStandardSmallReverse'
                      onClick={handleMint}
                    >
                      MINT
                    </ReactButton>
                  ) : (
                    <ReactButton disabled className='btnStandardSmallReverse'>
                      MINT
                    </ReactButton>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Mint;
