import http from '../http-common';
import { IArenaSkillData } from '../types/arenaskills.type';

class ArenaSkillsDataService {
  create(data: IArenaSkillData) {
    return http.post<IArenaSkillData>('/arena_skills', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/arena_skills/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/arena_skills/${id}`);
  }
}

export default new ArenaSkillsDataService();
