import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { routeNames } from 'routes';

const BetaFeatures = () => {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='pageTitle'>Beta Features</div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='statusWarning'>
                Rise of Faction's upcoming features can be found here.
                <br />
                These features are currently in beta version.
              </div>
            </div>
          </div>

          {/*
          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'>
                <div className='infosSubTitle'>Arena Ranked Mode</div>
              </div>
              <div className='textImportant'>
                The new version of the Arena Ranked Mode.
              </div>

              <div className='infosBoxSub'>
                <a href={routeNames.arenaranked} rel='noreferrer'>
                  <Button className='btnStandardReverse'>Ranked Arena</Button>
                </a>
                <a href={routeNames.arenaleaderboard} rel='noreferrer'>
                  <Button className='btnStandardReverse'>
                    Ranked Arena Leaderboard
                  </Button>
                </a>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default BetaFeatures;
