import http from '../http-common';
import { ISettingData, SettingStateType } from '../types/setting.type';

class SettingDataService {
  get(id: string) {
    return http.get<ISettingData>(`/settings/${id}`);
  }

  findByName(params: any) {
    return http.get<SettingStateType>('/settings', { params });
  }
}

export default new SettingDataService();
