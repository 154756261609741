export const leaderboardsBonusEmpty: ILeaderboardsBonusData = {
  id: 0,
  id_leaderboard: 0,
  id_user: 0,
  token_identifier: '',
  income_amount: 0,
  expense_amount: 0,
  ratio_exp_inc: 0,
  battle_participation: 0,
  arena_participation: 0,
  expense_points: 0,
  ratio_points: 0,
  battle_points: 0,
  arena_points: 0
};

export interface ILeaderboardsBonusData {
  id?: any | null;
  id_leaderboard: number;
  id_user: number;
  token_identifier: string;
  income_amount: number;
  expense_amount: number;
  ratio_exp_inc: number;
  battle_participation: number;
  arena_participation: number;
  expense_points: number;
  ratio_points: number;
  battle_points: number;
  arena_points: number;
}

export interface LeaderboardsBonusStateType {
  leaderboards_bonus: ILeaderboardsBonusData;
  message?: string;
  status: string;
}
