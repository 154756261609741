import http from '../http-common';
import { IMintData, MintStateType } from '../types/mints.type';

class MintsDataService {
  create(data: IMintData) {
    return http.post<IMintData>('/mints', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/mints/${id}`, data);
  }

  findOneByTxHash(params: any) {
    return http.get<MintStateType>('/mints/', { params });
  }
}

export default new MintsDataService();
