import http from '../http-common';
import { WalletsStateType, WalletStateType } from '../types/wallets.type';

class WalletsDataService {
  update(data: any, id: any) {
    return http.put<any>(`/wallets/${id}`, data);
  }

  findOneByUserToken(params: any) {
    return http.get<WalletStateType>('/wallets/user/token', { params });
  }

  findAllByUser(id_user: any) {
    return http.get<WalletsStateType>(`/wallets/user/${id_user}`);
  }
}

export default new WalletsDataService();
