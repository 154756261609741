import { ColorsStateType } from '../types/area.type';

class AreasFonctions {
  getAreasMap(colors: ColorsStateType) {
    const AreasMap = {
      name: 'Reiwa',
      areas: [
        {
          id: 1,
          shape: 'poly',
          coords: [
            100, 1182, 102, 885, 155, 833, 227, 834, 364, 701, 404, 744, 404,
            789, 417, 802, 426, 813, 442, 822, 440, 836, 449, 845, 457, 872,
            462, 900, 458, 932, 446, 952, 440, 966, 439, 981, 439, 999, 433,
            1015, 428, 1033, 417, 1051, 411, 1073, 408, 1095, 404, 1118, 401,
            1138, 392, 1147, 382, 1164, 381, 1182, 382, 1194, 386, 1222, 386,
            1245, 384, 1267, 379, 1283, 375, 1308, 258, 1310, 258, 1232, 209,
            1182
          ],
          preFillColor: colors.colors.color[1],
          fillColor: colors.colors.colorHover[1]
        },
        {
          id: 2,
          shape: 'poly',
          coords: [
            1444, 976, 1709, 976, 1711, 769, 1663, 719, 1660, 516, 1633, 514,
            1602, 518, 1568, 523, 1539, 523, 1510, 516, 1472, 511, 1445, 507,
            1416, 507, 1396, 520, 1365, 529, 1332, 527, 1302, 523, 1287, 505,
            1262, 480, 1240, 451, 1215, 424, 1181, 413, 1152, 429, 1128, 449,
            1103, 474, 1077, 498, 1068, 527, 1090, 556, 1119, 581, 1150, 603,
            1175, 617, 1200, 632, 1227, 644, 1254, 653, 1280, 668, 1314, 695,
            1341, 713, 1368, 740, 1387, 760, 1401, 780, 1414, 822, 1428, 847,
            1434, 878, 1439, 916, 1435, 948
          ],
          preFillColor: colors.colors.color[2],
          fillColor: colors.colors.colorHover[2]
        },
        {
          id: 3,
          shape: 'poly',
          coords: [
            793, 80, 759, 80, 650, 192, 408, 198, 406, 306, 355, 359, 375, 388,
            384, 415, 382, 442, 375, 458, 393, 483, 411, 502, 433, 521, 460,
            532, 489, 539, 527, 550, 560, 550, 596, 556, 629, 577, 663, 574,
            690, 565, 724, 545, 735, 512, 757, 494, 790, 480, 818, 474, 837,
            449, 862, 422, 896, 418, 918, 395, 932, 369, 918, 339, 896, 313,
            896, 277, 893, 236, 893, 199, 891, 163, 885, 131, 862, 127, 837,
            109, 811, 96
          ],
          preFillColor: colors.colors.color[3],
          fillColor: colors.colors.colorHover[3]
        },
        {
          id: 4,
          shape: 'poly',
          coords: [
            1175, 0, 1153, 33, 1121, 60, 1119, 90, 1121, 134, 1106, 161, 1092,
            186, 1074, 212, 1066, 248, 1070, 275, 1084, 288, 1110, 299, 1128,
            302, 1135, 315, 1148, 335, 1168, 344, 1186, 342, 1209, 344, 1231,
            342, 1245, 329, 1262, 322, 1285, 322, 1309, 317, 1325, 311, 1340,
            320, 1359, 331, 1379, 340, 1403, 344, 1430, 344, 1448, 337, 1468,
            324, 1495, 322, 1517, 300, 1537, 284, 1567, 264, 1591, 257, 1609,
            242, 1634, 241, 1660, 239, 1685, 232, 1703, 217, 1709, 185, 1705,
            157, 1719, 136, 1747, 121, 1761, 101, 1768, 78, 1776, 52, 1768, 25,
            1757, 0
          ],
          preFillColor: colors.colors.color[4],
          fillColor: colors.colors.colorHover[4]
        },
        {
          id: 5,
          shape: 'poly',
          coords: [
            1329, 820, 1327, 798, 1316, 782, 1302, 769, 1289, 746, 1280, 719,
            1262, 711, 1236, 701, 1227, 681, 1207, 677, 1186, 668, 1159, 644,
            1142, 644, 1133, 628, 1119, 628, 1106, 610, 1088, 596, 1075, 585,
            1063, 585, 1052, 572, 1046, 579, 1046, 608, 1050, 623, 1050, 655,
            1059, 670, 1063, 697, 1063, 755, 1052, 762, 1050, 858, 1037, 865,
            1016, 869, 1005, 871, 998, 885, 998, 965, 985, 970, 961, 968, 947,
            976, 942, 997, 947, 1051, 965, 1071, 983, 1073, 1019, 1070, 1054,
            1051, 1079, 1044, 1113, 1050, 1157, 1041, 1200, 1037, 1213, 1026,
            1222, 986, 1222, 957, 1240, 948, 1260, 948, 1260, 903, 1262, 860,
            1283, 834, 1303, 824
          ],
          preFillColor: colors.colors.color[5],
          fillColor: colors.colors.colorHover[5]
        },
        {
          id: 6,
          shape: 'poly',
          coords: [
            1041, 568, 1017, 529, 1001, 500, 998, 487, 989, 483, 969, 442, 960,
            433, 947, 433, 934, 444, 925, 458, 900, 465, 884, 467, 882, 480,
            876, 489, 860, 496, 828, 511, 817, 525, 808, 534, 793, 534, 782,
            539, 764, 547, 770, 559, 759, 572, 742, 588, 730, 605, 730, 623,
            735, 653, 742, 672, 748, 691, 748, 720, 746, 748, 746, 778, 739,
            808, 737, 848, 730, 863, 719, 877, 719, 910, 719, 946, 764, 946,
            809, 943, 811, 961, 983, 964, 996, 960, 996, 867, 1028, 863, 1046,
            852, 1048, 755, 1061, 748, 1063, 702, 1057, 674, 1048, 658, 1048,
            625, 1043, 588
          ],
          preFillColor: colors.colors.color[6],
          fillColor: colors.colors.colorHover[6]
        },
        {
          id: 7,
          shape: 'poly',
          coords: [
            938, 966, 808, 966, 802, 947, 719, 949, 719, 984, 719, 1054, 714,
            1061, 600, 1061, 448, 1207, 410, 1211, 410, 1243, 406, 1272, 404,
            1316, 417, 1348, 420, 1370, 446, 1377, 491, 1361, 522, 1350, 553,
            1328, 572, 1321, 596, 1305, 607, 1317, 641, 1285, 629, 1269, 647,
            1258, 677, 1252, 701, 1254, 715, 1229, 746, 1232, 771, 1236, 795,
            1234, 817, 1223, 833, 1223, 864, 1231, 875, 1207, 896, 1193, 931,
            1182, 940, 1175, 954, 1160, 965, 1138, 963, 1075, 940, 1061, 936,
            1039, 938, 997
          ],
          preFillColor: colors.colors.color[7],
          fillColor: colors.colors.colorHover[7]
        },
        {
          id: 8,
          shape: 'poly',
          coords: [
            715, 1017, 717, 874, 732, 851, 734, 831, 739, 797, 744, 765, 743,
            692, 734, 663, 730, 645, 714, 643, 699, 655, 683, 661, 670, 675,
            658, 686, 636, 703, 603, 704, 572, 703, 565, 717, 542, 710, 527,
            719, 507, 721, 484, 728, 464, 739, 451, 750, 442, 764, 442, 782,
            458, 802, 478, 807, 478, 827, 478, 853, 477, 878, 473, 891, 473,
            909, 475, 934, 467, 952, 457, 979, 455, 1010, 448, 1030, 437, 1059,
            433, 1090, 419, 1131, 419, 1142, 411, 1160, 408, 1177, 410, 1196,
            410, 1206, 448, 1203, 489, 1165, 524, 1129, 569, 1085, 601, 1057,
            648, 1059, 686, 1059, 714, 1057
          ],
          preFillColor: colors.colors.color[8],
          fillColor: colors.colors.colorHover[8]
        },
        {
          id: 9,
          shape: 'poly',
          coords: [
            1329, 823, 1303, 825, 1287, 834, 1276, 848, 1267, 861, 1264, 883,
            1262, 953, 1242, 951, 1229, 959, 1224, 978, 1215, 1025, 1200, 1042,
            1177, 1042, 1150, 1045, 1115, 1053, 1075, 1049, 1045, 1060, 1019,
            1074, 967, 1076, 967, 1100, 967, 1138, 981, 1118, 1001, 1110, 1012,
            1116, 1019, 1123, 1032, 1132, 1043, 1134, 1055, 1138, 1070, 1143,
            1090, 1134, 1097, 1127, 1110, 1129, 1112, 1150, 1128, 1163, 1160,
            1159, 1222, 1167, 1267, 1150, 1338, 1110, 1374, 1078, 1381, 1031,
            1394, 975, 1379, 928, 1365, 915, 1350, 877, 1347, 861
          ],
          preFillColor: colors.colors.color[9],
          fillColor: colors.colors.colorHover[9]
        },
        {
          id: 10,
          shape: 'poly',
          coords: [
            364, 696, 408, 741, 428, 723, 444, 723, 442, 709, 455, 709, 469,
            700, 482, 698, 496, 693, 513, 696, 524, 689, 581, 693, 591, 694,
            607, 684, 629, 680, 645, 674, 661, 660, 677, 651, 688, 647, 697,
            635, 706, 622, 708, 606, 715, 586, 728, 564, 719, 548, 694, 562,
            670, 571, 638, 577, 614, 566, 592, 551, 560, 551, 533, 555, 509,
            550, 484, 542, 462, 535, 430, 526, 363, 591
          ],
          preFillColor: colors.colors.color[10],
          fillColor: colors.colors.colorHover[10]
        },
        {
          id: 11,
          shape: 'poly',
          coords: [
            1660, 474, 1756, 381, 1656, 284, 1656, 246, 1636, 242, 1620, 240,
            1602, 246, 1589, 258, 1575, 267, 1557, 267, 1548, 280, 1535, 284,
            1520, 293, 1506, 305, 1490, 316, 1481, 323, 1466, 331, 1443, 340,
            1423, 343, 1406, 345, 1383, 343, 1365, 333, 1352, 323, 1338, 320,
            1323, 313, 1307, 316, 1291, 322, 1276, 322, 1260, 323, 1247, 331,
            1238, 342, 1224, 343, 1206, 345, 1186, 343, 1171, 340, 1157, 333,
            1144, 340, 1133, 360, 1131, 378, 1146, 394, 1164, 403, 1184, 412,
            1204, 418, 1222, 428, 1233, 445, 1244, 457, 1251, 470, 1267, 483,
            1278, 492, 1287, 504, 1292, 515, 1311, 522, 1330, 524, 1350, 526,
            1368, 524, 1392, 521, 1406, 508, 1423, 504, 1448, 504, 1463, 510,
            1482, 513, 1497, 517, 1522, 517, 1544, 524, 1564, 519, 1584, 517,
            1607, 513, 1631, 512, 1660, 513
          ],
          preFillColor: colors.colors.color[11],
          fillColor: colors.colors.colorHover[11]
        }
      ]
    };
    return AreasMap;
  }
}
export default new AreasFonctions();
