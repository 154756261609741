//export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const walletConnectV2Token = '1b2277c490924b12b61da6e8389293fa';
export const apiTimeout = 6000;

export const googleClientID =
  '653587982934-jo6ql32jjt9uvivltgmvju2fu4rd9192.apps.googleusercontent.com';

export const dAppName = 'J-Corp | Rise of Factions';

export const mintLink = 'https://trust.market/buy/JCORP';
export const xoxnoLink = 'https://xoxno.com/collection/HERO-6271d1';
export const deadrareLink = 'https://deadrare.io/collection/HERO-6271d1';

export const initScore = 1000;
export const leaderboardMaxRanking = 50;

export const default_power = 33;
export const nft_power_max = 90;

export const maxSquads = 3;
export const maxNFTsPerSquad = 100;

export const idItemPowerPotion = 11;
export const idItemLifePotion = 12;
export const idItemSpeedPotion = 13;

export const collections = 'HERO-6271d1';
export const tokensList = 'JCORP-5639cb';
export const tokensWallet = 'JCORP-5639cb';

export const tokensListNames = [
  {
    token_identifier: 'JCORP-5639cb',
    name: 'JCORP'
  },
  {
    token_identifier: 'shard',
    name: 'SHARD'
  }
];

export const jcorpWallet =
  'erd1rnwt72kg4p9t9arf3l8c9x8427pwj28nuj3c2msgr734ygrd29uskpa7eh';
export const jcorpTreasuryWallet =
  'erd174gw7cvq4ptqzp2ra6wf8lc7fmrn03trv2rkwzsnjsq3kma3tlps68jues';

export const network: NetworkType = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.multiversx.com',
  apiAddress: 'https://api.elrond.com',
  gatewayAddress: 'https://gateway.multiversx.com',
  explorerAddress: 'https://explorer.multiversx.com'
};

interface NetworkType {
  id: 'devnet' | 'testnet' | 'mainnet';
  name: string;
  egldLabel: string;
  walletAddress: string;
  gatewayAddress: string;
  explorerAddress: string;
  apiAddress: string;
}
