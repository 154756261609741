import * as React from 'react';
import { Pagination } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { tokensListNames } from 'config';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import FactionDataService from '../../services/faction.service';
import FactionsShopsDataService from '../../services/factionsshops.service';
import ItemsDataService from '../../services/items.service';
import { FactionStateType, factionEmpty } from '../../types/faction.type';
import { FactionShopsStateType } from '../../types/factionsshops.type';
import { ItemsStateType } from '../../types/items.type';
import angelic from './../../assets/img/angelic.png';
import meiyo from './../../assets/img/meiyo.png';
import night from './../../assets/img/night.png';

const FactionMarketplaceHistory = () => {
  const refToTop = React.useRef<HTMLInputElement>(null);

  const { userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const pageSize = 50;

  const [factionImg, setFactionImg] = React.useState('');
  const [factionInfos, setFactionInfos] = React.useState<FactionStateType>({
    faction: factionEmpty,
    message: '',
    status: ''
  });
  const [itemsInfos, setItemsInfos] = React.useState<ItemsStateType>({
    items: [],
    message: '',
    status: ''
  });
  const [shopsInfos, setShopsInfos] = React.useState<FactionShopsStateType>({
    totalItems: 0,
    totalPages: 0,
    shops: [],
    message: '',
    status: ''
  });

  const handlePageChange = (event: any, value: any) => {
    setPage(value);

    refToTop.current &&
      refToTop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
  };

  const fetchFactionsData = () => {
    FactionDataService.findOneById(userInfos.user.id_faction).then(
      ({ data }) => {
        if (data.status == 'success') {
          setFactionInfos({
            faction: data.faction,
            status: data.status
          });
        }
      }
    );
  };

  const fetchFactionInfos = () => {
    switch (userInfos.user.id_faction) {
      case 1:
        setFactionImg(angelic);
        break;
      case 2:
        setFactionImg(night);
        break;
      case 3:
        setFactionImg(meiyo);
        break;
    }
  };

  const fetchItemsData = () => {
    ItemsDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setItemsInfos({
          items: data.items,
          status: data.status
        });
      }
    });
  };

  const fetchShopsData = () => {
    const params: any = {};
    params['page'] = page - 1;
    params['size'] = pageSize;

    FactionsShopsDataService.findAllByFaction(
      userInfos.user.id_faction,
      params
    ).then(({ data }) => {
      if (data.status == 'success') {
        setShopsInfos({
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          shops: data.shops,
          status: data.status
        });
      }
    });
  };

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      fetchFactionsData();
      fetchFactionInfos();
      fetchItemsData();
    }
  }, []);

  React.useEffect(() => {
    fetchShopsData();
  }, [page]);

  const { faction } = factionInfos;
  const { items } = itemsInfos;
  const { totalPages, shops } = shopsInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='infosConquestBox'>
                <div className='pageTitle'>Faction Marketplace History</div>
              </div>
            </div>
          </div>

          <div className='bigBox'>
            <div className='stripContainerRight stripContainerRightAdjusted'>
              <div className='stripBoxRight'>
                <div className='stripBoxRightRank'>
                  <div className='stripInfos'>
                    <div className='stripInfosIn'>
                      <div className='stripInfosText'>{faction.name}</div>
                    </div>
                  </div>
                </div>
                <div className='stripBoxRightBtn'>
                  <div className='imgBoxFaction'>
                    <img src={`${factionImg}`} className='imgFactionSmall' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='smallBox'>
            <div className='boxContainer topArenaContainer'>
              <div className='stripContainerRight'>
                <div className='stripBoxRight'>
                  <div className='stripBoxRightRank'>
                    <div className='stripInfos'>
                      <div className='stripInfosIn'>
                        <div className='stripInfosText'>{faction.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className='stripBoxRightBtn'>
                    <div className='imgBoxFaction'>
                      <img src={`${factionImg}`} className='imgFactionSmall' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer'>
            <div className='card-body p-2'>
              <div className='infosBox'></div>
              <div className='infosBoxSub'>
                <div className='buttonsNFTContainerBis'>
                  <Button
                    className='btnStandardReverse'
                    onClick={() => navigate(routeNames.factionmarketplace)}
                  >
                    Faction Marketplace
                  </Button>
                  <Button
                    className='btnStandard'
                    onClick={() => navigate(routeNames.factioninventory)}
                  >
                    Faction Inventory
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='boxContainer topContainer' ref={refToTop}>
            <div className='card-body text-center p-2'>
              <div className='table-responsive'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0'>Date</th>
                      <th className='border-0'>User</th>
                      <th className='border-0'>Item</th>
                      <th className='border-0'>Quantity</th>
                      <th className='border-0'>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shops.map((shop, i) => {
                      let token_name = '';
                      let item_name = '';

                      if (shop.token_identifier == 'EGLD') {
                        token_name = 'EGLD';
                      } else {
                        tokensListNames.map((token) => {
                          if (shop.token_identifier == token.token_identifier) {
                            token_name = token.name;
                          }
                        });
                      }
                      items.map((item) => {
                        if (item.id_item == shop.id_item) {
                          item_name = item.item_name;
                        }
                      });

                      let timeDate = '';
                      if (shop.createdAt != null) {
                        const time = new Date(shop.createdAt);
                        timeDate =
                          time.toLocaleDateString() +
                          ' ' +
                          time.toLocaleTimeString();
                      }

                      const price = shop.amount.toLocaleString('en-GB', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 3
                      });

                      return (
                        <tr key={i} className='rowTableContent textResult'>
                          <td>
                            <span>{timeDate}</span>
                          </td>
                          <td>
                            <span>{shop.user?.pseudo}</span>
                          </td>
                          <td>
                            <span>{item_name}</span>
                          </td>
                          <td>
                            <span>{shop.quantity}</span>
                          </td>
                          <td>
                            <span>
                              {price} {token_name}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {shops.length == 0 && (
                      <tr className='rowTableContent textResult'>
                        <td colSpan={5}>
                          <span>No faction marketplace purchase</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <React.Fragment>
              {totalPages > 1 && (
                <div className='paginBoxBottom'>
                  <Pagination
                    className='paginContainer'
                    count={totalPages}
                    defaultPage={1}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    shape='rounded'
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactionMarketplaceHistory;
