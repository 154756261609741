import * as React from 'react';
//import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
  useGetPendingTransactions,
  useGetFailedTransactions,
  useGetSuccessfulTransactions
} from '@multiversx/sdk-dapp/hooks';
//import InventoriesDataService from '../../../services/inventories.service';
import MintsDataService from '../../../services/mints.service';
//import ShopsDataService from '../../../services/shops.service';
//import UserDataService from '../../../services/user.service';
//import { IItemCreationData } from '../../../types/inventories.type';
//import { IItemData } from '../../../types/items.type';
import { IMintData } from '../../../types/mints.type';
//import { IShopData } from '../../../types/shops.type';
//import { IUserData } from '../../../types/user.type';

const TxTracking = () => {
  //const { account } = useGetAccountInfo();

  const PendingTx = useGetPendingTransactions();
  const FailedTx = useGetFailedTransactions();
  const SuccessfulTx = useGetSuccessfulTransactions();
  //const CompletedTx = useGetCompletedTransactions(); // ==> CompletedTx is for success tx with SmartContract calls

  React.useEffect(() => {
    if (PendingTx.pendingTransactionsArray.length > 0) {
      CheckPending();
    }
  }, [PendingTx.pendingTransactionsArray.length]);

  React.useEffect(() => {
    if (FailedTx.failedTransactionsArray.length > 0) {
      CheckFailed();
    }
  }, [FailedTx.failedTransactionsArray.length]);

  React.useEffect(() => {
    if (SuccessfulTx.successfulTransactionsArray.length > 0) {
      CheckSuccessful();
    }
  }, [SuccessfulTx.successfulTransactionsArray.length]);

  /*React.useEffect(() => {
    if (CompletedTx.completedTransactionsArray.length > 0) {
      CheckCompletedTx();
    }
  }, [CompletedTx.completedTransactionsArray.length]);*/

  function CheckPending() {
    //console.log('>>>>> PendingTx : ', PendingTx);
    const PendingTxArray = PendingTx.pendingTransactionsArray;
    for (let i = 0; i < PendingTxArray.length; i++) {
      //const transactionSessionId = PendingTxArray[i][0];
      if (PendingTxArray[i][1].transactions != null) {
        const transaction = PendingTxArray[i][1].transactions[0]; // ==> transactions[0] because we create only 1 tx by transactionSessionId
        const sessionInformation =
          PendingTxArray[i][1].customTransactionInformation.sessionInformation;

        if (sessionInformation != null) {
          const userId = sessionInformation.userId;
          const type = sessionInformation.type;
          const data = sessionInformation.data;

          switch (type) {
            case 'item':
              //// Create the tx in the database if the tx don't exist
              //createShopsData(userId, data, transaction);
              break;
            case 'mint':
              //// Create the tx in the database if the tx don't exist
              createMintsData(userId, data, transaction);
              break;
            default:
              break;
          }
        }
      }
    }
  }

  function CheckFailed() {
    //console.log('>>>>> FailedTx : ', FailedTx);
    const FailedTxArray = FailedTx.failedTransactionsArray;
    for (let i = 0; i < FailedTxArray.length; i++) {
      //const transactionSessionId = FailedTxArray[i][0];
      if (FailedTxArray[i][1].transactions != null) {
        const transaction = FailedTxArray[i][1].transactions[0]; // ==> transactions[0] because we create only 1 tx by transactionSessionId
        const sessionInformation =
          FailedTxArray[i][1].customTransactionInformation.sessionInformation;

        if (sessionInformation != null) {
          const type = sessionInformation.type;

          switch (type) {
            case 'item':
              //// Update the status of the tx in the database
              //updateShopsData(transaction);
              break;
            case 'mint':
              //// Update the status of the tx in the database
              updateMintsData(transaction);
              break;
            default:
              break;
          }
        }
      }

      //// Remove the tx with the corresponding transactionSessionId from failedTransactionsArray
      //// ==> by doing so, the toast with a failed status will not be shown
      //transactionServices.removeSignedTransaction(transactionSessionId);
    }
  }

  function CheckSuccessful() {
    //console.log('>>>>> SuccessfulTx : ', SuccessfulTx);
    const SuccessfulTxArray = SuccessfulTx.successfulTransactionsArray;
    for (let i = 0; i < SuccessfulTxArray.length; i++) {
      //const transactionSessionId = SuccessfulTxArray[i][0];
      if (SuccessfulTxArray[i][1].transactions != null) {
        const transaction = SuccessfulTxArray[i][1].transactions[0]; // ==> transactions[0] because we create only 1 tx by transactionSessionId
        const sessionInformation =
          SuccessfulTxArray[i][1].customTransactionInformation
            .sessionInformation;

        if (sessionInformation != null) {
          const type = sessionInformation.type;

          switch (type) {
            case 'item':
              //// Update the status of the tx in the database
              //updateShopsData(transaction);
              break;
            case 'mint':
              //// Update the status of the tx in the database
              updateMintsData(transaction);
              break;
            default:
              break;
          }
        }
      }

      //// Remove the tx with the corresponding transactionSessionId from successfulTransactionsArray
      //// ==> by doing so, the toast with a successful status will not be shown
      //transactionServices.removeSignedTransaction(transactionSessionId);
    }
  }

  /*
  function CheckCompletedTx() {
    //console.log('>>>>> CompletedTx : ', CompletedTx);
    const CompletedTxTxArray = CompletedTx.completedTransactionsArray;
    for (let i = 0; i < CompletedTxTxArray.length; i++) {
      //const transactionSessionId = CompletedTxTxArray[i][0];
      if (CompletedTxTxArray[i][1].transactions != null) {
        const transaction = CompletedTxTxArray[i][1].transactions[0]; // ==> transactions[0] because we create only 1 tx by transactionSessionId
        const sessionInformation =
          CompletedTxTxArray[i][1].customTransactionInformation
            .sessionInformation;

        console.log('CheckCompletedTx transaction : ', transaction);
        console.log(
          'CheckCompletedTx sessionInformation : ',
          sessionInformation
        );

        if (sessionInformation != null) {
          const type = sessionInformation.type;

          switch (type) {
            default:
              break;
          }
        }
      }

      //// Remove the tx with the corresponding transactionSessionId from completedTransactionsArray
      //// ==> by doing so, the toast with a successful status will not be shown
      //transactionServices.removeSignedTransaction(transactionSessionId);
    }
  }
  */

  /*function createShopsData(userId: any, item: IItemData, transaction: any) {
    if (userId != null && item != null) {
      const amount = item.item_cost * item.item_quantity;

      const dataShopCreation: IShopData = {
        id_user: userId,
        id_item: item.id_item,
        quantity: item.item_quantity,
        amount: amount,
        token_identifier: item.token_identifier,
        tx: transaction.hash,
        status: transaction.status
      };

      ShopsDataService.create(dataShopCreation).then((dataShop) => {
        if (dataShop.data.status == 'success') {
          const params: any = {};
          params['txhash'] = transaction.hash;

          ShopsDataService.findOneByTxHash(params).then(({ data }) => {
            if (data.status == 'success') {
              const params2: any = {};
              params2['address'] = account.address;

              UserDataService.findByAddress(params2).then(async function (
                userData
              ) {
                if (userData.data.status == 'success') {
                  const userInfosData: IUserData = userData.data.user;

                  let num_item = userInfosData.num_item;
                  const new_num_item = num_item + item.item_quantity;

                  const dataUser: any = {
                    num_item: new_num_item
                  };

                  await UserDataService.update(dataUser, userInfosData.id_user);

                  for (let j = 0; j < item.item_quantity; j++) {
                    num_item++;

                    const identifier =
                      item.item_identifier +
                      '-' +
                      userInfosData.id_user +
                      '-' +
                      num_item;

                    const dataItem: IItemCreationData = {
                      id_user: userInfosData.id_user,
                      id_shop: data.shop.id_shop,
                      id_item: item.id_item,
                      identifier: identifier,
                      flg_used: false,
                      flg_delivered: false,
                      flg_claimed: false
                    };

                    await InventoriesDataService.create(dataItem);
                  }
                }
              });
            }
          });
        }
      });
    }
  }*/

  /*function updateShopsData(transaction: any) {
    const params: any = {};
    params['txhash'] = transaction.hash;

    ShopsDataService.findOneByTxHash(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.shop.status == 'pending') {
          const dataShop: any = {
            status: transaction.status
          };

          if (transaction.status == 'success') {
            const quantity = data.shop.quantity;

            InventoriesDataService.findAllByIdShop(data.shop.id_shop).then(
              (inventoryData) => {
                if (inventoryData.data.status == 'success') {
                  if (quantity == inventoryData.data.inventory.length) {
                    const dataItem: any = {
                      flg_delivered: true
                    };

                    InventoriesDataService.updateAllByIdShop(
                      dataItem,
                      data.shop.id_shop
                    );

                    ShopsDataService.update(dataShop, data.shop.id_shop);
                  }
                }
              }
            );
          } else {
            ShopsDataService.update(dataShop, data.shop.id_shop);
          }
        }
      }
    });
  }*/

  function createMintsData(userId: any, mintData: any, transaction: any) {
    if (userId != null && mintData != null) {
      const dataMintCreation: IMintData = {
        id_user: userId,
        mint_type: mintData.mintType,
        quantity: mintData.mintQuantity,
        amount: mintData.mintPrice,
        token_identifier: mintData.mintToken,
        tx: transaction.hash,
        status: transaction.status
      };

      MintsDataService.create(dataMintCreation);
    }
  }

  function updateMintsData(transaction: any) {
    const params: any = {};
    params['txhash'] = transaction.hash;

    MintsDataService.findOneByTxHash(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.mint.status == 'pending') {
          const dataMint: any = {
            status: transaction.status
          };

          MintsDataService.update(dataMint, data.mint.id);
        }
      }
    });
  }
};

export default TxTracking;
