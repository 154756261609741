import http from '../http-common';
import {
  IWalletEventData,
  WalletEventStateType,
  WalletsEventsStateType,
  WalletEventCreation
} from '../types/wallets_events.type';

class WalletsEventsDataService {
  create(data: IWalletEventData) {
    return http.post<IWalletEventData>('/wallets_events', data);
  }

  createEvent(data: any) {
    return http.post<WalletEventCreation>('/wallets_events/new', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/wallets_events/${id}`, data);
  }

  findOneByTxHash(params: any) {
    return http.get<WalletEventStateType>('/wallets_events/', { params });
  }

  findAllByUser(id_user: any, params: any) {
    return http.get<WalletsEventsStateType>(`/wallets_events/user/${id_user}`, {
      params
    });
  }
}

export default new WalletsEventsDataService();
