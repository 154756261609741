import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import ledger from 'assets/img/ledger.png';
import multiversx from 'assets/img/multiversx.png';
import multiversx_defi from 'assets/img/multiversx_defi.png';
import { setAccountData, getAccountData } from 'common/user-account';
import { googleClientID, walletConnectV2Token } from 'config';
import { routeNames } from 'routes';
import UserDataService from 'services/user.service';

const UnlockPage = () => {
  const mvx = useGetAccountInfo();
  const { userAccount } = getAccountData();

  const navigate = useNavigate();
  const { search } = useLocation();
  const targetURL = new URLSearchParams(search).get('target');

  const commonProps = {
    callbackRoute: routeNames.unlock + search
  };

  const [isLoggedIn, setIsLoggedIn] = React.useState(Boolean(userAccount));

  const [connexionError, setConnexionError] = React.useState('');

  const googleConnectSuccess = (res: any) => {
    const params: any = {};
    params['clientId'] = googleClientID;
    params['jwtToken'] = res.credential;

    UserDataService.authenticate(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.payload.email_verified) {
          setAccountData('account', data.payload.email);
          setAccountData('provider', 'google');
          setIsLoggedIn(Boolean(data.payload.email));
        } else {
          setConnexionError('your email address must be verified');
        }
      }
    });
  };

  const googleConnectError = (err: any) => {
    // TODO
    console.log('googleConnectError : ', err);
  };

  React.useEffect(() => {
    if (!isLoggedIn && mvx.account.address != '') {
      setAccountData('provider', 'multiversx');
      setIsLoggedIn(Boolean(mvx.account.address));
    }
  }, [isLoggedIn, mvx.account.address]);

  const fetchUserData = () => {
    const params: any = {};
    params['address'] = userAccount;

    UserDataService.findByAddress(params).then(({ data }) => {
      if (data.status == 'success') {
        if (data.user.id_faction == 0) {
          handleCloseConnect(true);
        } else {
          handleCloseConnect(false);
        }
      } else {
        UserDataService.createAndInitialize(params).then((dataCreation) => {
          if (dataCreation.data.status == 'success') {
            handleCloseConnect(true);
          }
        });
      }
    });
  };

  const handleCloseConnect = (flg_profile: boolean) => {
    if (flg_profile) {
      navigate(routeNames.editprofile);
    } else {
      if (targetURL != null) {
        // TODO
        setAccountData('gameMode', 'conquest');
        navigate('/' + targetURL);
      } else {
        navigate(routeNames.menu);
      }
    }
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      fetchUserData();
    }
  }, [isLoggedIn]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageTitle'>
                Connect to Rise of Factions
                {/* <OverlayTrigger
                  placement='top'
                  containerPadding={20}
                  overlay={
                    <Tooltip id='tooltipConnect' className='textTooltip'>
                      Connect securely using one of the provided options
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className='iconInfoConnect'
                  />
                </OverlayTrigger> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-md-8 col-sm-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body text-center p-2'>
              <div className='textImportant'>
                Connect securely using one of the provided options
              </div>
            </div>
          </div>
          {connexionError && (
            <div className='boxContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>{connexionError}</div>
              </div>
            </div>
          )}
          <div className='boxContainer'>
            <div className='card-body text-center p-2'>
              <div className='blankBox'>
                <div className='labelConnect'>
                  <div className='textTitle'>Google</div>
                </div>
                <div className='alternativeConnectBtn'>
                  <GoogleLogin
                    onSuccess={googleConnectSuccess}
                    onError={() => googleConnectError}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='boxContainer'>
            <div className='card-body text-center p-2'>
              <div className='blankBox'>
                <div className='labelConnect'>
                  <div className='textTitle'>MultiversX</div>
                </div>
                <ExtensionLoginButton className='connectBtn'>
                  <span className='iconsLeftBlack'>
                    <img src={multiversx_defi} width='48px' />
                  </span>
                  <span className='connectBtnText'>MultiversX DeFi Wallet</span>
                </ExtensionLoginButton>
                <WalletConnectLoginButton
                  className='connectBtn'
                  {...(walletConnectV2Token ? { isWalletConnectV2: true } : {})}
                >
                  <span className='iconsLeftBlack'>
                    <img src={multiversx} width='48px' />
                  </span>
                  <span className='connectBtnText'>xPortal App</span>
                </WalletConnectLoginButton>
                <WebWalletLoginButton className='connectBtn' {...commonProps}>
                  <span className='iconsLeftBlack'>
                    <img src={multiversx} width='48px' />
                  </span>
                  <span className='connectBtnText'>MultiversX Web Wallet</span>
                </WebWalletLoginButton>
                <LedgerLoginButton className='connectBtn' {...commonProps}>
                  <span className='iconsLeftBlack'>
                    <img src={ledger} width='48px' />
                  </span>
                  <span className='connectBtnText'>Ledger</span>
                </LedgerLoginButton>
                <div className='margin10 textImportant text-center'>
                  New to MultiversX?
                  <div className='margin10'>
                    <a
                      href='https://wallet.multiversx.com/create'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Learn How to setup a wallet
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockPage;
