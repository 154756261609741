import http from '../http-common';
import { LeaderboardsLogsStateType } from '../types/leaderboardslogs.type';

class LeaderboardsLogsDataService {
  findAllByLeaderboard(id_leaderboard: any) {
    return http.get<LeaderboardsLogsStateType>(
      `/leaderboardslogs/leaderboard/${id_leaderboard}`
    );
  }

  findAllLeaderboardId() {
    return http.get<LeaderboardsLogsStateType>(
      '/leaderboardslogs/id_leaderboard/'
    );
  }
}

export default new LeaderboardsLogsDataService();
