import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../services/attributs.service';
import BonusesDataService from '../../services/bonuses.service';
import ConquestDataService from '../../services/conquest.service';
import SettingDataService from '../../services/setting.service';
import SquadsDataService from '../../services/squads.service';
import { AttributsStateType } from '../../types/attributs.type';
import { GameBonusesStateType } from '../../types/bonuses.type';
import { ConquestsStateType } from '../../types/conquest.type';
import { SquadsStateType } from '../../types/squads.type';
import Battle from './Battle';
import Deployment from './Deployment';

const Conquest = () => {
  const { userSession, userInfos } = useAuthContext();

  const navigate = useNavigate();

  const [currentConquest, setCurrentConquest] = React.useState(0);
  const [currentPhase, setCurrentPhase] = React.useState(0);

  const [userLeader, setUserLeader] = React.useState(false);

  const [gameBonusesInfos, setGameBonusesInfos] =
    React.useState<GameBonusesStateType>({
      bonuses: [],
      message: '',
      status: ''
    });
  const [conquestInfos, setConquestInfos] = React.useState<ConquestsStateType>({
    conquests: [],
    message: '',
    status: ''
  });
  const [listNFTs, setListNFTs] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });
  const [squadsInfos, setSquadsInfos] = React.useState<SquadsStateType>({
    squads: [],
    message: '',
    status: ''
  });

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'phase';

    // Fetch of the current phase in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentPhase(data.setting.value);
      }
    });

    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const fetchSquadsData = () => {
    SquadsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        setSquadsInfos({
          squads: data.squads,
          status: data.status
        });
      }
    });
  };

  const fetchNFTsData = () => {
    AttributsDataService.findAllByUser(userSession.userID).then(({ data }) => {
      if (data.status == 'success') {
        //data.attributs.sort((a, b) => b.nft_power - a.nft_power);
        setListNFTs({
          attributs: data.attributs,
          status: data.status
        });
      }
    });
  };

  const fetchGameBonusesData = () => {
    const params: any = {};
    params['id_conquest'] = currentConquest;

    BonusesDataService.getAllGameBonuses(params).then(({ data }) => {
      if (data.status == 'success') {
        setGameBonusesInfos({
          bonuses: data.bonuses,
          status: data.status
        });
      }
    });
  };

  const fetchConquestData = () => {
    const params: any = {};
    params['id_conquest'] = currentConquest;
    params['id_user'] = userSession.userID;

    ConquestDataService.findAllByUser(params).then(({ data }) => {
      if (data.status == 'success') {
        setConquestInfos({
          conquests: data.conquests,
          status: data.status
        });
      }
    });
  };

  const fetchUserInfos = () => {
    if (
      userInfos.user.id_user == userInfos.user.faction?.id_user ||
      userInfos.user.flg_leader
    ) {
      setUserLeader(true);
    } else {
      setUserLeader(false);
    }
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (userInfos.user.id_faction == 0) {
      navigate(routeNames.editprofile);
    } else {
      if (currentConquest != 0) {
        fetchUserInfos();
        fetchGameBonusesData();
        fetchConquestData();
        fetchNFTsData();
        fetchSquadsData();
      }
    }
  }, [currentConquest]);

  if (
    userInfos.status == 'success' &&
    conquestInfos.status == 'success' &&
    gameBonusesInfos.status == 'success' &&
    listNFTs.status == 'success' &&
    squadsInfos.status == 'success'
  ) {
    return (
      <React.Fragment>
        {currentPhase == 2 && (
          <Deployment
            userInfos={userInfos}
            gameBonusesInfos={gameBonusesInfos}
            conquestInfosInit={conquestInfos}
            listNFTs={listNFTs}
            squadsInfos={squadsInfos}
            userLeader={userLeader}
            isBlockchainAccount={userSession.isBlockchainAccount}
          />
        )}
        {currentPhase == 3 && (
          <Battle
            userInfos={userInfos}
            gameBonusesInfos={gameBonusesInfos}
            conquestInfosInit={conquestInfos}
            userLeader={userLeader}
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='boxLoadingContent'>
              <div className='card-body text-center p-2'>
                <div className='lds-facebook'>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className='boxContainer'>
                  <div className='infosModalTx'>Conquest loading ...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Conquest;
