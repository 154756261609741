import http from '../http-common';
import {
  IUserData,
  UserStateType,
  UsersStateType,
  UsersStatsStateType
} from '../types/user.type';

class UserDataService {
  getAll() {
    return http.get<Array<IUserData>>('/users');
  }

  get(id: string) {
    return http.get<IUserData>(`/users/${id}`);
  }

  create(data: IUserData) {
    return http.post<IUserData>('/users', data);
  }

  update(data: any, id: any) {
    return http.put<UserStateType>(`/users/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/users/${id}`);
  }

  deleteAll() {
    return http.delete<any>('/users');
  }

  createAndInitialize(params: any) {
    return http.get<any>('/users/initialize', { params });
  }

  refreshUserAttributs(params: any) {
    return http.get<any>('/users/refreshAttr', { params });
  }

  findLast() {
    return http.get<any>('/users/last');
  }

  findByAddress(params: any) {
    return http.get<UserStateType>('/users', { params });
  }

  findAllByFaction(id_faction: any) {
    return http.get<UsersStateType>(`/users/faction/${id_faction}`);
  }

  countUsers() {
    return http.get<UsersStatsStateType>('/users/countUsers');
  }

  authenticate(params: any) {
    return http.get<any>('/users/authenticate', { params });
  }
}

export default new UserDataService();
