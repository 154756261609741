import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import routes, { routeNames } from 'routes';

const InternalAuthRoutesWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { userSession } = useAuthContext();

  const isLoggedIn = Boolean(userSession.userAccount);
  const location = useLocation();
  const pathname = location.pathname.split('/');

  const authenticatedRoutesRef = React.useRef(
    routes.filter(function (route) {
      return Boolean(route.authenticatedRoute);
    })
  );

  const isOnAuthenticatedRoute = authenticatedRoutesRef.current.some(function (
    route
  ) {
    const path = route.path.split('/');
    return pathname[1] === path[1];
  });

  const shouldRedirect = isOnAuthenticatedRoute && !isLoggedIn;

  if (shouldRedirect) {
    window.location.href = routeNames.unlock;

    return null;
  }

  return React.createElement(React.Fragment, null, children);
};

export default InternalAuthRoutesWrapper;
