export const squadEmpty: ISquadData = {
  id_squad: 0,
  id_user: 0,
  name: '',
  icon: '',
  color: ''
};

export interface ISquadData {
  id_squad?: any | null;
  id_user: number;
  name: string;
  icon: string;
  color: string;
  attributs?: IAttributData[];
}

export interface IAttributData {
  id_nft: number;
  nft_identifier: string;
  nft_name: string;
  nft_url: string;
  nft_hierarchy: string;
  nft_id_hierarchy: number;
  nft_power: number;
}

export interface SquadsStateType {
  squads: ISquadData[];
  message?: string;
  status: string;
}

export interface SquadStateType {
  squad: ISquadData;
  message?: string;
  status: string;
}

export const squadGlobalInfosEmpty: ISquadsGlobalInfos = {
  id_squad: 0,
  nb_nfts: 0,
  tot_power: 0
};

export interface ISquadsGlobalInfos {
  id_squad: number;
  nb_nfts: number;
  tot_power: number;
}

export interface SquadsGlobalInfos {
  squads: ISquadsGlobalInfos[];
}
