import http from '../http-common';
import { AdminStateType } from '../types/admin.type';

class AdminDataService {
  findByAddress(params: any) {
    return http.get<AdminStateType>('/admins', { params });
  }
}

export default new AdminDataService();
