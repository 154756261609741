import http from '../http-common';
import {
  IItemCreationData,
  InventoryStateType
} from '../types/inventories.type';

class InventoriesDataService {
  create(data: IItemCreationData) {
    return http.post<IItemCreationData>('/inventories', data);
  }

  update(data: any, identifier: any) {
    return http.put<any>(`/inventories/${identifier}`, data);
  }

  updateAllByIdShop(data: any, id_shop: any) {
    return http.put<any>(`/inventories/shop/${id_shop}`, data);
  }

  findAllByIdShop(id_shop: any) {
    return http.get<InventoryStateType>(`/inventories/shop/${id_shop}`);
  }

  findAllByUser(id_user: any) {
    return http.get<InventoryStateType>(`/inventories/user/${id_user}`);
  }
}

export default new InventoriesDataService();
