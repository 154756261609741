import * as React from 'react';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { routeNames } from 'routes';
import ArenaLdbLogsDataService from '../../services/arenaldblogs.service';
import FactionDataService from '../../services/faction.service';
import LeaderboardsLogsDataService from '../../services/leaderboardslogs.service';
import LeaderboardsRewardsLogsDataService from '../../services/leaderboardsrewardslogs.service';
import SettingDataService from '../../services/setting.service';
import UserDataService from '../../services/user.service';
import { ArenaLdbLogsStateType } from '../../types/arenaldblogs.type';
import { FactionsStateType } from '../../types/faction.type';
import { LeaderboardsLogsStateType } from '../../types/leaderboardslogs.type';
import Alex from './../../assets/img/Alex.png';
import angelic from './../../assets/img/angelic.png';
import arena from './../../assets/img/arena.png';
import city from './../../assets/img/city.png';
import ZRadJ from './../../assets/img/Claude.png';
import daimyos from './../../assets/img/daimyos.png';
import dynamicnft from './../../assets/img/dynamicnft.png';
import Akaine from './../../assets/img/Jonathan.png';
import { ReactComponent as JujotteLogo } from './../../assets/img/jujotte.svg';
import { ReactComponent as LinkedInIcon } from './../../assets/img/linkedin.svg';
import logo_rof from './../../assets/img/logo_rof.svg';
import meiyo from './../../assets/img/meiyo.png';
import Mickael from './../../assets/img/Mickael.png';
import night from './../../assets/img/night.png';
import reiwa from './../../assets/img/reiwa.png';
import samurai from './../../assets/img/samurai.png';
import shogun from './../../assets/img/shogun.png';
import titans from './../../assets/img/titan.png';
import tournaments from './../../assets/img/tournaments.png';
import tower from './../../assets/img/tower.png';
import { ReactComponent as TwitterIcon } from './../../assets/img/twitter.svg';
import universe from './../../assets/img/universe.png';

import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
//import 'swiper/modules/free-mode/free-mode.min.css';
//import 'swiper/modules/navigation/navigation.scss';

const Home = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleScroll = () => {
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  };

  React.useEffect(() => {
    handleScroll();
  }, [hash]);

  const [leaderboardMaxRanking, setLeaderboardMaxRanking] = React.useState(10);

  const [showConquest, setShowConquest] = React.useState(true);
  const [showArena, setShowArena] = React.useState(false);
  const handleShowConquest = () => {
    setShowConquest(true);
    setShowArena(false);
  };
  const handleShowArena = () => {
    setShowConquest(false);
    setShowArena(true);
  };

  const [idLeaderboardConquest, setIdLeaderboardConquest] = React.useState(0);
  const [idLeaderboardArena, setIdLeaderboardArena] = React.useState(0);

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });

  const [leaderboardsInfos, setLeaderboardsInfos] =
    React.useState<LeaderboardsLogsStateType>({
      leaderboards_logs: [],
      message: '',
      status: ''
    });

  const [arenaleaderboardsInfos, setArenaLeaderboardsInfos] =
    React.useState<ArenaLdbLogsStateType>({
      arena_ldb_logs: [],
      message: '',
      status: ''
    });

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
      }
    });
  };

  const fetchLeaderboardsLogsData = () => {
    LeaderboardsLogsDataService.findAllByLeaderboard(
      idLeaderboardConquest
    ).then(({ data }) => {
      if (data.status == 'success') {
        setLeaderboardsInfos({
          leaderboards_logs: data.leaderboards_logs,
          status: data.status
        });
      }
    });
  };

  const fetchArenaLeaderboardsLogsData = () => {
    ArenaLdbLogsDataService.findAllByLeaderboard(idLeaderboardArena).then(
      ({ data }) => {
        if (data.status == 'success') {
          setArenaLeaderboardsInfos({
            arena_ldb_logs: data.arena_ldb_logs,
            status: data.status
          });
        }
      }
    );
  };

  const supo_link = 'https://twitter.com/GbsMickael';
  const akaine_link = 'https://twitter.com/Akaine67';
  const zradj_link = 'https://twitter.com/ZRadJ_OzK';
  const alex_link = 'https://twitter.com/ht_sitaru';
  const supo_link2 = 'https://www.linkedin.com/in/mickael-bracigliano';
  const akaine_link2 = 'https://www.linkedin.com/in/jonathan-picca-2b8670166/';
  const zradj_link2 = 'https://www.linkedin.com/in/mazeau-claude-5bb47083/';
  const alex_link2 = 'https://www.linkedin.com/in/sitaru-alexandru';

  const [nbConquests, setNbConquests] = React.useState(0);
  const [nbFights, setNbFights] = React.useState(0);
  const [nbUsers, setNbUsers] = React.useState(0);
  const [nbRewardsEGLD, setNbRewardsEGLD] = React.useState('');

  const fetchSettingData = async () => {
    const params: any = {};
    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setNbConquests(data.setting.value);
      }
    });

    let nb_fights = 0;

    params['name'] = 'idFightRanked';

    // Fetch of the current phase in the parameters
    await SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        nb_fights += data.setting.value;
      }
    });

    params['name'] = 'fightRanked';

    // Fetch of the current phase in the parameters
    await SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        nb_fights += data.setting.value;
      }
    });

    setNbFights(nb_fights);

    params['name'] = 'leaderboard';

    // Fetch of the current conquest in the parameters
    await SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setIdLeaderboardConquest(data.setting.value);
      }
    });

    params['name'] = 'arena_leaderboard';

    // Fetch of the current conquest in the parameters
    await SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setIdLeaderboardArena(data.setting.value);
      }
    });
  };

  const fetchUsersData = () => {
    UserDataService.countUsers().then(({ data }) => {
      if (data.status == 'success') {
        setNbUsers(data.nbUsers);
      }
    });
  };

  const fetchRewardsEGLDData = () => {
    LeaderboardsRewardsLogsDataService.countEGLD().then(({ data }) => {
      if (data.status == 'success') {
        setNbRewardsEGLD(data.leaderboards_rewards_log.totalAmount.toFixed(2));
      }
    });
  };

  const nbFAQ = 4;
  const [FAQInfos, setFAQInfos] = React.useState({
    showFAQInfos: new Array<boolean>()
  });

  const handleInitFAQ = () => {
    const faq = {
      showFAQInfos: FAQInfos.showFAQInfos
    };
    for (let i = 0; i < nbFAQ; i++) {
      faq.showFAQInfos[i] = false;
    }
    setFAQInfos(faq);
  };

  const handleFAQ = (id_faq: number) => {
    const faq = {
      showFAQInfos: FAQInfos.showFAQInfos
    };
    for (let i = 0; i < nbFAQ; i++) {
      if (i == id_faq) {
        if (faq.showFAQInfos[i] == true) {
          faq.showFAQInfos[i] = false;
        } else {
          faq.showFAQInfos[i] = true;
        }
      }
    }
    setFAQInfos(faq);
  };

  React.useEffect(() => {
    handleInitFAQ();
    fetchSettingData();
    fetchUsersData();
    fetchFactionsData();
    fetchRewardsEGLDData();
  }, []);

  React.useEffect(() => {
    if (idLeaderboardConquest != 0) {
      fetchLeaderboardsLogsData();
    }
  }, [idLeaderboardConquest]);

  React.useEffect(() => {
    if (idLeaderboardArena != 0) {
      fetchArenaLeaderboardsLogsData();
    }
  }, [idLeaderboardArena]);

  const { factions } = factionsInfos;
  const { leaderboards_logs } = leaderboardsInfos;
  const { arena_ldb_logs } = arenaleaderboardsInfos;
  const { showFAQInfos } = FAQInfos;

  return (
    <React.Fragment>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mx-auto'>
            <div className='homeInfosContainer'>
              <div className='homeVersionBox'>Rise of Factions V1.0</div>
              <div className='homeTextInfos'>派閥の台頭</div>
            </div>
            <div className='card-body p-2'>
              <div className='homeContainer'>
                <div className='homeBox'>
                  <div className='homeContainerTitle'>
                    <img src={logo_rof} className='homeLogo' />
                    <div>
                      DEPLOY
                      <br />
                      FIGHT
                      <br />
                      CONQUER
                    </div>
                  </div>
                  <div className='homeContainerText'>
                    Create your army of NFTs
                    <br />
                    and start to Earn now !
                  </div>
                  <div className='buttonsContainer'>
                    <Button
                      className='btnStandardReverse'
                      onClick={() => navigate(routeNames.menu)}
                    >
                      JOIN THE FIGHT
                    </Button>
                    <br className='smallBox' />
                    <Button
                      className='btnStandard'
                      onClick={() => navigate(routeNames.mint)}
                    >
                      THE MINT
                    </Button>
                    <Button
                      className='btnStandard'
                      onClick={() => navigate(routeNames.nftsearch)}
                    >
                      NFTS SEARCH
                    </Button>
                  </div>
                </div>
                <div className='homePictureBox'>
                  <div className='cardContainerHome1'>
                    <div className='cardBox'>
                      <div className='cardBoxIn'>
                        <div className='cardInfos'>
                          <div className='cardHierarchy'>Shogun</div>
                          <div className='cardPower'>Power : 90.00</div>
                        </div>
                      </div>
                    </div>
                    <div className='cardGamePictureBox'>
                      <img src={shogun} className='cardGamePicture' />
                    </div>
                    <div className='cardInfosBis'>
                      <div className='cardNumber'>1652</div>
                      <div className='cardName'>Hero</div>
                    </div>
                  </div>
                  <div className='cardContainerHome2'>
                    <div className='cardBox'>
                      <div className='cardBoxIn'>
                        <div className='cardInfos'>
                          <div className='cardHierarchy'>Daimyos</div>
                          <div className='cardPower'>Power : 50.88</div>
                        </div>
                      </div>
                    </div>
                    <div className='cardGamePictureBox'>
                      <img src={daimyos} className='cardGamePicture' />
                    </div>
                    <div className='cardInfosBis'>
                      <div className='cardNumber'>1105</div>
                      <div className='cardName'>Hero</div>
                    </div>
                  </div>
                  <div className='cardContainerHome3'>
                    <div className='cardBox'>
                      <div className='cardBoxIn'>
                        <div className='cardInfos'>
                          <div className='cardHierarchy'>Samurai</div>
                          <div className='cardPower'>Power : 42.05</div>
                        </div>
                      </div>
                    </div>
                    <div className='cardGamePictureBox'>
                      <img src={samurai} className='cardGamePicture' />
                    </div>
                    <div className='cardInfosBis'>
                      <div className='cardNumber'>2797</div>
                      <div className='cardName'>Hero</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
            <div className='videoContainer'>
              <div className='videoBox'>
                <iframe
                  src='https://www.youtube.com/embed/xy-d9pHM8Xg'
                  title='Rise of Factions Trailer'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  className='videoContent'
                ></iframe>
              </div>
              <div className='shade1'></div>
              <div className='shade2'></div>
              <div className='videoContainerIn'></div>
            </div>
            */}

            <div className='card-body p-2'>
              <div className='infosContainer'>
                <div className='infosBox'>
                  <div className='infosTitle'>Leaderboard</div>
                </div>
              </div>

              <div className='boxContainer'>
                <div className='card-body text-center p-2'>
                  {showConquest ? (
                    <React.Fragment>
                      <Button
                        className='navStandardSmallReverse'
                        onClick={handleShowConquest}
                      >
                        Conquest
                      </Button>
                      <Button
                        className='navStandardSmall'
                        onClick={handleShowArena}
                      >
                        Arena
                      </Button>
                    </React.Fragment>
                  ) : null}
                  {showArena ? (
                    <React.Fragment>
                      <Button
                        className='navStandardSmall'
                        onClick={handleShowConquest}
                      >
                        Conquest
                      </Button>
                      <Button
                        className='navStandardSmallReverse'
                        onClick={handleShowArena}
                      >
                        Arena
                      </Button>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>

              {showConquest ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>
                        Top {leaderboardMaxRanking} Ranking
                        <div className='leadSizeRankingBox'>
                          {leaderboardMaxRanking == 10 ? (
                            <div
                              className='leadSizeRanking'
                              onClick={() => setLeaderboardMaxRanking(50)}
                            >
                              (Show more)
                            </div>
                          ) : (
                            <div
                              className='leadSizeRanking'
                              onClick={() => setLeaderboardMaxRanking(10)}
                            >
                              (Show less)
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>Rank</th>
                            <th className='border-0'>User</th>
                            <th className='border-0 iconCol'>Faction</th>
                            <th className='border-0'></th>
                            <th className='border-0'>Score</th>
                          </tr>
                        </thead>
                        {leaderboards_logs.length == 0 ? (
                          <tbody>
                            <tr className='rowTableContent textResult'>
                              <td colSpan={7}>
                                <span>No leaderboard ranking</span>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {leaderboards_logs.map((leaderboards_log, i) => {
                              let factionName = '';
                              let factionImg = '';

                              factions.map((faction) => {
                                if (
                                  faction.id_faction ==
                                  leaderboards_log.id_faction
                                ) {
                                  factionName = faction.name;
                                }
                              });

                              switch (leaderboards_log.id_faction) {
                                case 1:
                                  factionImg = angelic;
                                  break;
                                case 2:
                                  factionImg = night;
                                  break;
                                case 3:
                                  factionImg = meiyo;
                                  break;
                              }

                              if (i < leaderboardMaxRanking) {
                                return (
                                  <tr
                                    key={i}
                                    className='rowTableResult textResult'
                                  >
                                    <td>
                                      <span>{leaderboards_log.rank}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {leaderboards_log.user.pseudo}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{factionName}</span>
                                    </td>
                                    <td>
                                      <img
                                        src={`${factionImg}`}
                                        className='imgFactionProfile'
                                      />
                                    </td>
                                    <td className='textBold'>
                                      <span>{leaderboards_log.score}</span>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              {showArena ? (
                <div className='boxContainer'>
                  <div className='card-body p-2'>
                    <div className='infosBox'>
                      <div className='infosSubTitle'>
                        Top {leaderboardMaxRanking} Ranking
                        <div className='leadSizeRankingBox'>
                          {leaderboardMaxRanking == 10 ? (
                            <div
                              className='leadSizeRanking'
                              onClick={() => setLeaderboardMaxRanking(50)}
                            >
                              (Show more)
                            </div>
                          ) : (
                            <div
                              className='leadSizeRanking'
                              onClick={() => setLeaderboardMaxRanking(10)}
                            >
                              (Show less)
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive text-center'>
                      <table className='transactions table pb-3'>
                        <thead>
                          <tr className='headTable'>
                            <th className='border-0'>Rank</th>
                            <th className='border-0'>User</th>
                            <th className='border-0 iconCol'>Faction</th>
                            <th className='border-0'></th>
                            <th className='border-0'>Score</th>
                          </tr>
                        </thead>
                        {arena_ldb_logs.length == 0 ? (
                          <tbody>
                            <tr className='rowTableContent textResult'>
                              <td colSpan={7}>
                                <span>No leaderboard ranking</span>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {arena_ldb_logs.map((arena_ldb_log, i) => {
                              let factionName = '';
                              let factionImg = '';

                              factions.map((faction) => {
                                if (
                                  faction.id_faction ==
                                  arena_ldb_log.user.id_faction
                                ) {
                                  factionName = faction.name;
                                }
                              });

                              switch (arena_ldb_log.user.id_faction) {
                                case 1:
                                  factionImg = angelic;
                                  break;
                                case 2:
                                  factionImg = night;
                                  break;
                                case 3:
                                  factionImg = meiyo;
                                  break;
                              }

                              if (i < leaderboardMaxRanking) {
                                return (
                                  <tr
                                    key={i}
                                    className='rowTableResult textResult'
                                  >
                                    <td>
                                      <span>{arena_ldb_log.rank}</span>
                                    </td>
                                    <td>
                                      <span>{arena_ldb_log.user.pseudo}</span>
                                    </td>
                                    <td>
                                      <span>{factionName}</span>
                                    </td>
                                    <td>
                                      <img
                                        src={`${factionImg}`}
                                        className='imgFactionProfile'
                                      />
                                    </td>
                                    <td className='textBold'>
                                      <span>{arena_ldb_log.score}</span>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className='card-body p-2' id='universeItem'>
              <div className='universeContainer'>
                <div className='universePictureBox'>
                  <div className='universePictureBoxA'>
                    <div className='universePictureBoxInA'>
                      <img src={universe} className='universePictureA' />
                    </div>
                  </div>
                  <div className='universePictureBoxB'>
                    <div className='universePictureBoxInB'>
                      <img src={city} className='universePictureB' />
                    </div>
                  </div>
                </div>
                <div className='universeBox'>
                  <div className='universeSub'>DISCOVER THE STORY</div>
                  <div className='universeTitle'>The J-Corp Universe</div>
                  <div className='universeTextSmall'>
                    Since the dawn of mankind, two sides have been fighting in
                    the Kingdom of the Gods; the Gods of Light, called
                    &quot;KAMI&quot;, and the Gods of Darkness, called
                    &quot;MARA&quot;.
                    <br />
                    In order to guarantee the balance between these two sides,
                    the Guild of adventurers called J-Corp have sworn for
                    thousands of years to ensure this purpose. Today we needs
                    you, the balance as we know is shifting in favor of the Evil
                    Gods.
                  </div>
                  <div className='universeText'>
                    Embrace your destiny and fight to restore the balance of the
                    Kingdom !
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='factionTitleContainer'>
                <div className='factionTitle'>DISCOVER</div>
                <div className='factionTitleSmall'>THE</div>
                <div className='factionTitle'>FACTIONS</div>
              </div>
              <div className='factionContainer'>
                <div className='factionBox'>
                  <div className='factionName'>Night Stalkers</div>
                  <div className='factionSub'>DISCOVER THE STORY</div>
                  <div className='factionText'>
                    Born beneath the veil of darkness, the Night Stakers have
                    always strived to restore peace and prosperity to Reiwa.
                    <br />
                    Reiwa needs our protection! We ensure the balance between
                    shadow and light.
                    <br />
                    <br />
                    We are the last hope for Reiwa!
                    <br />
                    What are you waiting for to reach the pinnacle of glory?
                    Join the ultimate faction and engage in epic battles
                    alongside us! Join Nightstalker
                  </div>
                  <div className='factionCardContainer'>
                    <div className='cardFactionBox'>
                      <div className='cardFactionBoxIn'>
                        <div className='cardFactionText'>Night Stalkers</div>
                      </div>
                    </div>
                    <div className='cardPictureBox'>
                      <img src={night} className='cardPicture' />
                    </div>
                  </div>
                </div>
                <div className='factionBox'>
                  <div className='factionName'>Meiyo</div>
                  <div className='factionSub'>DISCOVER THE STORY</div>
                  <div className='factionText'>
                    The Meiyo Clan is the most loyal to the Emperor. The swords
                    of his samurai are always ready to answer the call of the
                    Emperor for defend the kingdom of Reiwa.
                    <br />
                    <br />
                    Its members grow up with the teachings of the Bushido Code
                    where honor and help to the rest of the community are the
                    central axis of coexistence. Our motto is:
                    <br />
                    Our honor is our strengh.
                  </div>
                  <div className='factionCardContainer'>
                    <div className='cardFactionBox'>
                      <div className='cardFactionBoxIn'>
                        <div className='cardFactionText'>Meiyo</div>
                      </div>
                    </div>
                    <div className='cardPictureBox'>
                      <img src={meiyo} className='cardPicture' />
                    </div>
                  </div>
                </div>
                <div className='factionBox'>
                  <div className='factionName'>Angelic Army</div>
                  <div className='factionSub'>DISCOVER THE STORY</div>
                  <div className='factionText'>
                    After years of searching for those responsible for the fire
                    that ravaged Kiyoko. The spies of the Angelic Army finally
                    managed to get their hands on valuable information. They are
                    now ready to catch the culprits.
                    <br />
                    <br />
                    But first, it&apos;s important to build a strong army like
                    never before. They know their cause is just, but the battle
                    will be dark. The Anglelic army is therefore looking for new
                    warriors of lights to consolidate there ranks !
                  </div>
                  <div className='factionCardContainer'>
                    <div className='cardFactionBox'>
                      <div className='cardFactionBoxIn'>
                        <div className='cardFactionText'>Angelic Army</div>
                      </div>
                    </div>
                    <div className='cardPictureBox'>
                      <img src={angelic} className='cardPicture' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='gameContainer'>
              <div className='gamePictureBoxSmall'>
                <div className='gamePictureBoxA'>
                  <div className='gamePictureBoxInA'>
                    <img src={arena} className='gamePictureA' />
                  </div>
                </div>
                <div className='gamePictureBoxB'>
                  <div className='gamePictureBoxInB'>
                    <img src={reiwa} className='gamePictureB' />
                  </div>
                </div>
              </div>
              <div className='gameContainerSubBefore'></div>
              <div className='gameContainerSub'>
                <div className='gameBox'>
                  <div className='gameTitle'>Join the fight</div>
                  <div className='gameSub'>Discover Rise of Factions</div>
                  <div className='gameText'>
                    Three factions want to take control of the city of Reiwa and
                    its wealth.
                    <br />
                    Join one of these factions, set a strategy, deploy your
                    heroes and take part in the battle to conquer Reiwa.
                  </div>
                </div>
                <div className='gameButtonBoxSmall'>
                  <div className='buttonsContainer'>
                    <Button
                      className='btnRed'
                      onClick={() => navigate(routeNames.menu)}
                    >
                      JOIN THE FIGHT
                    </Button>
                    <br className='smallBox' />
                    <Button
                      className='btnRedReverse'
                      onClick={() => navigate(routeNames.mint)}
                    >
                      THE MINT
                    </Button>
                    <Button
                      className='btnRedReverse'
                      onClick={() => navigate(routeNames.nftsearch)}
                    >
                      NFTS SEARCH
                    </Button>
                  </div>
                </div>
              </div>
              <div className='gameContainerSubAfter'></div>
              <div className='gameButtonBox'>
                <div className='buttonsContainer'>
                  <Button
                    className='btnRed'
                    onClick={() => navigate(routeNames.menu)}
                  >
                    JOIN THE FIGHT
                  </Button>
                  <Button
                    className='btnRedReverse'
                    onClick={() => navigate(routeNames.mint)}
                  >
                    THE MINT
                  </Button>
                  <Button
                    className='btnRedReverse'
                    onClick={() => navigate(routeNames.nftsearch)}
                  >
                    NFTS SEARCH
                  </Button>
                </div>
              </div>
              <div className='gamePictureBox'>
                <div className='gamePictureBoxA'>
                  <div className='gamePictureBoxInA'>
                    <img src={arena} className='gamePictureA' />
                  </div>
                </div>
                <div className='gamePictureBoxB'>
                  <div className='gamePictureBoxInB'>
                    <img src={reiwa} className='gamePictureB' />
                  </div>
                </div>
              </div>
            </div>
            <div className='statsContainer'>
              <div className='statsContainerOut'>
                <div className='statsContainerIn'>
                  <div className='statsBox'>
                    <div className='statsInfos'>
                      <div className='statsInfosValue'>{nbFights}</div>
                      <div className='statsInfosTitle'>Fights</div>
                    </div>
                    <div className='statsInfos'>
                      <div className='statsInfosValue'>{nbConquests}</div>
                      <div className='statsInfosTitle'>Conquests</div>
                    </div>
                    <div className='statsInfos'>
                      <div className='statsInfosValue'>{nbUsers}</div>
                      <div className='statsInfosTitle'>Players</div>
                    </div>
                    <div className='statsInfos'>
                      <div className='statsInfosValue'>{nbRewardsEGLD}</div>
                      <div className='statsInfosTitle'>EGLD distributed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2' id='roadmapItem'>
              <div className='infosContainer'>
                <div className='infosBox'>
                  <div className='infosSub'>ABOUT THE FUTURE</div>
                  <div className='infosTitle'>Roadmap</div>
                </div>
                <div className='infosBoxSub'>
                  <div className='infosText'>
                    The path is built by taking one step at a time !
                  </div>
                </div>
              </div>
              <div className='roadmapContainer'>
                <Swiper
                  effect={'coverflow'}
                  grabCursor={true}
                  centeredSlides={true}
                  loop={true}
                  loopFillGroupWithBlank={true}
                  slidesPerView={'auto'}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                  }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  pagination={{
                    clickable: true
                  }}
                  modules={[EffectCoverflow, Pagination, Autoplay]}
                  className='mySwiper'
                >
                  <SwiperSlide>
                    <div className='roadmapCardContainer'>
                      <div className='cardRoadmapBox'>
                        <div className='cardRoadmapBoxIn'>
                          <div className='cardRoadmapPictureBox'>
                            <img
                              src={tournaments}
                              className='cardRoadmapPicture'
                            />
                          </div>
                          <div className='cardRoadmapInfos'>
                            <div className='cardRoadmapPhase'>Phase 1</div>
                            <div className='cardRoadmapTitle'>Tournaments</div>
                            <div className='cardRoadmapText'>
                              New game mode. Arena fighters will participate in
                              epic tournaments.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='roadmapCardContainer'>
                      <div className='cardRoadmapBox'>
                        <div className='cardRoadmapBoxIn'>
                          <div className='cardRoadmapPictureBox'>
                            <img
                              src={dynamicnft}
                              className='cardRoadmapPicture'
                            />
                          </div>
                          <div className='cardRoadmapInfos'>
                            <div className='cardRoadmapPhase'>Phase 2</div>
                            <div className='cardRoadmapTitle'>Dynamic NFT</div>
                            <div className='cardRoadmapText'>
                              Play, gain experience and evolve your NFTs.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='roadmapCardContainer'>
                      <div className='cardRoadmapBox'>
                        <div className='cardRoadmapBoxIn'>
                          <div className='cardRoadmapPictureBox'>
                            <img src={titans} className='cardRoadmapPicture' />
                          </div>
                          <div className='cardRoadmapInfos'>
                            <div className='cardRoadmapPhase'>Phase 3</div>
                            <div className='cardRoadmapTitle'>Titans</div>
                            <div className='cardRoadmapText'>
                              New game mode. Epic combats against world boss
                              coming from the Gods Kingdom.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='roadmapCardContainer'>
                      <div className='cardRoadmapBox'>
                        <div className='cardRoadmapBoxIn'>
                          <div className='cardRoadmapPictureBox'>
                            <img src={tower} className='cardRoadmapPicture' />
                          </div>
                          <div className='cardRoadmapInfos'>
                            <div className='cardRoadmapPhase'>Phase 4</div>
                            <div className='cardRoadmapTitle'>
                              Infinite Tower
                            </div>
                            <div className='cardRoadmapText'>
                              New game mode. Form your team and fight harder
                              enemies as you ascend the stages.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                {/* <div className='roadmapCardContainer'>
                  <div className='cardRoadmapBox'>
                    <div className='cardRoadmapBoxIn'>
                      <div className='cardRoadmapPictureBox'>
                        <img src={saboteur} className='cardRoadmapPicture' />
                      </div>
                      <div className='cardRoadmapInfos'>
                        <div className='cardRoadmapPhase'>Phase 1</div>
                        <div className='cardRoadmapTitle'>Tournaments</div>
                        <div className='cardRoadmapText'>
                          New game mode. Arena fighters will participate in epic
                          tournaments.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmapCardContainer'>
                  <div className='cardRoadmapBox'>
                    <div className='cardRoadmapBoxIn'>
                      <div className='cardRoadmapPictureBox'>
                        <img src={mercenary} className='cardRoadmapPicture' />
                      </div>
                      <div className='cardRoadmapInfos'>
                        <div className='cardRoadmapPhase'>Phase 2</div>
                        <div className='cardRoadmapTitle'>Titans</div>
                        <div className='cardRoadmapText'>
                          New game mode. Epic combats against world boss coming
                          from the Gods Kingdom.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmapCardContainer'>
                  <div className='cardRoadmapBox'>
                    <div className='cardRoadmapBoxIn'>
                      <div className='cardRoadmapPictureBox'>
                        <img src={archer} className='cardRoadmapPicture' />
                      </div>
                      <div className='cardRoadmapInfos'>
                        <div className='cardRoadmapPhase'>Phase 3</div>
                        <div className='cardRoadmapTitle'>Vilains</div>
                        <div className='cardRoadmapText'>
                          Improved art, dynamic NFTs with secondary stats
                          designed for future game modes and major updates.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmapCardContainer'>
                  <div className='cardRoadmapBox'>
                    <div className='cardRoadmapBoxIn'>
                      <div className='cardRoadmapPictureBox'>
                        <img src={saboteur} className='cardRoadmapPicture' />
                      </div>
                      <div className='cardRoadmapInfos'>
                        <div className='cardRoadmapPhase'>Phase 4</div>
                        <div className='cardRoadmapTitle'>Infinite Tower</div>
                        <div className='cardRoadmapText'>
                          New game mode. Form your team and fight harder enemies
                          as you ascend the stages.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='card-body p-2' id='teamItem'>
              <div className='infosContainer'>
                <div className='infosBox'>
                  <div className='infosSub'>DISCOVER US</div>
                  <div className='infosTitle'>Team</div>
                </div>
                <div className='infosBoxSub'>
                  <div className='infosText'>
                    The real success of a team is ensuring competitiveness in
                    sustainability.
                  </div>
                </div>
              </div>
              <div className='teamContainer'>
                <div className='teamCardContainer'>
                  <div className='cardTeamBox'>
                    <div className='cardTeamBoxIn'>
                      <div className='cardTeamInfos'>
                        <div className='cardTeamName'>Supo</div>
                        <div className='cardTeamFunction'>Co-Founder</div>
                      </div>
                      <div className='cardTeamSocial'>
                        <a
                          href={supo_link}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <TwitterIcon className='mx-1' />
                        </a>
                        <a
                          href={supo_link2}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <LinkedInIcon className='mx-1 socialIconsIn' />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='cardPictureBox'>
                    <img src={Mickael} className='cardPicture' />
                  </div>
                </div>
                <div className='teamCardContainer'>
                  <div className='cardTeamBox'>
                    <div className='cardTeamBoxIn'>
                      <div className='cardTeamInfos'>
                        <div className='cardTeamName'>Akaine</div>
                        <div className='cardTeamFunction'>Co-Founder</div>
                      </div>
                      <div className='cardTeamSocial'>
                        <a
                          href={akaine_link}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <TwitterIcon className='mx-1' />
                        </a>
                        <a
                          href={akaine_link2}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <LinkedInIcon className='mx-1 socialIconsIn' />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='cardPictureBox'>
                    <img src={Akaine} className='cardPicture' />
                  </div>
                </div>
                <div className='teamCardContainer'>
                  <div className='cardTeamBox'>
                    <div className='cardTeamBoxIn'>
                      <div className='cardTeamInfos'>
                        <div className='cardTeamName'>ZRadJ</div>
                        <div className='cardTeamFunction'>Co-Founder</div>
                      </div>
                      <div className='cardTeamSocial'>
                        <a
                          href={zradj_link}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <TwitterIcon className='mx-1' />
                        </a>
                        <a
                          href={zradj_link2}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <LinkedInIcon className='mx-1 socialIconsIn' />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='cardPictureBox'>
                    <img src={ZRadJ} className='cardPicture' />
                  </div>
                </div>
                <div className='teamCardContainer'>
                  <div className='cardTeamBox'>
                    <div className='cardTeamBoxIn'>
                      <div className='cardTeamInfos'>
                        <div className='cardTeamName'>Alex</div>
                        <div className='cardTeamFunction'>Co-Founder</div>
                      </div>
                      <div className='cardTeamSocial'>
                        <a
                          href={alex_link}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <TwitterIcon className='mx-1' />
                        </a>
                        <a
                          href={alex_link2}
                          target='_blank'
                          rel='noreferrer'
                          className='socialIcons'
                        >
                          <LinkedInIcon className='mx-1 socialIconsIn' />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='cardPictureBox'>
                    <img src={Alex} className='cardPicture' />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='homeROFLogoContainer'>
                <img src={logo_rof} className='homeROFLogo' />
              </div>
            </div>
            <div className='card-body p-2' id='partnersItem'>
              <div className='infosContainer'>
                <div className='infosBox'>
                  <div className='infosSub'>THEY HELP US</div>
                  <div className='infosTitle'>Partners</div>
                </div>
                <div className='infosBoxSub'>
                  <div className='infosText'>
                    Alone we go faster, together we go further.
                  </div>
                </div>
              </div>
              <div className='partnersContainer'>
                <div className='partnersBox'>
                  <JujotteLogo className='partnersLogo' />
                  <div className='partnersInfos'>UX/UI Design Agency</div>
                </div>
              </div>
            </div>
            <div className='card-body p-2' id='FAQItem'>
              <div className='infosContainer'>
                <div className='infosBox'>
                  <div className='infosSub'>YOUR QUESTIONS</div>
                  <div className='infosTitle'>FAQ</div>
                </div>
              </div>
              <div className='FAQContainer'>
                <div className='FAQBox'>
                  <div className='FAQBoxQuestion' onClick={() => handleFAQ(0)}>
                    <div className='FAQBoxText'>
                      Where can I found more information about the project ?
                    </div>
                    <div className='FAQBoxIcon'>
                      {showFAQInfos[0] == true ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </div>
                  {showFAQInfos[0] == true ? (
                    <div className='FAQBoxInfos'>
                      You can find all the details of the WhitePaper&nbsp;
                      <a
                        href='https://jcorp-battleofgods.gitbook.io/whitepaper'
                        target='_blank'
                        rel='noreferrer'
                      >
                        here
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className='FAQBox'>
                  <div className='FAQBoxQuestion' onClick={() => handleFAQ(1)}>
                    <div className='FAQBoxText'>
                      What is the Heroes Collection ?
                    </div>
                    <div className='FAQBoxIcon'>
                      {showFAQInfos[1] == true ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </div>
                  {showFAQInfos[1] == true ? (
                    <div className='FAQBoxInfos'>
                      All the details are&nbsp;
                      <a
                        href='https://jcorp-battleofgods.gitbook.io/whitepaper/our-nft-collections/heroes-collection'
                        target='_blank'
                        rel='noreferrer'
                      >
                        here
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className='FAQBox'>
                  <div className='FAQBoxQuestion' onClick={() => handleFAQ(2)}>
                    <div className='FAQBoxText'>How can I play this game ?</div>
                    <div className='FAQBoxIcon'>
                      {showFAQInfos[2] == true ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </div>
                  {showFAQInfos[2] == true ? (
                    <div className='FAQBoxInfos'>
                      You can find the Rules on the discord, on the game after
                      login in or in the WP&nbsp;
                      <a
                        href='https://jcorp-battleofgods.gitbook.io/whitepaper/rise-of-factions-game/global-rules'
                        target='_blank'
                        rel='noreferrer'
                      >
                        here
                      </a>
                      <br />
                      You also have Tutorials videos at this&nbsp;
                      <a
                        href='https://jcorp-battleofgods.gitbook.io/whitepaper/rise-of-factions-game/tutorial-videos'
                        target='_blank'
                        rel='noreferrer'
                      >
                        address
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className='FAQBox'>
                  <div className='FAQBoxQuestion' onClick={() => handleFAQ(3)}>
                    <div className='FAQBoxText'>What is the secret Word ?</div>
                    <div className='FAQBoxIcon'>
                      {showFAQInfos[3] == true ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                  </div>
                  {showFAQInfos[3] == true ? (
                    <div className='FAQBoxInfos'>Kyanite99</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='card-body p-2'>
              <div className='homeCommunityContainer'>
                <iframe
                  src='https://egld.community/api/products/b4cd7167-f1a7-4e33-88d8-c74513a67819/upvotes/embed'
                  className='egldCommunity'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
