import React from 'react';
import {
  Navbar as BsNavbar,
  Nav,
  Container,
  Modal,
  Button
} from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as DiscordIcon } from 'assets/img/discord.svg';
import logo from 'assets/img/JCORP_logo.png';
import logo_purple from 'assets/img/JCORP_logo_purple.png';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { ReactComponent as VectorCross } from 'assets/img/vector_cross.svg';
import { routeNames } from 'routes';
import NavMenuItem from '../NavMenuItem';

const NavbarConquest = () => {
  const discord_link = 'https://discord.gg/7B5ZuPmFjV';
  const twitter_link = 'https://twitter.com/JCorpBattle';

  const navigate = useNavigate();
  const location = useLocation();

  const [navCollapsed, setNavCollapsed] = React.useState(false);
  const [navGroupClass, setNavGroupClass] = React.useState('navGroup');
  const handleCollapse = () => {
    if (navCollapsed) {
      setNavGroupClass('navGroup');
      setNavCollapsed(false);
    } else {
      setNavGroupClass('navGroupCollapsed');
      setNavCollapsed(true);
    }
  };

  const [showSettings, setShowSettings] = React.useState(false);
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const handleShowSettings = () => {
    setNavGroupClass('navGroup');
    setNavCollapsed(false);
    setShowSettings(true);
  };

  React.useEffect(() => {
    setNavGroupClass('navGroup');
    setNavCollapsed(false);
    handleCloseSettings();
  }, [location]);

  return (
    <React.Fragment>
      <div onClick={() => navigate(routeNames.menu)}>
        <img src={logo} className='logoSmall' />
      </div>
      <BsNavbar expand='lg' id={navGroupClass}>
        <Container>
          <BsNavbar.Brand
            className='logoContainer'
            onClick={() => navigate(routeNames.menu)}
          >
            <img src={logo_purple} className='logoNav' />
          </BsNavbar.Brand>
          {!navCollapsed ? (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              __
              <br />
              __
              <br />
              __
            </BsNavbar.Toggle>
          ) : (
            <BsNavbar.Toggle
              className='navToggleBox'
              aria-controls='navbarResponsive'
              onClick={handleCollapse}
            >
              <div className='navToggle'>
                <div className='navToggleIn'>
                  <VectorCross className='navToggleVector' />
                </div>
              </div>
            </BsNavbar.Toggle>
          )}
          <BsNavbar.Collapse
            className='justify-content-end'
            id='navbarResponsive'
          >
            <Nav className='ms-auto navbarScrollable' navbarScroll>
              <Nav.Item className='fantasy socialIconsContainer'>
                <a
                  href={discord_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <DiscordIcon className='mx-1' />
                </a>
                <a
                  href={twitter_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <TwitterIcon className='mx-1' />
                </a>
              </Nav.Item>
              <BsNavbar.Brand
                className='logoSmallContainer'
                onClick={() => navigate(routeNames.menu)}
              >
                <img src={logo_purple} className='logoNav' />
              </BsNavbar.Brand>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.menu)}
                >
                  Game Modes
                </div>
              </Nav.Item>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.conquest)}
                >
                  Conquest
                </div>
              </Nav.Item>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.marketplace)}
                >
                  Marketplace
                </div>
              </Nav.Item>
              <Nav.Item>
                <div
                  className='navLink'
                  onClick={() => navigate(routeNames.leaderboard)}
                >
                  Leaderboard
                </div>
              </Nav.Item>
              <Nav.Item className='fantasy socialIconsSmallContainer'>
                <a
                  href={discord_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <DiscordIcon className='mx-1' />
                </a>
                <a
                  href={twitter_link}
                  target='_blank'
                  rel='noreferrer'
                  className='socialIcons'
                >
                  <TwitterIcon className='mx-1' />
                </a>
              </Nav.Item>
              <div className='navBtnContainerSmall'>
                <Nav.Item>
                  <div onClick={handleShowSettings} className='btnConnect'>
                    <div className='btnConnectIn'>
                      <div className='btnConnectText'>MENU</div>
                    </div>
                  </div>
                </Nav.Item>
              </div>
            </Nav>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
      <div className='navBtnContainer'>
        <div onClick={handleShowSettings} className='btnConnect'>
          <div className='btnConnectIn'>
            <div className='btnConnectText'>MENU</div>
          </div>
        </div>
      </div>
      <Modal
        show={showSettings}
        onHide={handleCloseSettings}
        centered
        scrollable
      >
        <Modal.Body className='modalContainer'>
          <NavMenuItem />

          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='buttonContainer'>
                <Button
                  className='btnStandardSmallReverse'
                  onClick={handleCloseSettings}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default NavbarConquest;
