import * as React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const PageNotFound = () => {
  const { pathname } = useLocation();
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-8 col-lg-5 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body text-center p-4'>
              <div className='d-flex flex-column justify-content-center'>
                <div className='dapp-icon icon-medium'>
                  <FontAwesomeIcon
                    icon={faSearch}
                    className='mx-auto text-muted fa-3x mb-2'
                  />
                </div>
                <span className='h4 textImportant mt-3'>Page not found</span>
                <span className='textImportant'>{pathname}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
