import * as React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import AttributsDataService from '../../services/attributs.service';
import { AttributsStateType } from '../../types/attributs.type';
import { hierarchyColor } from '../../types/token.type';

const Research = () => {
  const { userSession } = useAuthContext();

  const isLoggedIn = Boolean(userSession.userAccount);

  const navigate = useNavigate();

  const searchLabel = 'e.g. ';
  const nftIdentifier = searchLabel + 'HERO-6271d1-0674';
  const nftName = searchLabel + 'Hero #1652';

  const [isSearch, setIsSearch] = React.useState<boolean>();
  const [searchValue, setSearchValue] = React.useState('');

  const [NFTInfos, setNFTInfos] = React.useState<AttributsStateType>({
    attributs: [],
    message: '',
    status: ''
  });

  const [txtInfos, setTxtInfos] = React.useState(nftIdentifier);
  const [idTxtInfos, setIdTxtInfos] = React.useState(2);

  React.useEffect(() => {
    const interval = setInterval(() => {
      switch (idTxtInfos) {
        case 1:
          setIdTxtInfos(2);
          setTxtInfos(nftIdentifier);
          break;
        case 2:
          setIdTxtInfos(1);
          setTxtInfos(nftName);
          break;
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [idTxtInfos]);

  const handleChangeSearch = (e: any) => {
    setIsSearch(false);
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key == 'Enter' && searchValue != '') {
      fetchNFTData();
    }
  };

  const handleSearch = () => {
    if (searchValue != '') {
      fetchNFTData();
    }
  };

  const fetchNFTData = () => {
    const params: any = {};
    params['identifier'] = searchValue;

    AttributsDataService.findAllByIdentifierName(params).then(({ data }) => {
      if (data.status == 'success') {
        setNFTInfos({
          attributs: data.attributs,
          status: data.status
        });
      }
      setIsSearch(true);
    });
  };

  const { attributs } = NFTInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer'>
            <div className='card-body p-2'>
              <div className='pageTitle'>NFT Search</div>
            </div>
          </div>

          {!isLoggedIn ? (
            <React.Fragment>
              <div className='bigBox'>
                <div className='margin20'>
                  <div className='stripContainerRight'>
                    <div className='stripBoxRight'>
                      <div className='stripBoxRightBtn'>
                        <div
                          className='stripBtn'
                          onClick={() => navigate(routeNames.home)}
                        >
                          <div className='stripBtnIn'>
                            <div className='stripBtnText'>Home</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='smallBox'>
                <div className='boxContainer topContainer'>
                  <div className='stripContainerRight'>
                    <div className='stripBoxRight'>
                      <div className='stripBoxRightBtn'>
                        <div
                          className='stripBtn'
                          onClick={() => navigate(routeNames.home)}
                        >
                          <div className='stripBtnIn'>
                            <div className='stripBtnText'>Home</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}

          <div className='boxContainer topContainer'>
            <div className='stripContainerLeft'>
              <div className='stripBoxLeft'>
                <div className='stripBoxLeftSearchBox'>
                  <div className='stripBoxLeftSearch'>
                    <Form.Control
                      className='textAttribut'
                      type='text'
                      value={searchValue}
                      placeholder={txtInfos}
                      onChange={(e) => handleChangeSearch(e)}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className='stripBoxLeftBtnSearchBox'>
                    <div
                      className='stripBoxLeftBtnSearch'
                      onClick={handleSearch}
                    >
                      <div className='stripBoxLeftBtnSearchIn'>
                        <div className='stripBoxLeftIconSearchBox'>
                          <FontAwesomeIcon
                            icon={faSearch}
                            className='stripBoxLeftIconSearch'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isSearch && attributs.length == 0 ? (
            <div className='boxContainer topContainer'>
              <div className='card-body p-2'>
                <div className='statusAlert'>No result for {searchValue}</div>
              </div>
            </div>
          ) : null}

          {isSearch && attributs.length > 0 ? (
            <div className='boxContainer topContainer'>
              <div className='searchContainer'>
                <div className='searchBox'>
                  <div className='nftBlock'>
                    {attributs.map((token, i) => {
                      const divStyle = {
                        '--rarityColor': hierarchyColor[token.nft_hierarchy]
                      } as React.CSSProperties;

                      const NFTscoreRounded = token.nft_power.toFixed(2);
                      const nameT = token.nft_name.split(' ');
                      const nft_collection = nameT[0];
                      let nft_number;
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }

                      return (
                        <div key={i} style={divStyle} className='cardNFTBlock'>
                          <div className='cardNFTContainer'>
                            <div className='cardNFTBox'>
                              <div className='cardNFTBoxIn'>
                                <div className='cardNFTInfos'>
                                  <div className='cardNFTHierarchy'>
                                    {token.nft_hierarchy}
                                  </div>
                                  <div className='cardNFTPower'>
                                    Power : {NFTscoreRounded}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='cardNFTPictureBox'>
                              <img
                                src={`${token.nft_url}`}
                                className='cardNFTPicture'
                              />
                            </div>
                            <div className='cardNFTInfosBis'>
                              {!nft_number ? (
                                <div className='cardNFTTitle'>
                                  {nft_collection}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div className='cardNFTNumber'>
                                    {nft_number}
                                  </div>
                                  <div className='cardNFTName'>
                                    {nft_collection}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className='searchBoxIn2'>
                    <div className='searchBoxIn2Text'>Conquest</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {isSearch && attributs.length > 0 ? (
            <div className='boxContainer'>
              <div className='searchContainer'>
                <div className='searchBox'>
                  <div className='nftBlock'>
                    {attributs.map((token, i) => {
                      const divStyle = {
                        '--rarityColor': hierarchyColor[token.nft_hierarchy]
                      } as React.CSSProperties;

                      const nameT = token.nft_name.split(' ');
                      const nft_collection = nameT[0];
                      let nft_number;
                      if (nameT[1]) {
                        nft_number = nameT[1].replace('#', '');
                      }

                      let NFTpower = token.nft_power;
                      let NFTlife = 0;
                      let NFTspeed = 0;

                      // TODO
                      switch (token.nft_hierarchy) {
                        case 'Warrior':
                          NFTlife = 140;
                          NFTspeed = 5;
                          break;
                        case 'Samurai':
                          NFTlife = 155;
                          NFTspeed = 10;
                          break;
                        case 'Daimyos':
                          NFTlife = 170;
                          NFTspeed = 15;
                          break;
                        case 'Shogun':
                          NFTlife = 180;
                          NFTspeed = 20;
                          NFTpower = 60;
                          break;
                        default:
                          break;
                      }

                      const NFTpowerRounded = NFTpower.toFixed(2);

                      const damage = Math.ceil(NFTpower);
                      const damage_min = Math.round(damage * 0.9);
                      const damage_max = Math.round(damage * 1.1);

                      return (
                        <div
                          key={i}
                          style={divStyle}
                          className='cardDynNFTBlock'
                        >
                          <div className='cardDynNFTContainer'>
                            <div className='cardDynNFTBox'>
                              <div className='cardDynNFTBoxIn'>
                                <div className='cardDynNFTInfos'>
                                  <div className='cardDynNFTHierarchy'>
                                    {token.nft_hierarchy}
                                  </div>
                                </div>
                                <div className='cardDynNFTBoxIn2Empty'></div>
                                <div className='cardDynNFTBoxIn3'>
                                  <div className='cardDynNFTInfosLine'>
                                    <span className='lineTitle'>Power</span>
                                    <span className='lineAttribut'>
                                      {NFTpowerRounded}
                                    </span>
                                  </div>
                                  <div className='cardDynNFTInfosLine'>
                                    <span className='lineTitle'>Damage</span>
                                    <span className='lineAttribut'>
                                      {damage_min} - {damage_max}
                                    </span>
                                  </div>
                                  <div className='cardDynNFTInfosLine'>
                                    <span className='lineTitle'>HP</span>
                                    <span className='lineAttribut'>
                                      {NFTlife}
                                    </span>
                                  </div>
                                  <div className='cardDynNFTInfosLine'>
                                    <span className='lineTitle'>Speed</span>
                                    <span className='lineAttribut'>
                                      {NFTspeed}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='cardDynNFTPictureBox'>
                              <img
                                src={`${token.nft_url}`}
                                className='cardDynNFTPicture'
                              />
                            </div>
                            <div className='cardDynNFTInfosBis'>
                              {!nft_number ? (
                                <div className='cardDynNFTTitle'>
                                  {nft_collection}
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div className='cardDynNFTNumber'>
                                    {nft_number}
                                  </div>
                                  <div className='cardDynNFTName'>
                                    {nft_collection}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className='searchBoxIn2'>
                    <div className='searchBoxIn2Text'>Ranked Arena</div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Research;
