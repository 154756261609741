import http from '../http-common';
import { GameBonusesStateType } from '../types/bonuses.type';

class BonusesDataService {
  getAllGameBonuses(params: any) {
    return http.get<GameBonusesStateType>('/bonuses/game', { params });
  }
}

export default new BonusesDataService();
