import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth-context';
import { routeNames } from 'routes';
import Deployment from './Deployment';

const ConquestAdmin = () => {
  const navigate = useNavigate();

  const { userSession } = useAuthContext();

  if (userSession.isAdmin) {
    return (
      <React.Fragment>
        <Deployment />
      </React.Fragment>
    );
  } else {
    navigate(routeNames.conquest);
  }
};

export default ConquestAdmin;
