import http from '../http-common';
import {
  ITournamentCreationData,
  TournamentStateType,
  TournamentsStateType,
  TournamentCreation
} from '../types/tournaments.type';

class TournamentsDataService {
  create(data: ITournamentCreationData) {
    return http.post<any>('/tournaments/', data);
  }

  createEvent(data: any) {
    return http.post<TournamentCreation>('/tournaments/new', data);
  }

  update(data: any, id: any) {
    return http.put<any>(`/tournaments/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/tournaments/${id}`);
  }

  findLastActive(params: any) {
    return http.get<TournamentStateType>('/tournaments/last_active', {
      params
    });
  }

  findAllFinished(params: any) {
    return http.get<TournamentsStateType>('/tournaments/finished', {
      params
    });
  }

  findOneById(id: any) {
    return http.get<TournamentStateType>(`/tournaments/${id}`);
  }

  findAll() {
    return http.get<TournamentsStateType>('/tournaments/');
  }
}

export default new TournamentsDataService();
