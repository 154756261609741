import * as React from 'react';
import {
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import AreaDataService from '../../../../services/area.service';
import ConquestDataService from '../../../../services/conquest.service';
import FactionDataService from '../../../../services/faction.service';
import SettingDataService from '../../../../services/setting.service';
import { AreasStateType } from '../../../../types/area.type';
import { ConquestsStateType } from '../../../../types/conquest.type';
import { FactionsStateType } from '../../../../types/faction.type';

const Deployment = () => {
  const [currentConquest, setCurrentConquest] = React.useState(0);

  let count = 0;
  let countItems = 0;
  let countFactionItems = 0;
  const [filteredArea, setFilteredArea] = React.useState({
    id_area: 0,
    name: '',
    ratio: '',
    numDeployNFTs: 0,
    NFTsPower: '',
    NFTsPowerPercent: '',
    numDeployItems: 0,
    totalPower: ''
  });

  const [idArea, setIdArea] = React.useState(0);
  const [idFaction, setIdFaction] = React.useState(1);
  const [factionBonus, setFactionBonus] = React.useState(false);

  const [factionsInfos, setFactionsInfos] = React.useState<FactionsStateType>({
    factions: [],
    message: '',
    status: ''
  });
  const [conquestInfos, setConquestInfos] = React.useState<ConquestsStateType>({
    conquests: [],
    message: '',
    status: ''
  });
  const [AreasInfos, setAreasInfos] = React.useState<AreasStateType>({
    areas: [],
    message: '',
    status: ''
  });

  const handleFilterArea = (id_area: any) => {
    let areaFilteredName = '';
    AreasInfos.areas.map((area) => {
      if (area.id_area == id_area) {
        areaFilteredName = area.name;
      }
    });

    const areaPower = new Array(factionsInfos.factions.length);
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      areaPower[i] = 0;
    }
    conquestInfos.conquests.map((conquest) => {
      if (conquest.nft_type == 1) {
        if (conquest.attribut != null) {
          areaPower[conquest.id_faction - 1] += conquest.attribut.nft_power;
        }
      }
    });

    let faction_power_max = 0;
    for (let i = 0; i < factionsInfos.factions.length; i++) {
      if (faction_power_max == 0 || areaPower[i] > faction_power_max) {
        faction_power_max = areaPower[i];
      }
    }
    let ratio = 0;
    if (areaPower[idFaction - 1] > 0) {
      ratio = faction_power_max / areaPower[idFaction - 1];
    } else {
      ratio = 1;
    }

    let NFTsPowerTotal = 0;
    let nbDeployNFTs = 0;
    let nbDeployItems = 0;
    let NFTsPower = 0;
    let NFTsPowerPercent = 0;
    let totalPower = 0;
    let bonus = false;
    let isBonus = false;
    let totalBonus = 0;
    conquestInfos.conquests.map((conquest) => {
      let available = true;
      if (id_area != 0 && conquest.id_area != id_area) {
        available = false;
      }
      if (conquest.id_faction == idFaction) {
        if (conquest.nft_type == 1) {
          if (conquest.attribut != null) {
            NFTsPowerTotal += conquest.attribut.nft_power;
          }
        }
      }
      if (available) {
        if (conquest.id_faction == idFaction) {
          if (conquest.nft_type == 1) {
            if (conquest.attribut != null) {
              nbDeployNFTs++;
              NFTsPower += conquest.attribut.nft_power;
              totalPower += conquest.attribut.nft_power * ratio;
            }
          } else {
            if (conquest.item?.item_type == 2) {
              nbDeployItems++;
              totalPower += conquest.item.item_power;
            }

            if (conquest.item?.item_type == 4) {
              bonus = true;
            }
          }
        }

        if (conquest.nft_type == 2 && conquest.item?.item_type == 4) {
          isBonus = true;
          if (conquest.item.bonus_value > 0) {
            if (conquest.id_faction == idFaction) {
              totalBonus += conquest.item.bonus_value;
            }
          } else {
            if (conquest.id_faction != idFaction) {
              totalBonus += conquest.item.bonus_value;
            }
          }
        }
      }
    });
    if (isBonus && totalBonus != 0) {
      totalPower += (totalPower * totalBonus) / 100;
    }
    const totalPowerRounded = totalPower.toFixed(2);

    const NFTsPowerRounded = NFTsPower.toFixed(2);
    if (NFTsPower > 0 && NFTsPowerTotal > 0) {
      NFTsPowerPercent = (NFTsPower / NFTsPowerTotal) * 100;
    }
    const NFTsPowerPercentRounded = NFTsPowerPercent.toFixed(2);

    setFactionBonus(bonus);

    setFilteredArea({
      id_area: id_area,
      name: areaFilteredName,
      ratio: ratio.toFixed(6),
      numDeployNFTs: nbDeployNFTs,
      NFTsPower: NFTsPowerRounded,
      NFTsPowerPercent: NFTsPowerPercentRounded,
      numDeployItems: nbDeployItems,
      totalPower: totalPowerRounded
    });
  };

  const fetchSettingData = () => {
    const params: any = {};
    params['name'] = 'conquest';

    // Fetch of the current conquest in the parameters
    SettingDataService.findByName(params).then(({ data }) => {
      if (data.status == 'success') {
        setCurrentConquest(data.setting.value);
      }
    });
  };

  const fetchFactionsData = () => {
    FactionDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setFactionsInfos({
          factions: data.factions,
          status: data.status
        });
        setIdFaction(1);
      }
    });
  };

  const fetchConquestData = () => {
    ConquestDataService.findAllByConquest(currentConquest).then(({ data }) => {
      if (data.status == 'success') {
        setConquestInfos({
          conquests: data.conquests,
          status: data.status
        });
      }
    });
  };

  const fetchAreasData = () => {
    AreaDataService.getAll().then(({ data }) => {
      if (data.status == 'success') {
        setAreasInfos({
          areas: data.areas,
          status: data.status
        });
      }
    });
  };

  React.useEffect(fetchSettingData, []);

  React.useEffect(() => {
    if (currentConquest != 0) {
      fetchAreasData();
      fetchFactionsData();
      fetchConquestData();
    }
  }, [currentConquest]);

  React.useEffect(() => {
    if (
      AreasInfos.status == 'success' &&
      factionsInfos.status == 'success' &&
      conquestInfos.status == 'success'
    ) {
      handleFilterArea(idArea);
    }
  }, [AreasInfos, factionsInfos, conquestInfos, idFaction, idArea]);

  const { factions } = factionsInfos;
  const { conquests } = conquestInfos;
  const { areas } = AreasInfos;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContent'>
            <div className='card-body text-center p-2'>
              <div className='titleContent'>
                <h3 className='py-2'>
                  <span className='mr-1 fantasy'>
                    Conquest #{currentConquest}
                    <br />
                    Deployment Board
                  </span>
                </h3>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              {factions.map((faction, i) => {
                if (idFaction == faction.id_faction) {
                  return (
                    <Button
                      className='navStandardSmallReverse'
                      key={i}
                      onClick={() => setIdFaction(faction.id_faction)}
                    >
                      {faction.name}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      className='navStandardSmall'
                      key={i}
                      onClick={() => setIdFaction(faction.id_faction)}
                    >
                      {faction.name}
                    </Button>
                  );
                }
              })}
            </div>
          </div>

          {factionBonus && (
            <div className='boxContent'>
              <div className='card-body p-2'>
                <div className='titleContent'>
                  <h3 className='py-2'>
                    <span className='mr-1 fantasy'>Faction Bonuses</span>
                  </h3>
                </div>
              </div>
              <div className='card-body text-center p-2'>
                {conquests.map((conquest, i) => {
                  if (
                    conquest.nft_type == 2 &&
                    conquest.id_faction == idFaction &&
                    conquest.item?.item_type == 4
                  ) {
                    let bonusLabel = '';
                    let bonusType = '';
                    let bonusValue = '';
                    let iconName = faArrowCircleRight;
                    let colorName = '';

                    const itemName = conquest.item.item_name;
                    if (conquest.item.bonus_value > 0) {
                      bonusLabel = 'Faction Power';
                      bonusType = 'increased';
                      bonusValue = '+' + conquest.item.bonus_value + '%';
                      colorName = 'bonusUp';
                      iconName = faArrowCircleUp;
                    } else {
                      bonusLabel = 'Enemy Power';
                      bonusType = 'decreased';
                      bonusValue = conquest.item.bonus_value + '%';
                      colorName = 'bonusDown';
                      iconName = faArrowCircleDown;
                    }

                    return (
                      <div key={i}>
                        <span className='fantasy'>{itemName}</span>
                        &nbsp;deployed in the&nbsp;
                        <span className='fantasy'>{conquest.area?.name}</span>
                        &nbsp;! The {bonusLabel} in this area will be&nbsp;
                        {bonusType} by&nbsp;
                        <span className='fantasy'>
                          <span className={colorName}>
                            <FontAwesomeIcon icon={iconName} />
                            &nbsp;{bonusValue}
                          </span>
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

          <div className='boxContent'>
            <div className='card-body p-2'>
              <div className='titleContent'>
                <h3 className='py-2'>
                  <span className='mr-1 fantasy'>Deployment</span>
                </h3>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              {filteredArea.id_area == 0 ? (
                <Button
                  className='navStandardSmallReverse'
                  onClick={() => setIdArea(0)}
                >
                  All
                </Button>
              ) : (
                <Button
                  className='navStandardSmall'
                  onClick={() => setIdArea(0)}
                >
                  All
                </Button>
              )}
              {areas.map((area, i) => {
                let visible = false;
                switch (area.id_area) {
                  case 1:
                  case 2:
                  case 3:
                    visible = false;
                    break;
                  default:
                    visible = true;
                    break;
                }

                if (visible) {
                  if (filteredArea.id_area == area.id_area) {
                    return (
                      <Button
                        className='navStandardSmallReverse'
                        key={i}
                        onClick={() => setIdArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        className='navStandardSmall'
                        key={i}
                        onClick={() => setIdArea(area.id_area)}
                      >
                        {area.name}
                      </Button>
                    );
                  }
                }
              })}
            </div>
            <div className='card-body p-2'>
              <div className='dataContainer'>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs deployed</span>
                  <span className='dataAttribut'>
                    {filteredArea.numDeployNFTs}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>NFTs power</span>
                  <span className='dataAttribut'>
                    {filteredArea.NFTsPower}
                    &nbsp;({filteredArea.NFTsPowerPercent} %)
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Items deployed</span>
                  <span className='dataAttribut'>
                    {filteredArea.numDeployItems}
                  </span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Balancing ratio</span>
                  <span className='dataAttribut'>{filteredArea.ratio}</span>
                </div>
                <div className='dataSepartorLine'>
                  <span className='dataTitle'>Total power</span>
                  <span className='dataAttribut'>
                    {filteredArea.totalPower}
                  </span>
                </div>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              <div className='table-responsive'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0'>User</th>
                      <th className='border-0'>Name</th>
                      <th className='border-0'>Power</th>
                      <th className='border-0'>Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conquests.map((conquest, i) => {
                      let available = false;

                      if (
                        conquest.nft_type == 1 &&
                        conquest.id_faction == idFaction
                      ) {
                        available = true;

                        if (
                          filteredArea.id_area != 0 &&
                          conquest.id_area != filteredArea.id_area
                        ) {
                          available = false;
                        }
                      }
                      if (available) count++;

                      if (available) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{conquest.user?.pseudo}</span>
                            </td>
                            <td>
                              <span>{conquest.attribut?.nft_name}</span>
                            </td>
                            <td>
                              <span>
                                {conquest.attribut?.nft_power.toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span>{conquest.area?.name}</span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {count == 0 && (
                      <tr className='rowTableContent textResult'>
                        {filteredArea.id_area == 0 ? (
                          <td colSpan={4}>
                            <span>No NFT deployed</span>
                          </td>
                        ) : (
                          <td colSpan={4}>
                            <span>
                              No NFT deployed to the&nbsp;
                              {filteredArea.name}
                            </span>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              <h5 className='py-2'>
                <span className='mr-1 textBoldBig'>Player items</span>
              </h5>
              <div className='table-responsive'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0'>User</th>
                      <th className='border-0'>Name</th>
                      <th className='border-0'>Power</th>
                      <th className='border-0'>Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conquests.map((conquest, i) => {
                      let available = false;

                      if (
                        conquest.nft_type == 2 &&
                        conquest.id_faction == idFaction &&
                        conquest.item?.item_type == 2
                      ) {
                        available = true;

                        if (
                          filteredArea.id_area != 0 &&
                          conquest.id_area != filteredArea.id_area
                        ) {
                          available = false;
                        }

                        const identifier = conquest.nft_identifier.split('-');

                        if (identifier[1].substring(0, 1) == 'f') {
                          available = false;
                        }
                      }
                      if (available) countItems++;

                      if (available) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{conquest.user?.pseudo}</span>
                            </td>
                            <td>
                              <span>{conquest.item?.item_name}</span>
                            </td>
                            <td>
                              <span>
                                {conquest.item?.item_power.toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span>{conquest.area?.name}</span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {countItems == 0 && (
                      <tr className='rowTableContent textResult'>
                        {filteredArea.id_area == 0 ? (
                          <td colSpan={4}>
                            <span>No item deployed</span>
                          </td>
                        ) : (
                          <td colSpan={4}>
                            <span>
                              No item deployed to the&nbsp;
                              {filteredArea.name}
                            </span>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-body text-center p-2'>
              <h5 className='py-2'>
                <span className='mr-1 textBoldBig'>Faction items</span>
              </h5>
              <div className='table-responsive'>
                <table className='transactions table pb-3'>
                  <thead>
                    <tr className='headTable'>
                      <th className='border-0'>User</th>
                      <th className='border-0'>Name</th>
                      <th className='border-0'>Power</th>
                      <th className='border-0'>Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conquests.map((conquest, i) => {
                      let available = false;

                      if (
                        conquest.nft_type == 2 &&
                        conquest.id_faction == idFaction &&
                        conquest.item?.item_type == 2
                      ) {
                        available = true;

                        if (
                          filteredArea.id_area != 0 &&
                          conquest.id_area != filteredArea.id_area
                        ) {
                          available = false;
                        }

                        const identifier = conquest.nft_identifier.split('-');

                        if (identifier[1].substring(0, 1) != 'f') {
                          available = false;
                        }
                      }
                      if (available) countFactionItems++;

                      if (available) {
                        return (
                          <tr key={i} className='rowTableContent textResult'>
                            <td>
                              <span>{conquest.user?.pseudo}</span>
                            </td>
                            <td>
                              <span>{conquest.item?.item_name}</span>
                            </td>
                            <td>
                              <span>
                                {conquest.item?.item_power.toFixed(2)}
                              </span>
                            </td>
                            <td>
                              <span>{conquest.area?.name}</span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {countFactionItems == 0 && (
                      <tr className='rowTableContent textResult'>
                        {filteredArea.id_area == 0 ? (
                          <td colSpan={4}>
                            <span>No item deployed</span>
                          </td>
                        ) : (
                          <td colSpan={4}>
                            <span>
                              No item deployed to the&nbsp;
                              {filteredArea.name}
                            </span>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deployment;
