import http from '../http-common';
import {
  IFactionItemCreationData,
  FactionInventoryStateType
} from '../types/factionsinventories.type';

class FactionsInventoriesDataService {
  create(data: IFactionItemCreationData) {
    return http.post<IFactionItemCreationData>('/factions_inventories', data);
  }

  update(data: any, identifier: any) {
    return http.put<any>(`/factions_inventories/${identifier}`, data);
  }

  updateAllByIdShop(data: any, id_shop: any) {
    return http.put<any>(`/factions_inventories/shop/${id_shop}`, data);
  }

  findAllByFaction(id_faction: any) {
    return http.get<FactionInventoryStateType>(
      `/factions_inventories/faction/${id_faction}`
    );
  }
}

export default new FactionsInventoriesDataService();
