import * as React from 'react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import discord_logo from './../../assets/img/discord.svg';

import './../../assets/sass/maintenance.scss';

const Maintenance = () => {
  const discord_link = 'https://discord.gg/7B5ZuPmFjV';

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <div className='boxContainer topMaintenanceContainer'>
            <div className='card-body text-center p-2'>
              <div className='spinny-cogs'>
                <FontAwesomeIcon icon={faCog} className='fa' />
                <FontAwesomeIcon icon={faCog} className='fa fa-5x fa-spin' />
                <FontAwesomeIcon icon={faCog} className='fa fa-3x' />
              </div>
            </div>
            <div className='topContainer'>
              <div className='card-body text-center p-2'>
                <div className='pageTitleMaintenance'>
                  Maintenance in progress
                </div>
              </div>
            </div>
            <div className='topContainer'>
              <div className='card-body text-center p-2'>
                <p className='maintenanceText'>
                  You can join us on
                  <br />
                  <a href={discord_link} target='_blank' rel='noreferrer'>
                    <img src={discord_logo} width='32px' />
                    &nbsp;
                    <span className='fantasy'>Discord</span>
                  </a>
                  <br />
                  to get the latest updates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
