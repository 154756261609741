export interface IItemInfosData {
  id_item: number;
  item_type: number;
  item_identifier: string;
  item_name: string;
  item_power: number;
  item_rarity: string;
  item_rarityColor: string;
  item_img: string;
  flg_use: number;
  bonus_value: number;
  item_quantity: number;
  item_cost: number;
  token_identifier: string;
}

export interface IFactionItemCreationData {
  id?: any | null;
  id_faction: number;
  id_item: number;
  identifier: string;
  flg_used: boolean;
  flg_delivered: boolean;
}

export interface IFactionInventoryData {
  id?: any | null;
  id_faction: number;
  id_item: number;
  identifier: string;
  flg_used: boolean;
  flg_delivered: boolean;
  item: IItemInfosData;
}

export interface FactionInventoryStateType {
  inventory: IFactionInventoryData[];
  message?: string;
  status: string;
}

export const itemEmpty: IItemInfosData = {
  id_item: 0,
  item_type: 0,
  item_identifier: '',
  item_name: '',
  item_power: 0,
  item_rarity: '',
  item_rarityColor: '',
  item_img: '',
  flg_use: 0,
  bonus_value: 0,
  item_quantity: 0,
  item_cost: 0,
  token_identifier: ''
};

export const factionInventoryEmpty: IInventoryInfos = {
  id_item: 0,
  img: '',
  itemInfos: itemEmpty,
  itemsAvailableCount: 0,
  itemsAvailable: []
};

export interface IInventoryInfos {
  id_item: number;
  img: string;
  itemInfos: IItemInfosData;
  itemsAvailableCount: number;
  itemsAvailable: IItemsData[];
}

export interface IItemsData {
  identifier: string;
}

export interface FactionInventoryInfos {
  factionInventory: IInventoryInfos[];
}
